import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

import type { TransitionStatus } from 'react-transition-group';

type ThemeProps = ThemeFunction<{
  transitionState: TransitionStatus;
}>;

const getTransformation = (transitionState: TransitionStatus) => {
  if (transitionState === 'entering') {
    return {
      transform: 'translateX(35px)',
      opacity: 0,
    };
  }

  if (transitionState === 'entered') {
    return {
      transform: 'translateX(0)',
      opacity: 1,
    };
  }

  if (transitionState === 'exiting') {
    return {
      transform: 'translateX(-35px)',
      opacity: 0,
    };
  }

  return {
    transform: 'translateX(35px)',
    opacity: 0,
  };
};

export default {
  root: ({ transitionState }: ThemeProps) => ({
    width: '100%',
    transition: '250ms ease-in-out',
    ...getTransformation(transitionState),
  }),
};
