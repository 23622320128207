import * as types from 'src/shared/app/authentication/action/support/uiSupportActionTypes';
import {
  passwordSupportContactSection,
  passwordSupportLinkSection,
  passwordSupportResetSection,
} from 'src/shared/app/authentication/constant/supportConstants';
import { createReducer } from 'src/shared/core/util/reducerUtils';

/*
|------------------------------------------------------------------------------
| INITIAL STATE
|------------------------------------------------------------------------------
*/
const initialState = {
  passwordSection: null,
};

/*
|------------------------------------------------------------------------------
| REDUCERS
|------------------------------------------------------------------------------
*/
const _resetPasswordSupportSection = (
  state: Record<string, any>,
): Record<string, any> => ({ ...state, passwordSection: null });

const _showPasswordSupportLinkSection = (
  state: Record<string, any>,
): Record<string, any> => ({
  ...state,
  passwordSection: passwordSupportLinkSection,
});

const _showPasswordSupportResetSection = (
  state: Record<string, any>,
): Record<string, any> => ({
  ...state,
  passwordSection: passwordSupportResetSection,
});

const _showPasswordSupportContactSection = (
  state: Record<string, any>,
): Record<string, any> => ({
  ...state,
  passwordSection: passwordSupportContactSection,
});
/*
|------------------------------------------------------------------------------
| REDUCER
|------------------------------------------------------------------------------
*/

export default createReducer(initialState, {
  [types.RESET_PASSWORD_SUPPORT_SECTION]: _resetPasswordSupportSection,
  [types.SHOW_PASSWORD_SUPPORT_LINK_SECTION]: _showPasswordSupportLinkSection,
  [types.SHOW_PASSWORD_SUPPORT_RESET_SECTION]: _showPasswordSupportResetSection,
  [types.SHOW_PASSWORD_SUPPORT_CONTACT_SECTION]:
    _showPasswordSupportContactSection,
});
