import { useContext } from 'react';

import VirtualBraindateZoomContext from 'src/shared/app/base/plugin/virtual-braindate/providers/zoom/contexts/VirtualBraindateZoomContext';

export default function useZoomVideoContext() {
  const context = useContext(VirtualBraindateZoomContext);
  if (!context) {
    throw new Error(
      'useZoomVideoContext must be used within a VirtualBraindateZoomContext',
    );
  }
  return context;
}
