import { combineReducers } from 'redux';

import uiAccountReducer from 'src/shared/app/account/base/reducer/uiAccountReducer';
import uiAuthenticationBaseReducer from 'src/shared/app/authentication/reducer/base/uiAuthenticationBaseReducer';
import uiAuthenticationSupportReducer from 'src/shared/app/authentication/reducer/support/uiAuthenticationSupportReducer';
import modalReducer from 'src/shared/app/base/modal/slice/modalSlice';
import portalRegistrationSlice from 'src/shared/app/base/portal/notification/slice/portalRegistrationSlice';
import uiBookingReducer from 'src/shared/app/booking/reducer/uiBookingReducer';
import braindateReducer from 'src/shared/app/braindate/reducer/uiBraindateReducer';
import uiChatReducer from 'src/shared/app/chat/reducer/uiChatReducer';
import cheerSlice from 'src/shared/app/cheer/reducer/cheerSlice';
import commentsSlice from 'src/shared/app/comments/reducer/commentsSlice';
import uiConversationReducer from 'src/shared/app/conversation/reducer/uiConversationReducer';
import uiHelpButtonReducer from 'src/shared/app/help/reducer/uiHelpButtonReducer';
import uiInterestReducer from 'src/shared/app/interest/reducer/uiInterestReducer';
import marketReducer from 'src/shared/app/market/reducer/uiMarketReducer';
import peopleReducer from 'src/shared/app/market/reducer/uiPeopleSlice';
import navSliceReducer from 'src/shared/app/nav/slice/navSlice';
import uiNotificationReducer from 'src/shared/app/notification/reducer/uiNotificationReducer';
import uiOnboardingReducer from 'src/shared/app/onboarding/reducer/uiOnboardingReducer';
import uiSignupReducer from 'src/shared/app/signup/reducer/uiSignupReducer';
import uiNewTopicReducer from 'src/shared/app/topic/new/reducer/uiTopicReducer';

import virtualBraindateReducer from 'plugin/virtual-braindate/slice/virtualBraindateSlice';

const uiReducer = combineReducers({
  account: uiAccountReducer,
  authentication: uiAuthenticationBaseReducer,
  braindates: braindateReducer,
  chat: uiChatReducer,
  cheer: cheerSlice,
  comments: commentsSlice,
  conversation: uiConversationReducer,
  helpButton: uiHelpButtonReducer,
  interest: uiInterestReducer,
  modal: modalReducer,
  market: marketReducer,
  people: peopleReducer,
  notification: uiNotificationReducer,
  onboarding: uiOnboardingReducer,
  signup: uiSignupReducer,
  support: uiAuthenticationSupportReducer,
  newTopic: uiNewTopicReducer,
  booking: uiBookingReducer,
  virtualBraindate: virtualBraindateReducer,
  portalRegistration: portalRegistrationSlice,
  nav: navSliceReducer,
});
export default uiReducer;
