import { memo } from 'react';
import type { Node } from 'react';

import FormBuilderCheckbox from './fields/FormBuilderCheckbox';
import FormBuilderCheckboxList from './fields/FormBuilderCheckboxList';
import FormBuilderRating from './fields/FormBuilderRating';
import FormBuilderText from './fields/FormBuilderText';

import type { Field } from './type/formBuilderTypes';

const componentsList = {
  checkbox_list: FormBuilderCheckboxList,
  rating: FormBuilderRating,
  checkbox: FormBuilderCheckbox,
  text: FormBuilderText,
};

const FormBuilderField = ({
  field,
  type,
  name,
  'aria-describedby': ariaDescribedby,
}: Field): Node => {
  const Component = componentsList[type];
  if (!Component) return null;
  return (
    <Component
      field={field}
      type={type}
      name={name}
      aria-describedby={ariaDescribedby}
    />
  );
};

export default memo<Field>(FormBuilderField);
