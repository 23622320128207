import { memo, useEffect, useRef, useState } from 'react';
import type { Node } from 'react';

import classNames from 'classnames';
import { useButton, useKeyboard } from 'react-aria';
import { useIntl } from 'react-intl';

import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import Tour from 'src/shared/app/coachmark/component/Tour';
import {
  TOUR_SELECTOR_KEYS,
  tours,
} from 'src/shared/app/coachmark/config/tourConfig';
import DismissButton from 'src/shared/ui/component/button/DismissButton';
import messages from 'src/shared/ui/component/form/l10n/searchL10n';
import MagnifierIcon from 'src/shared/ui/component/icon/MagnifierIcon';

import styles from './SearchInput.style';

type Props = {
  id: string;
  name: string;
  tabIndex: number;
  placeholder: string;
  defaultValue?: string;
  disableTour?: boolean;
  onDismiss: () => void;
  onChange: (query: string) => void;
  onSubmit: (query: string) => void;
};
const useStyles = createUseThemeStyles(styles);

const SearchInput = ({
  id,
  name,
  tabIndex,
  placeholder,
  defaultValue,
  onDismiss,
  onChange,
  onSubmit,
  disableTour = false,
}: Props): Node => {
  const intl = useIntl();
  const [value, setValue] = useState(defaultValue || '');
  const classes = useStyles({
    value: !!value,
  });
  const inputRef = useRef<HTMLElement | null>(null);
  useEffect(() => {
    if (defaultValue || defaultValue === '') {
      setValue(defaultValue);
    }
  }, [defaultValue]);
  const { keyboardProps } = useKeyboard({
    onKeyUp: (e) => {
      if (e.key === 'Escape') {
        e.currentTarget.blur();
        onDismiss();
      } else {
        e.continuePropagation();
      }
    },
  });

  const handleChange = (e: React.SyntheticEvent<HTMLInputElement, Event>) => {
    const input = e.currentTarget;
    setValue(input.value);

    if (onChange) {
      onChange(input.value);
    }
  };

  const handleSubmit = (e?: Event) => {
    e?.preventDefault();
    if (value === defaultValue) return;
    inputRef.current?.blur();

    if (onSubmit) {
      onSubmit(value);
    }
  };

  const { buttonProps } = useButton({
    onPress: () => handleSubmit(),
    elementType: 'span',
  });
  const dismissElt = !!defaultValue && (
    <span className={classes.dismiss}>
      <DismissButton
        onClick={onDismiss}
        tabIndex={tabIndex}
        isLarge
        isCircled={false}
      />
    </span>
  );
  const shouldRunTour = !!defaultValue && !disableTour;
  return (
    <>
      <Tour tour={tours.emptyMarket} run={shouldRunTour} />

      <form
        onSubmit={handleSubmit}
        className={classNames(
          classes.form,
          TOUR_SELECTOR_KEYS.MARKET_SEARCH_INPUT,
        )}
        {...keyboardProps}
      >
        <span
          data-testid="magnifier"
          className={classes.magnifier}
          aria-label={intl.formatMessage(messages.submit)}
          {...buttonProps}
          tabIndex={tabIndex}
        >
          <MagnifierIcon />
        </span>
        <input
          className={classes.input}
          id={id}
          data-testid={id}
          tabIndex={tabIndex}
          name={name}
          placeholder={value ? '' : placeholder}
          value={value}
          onChange={handleChange}
          onBlur={handleSubmit}
          ref={inputRef}
          autoComplete="off"
          aria-label={placeholder}
        />
        {dismissElt}
      </form>
    </>
  );
};

export default memo<Props>(SearchInput);
