import { createSelector } from '@reduxjs/toolkit';

import type { Notification } from 'src/shared/app/notification/type/notificationTypes';
import { getNotificationBundleType } from 'src/shared/app/notification/util/notificationUtil';

/*
|------------------------------------------------------------------------------
| INPUTS
|------------------------------------------------------------------------------
*/
const _segment = (state) => state.ui.notification;

const _getNotificationQueue = (state) => _segment(state).queue;

/*
|------------------------------------------------------------------------------
| SELECTORS
|------------------------------------------------------------------------------
*/
export const getNotificationQueue: (state: Record<string, any>) => any =
  createSelector([_getNotificationQueue], (x: any): any => x);
export const getNotificationQueueOfBundleType: (
  state: Record<string, any>,
) => any = (originalNotification: Notification<any>) =>
  createSelector([_getNotificationQueue], (notifications: any): any =>
    getNotificationBundleType(originalNotification)
      ? notifications.filter(
          (notification) =>
            getNotificationBundleType(notification) ===
            getNotificationBundleType(originalNotification),
        )
      : [],
  );
