import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';

import {
  resetMarketFilters,
  resetMarketSortOrdering,
} from 'src/shared/app/market/action/marketActions';
import type { PossibleMarketFilters } from 'src/shared/app/market/type/marketFiltersType';
import { syncFiltersToURL } from 'src/shared/app/market/util/marketUtils';

export const defaultMarketFilters: PossibleMarketFilters = {
  kindFilter: [],
  keyword: '',
  languageFilter: [],
  dateFilter: null,
  canJoinFilter: false,
  locationFilter: [],
  conversationTypeFilter: [],
  participantCategoriesFilter: [],
};

const useMarketFiltersReset = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  return () => {
    dispatch(resetMarketSortOrdering());
    dispatch(resetMarketFilters());
    syncFiltersToURL(location, navigate, {
      ...defaultMarketFilters,
      sortValue: '',
    });
  };
};

export default useMarketFiltersReset;
