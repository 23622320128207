import { useIntl } from 'react-intl';

import {
  getEventCheerModalSponsors,
  getEventSponsorLogo,
  getEventSponsorLogoAltText,
} from '@braindate/domain/lib/event/util';

import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import messages from 'src/shared/app/cheer/l10n/cheerl10n';
import useEvent from 'src/shared/domain/event/hook/useEvent';
import ResponsivePicture from 'src/shared/ui/component/image/ResponsivePicture';

import styles from './CheerModalSponsors.style';

const useStyles = createUseThemeStyles(styles);

const CheerModalSponsors = () => {
  const intl = useIntl();
  const event = useEvent();
  const classes = useStyles();
  const sponsors = getEventCheerModalSponsors(event);
  if (!sponsors.length) return null;
  return (
    <div className={classes.root}>
      <p>{intl.formatMessage(messages.sponsoredBy)}</p>
      <div className={classes.sponsorsList}>
        {sponsors.map((sponsor, key) => (
          <ResponsivePicture
            className={classes.logo}
            picture={getEventSponsorLogo(sponsor)}
            alt={getEventSponsorLogoAltText(sponsor, intl.locale)}
            displayWidth={130}
            provideFallback
            key={key}
          />
        ))}
      </div>
    </div>
  );
};

export default CheerModalSponsors;
