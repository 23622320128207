import { memo, useRef } from 'react';
import type { Node } from 'react';

import { useId } from 'react-aria';
import { useIntl } from 'react-intl';

import { getUserFullName } from '@braindate/domain/lib/user/util';

import useRoutes from 'src/shared/app/base/route/hook/useRoutes';
import { profileRoute } from 'src/shared/app/base/route/setting/routeSettings';
import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import DesktopDrawerMenuItem from 'src/shared/app/nav/component/drawermenu/DesktopDrawerMenuItem';
import type { DrawerMenuItemType } from 'src/shared/app/nav/component/drawermenu/DrawerMenu';
import DrawerMenuButton from 'src/shared/app/nav/component/drawermenu/DrawerMenuButton';
import messages from 'src/shared/app/nav/l10n/navigationL10n.json';
import UserAvatar, {
  xsmall,
} from 'src/shared/components/domain/user/avatar/UserAvatar';
import useIsPathActive from 'src/shared/core/hooks/useIsPathActive';
import useSelf from 'src/shared/domain/user/hook/useSelf';
import MenuPopover from 'src/shared/ui/component/menu/MenuPopover';
import MenuPopoverItem from 'src/shared/ui/component/menu/MenuPopoverItem';

import styles from './DesktopDrawerMenu.style';

type Props = {
  menuItems: DrawerMenuItemType[];
  isMenuOpened: boolean;
  setIsMenuOpened: (newValue: boolean) => void;
};
const useStyles = createUseThemeStyles(styles);

const DrawerMenu = ({
  menuItems,
  isMenuOpened,
  setIsMenuOpened,
}: Props): Node => {
  const intl = useIntl();
  const self = useSelf();
  const menuId = useId();
  const anchorEl = useRef();
  const classes = useStyles();
  const [profilePath] = useRoutes(profileRoute);
  const isActive = useIsPathActive(profilePath);
  return (
    <>
      <DrawerMenuButton
        ref={anchorEl}
        isMenuOpened={isMenuOpened}
        setIsMenuOpened={setIsMenuOpened}
      />

      <MenuPopover
        id={menuId}
        anchorEl={anchorEl.current && anchorEl.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        getContentAnchorEl={null}
        open={isMenuOpened}
        onClose={() => setIsMenuOpened(false)}
      >
        {self ? (
          <MenuPopoverItem
            icon={<UserAvatar user={self} variant={xsmall} />}
            title={getUserFullName(self)}
            subtitle={intl.formatMessage(messages.myProfile)}
            className={classes.profile}
            to={profilePath}
            isActive={isActive}
            hasDivider
          />
        ) : null}

        {menuItems
          .filter((menuItem) => !menuItem.mobileOnly)
          .map((menuItem, key) => (
            <DesktopDrawerMenuItem menuItem={menuItem} key={key} />
          ))}
      </MenuPopover>
    </>
  );
};

export default memo<Props>(DrawerMenu);
