import { useEffect, useRef, useState } from 'react';

import { v4 as uuidv4 } from 'uuid';

import useAuthenticationToken from 'src/shared/app/authentication/hook/useAuthenticationToken';
import useRefState from 'src/shared/app/base/hook/useRefState';

export default function useSocket(
  url: string | null | undefined,
  handleMessage: (arg0: Record<string, any>) => void,
  handleInit?: () => void,
  handleDestroy?: () => void,
) {
  const [loadingId, setLoadingId, loadingIdRef] = useRefState<string | boolean>(
    false,
  );
  const [isConnecting, setIsConnecting] = useState<boolean>(true);

  const handleLoadingId = ({ uuid }) => {
    // Uses ref because it is triggered by an event listener and is unsynced with the compoenent state
    if (loadingIdRef.current && uuid && uuid === loadingIdRef.current) {
      setLoadingId(false);
    }
  };

  const token = useAuthenticationToken();
  const socket = useRef<WebSocket | null>(null);
  let delay = 1000;
  let timeoutId;

  /*
   |----------------------------------------------------------------------------
   | Effects
   |----------------------------------------------------------------------------
   */
  useEffect(() => {
    if (url && token) {
      resetSocket();
    }

    return () => {
      closeSocket();
      destroySocket();

      if (timeoutId) {
        window.clearTimeout(timeoutId);
      }
    };
  }, [url, token]);

  /*
   |----------------------------------------------------------------------------
   | Event Handlers
   |----------------------------------------------------------------------------
   */
  const onMessage = ({ data }: Record<string, any>) => {
    const json = JSON.parse(String(data));
    handleLoadingId(json);
    handleMessage(json);
  };

  const onClose = ({ wasClean }: Record<string, any>) => {
    destroySocket();

    if (!wasClean) {
      setTimeout(
        () => {
          initSocket();
        },
        Math.min(delay, 300000),
      );
    }

    delay *= 2;
  };

  const onOpen = () => {
    setIsConnecting(false);
  };

  const onError = () => {};

  /*
   |----------------------------------------------------------------------------
   | Functions
   |----------------------------------------------------------------------------
   */
  const initSocket = () => {
    if (!socket.current && url && token) {
      timeoutId = window.setTimeout(() => {
        socket.current = new WebSocket(url, token.split(' '));

        if (socket.current) {
          socket.current.addEventListener('message', onMessage);
          // $FlowIssue
          socket.current.addEventListener('close', onClose);
          // $FlowIssue
          socket.current.addEventListener('error', onError);
          // $FlowIssue
          socket.current.addEventListener('open', onOpen);
        }

        setLoadingId(null);

        if (handleInit) {
          handleInit();
        } // FIXME: timeout is necessary because of BE issue
      }, 1000);
    }
  };

  const closeSocket = () => {
    if (socket.current) {
      socket.current.close();
    }
  };

  const resetSocket = () => {
    closeSocket();
    initSocket();
  };

  const destroySocket = () => {
    if (socket.current) {
      socket.current.removeEventListener('message', onMessage);
      // $FlowIssue
      socket.current.removeEventListener('close', onClose);
      // $FlowIssue
      socket.current.removeEventListener('error', onError);
      // $FlowIssue
      socket.current.removeEventListener('open', onOpen);
      socket.current = null;

      if (handleDestroy) {
        handleDestroy();
      }
    }
  };

  const generateUuid = (namespace) => `${namespace}-${uuidv4()}`;

  const handleSend = (
    namespace: string,
    data: Record<string, any>,
    ignoreLoading: boolean | null | undefined,
  ) => {
    const uuid = generateUuid(namespace);

    if (!ignoreLoading) {
      setLoadingId(uuid);
    }

    return socket.current?.send(JSON.stringify({ ...data, uuid }));
  };

  return {
    send: handleSend,
    isLoading: !!loadingId || isConnecting,
    isConnecting,
  };
}
