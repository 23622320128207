import messages from 'src/shared/app/base/component/data-entry/form-builder/l10n/formBuilderL10n';

import { feedbackKeys } from 'plugin/virtual-braindate/setting/virtualBraindateSettings';

const defaultFieldsData = [
  {
    key: 'rating',
    label_intl: messages.feedbackLabelRating,
    field_type: 'rating',
  },
  {
    key: 'which_of_the_following_did_you_experience_on_your_braindate',
    label_intl: messages.feedbackLabelExperience,
    choices: [
      {
        key: feedbackKeys.insight_check,
        label_intl: messages.feedbackLabelInsight,
      },
      {
        key: feedbackKeys.valuable_check,
        label_intl: messages.feedbackLabelValuable,
      },
      {
        key: feedbackKeys.professional_personnal_check,
        label_intl: messages.feedbackLabelProfessionalOrPersonnal,
      },
      {
        key: feedbackKeys.heard_and_supported_check,
        label_intl: messages.feedbackHeardAndSupported,
      },
      {
        key: feedbackKeys.nobenefits_check,
        label_intl: messages.feedbackLabelNobenefits,
        more: [
          {
            key: feedbackKeys.nobenefits_more,
            field_type: 'text',
            label_intl: messages.feedbackLabelNobenefitsMore,
          },
        ],
      },
      {
        key: feedbackKeys.other_check,
        label_intl: messages.feedbackLabelOther,
        more: [
          {
            key: feedbackKeys.other_more,
            field_type: 'text',
            label_intl: messages.feedbackLabelOtherMore,
          },
        ],
      },
    ],
    field_type: 'checkbox_list',
  },
  {
    key: feedbackKeys.more,
    label_intl: messages.feedbackLabelMore,
    field_type: 'text',
  },
];
export default defaultFieldsData;
