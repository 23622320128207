import { useIntl } from 'react-intl';

import messages from 'src/shared/components/domain/complaints/l10n/complaintsL10n';

const usePossibleComplaintReasons = () => {
  const intl = useIntl();
  const complaintReasons = [
    'spam',
    'inappropriate',
    'confidential',
    'deceptive',
    'false_information',
    'other',
  ];
  return complaintReasons.map((reason) => ({
    value: reason,
    label: intl.formatMessage(messages[reason]),
  }));
};

export default usePossibleComplaintReasons;
