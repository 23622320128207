import { rem } from 'polished';

import { baseFontSize } from '@braindate/ui/lib/token/typography';

import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

import avatarSizes from './setting/avatarSizes';

const styles = {
  root: {
    position: 'relative',
    borderRadius: '50%',
    display: 'flex',
    flexDirection: 'row',
    width: ({
      variant,
    }: ThemeFunction<{
      variant: string;
    }>) => avatarSizes[variant],
    height: ({
      variant,
    }: ThemeFunction<{
      variant: string;
    }>) => avatarSizes[variant],
  },
  avatars: {
    '& > picture': {
      width: ({
        variant,
      }: ThemeFunction<{
        variant: string;
      }>) => rem(avatarSizes[variant], baseFontSize),
    },
    display: 'flex',
    '& > picture, & > div': {
      marginLeft: -10,
      zIndex: 0,
      width: ({
        variant,
      }: ThemeFunction<{
        variant: string;
      }>) => rem(avatarSizes[variant], baseFontSize),
      '&:first-child': {
        marginLeft: 0,
      },
    },
  },
};
export default styles;
