import type { Node } from 'react';

import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { getEventName } from '@braindate/domain/lib/event/util';

import messages from 'src/shared/app/moderation/l10n/moderationL10n';
import { moderationPostNotificationTimeout } from 'src/shared/app/moderation/settings/moderationSettings';
import { dismissNotification } from 'src/shared/app/notification/action/uiNotificationActions';
import Notification from 'src/shared/app/notification/component/Notification';
import type { Notification as NotificationType } from 'src/shared/app/notification/type/notificationTypes';
import useEvent from 'src/shared/domain/event/hook/useEvent';

type Props = {
  notification: NotificationType<any>;
};

const ModerationPostNotification = ({ notification }: Props): Node => {
  const intl = useIntl();
  const event = useEvent();
  const dispatch = useDispatch();
  const eventName = getEventName(event, intl.locale);

  const handleDismiss = () => {
    dispatch(dismissNotification(notification));
  };

  return (
    <Notification
      timeout={moderationPostNotificationTimeout}
      withWarning
      handleDismiss={handleDismiss}
    >
      {intl.formatMessage(messages.moderationPostNotification, {
        eventName,
      })}
    </Notification>
  );
};

export default ModerationPostNotification;
