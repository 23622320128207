import { memo } from 'react';

import type { Braindate } from '@braindate/domain/lib/braindate/type';

import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import BraindateCardTags from 'src/shared/app/braindate/component/card/BraindateCardTags';

import styles from './UpcomingBraindateCardHeader.style';

const useStyles = createUseThemeStyles(styles);
type Props = {
  braindate: Braindate;
};

const UpcomingBraindateCardHeader = ({ braindate }: Props) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <BraindateCardTags braindate={braindate} />
    </div>
  );
};

export default memo<Props>(UpcomingBraindateCardHeader);
