import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import Modal from 'src/shared/app/base/modal/component/Modal';
import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import { dismissNotification } from 'src/shared/app/notification/action/uiNotificationActions';
import type { Notification as NotificationType } from 'src/shared/app/notification/type/notificationTypes';
import messages from 'src/shared/components/domain/user/modals/l10n/phoneReminderL10n';
import SMSReminderModule from 'src/shared/components/domain/user/modals/phone-reminder/SMSReminderModule';

import styles from './SMSPhoneReminderModal.style';

const useStyles = createUseThemeStyles(styles);
type Props = {
  notification: NotificationType<any>;
};

const SMSPhoneReminderModal = ({ notification }: Props) => {
  const intl = useIntl();
  const classes = useStyles();
  const dispatch = useDispatch();

  const dismiss = () => dispatch(dismissNotification(notification));

  return (
    <Modal
      width={550}
      title={intl.formatMessage(messages.title)}
      content={
        <SMSReminderModule
          onSubmit={dismiss}
          content={
            <p className={classes.text}>
              {intl.formatMessage(messages.modalDescription)}
            </p>
          }
        />
      }
      handleClose={dismiss}
      closeCTA={intl.formatMessage(messages.noThanks)}
    />
  );
};

export default SMSPhoneReminderModal;
