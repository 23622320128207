import { combineReducers } from 'redux';

import { apiRoot } from 'src/shared/app/base/api/apiRoot';

import appReducer from './appReducer';
import domainReducer from './domainReducer';
import uiReducer from './uiReducer';

export default combineReducers({
  app: appReducer,
  domain: domainReducer,
  ui: uiReducer,
  [apiRoot.reducerPath]: apiRoot.reducer,
});
