import { $Shape } from 'utility-types';

import type { Complaint } from '@braindate/domain/lib/complaint/type';

import { TAG_TYPE } from 'src/shared/app/base/api/apiConstant';
import { apiRoot } from 'src/shared/app/base/api/apiRoot';
import type { UseMutation } from 'src/shared/app/base/api/type/apiQueryType';
import { complaintsEndpoint } from 'src/shared/app/base/settings/endpointSettings';

type Api = {
  usePostComplaintMutation: UseMutation<$Shape<Complaint>, Complaint>;
};
const extendedApi: Api = apiRoot.injectEndpoints({
  endpoints: (builder) => ({
    postComplaint: builder.mutation({
      query: ({
        content_type,
        object_id,
        reason,
        more,
      }: $Shape<Complaint>) => ({
        url: complaintsEndpoint(),
        method: 'POST',
        params: {
          content_type,
          object_id,
          reason,
          more,
        },
      }),
      invalidatesTags: [
        {
          type: TAG_TYPE.COMPLAINT,
        },
      ],
    }),
  }),
});
export const { usePostComplaintMutation } = extendedApi;
