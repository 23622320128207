import { combineReducers } from 'redux';

import appAuthenticationBaseReducer from 'src/shared/app/authentication/reducer/base/appAuthenticationBaseReducer';
import appAuthenticationSupportReducer from 'src/shared/app/authentication/reducer/support/appAuthenticationSupportReducer';
import appDeviceReducer from 'src/shared/app/base/reducer/appDeviceReducer';
import appEnvReducer from 'src/shared/app/base/reducer/appEnvReducer';
import appEventReducer from 'src/shared/app/base/reducer/appEventReducer';
import appMembershipReducer from 'src/shared/app/base/reducer/appMembershipReducer';
import appPluginsReducer from 'src/shared/app/base/reducer/appPluginsReducer';
import appBraindatesReducer from 'src/shared/app/braindate/reducer/appBraindateReducer';
import quickActionReducer from 'src/shared/app/braindate/reducer/quickActionReducer';
import integrationReducer from 'src/shared/app/integration/reducer/integrationReducer';
import * as interestNamespaces from 'src/shared/app/interest/action/interestActionNamespaces';
import interestReducer from 'src/shared/app/interest/reducer/interestReducer';
import appLocaleReducer from 'src/shared/app/locale/reducer/appLocaleReducer';
import marketReducer from 'src/shared/app/market/reducer/marketReducer';
import appNotificationsReducer from 'src/shared/app/notification/reducer/appNotificationsReducer';
import perfReducer from 'src/shared/app/perf/reducer/perfReducer';
import appPermissionReducer from 'src/shared/app/permission/reducer/appPermissionReducer';
import appSignupReducer from 'src/shared/app/signup/reducer/appSignupReducer';
import { createFilteredReducer } from 'src/shared/core/util/reducerUtils';
import appLocationReducer from 'src/shared/domain/location/reducer/appLocationsReducer';

const appReducer = combineReducers({
  authentication: appAuthenticationBaseReducer,
  braindates: appBraindatesReducer,
  device: appDeviceReducer,
  env: appEnvReducer,
  event: appEventReducer,
  integration: integrationReducer,
  interest: createFilteredReducer(
    interestReducer,
    (action) => action.namespace === interestNamespaces.INTEREST_KEYWORDS,
  ),
  interestAutocomplete: createFilteredReducer(
    interestReducer,
    (action) => action.namespace === interestNamespaces.INTEREST_AUTOCOMPLETE,
  ),
  language: appLocaleReducer,
  location: appLocationReducer,
  market: marketReducer,
  membership: appMembershipReducer,
  notifications: appNotificationsReducer,
  perf: perfReducer,
  quickAction: quickActionReducer,
  signup: appSignupReducer,
  support: appAuthenticationSupportReducer,
  plugins: appPluginsReducer,
  permission: appPermissionReducer,
});
export default appReducer;
