import MuiAvatar from '@mui/material/Avatar';
import { useTheme } from 'react-jss';

import type { User } from '@braindate/domain/lib/user/type';
import {
  getUserFirstName,
  getUserLastName,
} from '@braindate/domain/lib/user/util';

import type { AvatarProps } from 'src/shared/ui/component/image/type/avatarTypes';
import { getAvatarSizeFromVariant } from 'src/shared/ui/component/image/util/avatarUtils';

type Props = {
  user: User;
} & AvatarProps;

const InitialsAvatar = ({ user, variant, customSize, alt }: Props) => {
  const theme = useTheme();
  const firstName = getUserFirstName(user);
  const lastName = getUserLastName(user);
  const initials =
    firstName.charAt(0).toUpperCase() + lastName.charAt(0).toUpperCase();
  const size =
    customSize || (variant ? getAvatarSizeFromVariant(variant) : null);
  return (
    <MuiAvatar
      sx={{
        bgcolor: theme.accentColor,
        color: theme.colorBase,
        ...(size
          ? {
              width: size,
              height: size,
              fontSize: size / 2,
            }
          : {}),
      }}
      alt={alt}
    >
      {initials}
    </MuiAvatar>
  );
};

export default InitialsAvatar;
