import { memo } from 'react';
import type { Node } from 'react';

import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import useIsErrorReportingEnabled from 'src/shared/app/base/hook/useIsErrorReportingEnabled';
import messages from 'src/shared/app/base/l10n/errorL10n';
import useRoutes from 'src/shared/app/base/route/hook/useRoutes';
import { supportRoute } from 'src/shared/app/base/route/setting/routeSettings';
import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import LazySupportMain from 'src/shared/app/support/component/LazySupportMain';

import ErrorScreen from './ErrorScreen';
import styles from './ErrorScreen.style';

type Props = {
  sentryId: string | null | undefined;
  error: Error;
  errorInfo: Record<string, any>;
};
const useStyles = createUseThemeStyles(styles);

const Error500Dumb = ({ sentryId, error, errorInfo }: Props): Node => {
  const isErrorReportingEnabled = useIsErrorReportingEnabled();
  const intl = useIntl();
  const [supportPath] = useRoutes(supportRoute);

  const onRetry = () => window.location.reload();

  const onMouseOver = () => LazySupportMain.preload();

  const classes = useStyles();
  const detailsElt =
    isErrorReportingEnabled && sentryId ? (
      <p className={classes.ref}>
        <span className={classes.refLabel}>
          {intl.formatMessage(messages.errorId)}
        </span>
        <br />
        <span className={classes.refId}>{sentryId}</span>
      </p>
    ) : (
      <div className={classes.message}>
        <p>{error.message}</p>
        {errorInfo.componentStack.split('\n').map((line, index) => (
          <p key={index}>{line}</p>
        ))}
      </div>
    );
  return (
    <ErrorScreen
      title={intl.formatMessage(messages.genericError)}
      actionLabel={intl.formatMessage(messages.retry)}
      actionOnClick={onRetry}
      extraAction={
        <Link to={supportPath} onMouseOver={onMouseOver}>
          {intl.formatMessage(messages.contact)}
        </Link>
      }
      extraContent={detailsElt}
    />
  );
};

export default memo<Props>(Error500Dumb);
