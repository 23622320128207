import { memo } from 'react';
import type { ComponentType, Node } from 'react';

import Container from 'src/shared/app/base/component/container/Container';
import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import EventBranding from 'src/shared/app/nav/component/branding/event/EventBranding';
import NotificationPortal from 'src/shared/app/notification/component/NotificationPortal';

import styles from './BlankWithDesktopSideBarTemplate.style';

const useStyles = createUseThemeStyles(styles);
type Props = {
  Switch: ComponentType<any>;
};

const BlankWithDesktopSideBarTemplate = ({ Switch }: Props): Node => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Container>
        <div className={classes.branding}>
          <EventBranding
            classes={{
              logo: classes.logo,
            }}
          />
        </div>

        <NotificationPortal />

        <div className={classes.main}>
          <Switch layoutClass={classes.content} />
        </div>
      </Container>
    </div>
  );
};

export default memo<Props>(BlankWithDesktopSideBarTemplate);
