export const actionQueryParam = 'action';
export const actionArgsQueryParam = 'action-args';
export const kindQueryParam = 'kind';
export const emailQueryParam = 'email';
export const embedQueryParam = 'embed';
export const localeQueryParam = 'locale';
export const redirectQueryParam = 'redirect';
export const resetTokenQueryParam = 'reset-token';
export const tokenQueryParam = 'token';
export const loginFromParam = 'login_from';
export const uidQueryParam = 'uid';
export const LOGIN_FROM_OPTION_BOS = 'bos';
