import { Fragment } from 'react';

import { Divider } from '@mui/material';

import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import type { DrawerMenuItemType } from 'src/shared/app/nav/component/drawermenu/DrawerMenu';
import MobileDrawerMenuListItem from 'src/shared/app/nav/component/drawermenu/MobileDrawerMenuListItem';

import styles from './MobileDrawerMenuList.style';

const useStyles = createUseThemeStyles(styles);
type Props = {
  items: DrawerMenuItemType[];
};

const MobileDrawerMenuList = ({ items }: Props) => {
  const classes = useStyles();
  return (
    <ul className={classes.root}>
      {items.map((menuItem, key) => (
        <Fragment key={key}>
          <MobileDrawerMenuListItem {...menuItem} />
          {key < items.length - 1 ? <Divider /> : null}
        </Fragment>
      ))}
    </ul>
  );
};

export default MobileDrawerMenuList;
