import type { State } from 'src/shared/core/type/reduxTypes';

const segment = (state) => state.ui.onboarding;

export const isSplashSeen = (state: State): boolean =>
  segment(state).isSplashSeen;
export const isOnboardingCompletedOnDevice = (state: State): boolean =>
  segment(state).isCompletedOnDevice;
export const isTimezoneWarningShown = (state: State): boolean =>
  segment(state).isTimezoneWarningShown;
export const onboardingSelector = (state: State): boolean => segment(state);
