import { em } from 'polished';

import { headlineFontSize } from '@braindate/ui/lib/token/typography';

import { antialiasing } from 'src/shared/app/base/style/token/helper';

const fontWeights = [700, 600, 600, 600, 600];
const fontSizes = [18, 20, 16, 14, 14];
export default {
  root: {
    extend: antialiasing,
    margin: [em(24, headlineFontSize), 0, em(8, headlineFontSize)],
    textAlign: ({ level }: Record<string, any>) => level === 1 && 'center',
    color: ({ theme, level }: Record<string, any>) =>
      level !== 1 && theme.accentColor,
    fontSize: ({ level }: Record<string, any>) => fontSizes[level - 1],
    fontWeight: ({ level }: Record<string, any>) => fontWeights[level - 1],
  },
};
