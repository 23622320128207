import { memo } from 'react';
import type { Node } from 'react';

import classNames from 'classnames';

import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';

import styles from './Container.style';

const useStyles = createUseThemeStyles(styles);
type Props = {
  children: Node;
  className?: string;
};

const Container = ({ children, className }: Props) => {
  const classes = useStyles();
  return <div className={classNames(classes.root, className)}>{children}</div>;
};

export default memo<Props>(Container);
