export const notificationErrorLevel = 'error';
export const notificationSuccessLevel = 'success';
export const notificationInfoLevel = 'info';
export const notificationWarningLevel = 'warning';
export const notificationTakeOverType = 'takeOver';
export const notificationTopType = 'top';
export const notificationModalType = 'modal';
export const notificationVanillaType = 'vanilla';
export const notificationBundleTypeFeedback = 'braindate-feedback';
export const notificationBundleTypeCheerPost = 'cheer-post';
export const notificationTransitionDuration = 800;
