import { useEffect } from 'react';

import { generatedStyleId } from 'src/shared/app/base/constant/styleConstants'; // Remove server rendered styles to avoid duplicates

export default function useServerStylesRemoval() {
  useEffect(() => {
    const styleElt = document.getElementById(generatedStyleId);

    if (styleElt instanceof HTMLElement && styleElt.remove) {
      styleElt.remove();
    }
  }, []);
}
