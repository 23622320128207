import { memo } from 'react';
import type { Node } from 'react';

import classNames from 'classnames';

import type { User } from '@braindate/domain/lib/user/type';

import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';

import { small } from './Avatar';
import styles from './GroupAvatarQueue.style';
import GroupAvatarQueueAvatar from './GroupAvatarQueueAvatar';
import GroupAvatarQueueEmpty from './GroupAvatarQueueEmpty';

const MAX_SHOWN_CIRCLE = 5;
type Props = {
  spots?: number;
  participants: Array<User>;
  fadedAvatarsNumber?: number;
  variant?: string;
  layoutClass?: string;
};
const useStyles = createUseThemeStyles(styles);

const GroupAvatarQueue = ({
  spots = 0,
  participants,
  fadedAvatarsNumber = 0,
  variant = small,
  layoutClass,
}: Props): Node => {
  /*
   |----------------------------------------------------------------------------
   | Classes
   |----------------------------------------------------------------------------
   */
  const classes = useStyles({
    variant,
  });

  /*
   |----------------------------------------------------------------------------
   | Elements
   |----------------------------------------------------------------------------
   */
  // SPECS = https://e180.atlassian.net/browse/PAX-1376
  const isFull = spots < 1;
  const isLengthExceedsMax = participants.length + spots > MAX_SHOWN_CIRCLE;
  const firstSectionNumber = isLengthExceedsMax
    ? MAX_SHOWN_CIRCLE - 1
    : participants.length + spots;
  const avatarsElts = participants
    .slice(0, firstSectionNumber)
    .map((participant, idx) => (
      <GroupAvatarQueueAvatar
        participant={participant}
        isFaded={idx < fadedAvatarsNumber}
        variant={variant}
        key={idx}
      />
    ));
  const emptyCirclesNumber = Math.max(
    firstSectionNumber - avatarsElts.length,
    0,
  );

  const lastElt = () => {
    if (isLengthExceedsMax) {
      const emptySpotsSuppNumber =
        spots - (firstSectionNumber - avatarsElts.length);

      const getNumber = () => {
        if (isFull) {
          return participants.length - firstSectionNumber;
        }

        if (spots === emptySpotsSuppNumber) {
          return emptySpotsSuppNumber;
        }

        return null;
      };

      return (
        <GroupAvatarQueueEmpty
          number={getNumber()}
          hasPlus={emptySpotsSuppNumber > 1}
          variant={variant}
          isFilled={isFull}
        />
      );
    }

    return null;
  };

  const emnptyCirclesElt = Array(emptyCirclesNumber)
    .fill()
    .slice(0, spots)
    .map((i, key) => <GroupAvatarQueueEmpty variant={variant} key={key} />);
  const firstSectionElts = (
    <>
      {avatarsElts} {emnptyCirclesElt}
    </>
  );
  return (
    <span className={classNames(classes.avatars, layoutClass)}>
      {firstSectionElts} {lastElt()}
    </span>
  );
};

export default memo<Props>(GroupAvatarQueue);
