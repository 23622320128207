import { darken } from 'polished';

import {
  black100,
  lightGreen,
  lightRed,
  lightYellow,
  whitish,
} from './palette';

export const bgColor = whitish;
export const textColor = black100;
export const successBgColor = lightGreen;
export const successTextColor = darken(0.6, lightGreen);
export const warningBgColor = lightYellow;
export const warningTextColor = darken(0.6, lightYellow);
export const errorBgColor = lightRed;
export const errorTextColor = darken(0.6, lightRed);
