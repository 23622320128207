import { createContext } from 'react';

import { Stream, VideoClient } from '@zoom/videosdk';

type context = {
  zoomSession: typeof Stream | null;
  zoomVideo: { current: typeof VideoClient | null };
  isInitiated: boolean;
  setMediaError: (error: Error | null) => void;
  localTracksState: {
    isAudioEnabled: boolean;
    isVideoEnabled: boolean;
    setIsAudioEnabled: (enabled: boolean) => void;
    setIsVideoEnabled: (enabled: boolean) => void;
  };
};

const VirtualBraindateZoomContext = createContext<context>({
  zoomSession: null,
  zoomVideo: { current: null },
  setMediaError: () => {},
  isInitiated: false,
  localTracksState: {
    isAudioEnabled: false,
    isVideoEnabled: false,
    setIsAudioEnabled: () => {},
    setIsVideoEnabled: () => {},
  },
});

export default VirtualBraindateZoomContext;
