import { useEffect } from 'react';
import type { Node } from 'react';

import { useLocation, useNavigate } from 'react-router-dom';

import { hasURLProtocol } from '@braindate/util/lib/url';

import useAuthenticationToken from 'src/shared/app/authentication/hook/useAuthenticationToken';
import useRouteWithUrlParamsString from 'src/shared/app/authentication/hook/useRouteWithUrlParamsString';
import usePlatformURL from 'src/shared/app/base/hook/usePlatformURL';
import useReduxState from 'src/shared/app/base/hook/useReduxState';
import useRouteConfig from 'src/shared/app/base/route/hook/useRouteConfig';
import useRouteMatch from 'src/shared/app/base/route/hook/useRouteMatch';
import { redirectQueryParam } from 'src/shared/app/base/route/setting/querySettings';
import { homeRoute } from 'src/shared/app/base/route/setting/routeSettings';
import {
  getRouteAllowAbsoluteURLRedirect,
  getRouteRedirect,
} from 'src/shared/app/base/route/util/routeUtils';

const debug = require('debug')('RedirectSwitch');

const RedirectSwitch = (): Node => {
  /*
   |----------------------------------------------------------------------------
   | Hooks
   |----------------------------------------------------------------------------
   */
  const token = useAuthenticationToken();
  const platformURL = usePlatformURL();
  const state = useReduxState();
  const config = useRouteConfig();
  const navigate = useNavigate();
  const location = useLocation();

  const route = useRouteMatch();
  const homePath = useRouteWithUrlParamsString(homeRoute);
  const redirectPath = getRouteRedirect(route, state, config, location);
  debug('redirectPath', redirectPath);
  const { search, pathname } = location;

  useEffect(() => {
    if (!redirectPath) return;
    const allowAbsoluteURLRedirect = getRouteAllowAbsoluteURLRedirect(route);

    // Login page is an exception and can be on a different domain
    if (allowAbsoluteURLRedirect && hasURLProtocol(redirectPath)) {
      // If we are in the browser, a simple javascript redirection will do
      if (typeof window !== 'undefined') {
        window.location = redirectPath;
        // Prevent error screen to be displayed while redirecting occurs
        return;
      }

      // Otherwise we do a server redirection
      navigate(redirectPath);
      return;
    }

    // Propagate query arguments with the redirection. A base is required,
    // otherwise an InvalidURL exception is thrown.
    let url;

    try {
      url = new URL(redirectPath, platformURL);
    } catch (e) {
      url = new URL(homePath + search, platformURL);
    }

    // Open redirect shouldn't be allowed for security purpose
    const baseUrl = new URL('/', platformURL);

    if (url.origin !== baseUrl.origin) {
      url.pathname = homePath;
    }

    // By default, we remove the redirect path
    url.searchParams.delete(redirectQueryParam);

    // If user is not authenticated, add the current path to the
    // `redirect` query param. Skip this step if the current path is the
    // home path, since after login the user will be redirected there
    // by default
    if (!token && pathname !== homePath) {
      url.searchParams.append(redirectQueryParam, pathname);
    }

    // Get rid of the URL base required above by the URL constructor
    // @TODO - PAX-3084 navigate is not working because the server middlware needs to read the redirect path to store it in the store.
    // While using navigate, the server middlware is not called. So using window.location will force a page reload and calls the server.
    // navigate(url.href.replace(url.origin, ''));
    window.location.replace(url.href.replace(url.origin, ''));
  }, [redirectPath]);
  return null;
};

export default RedirectSwitch;
