import * as types from 'src/shared/app/booking/action/uiBookingActionTypes';
import { createReducer } from 'src/shared/core/util/reducerUtils';

/*
 |------------------------------------------------------------------------------
 | Initial state
 |------------------------------------------------------------------------------
 */
const initialState = {
  isBookingScheduleShown: false,
  isAcceptTimeScheduleShown: false,
  isBookingConfirmationModalShown: false,
  isEventOverModalShown: false,
  isBraindatingUnavailableModalShown: false,
  bookingMessage: {},
};

/*
 |------------------------------------------------------------------------------
 | Reducers
 |------------------------------------------------------------------------------
 */
const showBookingSchedule = (
  state: Record<string, any>,
): Record<string, any> => ({ ...state, isBookingScheduleShown: true });

const hideBookingSchedule = (
  state: Record<string, any>,
): Record<string, any> => ({ ...state, isBookingScheduleShown: false });

const showAcceptTimeSchedule = (
  state: Record<string, any>,
): Record<string, any> => ({ ...state, isAcceptTimeScheduleShown: true });

const hideAcceptTimeSchedule = (
  state: Record<string, any>,
): Record<string, any> => ({ ...state, isAcceptTimeScheduleShown: false });

const showBookingConfirmationModal = (
  state: Record<string, any>,
): Record<string, any> => ({ ...state, isBookingConfirmationModalShown: true });

const hideBookingConfirmationModal = (
  state: Record<string, any>,
): Record<string, any> => ({
  ...state,
  isBookingConfirmationModalShown: false,
});

const toggleEventOverModal = (
  state: Record<string, any>,
): Record<string, any> => ({
  ...state,
  isEventOverModalShown: !state.isEventOverModalShown,
});

const toggleBraindatingUnavailableModal = (
  state: Record<string, any>,
): Record<string, any> => ({
  ...state,
  isBraindatingUnavailableModalShown: !state.isBraindatingUnavailableModalShown,
});

const setBookingMessage = (
  state: Record<string, any>,
  { message, topicId }: Record<string, any>,
): Record<string, any> => ({
  ...state,
  bookingMessage: { ...state.bookingMessage, [topicId]: message },
});

const resetBookingMessage = (
  state: Record<string, any>,
  { topicId }: Record<string, any>,
): Record<string, any> => ({
  ...state,
  bookingMessage: { ...state.bookingMessage, [topicId]: '' },
});

export default createReducer(initialState, {
  [types.OPEN_BOOKING_SCHEDULE]: showBookingSchedule,
  [types.CLOSE_BOOKING_SCHEDULE]: hideBookingSchedule,
  [types.OPEN_ACCEPT_TIME_SCHEDULE]: showAcceptTimeSchedule,
  [types.CLOSE_ACCEPT_TIME_SCHEDULE]: hideAcceptTimeSchedule,
  [types.OPEN_BOOKING_CONFIRMATION_MODAL]: showBookingConfirmationModal,
  [types.CLOSE_BOOKING_CONFIRMATION_MODAL]: hideBookingConfirmationModal,
  [types.OPEN_EVENT_OVER_MODAL]: toggleEventOverModal,
  [types.CLOSE_EVENT_OVER_MODAL]: toggleEventOverModal,
  [types.OPEN_BRAINDATING_UNAVAILABLE_MODAL]: toggleBraindatingUnavailableModal,
  [types.CLOSE_BRAINDATING_UNAVAILABLE_MODAL]:
    toggleBraindatingUnavailableModal,
  [types.SAVE_BOOKING_MESSAGE]: setBookingMessage,
  [types.CLEAR_BOOKING_MESSAGE]: resetBookingMessage,
});
