import { useEffect } from 'react';

import usePushNotificationSubscription from 'src/client/worker/hooks/usePushNotificationSubscription';
import { notificationsPermissionsGranted } from 'src/client/worker/utils/pushNotificationsUtils';
import { useDeletePushSubscriptionMutation } from 'src/shared/app/base/api/endpoint/notificationsEndpoint';

const usePushNotificationsUnregister = () => {
  const pushSubscription = usePushNotificationSubscription();
  const [deleteSubscription, { isLoading }] =
    useDeletePushSubscriptionMutation();
  useEffect(() => {
    if (!pushSubscription) return;

    if (
      Notification.permission !== notificationsPermissionsGranted &&
      !isLoading
    ) {
      try {
        deleteSubscription(pushSubscription.id);
      } catch (error) {
        // Silence error if the subscription is already deleted for whatever reason
      }
    }
  }, [pushSubscription]);
};

export default usePushNotificationsUnregister;
