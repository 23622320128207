import {
  getEventCustomId,
  getEventPlatformURL,
} from '@braindate/domain/lib/event/util';
import { assertObject } from '@braindate/util/lib/assert';

import {
  getNextUrl,
  getPlatformBaseDomain,
} from 'src/shared/app/base/selector/appEnvSelectors';
import type { State } from 'src/shared/core/type/reduxTypes';
import { getEventSafely } from 'src/shared/domain/event/selector/eventSelectors';

export const rootPath = '/';
export const errorReportingWhiteUrls = [/^(.*)\.braindate\.com$/];
export const iconBgColor = '#93ccc0';
export const privacyUrls = {
  en: 'https://api-media.braindate.com/misc/Privacy+policy-en.pdf',
  fr: 'https://api-media.braindate.com/misc/Privacy+policy-fr.pdf',
  ja: 'https://api-media.braindate.com/misc/Privacy+policy-ja.pdf',
  ko: 'https://api-media.braindate.com/misc/Privacy+policy-ko.pdf',
};
export const termsUrls = {
  en: 'https://api-media.braindate.com/misc/Terms+of+service-en.pdf',
  fr: 'https://api-media.braindate.com/misc/Terms+of+service-fr.pdf',
  ja: 'https://api-media.braindate.com/misc/Terms+of+service-ja.pdf',
  ko: 'https://api-media.braindate.com/misc/Terms+of+service-ko.pdf',
};
export const defaultPlatformUrl = 'https://braindate.com';
// CSP related urls
export const cspLocalhostSrc = 'http://localhost:8080 ws://localhost:8080';
export const cspFontSrc = 'https://fonts.gstatic.com/';
export const cspFrameSrc = 'https://www.youtube.com';
export const cspGoogleFontSrc = 'http://fonts.googleapis.com/css';

/**
 * Get the platform URL. If found in the event key of the state, returns that URL.
 * If not, build the URL from the customID of the event and the environment variable
 * for the platform.
 * @param   {State} state - Redux state
 * @returns {string} - The Platform absolute URL
 *
 * @throws Will throw an exception if parameters are not valid
 */
export function getPlatformURL(state: State): string {
  assertObject(state, 'state');
  const event = getEventSafely(state);
  return (
    getEventPlatformURL(event) ||
    `https://${getEventCustomId(event)}${getPlatformBaseDomain(state)}/`
  );
}

export function buildNextUrl(state: State): string {
  const nextUrl = getNextUrl(state);
  const url = nextUrl || '/next';
  return url;
}

export function getUrlTOnboardingPagePlatform(state: State): string {
  const redirectPath = `${buildNextUrl(state)}/onboarding`;
  return redirectPath;
}

export function getUrlToHomePagePlatform(state: State): string {
  const redirectPath = `${buildNextUrl(state)}/home`;
  return redirectPath;
}
