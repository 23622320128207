import { em } from 'polished';

import { baseFontSize } from '@braindate/ui/lib/token/typography';

import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

export default {
  root: {
    width: '100%',
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    listStyleType: 'none',
    padding: [0, 10],
    margin: 0,
  },
  item: {
    width: '100%',
  },
  link: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: [13, 15],
    color: ({ theme }: ThemeFunction<unknown>) => theme.textColor,
    fontSize: em(14, baseFontSize),
    gap: '10px',
    transition: '0.15s ease-out',
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: ({ theme }: ThemeFunction<unknown>) =>
        theme.colorBaseHover,
    },
  },
  active: {
    color: ({ theme }: ThemeFunction<unknown>) => theme.accentColor,
  },
  iconWrapper: {
    width: 38,
    height: 38,
    borderRadius: '50%',
    overflow: 'hidden',
    padding: 5,
    '& svg': {
      width: '100%',
      height: '100%',
      fontSize: em(16, baseFontSize),
    },
  },
  iconWrapperActive: {
    backgroundColor: ({ theme }: ThemeFunction<unknown>) => theme.accentColor,
    '& svg': {
      color: ({ theme }: ThemeFunction<unknown>) => theme.textColorReverse,
    },
  },
  img: {
    width: 28,
    height: 28,
    objectFit: 'contain',
  },
};
