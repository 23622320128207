import type { Node } from 'react';
import React from 'react';

import classNames from 'classnames';

import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';

import styles from './BaseIcon.style';
import IconContext from './IconContext';

import type { Svg } from './type/iconTypes';

type Props = {
  className?: string;
  svg: Svg;
  title?: string;
  style?: Record<string, any>;
  css?: any;
};
const useStyles = createUseThemeStyles(styles);

const BaseIcon = ({ svg, title, className, style, css }: Props): Node => {
  const classes = useStyles();
  return (
    <IconContext.Consumer>
      {(registry) => {
        if (registry) {
          registry.addIcon(svg.id, svg.content);
        }

        const { id } = svg;
        const titleId = `icon-${id}`;
        const titleTag = title ? `<title id="${titleId}">${title}</title>` : '';
        const html = `${titleTag || ''}<use xlink:href="#${id}"></use>`;
        let props = {
          css,
          className: classNames(classes.root, className),
          viewBox: svg.viewBox,
          dangerouslySetInnerHTML: {
            __html: html,
          },
          style,
        };

        if (title) {
          props = { ...props, role: 'img', 'aria-labelledby': titleId };
        } else {
          props = { ...props, role: 'img', 'aria-hidden': true };
        }

        return <svg {...props} />;
      }}
    </IconContext.Consumer>
  );
};

export default BaseIcon;
