import { useDispatch } from 'react-redux';

import type { Braindate } from '@braindate/domain/lib/braindate/type';
import type { User } from '@braindate/domain/lib/user/type';

import ResponsiveModal from 'src/shared/app/base/modal/component/ResponsiveModal';
import NewCheerForm from 'src/shared/app/cheer/component/modal/new/NewCheerForm';
import { dismissNotification } from 'src/shared/app/notification/action/uiNotificationActions';
import type { Notification } from 'src/shared/app/notification/type/notificationTypes';

type Props = {
  notification: Notification<{
    user: User;
    braindate?: Braindate;
  }>;
  onClose?: () => void;
};

const NewCheerModal = ({ notification, onClose }: Props) => {
  const dispatch = useDispatch();

  const handleClose = () => {
    if (onClose) {
      onClose();
    }

    dispatch(dismissNotification(notification));
  };

  return (
    <ResponsiveModal forceOpen={notification.active} onClose={handleClose}>
      <NewCheerForm notification={notification} handleClose={handleClose} />
    </ResponsiveModal>
  );
};

export default NewCheerModal;
