export default {
  root: {
    display: 'flex',
    width: '100%',
    alignItems: 'center',
  },
  wrapper: {
    width: '100%',
  },
  extra: {
    marginLeft: '1em',
  },
  menuPaper: {
    maxHeight: '350px !important',
  },
};
