import { $Keys } from 'utility-types';

import { isString } from '@braindate/util/lib/type';

import type { Methods } from 'src/shared/app/base/middleware/httpMethodMiddleware';
import { parseResponse } from 'src/shared/app/base/util/ajaxUtils';
import type { GetState } from 'src/shared/core/type/reduxTypes';

import { parseErrors } from './djrf-error-parser';

type Args = {
  url: string;
  next: string;
  params: Record<string, any>;
  method: $Keys<Methods>;
};
type API = {
  signal: any;
  dispatch: (...args: Array<any>) => any;
  getState: GetState;
}; // https://redux-toolkit.js.org/rtk-query/usage/customizing-queries

export default () =>
  ({ url, next, params, method = 'get' }: Args, api: API) =>
    api.dispatch(async (dispatch: any, _: GetState, methods: Methods) => {
      const methodCb = methods[method];

      try {
        const response = next
          ? await methodCb(next)
          : await methodCb(url, params);
        const parsedResponse = await parseResponse(response);
        return {
          data: parsedResponse,
        };
      } catch (e) {
        return {
          error: e.json || e.message,
        };
      }
    });
export function parseErrorResponse(error: string | Record<string, any>) {
  if (!error) return null;
  if (isString(error)) return error;

  try {
    const parsedErrorMessage = parseErrors(error);
    if (parsedErrorMessage === '' || !parsedErrorMessage) throw error;
    return parsedErrorMessage;
  } catch {
    return JSON.stringify(error);
  }
}
