export const SET_MARKET_STATUS_FILTER = 'SET_MARKET_STATUS_FILTER';
export const RESET_MARKET_STATUS_FILTER = 'RESET_MARKET_STATUS_FILTER';
export const SET_MARKET_KIND_FILTER = 'SET_MARKET_KIND_FILTER';
export const RESET_MARKET_KIND_FILTER = 'RESET_MARKET_KIND_FILTER';
export const SET_MARKET_LANGUAGE_FILTER = 'SET_MARKET_LANGUAGE_FILTER';
export const RESET_MARKET_LANGUAGE_FILTER = 'RESET_MARKET_LANGUAGE_FILTER';
export const RESET_MARKET_FILTERS = 'RESET_MARKET_FILTERS';
export const SET_MARKET_DATE_FILTER = 'SET_MARKET_DATE_FILTER';
export const RESET_MARKET_DATE_FILTER = 'RESET_MARKET_DATE_FILTER';
export const SET_MARKET_LOCATION_FILTER = 'SET_MARKET_LOCATION_FILTER';
export const SET_MARKET_CANJOIN_FILTER = 'SET_MARKET_CANJOIN_FILTER';
export const SET_MARKET_CONVERSATION_TYPE = 'SET_MARKET_CONVERSATION_TYPE';
export const SET_PARTICIPANT_CATEGORY = 'SET_PARTICIPANT_CATEGORY';
export const SET_MARKET_SORT_ORDERING = 'SET_MARKET_SORT_ORDERING';
export const RESET_MARKET_SORT_ORDERING = 'RESET_MARKET_SORT_ORDERING';
export const SET_MARKET_QUERY = 'SET_MARKET_QUERY';
export const RESET_MARKET_QUERY = 'RESET_MARKET_QUERY';
export const SET_MARKET_QUERY_CONTEXT = 'SET_MARKET_QUERY_CONTEXT';
export const UPDATE_SEARCH_QUERY_SUCCESS = 'UPDATE_SEARCH_QUERY_SUCCESS';
export const RESET_MARKET_CONVERSATION_TYPE = 'RESET_MARKET_CONVERSATION_TYPE';
export const RESET_PARTICIPANT_CATEGORY = 'RESET_PARTICIPANT_CATEGORY';
export const INVALIDATE_MARKET = 'INVALIDATE_MARKET';
