import { memo } from 'react';
import type { Node } from 'react';

import { useIntl } from 'react-intl';

import type { Braindate } from '@braindate/domain/lib/braindate/type';
import { getBraindatePath } from '@braindate/domain/lib/braindate/util';
import { isGroupTopic } from '@braindate/domain/lib/topic/util';
import { getUserFirstName } from '@braindate/domain/lib/user/util';

import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import messages from 'src/shared/app/check-in/l10n/checkInTakeOverL10n';
import {
  getBraindateOtherParticipant,
  getBraindateTopic,
} from 'src/shared/domain/braindate/util/braindateUtil';
import useSelf from 'src/shared/domain/user/hook/useSelf';
import LinkButton from 'src/shared/ui/component/link/LinkButton';

import styles from './CheckInTakeOverOnsiteAction.style';

type Props = {
  braindate: Braindate;
  onClick?: () => void;
};
const useStyles = createUseThemeStyles(styles);

const CheckInTakeOverOnsiteAction = ({ braindate, onClick }: Props): Node => {
  const intl = useIntl();
  const topic = getBraindateTopic(braindate);
  const isGroup = isGroupTopic(topic);
  const selfUser = useSelf();
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.button}>
        <LinkButton to={getBraindatePath(braindate)} onClick={onClick}>
          {isGroup
            ? intl.formatMessage(messages.chatWithGroup)
            : intl.formatMessage(messages.chatWith, {
                firstname:
                  selfUser &&
                  getUserFirstName(
                    getBraindateOtherParticipant(braindate, selfUser),
                  ),
              })}
        </LinkButton>
      </div>
    </div>
  );
};

export default memo<Props>(CheckInTakeOverOnsiteAction);
