import { createElement, memo } from 'react';
import type { Node } from 'react';

import classNames from 'classnames';

import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';

import styles from './HeroText.style';

type Props = {
  as?: string;
  layoutClass?: string;
  small?: boolean;
  children: Node;
};
const useStyles = createUseThemeStyles(styles);

const HeroText = ({ as, layoutClass, small, children }: Props): Node => {
  const classes = useStyles();
  const rootClasses = classNames(classes.root, layoutClass, {
    [classes['root-small']]: small,
  });
  const tagName = as || 'p';
  return createElement(
    tagName,
    {
      className: rootClasses,
    },
    children,
  );
};

export default memo<Props>(HeroText);
