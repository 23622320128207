import { rem } from 'polished';

import { tablet } from '@braindate/ui/lib/token/mediaQuery';
import { baseFontSize } from '@braindate/ui/lib/token/typography';

export default {
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    textAlign: 'left',
    padding: [0, 16, 16, 16],
    [tablet]: {
      padding: 0,
    },
  },
  title: {
    textAlign: 'center',
    margin: 0,
  },
  subtitle: {
    fontSize: rem(16, baseFontSize),
    fontWeight: 700,
  },
  explanation: {
    fontSize: rem(16, baseFontSize),
  },
  submit: {
    display: 'flex',
    justifyContent: 'center',
  },
};
