import { em } from 'polished';

import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

export default {
  root: {
    height: 'min-content',
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    width: '100%',
    textAlign: 'left',
    marginTop: 0,
    color: ({ theme }: ThemeFunction<unknown>) => theme.textColor,
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  image: {
    width: 400,
    height: 225,
    objectFit: 'contain',
    marginBottom: '50px',
  },
  actions: {
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    maxHeight: 40,
    margin: 0,
  },
  icon: {
    width: `${em(25)} !important`,
    height: `${em(25)} !important`,
  },
  xButton: {
    padding: 12,
  },
  x: {
    color: ({ theme }: ThemeFunction<unknown>) => theme.textColor,
  },
  facebook: {
    color: '#3d5a98',
  },
  shareTo: {
    textAlign: 'center',
    fontWeight: 600,
    fontSize: em(14),
    color: ({ theme }: ThemeFunction<unknown>) =>
      `${theme.textColorTertiary} !important`,
  },
  icons: {
    display: 'flex',
    justifyContent: 'center',
    gap: '20px',
  },
  iconButton: {
    boxShadow: ({ theme }: ThemeFunction<unknown>) => theme.cardShadowBox,
  },
};
