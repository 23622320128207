/* eslint-disable prettier/prettier */
import { rem } from "polished";

import { hoverSelector } from "@braindate/ui/lib/token/selector";
import { baseFontSize } from "@braindate/ui/lib/token/typography";

import type { ThemeFunction } from "src/shared/app/base/types/themeTypes";

import { getReadableColor } from "plugin/light/util/lightUtils";

type ThemeStyles = ThemeFunction<{
  customColor?: string;
  withSuccess: boolean;
}>;
export default {
  root: {
    textTransform: 'uppercase',
    fontSize: rem(12, baseFontSize),
    border: '1px solid transparent',
    fill: ({
      customColor,
      theme
    }: ThemeStyles) => getReadableColor(customColor || theme.accentColorSoft),
    stroke: ({
      customColor,
      theme
    }: ThemeStyles) => getReadableColor(customColor || theme.accentColorSoft),
    backgroundColor: ({
      customColor,
      withSuccess,
      theme
    }: ThemeStyles) => {
      if (withSuccess) {
        return theme.successTextColor;
      }

      return customColor || theme.accentColorSoft;
    },
    color: ({
      customColor,
      withSuccess,
      theme
    }: ThemeStyles) => {
      if (withSuccess) {
        return getReadableColor(theme.successTextColor);
      }

      return getReadableColor(customColor || theme.accentColorSoft);
    },
    [hoverSelector]: {
      color: ({
        theme
      }: ThemeStyles) => theme.accentColor,
      textDecoration: 'none',
      border: ({
        customColor,
        withSuccess,
        theme
      }: ThemeStyles) => {
        if (withSuccess) {
          return `1px solid ${theme.successTextColor}`;
        }

        return `1px solid ${customColor || theme.accentColor}`;
      }
    },
    '&:active': {
      textDecoration: 'underline`'
    },
    '& svg': {
      fill: 'currentColor',
      stroke: 'currentColor'
    }
  },
  'root-withError': {
    color: ({
      theme
    }: ThemeStyles) => getReadableColor(theme.errorMessageColor),
    backgroundColor: ({
      theme
    }: ThemeStyles) => theme.errorMessageColor,
    '&:hover': {
      border: ({
        theme
      }: ThemeStyles) => `1px solid ${theme.accentColor}`
    }
  }
};