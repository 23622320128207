import { lap, tablet } from '@braindate/ui/lib/token/mediaQuery';

import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

type ThemeOptions = ThemeFunction<{
  wrapToContent: boolean;
}>;
export default {
  root: {
    width: ({ wrapToContent }: ThemeOptions) =>
      wrapToContent ? 'min-content' : '100%',
    height: ({ wrapToContent }: ThemeOptions) =>
      wrapToContent ? 'unset' : '100%',
    '& a > span': {
      whiteSpace: 'nowrap',
    },
  },
  list: {
    display: 'flex',
    width: ({ wrapToContent }: ThemeOptions) =>
      wrapToContent ? 'min-content' : '100%',
    justifyContent: ({ wrapToContent }: ThemeOptions) =>
      wrapToContent ? 'flex-start' : 'space-around',
    height: '100%',
    margin: 0,
    padding: 0,
    [tablet]: {
      columnGap: 16,
    },
    [lap]: {
      columnGap: 20,
    },
  },
  badge: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
  badgeMenuOpened: {
    border: ({ theme }: ThemeOptions) => `1px solid ${theme.colorBase}`,
  },
  hidden: {
    display: 'none',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    justifyContent: 'center',
    '& svg': {
      width: 23,
      height: 23,
    },
  },
  submenuItem: {
    maxHeight: ({
      wrapToContent,
    }: ThemeFunction<{
      wrapToContent: boolean;
    }>) => (wrapToContent ? 'unset' : 94),
    marginBottom: 6,
    '&:last-child': {
      marginBottom: 'unset',
    },
  },
  itemMobileOnly: {
    [lap]: {
      display: 'none',
    },
  },
  itemDesktopOnly: {
    display: 'none',
    [lap]: {
      display: 'flex',
    },
  },
};
