import * as authenticationTypes from 'src/shared/app/authentication/action/base/authenticationActionTypes';
import * as signupTypes from 'src/shared/app/signup/action/signupActionTypes';
import { createReducer } from 'src/shared/core/util/reducerUtils';
import * as membershipTypes from 'src/shared/domain/membership/action/membershipActionTypes';

const initialState = null;

const reset = () => null;

const _setMembership = (state: number, action: Record<string, any>) =>
  action.membershipId;

export default createReducer(initialState, {
  [membershipTypes.RESET_MEMBERSHIP]: reset,
  [authenticationTypes.LOGIN_SUCCESS]: _setMembership,
  [signupTypes.SIGNUP_CREATE_USER_SUCCESS]: _setMembership,
  [authenticationTypes.CREATE_PASSWORD_SUCCESS]: _setMembership,
  [authenticationTypes.RESET_PASSWORD_SUCCESS]: _setMembership,
});
