import { memo } from 'react';
import type { Node } from 'react';

import classNames from 'classnames';

import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import BaseIcon from 'src/shared/ui/component/icon/BaseIcon';

import svg from './cross.svg';
import styles from './CrossIcon.style';

type Props = {
  className?: string;
  title?: string;
  large?: boolean; // Self passed
};
const useStyles = createUseThemeStyles(styles);

const CrossIcon = ({ className, title, large }: Props): Node => {
  const classes = useStyles();
  // Classes
  const rootClasses = classNames(classes.root, className, {
    [classes['root-large']]: large,
  });
  return <BaseIcon className={rootClasses} svg={svg} title={title} />;
};

export default memo<Props>(CrossIcon);
