import { useState } from 'react';

import NotificationsIcon from '@mui/icons-material/NotificationsOutlined';
import { useMediaQuery } from '@mui/material';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { lap } from '@braindate/ui/lib/token/mediaQuery';

import { ampli } from 'src/ampli';
import { useCountPendingBraindatesQuery } from 'src/shared/app/base/api/endpoint/braindatesEndpoint';
import TopBarIcon from 'src/shared/app/base/component/maintopbar/TopBarIcon';
import useRouteMatch from 'src/shared/app/base/route/hook/useRouteMatch';
import useRoutes from 'src/shared/app/base/route/hook/useRoutes';
import {
  braindatesRoute,
  confirmedBraindatesRoute,
  pendingBraindatesRoute,
} from 'src/shared/app/base/route/setting/routeSettings';
import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import useFeatureDecisions from 'src/shared/app/feature/hook/useFeatureDecisions';
import { marketQueryFormId } from 'src/shared/app/market/component/header/search/query/MarketQueryForm';
import DrawerMenu from 'src/shared/app/nav/component/drawermenu/DrawerMenu';
import NotificationsMenu from 'src/shared/app/nav/component/notifications-menu/NotificationsMenu';
import messages from 'src/shared/app/nav/l10n/navigationL10n';
import { notificationsMenuId } from 'src/shared/app/nav/settings/navSettings';
import { getNotificationCount } from 'src/shared/app/notification/selector/appNotificationSelectors';
import useSelf from 'src/shared/domain/user/hook/useSelf';
import CalendarIcon from 'src/shared/ui/component/icon/CalendarIcon2';
import MagnifierIcon from 'src/shared/ui/component/icon/MagnifierIcon';
import NotificationBadge from 'src/shared/ui/component/messaging/NotificationBadge';

import styles from './MainTopBarActions.style';

const useStyles = createUseThemeStyles(styles);
export const marketQueryFormButtonId = 'market-query-form-button-id';
type Props = {
  isMarketPage: boolean;
  isSearchOpened: boolean;
  onOpenSearch: () => void;
};

const MainTopBarActions = ({
  isMarketPage,
  isSearchOpened,
  onOpenSearch,
}: Props) => {
  const intl = useIntl();
  const self = useSelf();
  const classes = useStyles();
  const isDesktop = useMediaQuery(lap);
  const { isDrawerMenuEnabled } = useFeatureDecisions();
  const [braindatesPath, pendingBraindatesPath, confirmedBraindatesPath] =
    useRoutes(
      braindatesRoute,
      pendingBraindatesRoute,
      confirmedBraindatesRoute,
    );
  const notificationsCount = useSelector(getNotificationCount);
  const hasNotifications = notificationsCount > 0;
  const [notificationsMenuOpened, setNotificationsMenuOpened] = useState(false);
  const activeRoute = useRouteMatch();
  const braindatesRouteActive = [
    braindatesPath,
    pendingBraindatesPath,
    pendingBraindatesPath,
    confirmedBraindatesPath,
  ].includes(activeRoute.path);
  const { data } = useCountPendingBraindatesQuery();
  const hasPendingBraindates = (data?.count || 0) > 0;
  return (
    <div className={classes.root}>
      {isMarketPage && !isDesktop ? (
        <TopBarIcon
          id={marketQueryFormButtonId}
          label={intl.formatMessage(messages.search)}
          active={isSearchOpened}
          aria-controls={marketQueryFormId}
          tabIndex="0"
          onClick={onOpenSearch}
        >
          <MagnifierIcon />
        </TopBarIcon>
      ) : null}

      <NotificationsMenu
        isOpened={notificationsMenuOpened}
        className={classes.notifications}
        onClose={() => {
          ampli.notificationOpen();
          setNotificationsMenuOpened(false);
        }}
        withRoundedChild
      >
        {hasNotifications ? (
          <NotificationBadge layoutClass={classes.badge} />
        ) : null}
        <TopBarIcon
          label={intl.formatMessage(messages.toggle)}
          active={notificationsMenuOpened}
          aria-controls={notificationsMenuId}
          tabIndex="0"
          onClick={() => setNotificationsMenuOpened(true)}
        >
          <NotificationsIcon />
        </TopBarIcon>
      </NotificationsMenu>

      {isDrawerMenuEnabled && !isDesktop ? (
        <TopBarIcon
          label={intl.formatMessage(messages.search)}
          active={braindatesRouteActive}
          to={
            hasPendingBraindates
              ? pendingBraindatesPath
              : confirmedBraindatesPath
          }
        >
          {hasPendingBraindates ? (
            <NotificationBadge layoutClass={classes.badge} />
          ) : null}
          <CalendarIcon />
        </TopBarIcon>
      ) : null}

      {self && <DrawerMenu />}
    </div>
  );
};

export default MainTopBarActions;
