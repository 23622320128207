import { fishbowlGreen } from 'src/shared/app/base/style/token/color';

export default {
  join: {
    display: 'flex',
    alignItems: 'center',
    background: fishbowlGreen,
    border: 0,
  },
  joinIcon: {
    fontSize: '1.4em',
    position: 'relative',
    left: '-0.3em',
  },
};
