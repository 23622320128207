import { memo } from 'react';
import type { Node } from 'react';

import { Form, Formik } from 'formik';
import { useIntl } from 'react-intl';

import {
  getFormBuilderSchema,
  getFormInitialValues,
} from 'src/shared/app/base/component/data-entry/form-builder/utils/formBuilderUtils';
import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import PrimaryButton from 'src/shared/ui/component/button/PrimaryButton';

import styles from './FormBuilder.style';
import FormBuilderField from './FormBuilderField';
import messages from './l10n/formBuilderL10n';

import type { FieldData } from './type/formBuilderTypes';
import type { FormikHelpers } from 'formik';

const useStyles = createUseThemeStyles(styles);
type Props = {
  fieldsData?: Array<FieldData>;
  onSubmit: (
    arg0: Record<string, any>,
    arg1: FormikHelpers<Record<string, any>>,
  ) => void;
};

const FormBuilder = ({ fieldsData, onSubmit }: Props): Node => {
  const intl = useIntl();
  const classes = useStyles();
  if (!fieldsData) return null;
  const initialValues = getFormInitialValues(fieldsData);
  const validationSchema = getFormBuilderSchema(fieldsData, intl);
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting }) => (
        <Form className={classes.form}>
          {fieldsData?.map((field) => (
            <div key={field.key}>
              <FormBuilderField
                name={field.key}
                field={field}
                type={field.field_type}
              />
            </div>
          ))}

          <div className={classes.actions}>
            <PrimaryButton isSubmitting={isSubmitting}>
              {intl.formatMessage(messages.submitBtn)}
            </PrimaryButton>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default memo<Props>(FormBuilder);
