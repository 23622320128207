import { IntlShape } from 'react-intl';
import * as Yup from 'yup';

import { getLocalizedData } from '@braindate/domain/lib/base/l10n/util';

import messages from 'src/shared/app/base/component/data-entry/form-builder/l10n/formBuilderL10n';
import type { FieldData } from 'src/shared/app/base/component/data-entry/form-builder/type/formBuilderTypes';

export function getFieldTitle(
  { body, label_intl: labelIntl }: FieldData,
  intl: IntlShape,
) {
  if (labelIntl) {
    return intl.formatMessage(labelIntl);
  }

  return getLocalizedData(body).label;
}
export const getFormInitialValues = (fieldsData: FieldData[]) => {
  const defaultValues = {};
  fieldsData?.forEach(({ key, field_type, choices }) => {
    const choicesValues = {};

    if (choices) {
      choices.forEach(({ key: choiceKey }) => {
        choicesValues[choiceKey] = false;
      });
    }

    const field = {
      checkbox_list: choicesValues,
      rating: 0,
      checkbox: '',
      text: '',
    };
    defaultValues[key] = field[field_type];
  });
  // $FlowIssue
  return defaultValues;
};
export const getFormBuilderSchema = (
  fieldsData: FieldData[],
  intl: IntlShape,
) => {
  const shapeObject = {};
  fieldsData?.forEach(({ key, field_type }) => {
    const field = {
      checkbox_list: Yup.object().required(),
      rating: Yup.number().oneOf(
        [1, 2, 3, 4, 5],
        intl.formatMessage(messages.ratingError),
      ),
      checkbox: Yup.string().required(),
      text: Yup.string().max(200),
    };
    shapeObject[key] = field[field_type];
  });
  // $FlowIssue
  return Yup.object().shape(shapeObject);
};
