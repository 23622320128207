import { memo } from 'react';
import type { Node } from 'react';

import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import { DateTime } from 'luxon';
import { useIntl } from 'react-intl';

import { isString } from '@braindate/util/lib/type';

import useTimeFormat from 'src/shared/app/base/date/component/hook/useTimeFormat';
import messages from 'src/shared/app/base/date/l10n/dateL10n';
import {
  formatDateTime,
  formatDay,
  formatTime,
  formatWeekDay,
} from 'src/shared/app/base/date/util/dateFormatters';
import { isDateToday } from 'src/shared/app/base/date/util/dateUtils';
import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import LiveIcon from 'src/shared/ui/component/icon/LiveIcon';

import styles from './DateTime.style';

type Props = {
  date?: DateTime | string;
  id?: string;
  withoutMonth?: boolean;
  withToday?: boolean;
  withLocalZone?: boolean;
  withIcon?: boolean;
  withAccent?: boolean;
  className?: string;
  timeText?: string | Node;
  isLive?: boolean;
  zone?: string;
};
const useStyles = createUseThemeStyles(styles);

/* if today && withToday = Today, Time
  if !today && withToday = Weekday date, Time => Wed 22, 10:00
  if !withToday = Weekday, Month Date, Time => Wed, April 24, 10:00
  if withoutMonth = Weekday date, Time => Wed 22, 10:00
 */
const DateTimeComp = ({
  date: propDate,
  id,
  withoutMonth,
  withToday,
  withLocalZone,
  withIcon = false,
  withAccent = false,
  className,
  timeText,
  isLive = false,
  zone,
}: Props): Node => {
  const classes = useStyles({
    withAccent,
  });

  /*
   |----------------------------------------------------------------------------
   | Hooks
   |----------------------------------------------------------------------------
   */
  const intl = useIntl();
  const { locale } = intl;
  const timeFormat = useTimeFormat();

  /*
   |----------------------------------------------------------------------------
   | Elements
   |----------------------------------------------------------------------------
   */
  let txt;
  const date: DateTime | null =
    propDate && isString(propDate)
      ? DateTime.fromISO(propDate, {
          zone,
        })
      : propDate;

  if (propDate) {
    if (withToday) {
      if (isDateToday(date)) {
        txt = `${intl.formatMessage(messages.today)}, ${formatTime(
          date,
          locale,
          timeFormat,
          withLocalZone,
        )}`;
      } else if (withoutMonth) {
        txt = `${formatWeekDay(date, locale)} ${formatDay(
          date,
          locale,
        )}, ${formatTime(date, locale, timeFormat, withLocalZone)}`;
      } else {
        txt = formatDateTime(date, locale, timeFormat, withLocalZone);
      }
    } else if (withoutMonth) {
      txt = `${formatWeekDay(date, locale)} ${formatDay(
        date,
        locale,
      )}, ${formatTime(date, locale, timeFormat, withLocalZone)}`;
    } else {
      txt = formatDateTime(date, locale, timeFormat, withLocalZone);
    }
  }

  const icon = isLive ? (
    <LiveIcon className={classes.icon} />
  ) : (
    <AccessTimeOutlinedIcon
      classes={{
        root: classes.icon,
      }}
    />
  );
  return (
    <div className={`${className || ''} ${classes.root}`}>
      {withIcon && <span>{icon}</span>}
      <span>
        {timeText ||
          (date && (
            <time id={id} dateTime={date.toISO()} aria-label={txt}>
              {txt}
            </time>
          ))}
      </span>
    </div>
  );
};

export default memo<Props>(DateTimeComp);
