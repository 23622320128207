import { memo } from 'react';
import type { ComponentType, Node } from 'react';

import Container from 'src/shared/app/base/component/container/Container';
import MainTopBar from 'src/shared/app/base/component/maintopbar/MainTopBar';
import MobileNavBar from 'src/shared/app/base/component/mobilenavbar/MobileNavBar';
import TemplatePortals from 'src/shared/app/base/component/template/component/TemplatePortals';
import useRouteBackgroundImage from 'src/shared/app/base/route/hook/useRouteBackgroundImage';
import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';

import styles from './FullWidthTemplate.style';

const useStyles = createUseThemeStyles(styles);
type Props = {
  contentMaxWidth?: number;
  Switch: ComponentType<any>;
};

const FullWidthNavTemplate = ({ contentMaxWidth, Switch }: Props): Node => {
  const classes = useStyles({
    contentMaxWidth,
  });
  const backgroundImage = useRouteBackgroundImage();
  return (
    <div
      className={classes.root}
      style={{
        backgroundImage: backgroundImage ? `url(${backgroundImage})` : 'none',
      }}
    >
      <MainTopBar />

      <div className={classes.takeOverContainer}>
        <TemplatePortals />
      </div>

      <div className={classes.content}>
        <Container className={classes.container}>
          <div className={classes.contentInner}>
            <Switch layoutClass={classes.main} />
          </div>
        </Container>
      </div>

      <MobileNavBar />
    </div>
  );
};

export default memo<Props>(FullWidthNavTemplate);
