import { memo, useEffect } from 'react';
import type { Node } from 'react';

import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';

import type { Topic } from '@braindate/domain/lib/topic/type';
import { getTopicId } from '@braindate/domain/lib/topic/util';

import { topicRoute } from 'src/shared/app/base/route/setting/routeSettings';
import { generatePath } from 'src/shared/app/base/route/util/routeUtils';
import { dismissNotification } from 'src/shared/app/notification/action/uiNotificationActions';
import Notification from 'src/shared/app/notification/component/Notification';
import type { Notification as NotificationType } from 'src/shared/app/notification/type/notificationTypes';
import { getNotificationData } from 'src/shared/app/notification/util/notificationUtil';
import { trackGAEvent } from 'src/shared/core/service/tracking/gaService';

type Props = {
  notification: NotificationType<any>;
};

const CheckInNotificationDumb = ({ notification }: Props): Node => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  // $FlowIssue
  const { disabledReason, topic } = getNotificationData<{
    topic: Topic;
    disabledReason: string;
  }>(notification);

  const handleDismiss = () => {
    dispatch(dismissNotification(notification));
    trackGAEvent('Topic not joinable', 'Dismiss notification', 'Notification');
  };

  useEffect(
    () => () => {
      const routePath = generatePath(topicRoute, {
        id: getTopicId(topic),
      });
      if (routePath === pathname) dispatch(dismissNotification(notification));
    },
    [topic, pathname],
  );
  return (
    <Notification withError handleDismiss={handleDismiss}>
      {disabledReason}
    </Notification>
  );
};

export default memo<Props>(CheckInNotificationDumb);
