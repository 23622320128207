import { RemoteParticipant } from 'twilio-video';

import type { User } from '@braindate/domain/lib/user/type';
import { getUserId } from '@braindate/domain/lib/user/util';

import { FISHBOWL_STATUS } from 'src/shared/app/base/plugin/virtual-braindate/constant/virtualBraindateConstants';
import type {
  SocketData,
  UserSocketParticipant,
} from 'src/shared/app/base/plugin/virtual-braindate/type/virtualTypes';

// It is used - why an error?
// eslint-disable-next-line unused-imports/no-unused-imports, import/order
import type { ConversationParticipant } from '@braindate/domain/lib/conversation/type';

export const isStatusSpeaker = (status: string | null | undefined) =>
  status === FISHBOWL_STATUS.SPEAKER;
export const isStatusWatcher = (status: string | null | undefined) =>
  status === FISHBOWL_STATUS.WATCHER;
export const isStatusHotseat = (status: string | null | undefined) =>
  status === FISHBOWL_STATUS.HOTSEAT;
export const isStatusReservedSpeaker = (status: string | null | undefined) =>
  status === FISHBOWL_STATUS.RESERVED_SPEAKER;
export const isWatcher = (participant: UserSocketParticipant) =>
  isStatusWatcher(participant.fishbowl_data.status);
export const isSpeaker = (participant: UserSocketParticipant) =>
  isStatusSpeaker(participant.fishbowl_data.status);
export const isHotseat = (participant: UserSocketParticipant) =>
  isStatusHotseat(participant.fishbowl_data.status);
export const isReservedSpeaker = (participant: UserSocketParticipant) =>
  isStatusReservedSpeaker(participant.fishbowl_data.status);
export const filterParticipantsSpeakers = (
  participants: RemoteParticipant[],
): RemoteParticipant[] => participants.filter((p) => isSpeaker(p));
export const filterParticipantsWatchers = (
  participants: RemoteParticipant[],
): RemoteParticipant[] => participants.filter((p) => isWatcher(p));
export const getParticipantsHotseat = (
  participants: RemoteParticipant[],
): RemoteParticipant | null => participants.find((p) => isHotseat(p)) || null;
export const getParticipantsReserveSpeaker = (
  participants: RemoteParticipant[],
): Array<RemoteParticipant> => participants.filter((p) => isReservedSpeaker(p));
export function getParticipant<
  T = ConversationParticipant | UserSocketParticipant | User,
>(id: number, participants: T[]): T | void {
  // $FlowIssue
  return participants.find((p: T) => getUserId(p) === id);
}
export const getTwilioParticipant = (
  APIParticipant: UserSocketParticipant,
  twilioParticipant: RemoteParticipant,
) =>
  getUserId(APIParticipant).toString() ===
    twilioParticipant.identity.toString() &&
  (isSpeaker(APIParticipant) || isHotseat(APIParticipant));
export const isTwilioParticipant = (
  APIParticipants: UserSocketParticipant[],
  twilioParticipant: RemoteParticipant,
) =>
  APIParticipants.some((pAPI) => getTwilioParticipant(pAPI, twilioParticipant));

/**
 * Getters
 */
export const getFishbowlData = (u: UserSocketParticipant) => u?.fishbowl_data;
export const getStatus = (p: UserSocketParticipant) =>
  getFishbowlData(p)?.status;
export const getDesiredStatus = (p: UserSocketParticipant) =>
  getFishbowlData(p)?.desired_status;
export const getPending = (p: UserSocketParticipant) =>
  getFishbowlData(p)?.pending;
export const getChanging = (p: UserSocketParticipant) =>
  getFishbowlData(p)?.changing;
export const getFishbowlUserId = (p: UserSocketParticipant) =>
  getFishbowlData(p)?.user;
export const getPossibleStatuses = (data: SocketData) =>
  data?.possible_statuses;
export const getLastChangeDatetime = (data: SocketData) =>
  data?.last_status_change_datetime;
export const getCallStarted = (data: SocketData) => data?.call_started;
export const getFishbowlSelf = (
  participants: UserSocketParticipant[],
  user: User,
): UserSocketParticipant | null | undefined =>
  user && participants.find((p) => getFishbowlUserId(p) === getUserId(user));
export const getFishbowlSelfStatus = (
  participants: UserSocketParticipant[],
  user: User,
): string | null | undefined => {
  const self = getFishbowlSelf(participants, user);
  return self && getStatus(self);
};
