import {
  convertModerationAcceptedAndSlackToNotification,
  convertModerationAcceptedToNotification,
  convertModerationPostToNotification,
  convertModerationRejectedToNotification,
} from 'src/shared/app/moderation/util/moderationUtils';
import { queueNotification } from 'src/shared/app/notification/action/uiNotificationActions';

export function showModerationPostBanner() {
  return queueNotification(convertModerationPostToNotification());
}
export function showModerationAcceptedBanner() {
  return queueNotification(convertModerationAcceptedToNotification());
}
export function showModerationRejectedBanner() {
  return queueNotification(convertModerationRejectedToNotification());
}
export function showModerationAcceptedAndSlackBanner() {
  return queueNotification(convertModerationAcceptedAndSlackToNotification());
}
