export const popOverElevation = 51;
export const dialogElevation = 50; // All modals must be on top of everything

export const VideoCallTopNotificationElevation = 45;
export const videoCallElevation = 40; // Only things that can be on Video call is the modals

export const navTabElevation = 24;
export const notificationElevation = 23;
export const takeOverElevation = 22;
export const mobileBottomNavElevation = 21;
export const utilityTopBarElevation = 20;
export const bottomAppBarElevation = 8;
export const bottomNavBarElevation = 8;
export const topAppBarElevation = 4;
export const sidebarElevation = 2;
export const boxShadow = '0 0 3px 0 rgba(0, 0, 0, 0.5)';
