/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { em } from 'polished';

const styles = () => ({
  root: css({
    fontSize: em(14),
    width: em(16),
    height: em(16),
    verticalAlign: 'middle',
  }),
});

export default styles;
