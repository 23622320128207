import { useEffect } from 'react';

import { useId } from 'react-aria';
import { useDispatch } from 'react-redux';

import {
  registerPortal,
  unRegisterPortal,
} from 'src/shared/app/base/portal/notification/slice/portalRegistrationSlice';

export default function usePortalRegister(
  portalKey: string,
): Record<string, any> {
  const dispatch = useDispatch();
  const id = useId();
  useEffect(() => {
    dispatch(
      registerPortal({
        portalKey,
        id,
      }),
    );
    return () => {
      dispatch(unRegisterPortal(id));
    };
  }, []);
  return id;
}
