import type { State } from 'src/shared/core/type/reduxTypes';

const slice = (state: State) => state.ui.modal;

export function selectModalId(state: State): string {
  return slice(state).id;
}
export function selectModalData(
  state: State,
): Record<string, any> | null | undefined {
  return slice(state).data;
}
