import classNames from 'classnames';
import { Link } from 'react-router-dom';

import { hasURLProtocol } from '@braindate/util/lib/url';

import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import type { DrawerMenuItemType } from 'src/shared/app/nav/component/drawermenu/DrawerMenu';
import useIsPathActive from 'src/shared/core/hooks/useIsPathActive';
import ConditionalWrapper from 'src/shared/ui/component/misc/ConditionalWrapper';

import styles from './MobileDrawerMenuList.style';

const useStyles = createUseThemeStyles(styles);

const MobileDrawerMenuListItem = ({
  to,
  // href will force a "a" tag
  href,
  title,
  icon,
  target,
  match,
  onClick,
}: DrawerMenuItemType) => {
  const classes = useStyles();

  const hasHref = !!href;
  const link = to || href;
  const isActive = useIsPathActive(match ? [...match, link] : link || '');

  const getIcon = () => {
    if (icon.src && icon.alt) {
      return (
        // $FlowIssue
        <img className={classes.img} src={icon.src} alt={icon.alt} />
      );
    }

    return icon;
  };

  const isExternalLink = hasHref || (link && hasURLProtocol(link));
  const baseLinkProps = {
    target,
    className: classNames(classes.link, {
      [classes.active]: isActive,
    }),
    onClick,
  };

  return (
    <li className={classes.item}>
      <ConditionalWrapper
        condition={isExternalLink}
        wrapper={(linkChild) => (
          <a {...baseLinkProps} href={link}>
            {linkChild}
          </a>
        )}
        elseWrapper={(linkChild) => (
          <Link {...baseLinkProps} to={link}>
            {linkChild}
          </Link>
        )}
      >
        <>
          <span
            className={classNames(classes.iconWrapper, {
              [classes.iconWrapperActive]: isActive,
            })}
          >
            {getIcon()}
          </span>
          {title}
        </>
      </ConditionalWrapper>
    </li>
  );
};

export default MobileDrawerMenuListItem;
