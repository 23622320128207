import { rgba } from 'polished';

import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

export default {
  selectedItem: {
    flex: '1 1 50%',
    width: '70%',
  },
  selectedItemWrapper: {
    background: ({ theme }: ThemeFunction<unknown>) =>
      rgba(theme.accentColor, 0.2),
    marginBottom: 1,
    borderRadius: 8,
    padding: 8,
    alignItems: 'center',
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
  },
};
