import OutlinedFlagIcon from '@mui/icons-material/OutlinedFlag';
import { useIntl } from 'react-intl';

import type { ComplaintContentType } from '@braindate/domain/lib/complaint/type';

import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import useComplaintText from 'src/shared/components/domain/complaints/hooks/useComplaintText';
import messages from 'src/shared/components/domain/complaints/l10n/complaintsL10n';
import PrimaryButton from 'src/shared/ui/component/button/PrimaryButton';
import Heading from 'src/shared/ui/component/text/Heading';

import styles from './ReportingSuccess.style';

const useStyles = createUseThemeStyles(styles);
type Props = {
  contentType: ComplaintContentType;
  onClose: () => void;
};

const ReportingSuccess = ({ contentType, onClose }: Props) => {
  const intl = useIntl();
  const classes = useStyles();
  const { success } = useComplaintText(contentType);
  return (
    <div className={classes.root}>
      <OutlinedFlagIcon className={classes.icon} />

      <Heading level={2}>{success}</Heading>

      <p>{intl.formatMessage(messages.success)}</p>

      <PrimaryButton onClick={onClose}>
        {intl.formatMessage(messages.close)}
      </PrimaryButton>
    </div>
  );
};

export default ReportingSuccess;
