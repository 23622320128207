import { memo } from 'react';

import { useIntl } from 'react-intl';

import type { Braindate } from '@braindate/domain/lib/braindate/type';
import {
  getBraindateLocation,
  getBraindatePath,
} from '@braindate/domain/lib/braindate/util';
import { isVirtualLocation } from '@braindate/domain/lib/location/util';

import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import messages from 'src/shared/app/braindate/l10n/braindateL10n';
import CheckInActionButton from 'src/shared/app/check-in/component/notification/CheckInActionButton';
import {
  isBraindateCheckInReady,
  isBraindateVideoCallReady,
} from 'src/shared/domain/braindate/util/braindateUtil';
import useIsSelfCheckinEnabled from 'src/shared/domain/event/hook/useIsSelfCheckinEnabled';
import LinkButton from 'src/shared/ui/component/link/LinkButton';

import styles from './UpcomingBraindateCardAction.style';

const useStyles = createUseThemeStyles(styles);
type Props = {
  braindate: Braindate;
  showCheckin: boolean;
};

const UpcomingBraindateCardAction = ({ braindate, showCheckin }: Props) => {
  const intl = useIntl();
  const classes = useStyles();
  const isSelfCheckInEnabled = useIsSelfCheckinEnabled();
  const location = getBraindateLocation(braindate);
  const isVirtual = location && isVirtualLocation(location);
  const isReady = isVirtual
    ? isBraindateVideoCallReady(braindate)
    : isBraindateCheckInReady(braindate);

  if (!showCheckin || !isSelfCheckInEnabled || !isReady) {
    return (
      <LinkButton to={getBraindatePath(braindate)} variant="primary">
        {intl.formatMessage(messages.message)}
      </LinkButton>
    );
  }

  return (
    <div className={classes.root}>
      <CheckInActionButton braindate={braindate} />
    </div>
  );
};

export default memo<Props>(UpcomingBraindateCardAction);
