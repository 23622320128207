import type { State } from 'src/shared/core/type/reduxTypes';

export function isVideoOverlayForced(state: State): boolean {
  return state.ui.virtualBraindate.forceVideoOverlay;
}
export function getVirtualBraindateOverlayShown(state: State): number {
  return state.ui.virtualBraindate.overlayShown;
}
export function isVirtualBraindateTimezoneConfirmed(state: State): boolean {
  return state.ui.virtualBraindate.isTimezoneConfirmed;
}
