import * as types from 'src/shared/app/authentication/action/base/authenticationActionTypes';
import { saveTokenInCookie } from 'src/shared/app/base/util/tokenUtils';
import * as signupTypes from 'src/shared/app/signup/action/signupActionTypes';
import { createReducer } from 'src/shared/core/util/reducerUtils';
import { isClient } from 'src/shared/core/util/ssrUtil';

/*
|------------------------------------------------------------------------------
| INITIAL STATE
|------------------------------------------------------------------------------
*/
export const initialState = {
  token: '',
  tokenPassedAsQueryParam: false,
  resetToken: '',
  uid: '',
  cid: '',
  email: '',
  url: '',
  isPasswordSet: false,
  isPasswordLinkSent: false,
  isPasswordLinkInvalid: false,
  isPasswordReset: false,
  loggedInWithBOS: false,
  isVerificationEmailSending: false,
  isVerificationEmailSent: false,
};

/*
|------------------------------------------------------------------------------
| REDUCERS
|------------------------------------------------------------------------------
*/
const _setToken = (
  state: Record<string, any>,
  action: Record<string, any>,
): Record<string, any> => {
  const { token, tokenPassedAsQueryParam, event, tokenBearer } = action;

  // @TODO - TEMP-AUTH
  if (isClient) {
    // @TODO - TEMP-AUTH Use env provider
    saveTokenInCookie(token, true, event, tokenBearer);
    return { ...state, tokenPassedAsQueryParam };
  }

  return { ...state, token, tokenBearer, tokenPassedAsQueryParam };
};

const _unsetToken = (state: Record<string, any>): Record<string, any> => ({
  ...state,
  token: '',
  tokenPassedAsQueryParam: false,
});

const _setAuthenticationEmail = (
  state: Record<string, any>,
  action: Record<string, any>,
): Record<string, any> => {
  const { email } = action;
  return { ...state, email };
};

const _unsetAuthenticationEmail = (
  state: Record<string, any>,
): Record<string, any> => ({ ...state, email: '' });

const _setAuthenticationUid = (
  state: Record<string, any>,
  action: Record<string, any>,
): Record<string, any> => {
  const { uid } = action;
  return { ...state, uid };
};

const _unsetAuthenticationUid = (
  state: Record<string, any>,
): Record<string, any> => ({ ...state, uid: '' });

const _setAuthenticationCid = (
  state: Record<string, any>,
  action: Record<string, any>,
): Record<string, any> => {
  const { cid } = action;
  return { ...state, cid };
};

const _unsetAuthenticationCid = (
  state: Record<string, any>,
): Record<string, any> => ({ ...state, cid: '' });

const _setAuthenticationResetToken = (
  state: Record<string, any>,
  action: Record<string, any>,
): Record<string, any> => {
  const { token } = action;
  return { ...state, resetToken: token };
};

const _unsetAuthenticationResetToken = (
  state: Record<string, any>,
): Record<string, any> => ({ ...state, resetToken: '' });

const _verifyEmailRequest = (
  state: Record<string, any>,
): Record<string, any> => ({
  ...state,
  isVerificationEmailSending: true,
  isVerificationEmailSent: false,
});

const _verifyEmailSuccess = (
  state: Record<string, any>,
): Record<string, any> => {
  return {
    ...state,
    isVerificationEmailSending: false,
    isVerificationEmailSent: true,
  };
};

const _verifyEmailFailure = (
  state: Record<string, any>,
): Record<string, any> => {
  return {
    ...state,
    isVerificationEmailSending: false,
    isVerificationEmailSent: false,
  };
};

const _checkPassword = (
  state: Record<string, any>,
  action: Record<string, any>,
): Record<string, any> => {
  const { email, isPasswordSet } = action;
  return { ...state, email, isPasswordSet };
};

const _toggleOffPasswordLinkSent = (
  state: Record<string, any>,
): Record<string, any> => ({ ...state, isPasswordLinkSent: false });

const _toggleOnPasswordLinkSent = (
  state: Record<string, any>,
  action: Record<string, any>,
): Record<string, any> => {
  const { email } = action;
  return { ...state, email, isPasswordLinkSent: true };
};

const _toggleOffPasswordLinkInvalid = (
  state: Record<string, any>,
): Record<string, any> => ({ ...state, isPasswordLinkInvalid: false });

const _toggleOnPasswordLinkInvalid = (
  state: Record<string, any>,
): Record<string, any> => ({ ...state, isPasswordLinkInvalid: true });

const _toggleOnPassordReset = (
  state: Record<string, any>,
  action: Record<string, any>,
): Record<string, any> => {
  const { token } = action;
  return {
    ...state,
    token,
    tokenPassedAsQueryParam: false,
    isPasswordReset: true,
  };
};

const _setInitialUrl = (
  state: Record<string, any>,
  action: Record<string, any>,
): Record<string, any> => {
  const { url } = action;
  return { ...state, url };
};

const _resetInitialUrl = (state: Record<string, any>): Record<string, any> => ({
  ...state,
  url: '',
});

const _setLoggedInWithBOS = (
  state: Record<string, any>,
): Record<string, any> => ({ ...state, loggedInWithBOS: true });
/*
|------------------------------------------------------------------------------
| REDUCER
|------------------------------------------------------------------------------
*/

export default createReducer(initialState, {
  [types.SET_AUTHENTICATION_TOKEN]: _setToken,
  [types.UNSET_AUTHENTICATION_TOKEN]: _unsetToken,
  [types.SET_AUTHENTICATION_EMAIL]: _setAuthenticationEmail,
  [types.UNSET_AUTHENTICATION_EMAIL]: _unsetAuthenticationEmail,
  [types.SET_AUTHENTICATION_UID]: _setAuthenticationUid,
  [types.UNSET_AUTHENTICATION_UID]: _unsetAuthenticationUid,
  [types.SET_AUTHENTICATION_CID]: _setAuthenticationCid,
  [types.UNSET_AUTHENTICATION_CID]: _unsetAuthenticationCid,
  [types.SET_AUTHENTICATION_RESET_TOKEN]: _setAuthenticationResetToken,
  [types.UNSET_AUTHENTICATION_RESET_TOKEN]: _unsetAuthenticationResetToken,
  [types.VERIFY_EMAIL_REQUEST]: _verifyEmailRequest,
  [types.VERIFY_EMAIL_SUCCESS]: _verifyEmailSuccess,
  [types.VERIFY_EMAIL_FAILURE]: _verifyEmailFailure,
  [types.VALIDATE_EMAIL_SUCCESS]: _checkPassword,
  [types.CREATE_PASSWORD_SUCCESS]: _setToken,
  [types.SEND_PASSWORD_LINK_REQUEST]: _toggleOffPasswordLinkSent,
  [types.SEND_PASSWORD_LINK_FAILURE]: _toggleOffPasswordLinkSent,
  [types.SEND_PASSWORD_LINK_SUCCESS]: _toggleOnPasswordLinkSent,
  [types.VALIDATE_PASSWORD_LINK_REQUEST]: _toggleOffPasswordLinkInvalid,
  [types.VALIDATE_PASSWORD_LINK_SUCCESS]: _toggleOffPasswordLinkInvalid,
  [types.VALIDATE_PASSWORD_LINK_FAILURE]: _toggleOnPasswordLinkInvalid,
  [types.RESET_PASSWORD_LINK_SENT]: _toggleOffPasswordLinkSent,
  [types.RESET_PASSWORD_SUCCESS]: _toggleOnPassordReset,
  [types.LOGIN_SUCCESS]: _setToken,
  [signupTypes.SIGNUP_CREATE_USER_SUCCESS]: _setToken,
  [types.LOGIN_FAILURE]: _unsetToken,
  [types.SAVE_INITIAL_URL]: _setInitialUrl,
  [types.RESET_INITIAL_URL]: _resetInitialUrl,
  [types.SET_LOGGED_IN_WITH_BOS]: _setLoggedInWithBOS,
});
