import { triggerModal } from 'src/shared/app/base/modal/slice/modalSlice';

export function triggerErrorModal(
  id: string,
  message:
    | string
    | Error
    | {
        json: {
          error: string;
        };
        message: string;
      },
  onClose?: () => void,
) {
  const getErrorMessage = () => {
    if (typeof message === 'string') return message;
    if (message.json && message.json.error) return message.json.error;
    return message.message;
  };

  return (dispatch: any) =>
    dispatch(
      triggerModal(id, {
        message: getErrorMessage(),
        onClose,
      }),
    );
}
