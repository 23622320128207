import { memo, useRef, useState } from 'react';

import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { IconButton, Tooltip, useMediaQuery } from '@mui/material';
import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { lap } from '@braindate/ui/lib/token/mediaQuery';

import useRouteMatch from 'src/shared/app/base/route/hook/useRouteMatch';
import useRoutes from 'src/shared/app/base/route/hook/useRoutes';
import {
  loginRoute,
  signupRoute,
} from 'src/shared/app/base/route/setting/routeSettings';
import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import useFeatureDecisions from 'src/shared/app/feature/hook/useFeatureDecisions';
import NewTopicFab from 'src/shared/app/help/component/NewTopicFab';
import messages from 'src/shared/app/help/l10n/helpL10n';
import { isHelpButtonHidden } from 'src/shared/app/help/selector/uiHelpButtonSelectors';
import { isSignupCompleted } from 'src/shared/app/signup/selector/uiSignupSelector';
import { trackGAEvent } from 'src/shared/core/service/tracking/gaService';
import useSelf from 'src/shared/domain/user/hook/useSelf';

import styles from './HelpButton.style';
import HelpPopover from './HelpPopover';

const useStyles = createUseThemeStyles(styles);
type Props = {
  layoutClass?: string;
};

const HelpButton = ({ layoutClass }: Props) => {
  const self = useSelf();
  const intl = useIntl();
  const { isDrawerMenuEnabled } = useFeatureDecisions();
  const classes = useStyles({
    isDrawerMenuEnabled,
  });
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const isDesktop = useMediaQuery(lap);
  const isHidden = useSelector(isHelpButtonHidden);
  const [popoverOpened, setPopoverOpened] = useState<boolean>(false);
  const route = useRouteMatch();

  const onClick = () => {
    trackGAEvent('Help', 'Click help', 'From floating button');
    setPopoverOpened(true);
  };

  const signupCompleted = useSelector((state) => {
    if (!self) return false;
    return isSignupCompleted(state, self);
  });
  // ON mobile:
  // Help/FAQ floating icon ONLY appears on mobile sign-up/login pages, remove everywhere else
  // New topic creation floating icon appears on every page, except for topic creation, COI and time picker/calendar picker
  const mobilePagesToShowHelp = useRoutes(loginRoute, signupRoute);
  const showNewTopicButton =
    !isDesktop &&
    !mobilePagesToShowHelp.includes(route.path) &&
    signupCompleted &&
    isDrawerMenuEnabled;
  if (isHidden) return null;

  if (showNewTopicButton) {
    return <NewTopicFab />;
  }

  return (
    <>
      <Tooltip title={intl.formatMessage(messages.tooltip)} arrow>
        <IconButton
          color="inherit"
          variant="outlined"
          aria-label={intl.formatMessage(messages.openHelpWindow)}
          onClick={onClick}
          classes={{
            root: classNames(classes.root, layoutClass),
          }}
          ref={buttonRef}
        >
          <SupportAgentIcon className={classes.icon} color="inherit" />
        </IconButton>
      </Tooltip>

      <HelpPopover
        open={popoverOpened}
        anchorEl={buttonRef.current}
        onClose={() => setPopoverOpened(false)}
      />
    </>
  );
};

export default memo<Props>(HelpButton);
