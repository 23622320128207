import { createSelector } from '@reduxjs/toolkit';

import type {
  BraindateEvent,
  BraindateEventPublicData,
} from '@braindate/domain/lib/event/type';
import {
  getEventCustomId,
  getEventPlugins,
  hydrateEvent,
} from '@braindate/domain/lib/event/util';

import {
  getSearchStringFromLocation,
  getSearchStringFromQuery,
} from 'src/shared/app/authentication/util/urlUtils';
import { getShowcasePageUrl } from 'src/shared/app/base/selector/appEnvSelectors';
import type { State } from 'src/shared/core/type/reduxTypes';

import type { Location } from 'react-router-dom';

/*
 |------------------------------------------------------------------------------
 | Slice selectors
 |------------------------------------------------------------------------------
 */
const getAppEventId = (state) => state.app.event;

const getEntities = (state) => state.domain.entities;

const getPublicEvents = (state) => state.domain.public.events;

/*
 |------------------------------------------------------------------------------
 | Exports
 |------------------------------------------------------------------------------
 */
export const getEventId: (arg0: State) => number = createSelector(
  [getAppEventId],
  (x) => x,
);
export const getEvent: (arg0: State) => BraindateEvent | null | undefined =
  createSelector([getAppEventId, getEntities], (id, entities) =>
    hydrateEvent(id, entities),
  );
export const getEventPublicData: (
  arg0: State,
) => BraindateEventPublicData | null | undefined = createSelector(
  [getAppEventId, getPublicEvents],
  (id, events) => events[id],
);
export function getEventSafely(state: State): BraindateEvent {
  // $FlowIssue: FIXME
  return getEvent(state) || getEventPublicData(state);
}
export function getEventShowcasePageUrl(
  state: State,
  location?: Location | null | undefined,
  query?: Record<string, any> | null | undefined,
): string | null | undefined {
  const event = getEventSafely(state);
  const plugins = getEventPlugins(event);

  if (plugins.showcase_page) {
    const customId = getEventCustomId(event);
    const url = getShowcasePageUrl(state);

    if (url) {
      const searchString = location
        ? getSearchStringFromLocation(location)
        : getSearchStringFromQuery(query);
      return `${url.replace('{}', customId)}?${searchString}`;
    }
  }

  return null;
}
