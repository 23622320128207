import { createRef, memo, useEffect, useState } from 'react';
import type { Node } from 'react';

import classNames from 'classnames';
import ReactModal from 'react-modal';

import { modalId } from 'src/shared/app/base/constant/domConstants';
import useTrapTabs from 'src/shared/app/base/hook/useTrapTabs';
import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';

import styles, { fadeDuration } from './AccessibleModal.style';

type Props = {
  children: Node;
  label?: string;
  contentClass?: string;
  overlayClass?: string;
  forceClose?: boolean;
  shouldCloseOnOverlayClick?: boolean;
  customStyles?: Record<string, any>;
  handleAfterOpen?: () => void;
  handleAfterClose?: () => void;
  handleRequestClose?: () => void;
};
const useStyles = createUseThemeStyles(styles);

const AccessibleModal = ({
  children,
  label,
  contentClass,
  overlayClass,
  forceClose,
  shouldCloseOnOverlayClick = true,
  customStyles = {},
  handleAfterOpen,
  handleAfterClose,
  handleRequestClose,
}: Props): Node => {
  /*
   |----------------------------------------------------------------------------
   | State
   |----------------------------------------------------------------------------
   */
  const [isOpen, setOpen] = useState(true);

  /*
   |----------------------------------------------------------------------------
   | Refs
   |----------------------------------------------------------------------------
   */
  const rootRef = createRef();
  useTrapTabs(rootRef);

  /*
   |----------------------------------------------------------------------------
   | Effects
   |----------------------------------------------------------------------------
   */
  useEffect(() => {
    if (forceClose) {
      setOpen(false);
    }
  }, [forceClose]);

  /*
   |----------------------------------------------------------------------------
   | Event Handlers
   |----------------------------------------------------------------------------
   */
  const onRequestClose = () => {
    setOpen(false);

    if (handleRequestClose) {
      handleRequestClose();
    }
  };

  /*
   |----------------------------------------------------------------------------
   | Classes
   |----------------------------------------------------------------------------
   */
  const classes = useStyles();

  /*
   |----------------------------------------------------------------------------
   | Elements
   |----------------------------------------------------------------------------
   */
  return (
    <ReactModal
      className={contentClass}
      style={customStyles}
      isOpen={isOpen}
      contentLabel={label}
      closeTimeoutMS={fadeDuration}
      htmlOpenClassName={classes.html}
      bodyOpenClassName={classes.body}
      overlayClassName={{
        base: classNames(classes.overlay, overlayClass),
        afterOpen: classes['overlay-afterOpen'],
        beforeClose: classes['overlay-beforeClose'],
      }}
      parentSelector={() => document.getElementById(modalId)}
      onAfterOpen={handleAfterOpen}
      onAfterClose={handleAfterClose}
      onRequestClose={onRequestClose}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
    >
      {children}
    </ReactModal>
  );
};

export default memo<Props>(AccessibleModal);
