import { brightRed } from '@braindate/ui/lib/token/palette';

export default {
  root: {
    display: 'inline-block',
    width: 10,
    height: 10,
    backgroundColor: brightRed,
    borderRadius: '50%',
  },
};
