import { em } from 'polished';

import { tablet } from '@braindate/ui/lib/token/mediaQuery';
import { baseFontSize } from '@braindate/ui/lib/token/typography';

import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

export default {
  root: {
    fontSize: em(14, baseFontSize),
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
  },
  icon: {
    fontSize: `${em(18, baseFontSize)} !important`,
    color: ({
      theme,
      withAccent,
    }: ThemeFunction<{
      withAccent: boolean;
    }>) => (withAccent ? theme.accentColor : 'currentColor'),
  },
  text: {
    display: 'block',
  },
  textContent: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: ({ isBlock }: Record<string, any>) =>
      isBlock ? 'column' : 'row',
    fontWeight: () => 800,
  },
  locationTitle: {
    fontSize: em(16, baseFontSize),
    fontWeight: 600,
  },
  location: {
    display: ({ isCompactForMobile }: Record<string, any>) =>
      isCompactForMobile ? 'none' : 'initial',
    order: 0,
    fontSize: ({ isBlock }: Record<string, any>) => isBlock && '0.8em',
    fontWeight: ({ isBlock }: Record<string, any>) => isBlock && 300,
    fontStyle: ({ isBlock }: Record<string, any>) => isBlock && 'italic',
    [tablet]: {
      display: () => 'initial',
    },
  },
  lounge: ({ isBlock }: Record<string, any>) =>
    isBlock && {
      marginBottom: '0.4em',
      order: -1,
    },
};
