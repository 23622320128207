import type { Node } from 'react';

import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import messages from 'src/shared/app/moderation/l10n/moderationL10n';
import { moderationAcceptedNotificationTimeout } from 'src/shared/app/moderation/settings/moderationSettings';
import { dismissNotification } from 'src/shared/app/notification/action/uiNotificationActions';
import Notification from 'src/shared/app/notification/component/Notification';
import type { Notification as NotificationType } from 'src/shared/app/notification/type/notificationTypes';

type Props = {
  notification: NotificationType<any>;
};

const ModerationAcceptedNotificationDumb = ({ notification }: Props): Node => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const handleDismiss = () => {
    dispatch(dismissNotification(notification));
  };

  return (
    <Notification
      timeout={moderationAcceptedNotificationTimeout}
      handleDismiss={handleDismiss}
    >
      {intl.formatMessage(messages.moderationAcceptedNotification)}
    </Notification>
  );
};

export default ModerationAcceptedNotificationDumb;
