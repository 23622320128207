import { memo, useState } from 'react';
import type { Node } from 'react';

import { Field } from 'formik';
import { useId } from 'react-aria';
import { useDebouncedCallback } from 'use-debounce';

import type { User } from '@braindate/domain/lib/user/type';
import { getUserFullName, getUserId } from '@braindate/domain/lib/user/util';

import { useGetUsersQuery } from 'src/shared/app/base/api/endpoint/usersEndpoint';
import FormikAutocomplete from 'src/shared/app/base/component/data-entry/form/FormikAutocomplete';
import FormikFormGroup from 'src/shared/app/base/component/data-entry/form/FormikFormGroup';
import UserAvatar, {
  xsmall as xSmallUserAvatar,
} from 'src/shared/components/domain/user/avatar/UserAvatar';
import UserBanner, {
  small,
} from 'src/shared/components/domain/user/banner/UserBanner';

type Props = {
  handleChange?: () => void;
  suppParams?: Record<string, any>;
  className?: string;
  possibleValues: User[];
  label: string;
  readOnly?: boolean;
  disabled?: boolean;
};

const FieldUserAutocomplete = ({
  handleChange,
  suppParams,
  className,
  possibleValues,
  label,
  readOnly,
  disabled,
}: Props): Node => {
  const [searchValue, setSearchValue] = useState<string>('');
  const debouncedHandleSearch = useDebouncedCallback(setSearchValue, 200);

  const handleSearchInput = (value: string) => debouncedHandleSearch(value);

  const { data, isFetching } = useGetUsersQuery(
    {
      limit: 10,
      ...(searchValue
        ? {
            q: searchValue,
          }
        : {}),
      ...(suppParams || {}),
    },
    {
      skip: !!possibleValues,
    },
  );
  const { results } = data || {
    results: [],
  };
  const users = possibleValues || results;
  const id = useId();
  return (
    <Field
      id={id}
      name="user"
      required
      onChange={handleChange}
      component={FormikFormGroup}
      componentOpts={{
        label,
        inputComp: FormikAutocomplete,
        className,
        inputOpts: {
          isFetching,
          readOnly,
          disabled,
          options: users,
          renderOption: (props, value) => (
            <span {...props}>
              <UserBanner user={value} variant={small} />
            </span>
          ),
          isOptionEqualToValue: (option, value) =>
            option && value && getUserId(option) === getUserId(value),
          getOptionLabel: getUserFullName,
          onInputChange: handleSearchInput,
          startAdornment: (value) => (
            <UserAvatar user={value} variant={xSmallUserAvatar} />
          ),
        },
      }}
    />
  );
};

export default memo<Props>(FieldUserAutocomplete);
