import { createRef, memo, useEffect, useState } from 'react';
import type { Node } from 'react';

import classNames from 'classnames';

import Container from 'src/shared/app/base/component/container/Container';
import useTrapTabs from 'src/shared/app/base/hook/useTrapTabs';
import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import {
  disableBodyScrolling,
  enableBodyScrolling,
} from 'src/shared/core/util/browserUtil';
import CloseButton from 'src/shared/ui/component/button/CloseButton';

import styles from './TakeOver.style';

type Props = {
  bypassOpeningAnimation?: boolean;
  hideCloseButton?: boolean;
  forceClosing?: boolean;
  handleOpen?: () => void;
  handleDismiss?: () => void;
  children: Node;
};
const useStyles = createUseThemeStyles(styles);

const TakeOver = ({
  bypassOpeningAnimation,
  hideCloseButton,
  forceClosing,
  children,
  handleOpen,
  handleDismiss,
}: Props): Node => {
  /*
   |----------------------------------------------------------------------------
   | State
   |----------------------------------------------------------------------------
   */
  const [isHidden, setIsHidden] = useState(!bypassOpeningAnimation);

  /*
   |----------------------------------------------------------------------------
   | Effects
   |----------------------------------------------------------------------------
   */
  useEffect(() => {
    if (!bypassOpeningAnimation) {
      open();
    }

    disableBodyScrolling();
    return () => {
      enableBodyScrolling();
    };
  }, []);
  useEffect(() => {
    if (forceClosing) {
      close();
    }
  }, [forceClosing]);

  /*
   |----------------------------------------------------------------------------
   | Event Handlers
   |----------------------------------------------------------------------------
   */
  const onDismiss = () => close();

  const onTransitionEnd = ({ target }: TransitionEvent) => {
    const { current: rootElt } = rootRef;

    if (target === rootElt) {
      if (isHidden) {
        if (handleDismiss) {
          handleDismiss();
        }
      } else if (handleOpen) {
        handleOpen();
      }
    }
  };

  /*
   |----------------------------------------------------------------------------
   | Functions
   |----------------------------------------------------------------------------
   */
  const open = () => {
    window.requestAnimationFrame(() =>
      window.requestAnimationFrame(() => setIsHidden(false)),
    );
  };

  const close = () => {
    window.requestAnimationFrame(() =>
      window.requestAnimationFrame(() => setIsHidden(true)),
    );
  };

  /*
   |----------------------------------------------------------------------------
   | Classes
   |----------------------------------------------------------------------------
   */
  const classes = useStyles();
  const rootClasses = classNames(classes.root, {
    [classes['root-isHidden']]: isHidden,
  });

  /*
   |----------------------------------------------------------------------------
   | Refs
   |----------------------------------------------------------------------------
   */
  const rootRef = createRef();
  useTrapTabs(rootRef);

  /*
   |----------------------------------------------------------------------------
   | Elements
   |----------------------------------------------------------------------------
   */
  const closeBtn = !hideCloseButton && (
    <CloseButton circled layoutClass={classes.closeBtn} onClick={onDismiss} />
  );
  return (
    <div
      className={rootClasses}
      onTransitionEnd={onTransitionEnd}
      ref={rootRef}
    >
      <Container>
        <div className={classes.inner}>
          {closeBtn}
          {children}
        </div>
      </Container>
    </div>
  );
};

export default memo<Props>(TakeOver);
