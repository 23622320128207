/** @jsxImportSource @emotion/react */
import { memo } from 'react';
import type { Node } from 'react';

import { css } from '@emotion/react';

import useCreateBaseStyleProps from 'src/shared/app/base/hook/useCreateBaseStyleProps';
import BaseIcon from 'src/shared/ui/component/icon/BaseIcon';

import svg from './bell.svg';
import styles from './BellIcon.style';

type Props = {
  className?: string;
  title?: string;
};

const BellIcon = ({ className, title }: Props): Node => {
  const theme = useCreateBaseStyleProps();
  const classes = styles({ theme: theme.theme });

  return (
    <BaseIcon css={css(classes.root, className)} svg={svg} title={title} />
  );
};

export default memo<Props>(BellIcon);
