import { isSelfCheckinEnabled } from '@braindate/domain/lib/event/util';

import useEvent from 'src/shared/domain/event/hook/useEvent';

const useIsSelfCheckinEnabled = () => {
  const event = useEvent();
  return isSelfCheckinEnabled(event);
};

export default useIsSelfCheckinEnabled;
