import { rem } from 'polished';

import { baseFontSize } from '@braindate/ui/lib/token/typography';

export default {
  form: {
    '& label': {
      textAlign: 'left',
    },
  },
  labelSubtitle: {
    fontWeight: 300,
    fontStyle: 'italic',
    fontSize: rem(14, baseFontSize),
  },
  checkboxList: {
    paddingTop: '1em',
  },
  group: {
    paddingTop: '2em',
  },
  subTextarea: {
    marginLeft: 22,
    paddingLeft: rem(16, baseFontSize),
    '& label': {
      fontWeight: 300,
      fontStyle: 'italic',
      fontSize: rem(14, baseFontSize),
      marginTop: 0,
    },
  },
  more: {
    marginLeft: 22,
    paddingLeft: rem(16, baseFontSize),
    maxWidth: 358,
    '& label': {
      fontWeight: 300,
      fontStyle: 'italic',
      fontSize: rem(14, baseFontSize),
      marginTop: '0px !important',
    },
  },
  actions: {
    textAlign: 'center',
    marginTop: '2em',
  },
  ratings: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    gap: '12px',
    border: 'none',
    padding: [12, 0],
  },
  ratingButton: {
    width: 54,
    height: 54,
    padding: 0,
    margin: '0 !important',
    borderRadius: '50%',
    '& svg': {
      width: 54,
      height: 54,
    },
  },
  error: {
    position: 'relative',
  },
};
