import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

export default {
  root: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10px',
    marginTop: 20,
    marginBottom: 28,
  },
  title: {
    margin: 0,
    color: ({ theme }: ThemeFunction<unknown>) => theme.favoriteIconColor,
  },
  logo: {
    maxWidth: 45,
  },
};
