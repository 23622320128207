import { memo, useEffect, useRef, useState } from 'react';
import type { Node } from 'react';

import { Popover } from '@mui/material';
import { useId } from 'react-aria';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';

import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import messages from 'src/shared/app/nav/l10n/navigationL10n';
import MobileMenuIcon from 'src/shared/ui/component/icon/MobileMenuIcon';

import MainNavLink from './MainNavLink';
import styles from './MainNavSubMenu.style';

const useStyles = createUseThemeStyles(styles);
type Props = {
  className?: string;
  items: Node[];
};

const MainNavSubMenu = ({ className, items }: Props): Node => {
  /*
  |------------------------------------------------------------------------------
  | States
  |------------------------------------------------------------------------------
  */
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMenu = () => setIsMobileMenuOpen((prevState) => !prevState);

  const anchorRef = useRef();

  /*
    |------------------------------------------------------------------------------
    | Hooks
    |------------------------------------------------------------------------------
  */
  const mobileMenuRef = useRef(null);
  const location = useLocation();
  const intl = useIntl();
  const classes = useStyles();
  const subMenuId = useId();
  useEffect(() => {
    if (isMobileMenuOpen) setIsMobileMenuOpen(false);
  }, [location.pathname]);
  return (
    <li className={className} ref={anchorRef}>
      <button
        type="button"
        onClick={(e) => {
          e.preventDefault();
          toggleMenu();
        }}
        aria-expanded={isMobileMenuOpen}
        aria-controls={subMenuId}
        aria-label={intl.formatMessage(messages.submenu)}
        className={classes.item}
      >
        <MainNavLink
          title={intl.formatMessage(messages.more)}
          icon={<MobileMenuIcon />}
          disableActiveBackgroundColor
        />
      </button>

      <Popover
        id={subMenuId}
        open={isMobileMenuOpen}
        onClose={() => setIsMobileMenuOpen(false)}
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <ul className={classes.mobileMenu} ref={mobileMenuRef}>
          {items.map((item, key) => (
            <li className={classes.mobileMenuItem} key={key}>
              {item}
            </li>
          ))}
        </ul>
      </Popover>
    </li>
  );
};

export default memo<Props>(MainNavSubMenu);
