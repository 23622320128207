import { memo } from 'react';
import type { Node } from 'react';

import classNames from 'classnames';
import { isEmpty } from 'ramda';

import type { User } from '@braindate/domain/lib/user/type';
import {
  getUserAvatar,
  getUserFullName,
  isUserOnline,
} from '@braindate/domain/lib/user/util';

import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import InitialsAvatar from 'src/shared/components/domain/user/avatar/InitialsAvatar';
import UserBadge, {
  large as userBadgeLarge,
} from 'src/shared/components/domain/user/badge/UserBadge';
import UserOnlineBadge, {
  large as userOnlineBadgeLarge,
} from 'src/shared/components/domain/user/badge/UserOnlineBadge';
import Avatar from 'src/shared/ui/component/image/Avatar';
import DefaultAvatar from 'src/shared/ui/component/image/DefaultAvatar';
import {
  large,
  medium,
  mobilePeopleCardSize,
  peopleCardSize,
  small,
  xlarge,
  xsmall,
  xxlarge,
} from 'src/shared/ui/component/image/setting/avatarVariants';
import type { AvatarProps } from 'src/shared/ui/component/image/type/avatarTypes';

import styles from './UserAvatar.style';

type Props = AvatarProps & {
  user: User;
  className?: string;
  showOnlineBadge?: boolean;
  isHost?: boolean;
  useDefaultAvatar?: boolean;
  onDarkBackground?: boolean;
  'aria-label'?: string;
};
const useStyles = createUseThemeStyles(styles);

const UserAvatar = ({
  className,
  layoutClass,
  variant,
  customSize,
  user,
  showOnlineBadge,
  isHost,
  onDarkBackground,
  useDefaultAvatar,
  'aria-label': ariaLabel,
}: Props): Node => {
  const avatar = getUserAvatar(user);

  /*
   |----------------------------------------------------------------------------
   | Classes
   |----------------------------------------------------------------------------
   */
  const classes = useStyles({
    isHost,
  });
  const userOnlineBadgeClasses = classNames(classes.badge, {
    [classes['badge-small']]: variant === small,
    [classes['badge-xsmall']]: variant === xsmall,
    [classes['badge-medium']]: variant === medium,
    [classes['badge-large']]: variant === large,
    [classes['badge-xlarge']]: variant === xlarge,
    [classes['badge-xxlarge']]: variant === xxlarge,
    [classes['badge-mobilePeopleCardSize']]: variant === mobilePeopleCardSize,
    [classes['badge-peopleCardSize']]: variant === peopleCardSize,
  });
  const userBadgeClasses = classNames(classes.userBadge, {
    [classes['user-badge-small']]: variant === small,
    [classes['user-badge-xsmall']]: variant === xsmall,
    [classes['user-badge-medium']]: variant === medium,
    [classes['user-badge-large']]: variant === large,
    [classes['user-badge-xlarge']]: variant === xlarge,
    [classes['user-badge-xxlarge']]: variant === xxlarge,
    [classes['user-badge-mobilePeopleCardSize']]:
      variant === mobilePeopleCardSize,
    [classes['user-badge-peopleCardSize']]: variant === peopleCardSize,
  });

  /*
   |----------------------------------------------------------------------------
   | Elements
   |----------------------------------------------------------------------------
   */
  const avatarProps = {
    layoutClass,
    variant,
    customSize,
    alt: getUserFullName(user),
    'aria-label': ariaLabel,
  };
  let avatarElt = <InitialsAvatar {...avatarProps} user={user} />;

  if (avatar && !isEmpty(avatar)) {
    avatarElt = <Avatar {...avatarProps} src={avatar} />;
  } else if (useDefaultAvatar) {
    avatarElt = <DefaultAvatar {...avatarProps} />;
  }

  const userOnlineBadgeElt = showOnlineBadge && isUserOnline(user) && (
    <UserOnlineBadge
      layoutClass={userOnlineBadgeClasses}
      variant={
        [large, xlarge, xxlarge, mobilePeopleCardSize, peopleCardSize].includes(
          variant,
        )
          ? userOnlineBadgeLarge
          : null
      }
      onDarkBackground={onDarkBackground}
    />
  );
  const userBadgeElt = isHost && (
    <UserBadge
      layoutClass={userBadgeClasses}
      variant={
        [large, xlarge, xxlarge, mobilePeopleCardSize, peopleCardSize].includes(
          variant,
        )
          ? userBadgeLarge
          : null
      }
      onDarkBackground={onDarkBackground}
    />
  );
  return (
    <div className={classNames(classes.root, className)}>
      {avatarElt}
      {userOnlineBadgeElt}
      {userBadgeElt}
    </div>
  );
};

// Make variants available
export * from 'src/shared/ui/component/image/setting/avatarVariants';
export default memo<Props>(UserAvatar);
