import { useEffect } from 'react';

import { Field, useField } from 'formik';
import { useIntl } from 'react-intl';
import { TransitionStatus } from 'react-transition-group';

import type { Braindate } from '@braindate/domain/lib/braindate/type';
import { getBraindateId } from '@braindate/domain/lib/braindate/util';
import { getTopicTitle } from '@braindate/domain/lib/topic/util';
import { getUserFirstName } from '@braindate/domain/lib/user/util';

import { useGetPastBraindatesQuery } from 'src/shared/app/base/api/endpoint/braindatesEndpoint';
import FormikAutocomplete from 'src/shared/app/base/component/data-entry/form/FormikAutocomplete';
import FormikFormGroup from 'src/shared/app/base/component/data-entry/form/FormikFormGroup';
import FormikSelect from 'src/shared/app/base/component/data-entry/form/FormikSelect';
import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import CheerModalSponsors from 'src/shared/app/cheer/component/modal/CheerModalSponsors';
import CheerModalWrapper from 'src/shared/app/cheer/component/modal/CheerModalWrapper';
import messages from 'src/shared/app/cheer/l10n/cheerl10n.json';
import defaultCheersMessages from 'src/shared/app/cheer/l10n/defaultCheersl10n.json';
import {
  cheerCategoryDelightful,
  cheerCategoryFearless,
  cheerCategoryGenerous,
  cheerCategoryInsightful,
  cheersMessageMaxLength,
} from 'src/shared/app/cheer/util/cheerUtils';
import UserAvatar, {
  small,
} from 'src/shared/components/domain/user/avatar/UserAvatar';
import {
  getBraindateTopic,
  getCommonBraindatesWithUser,
} from 'src/shared/domain/braindate/util/braindateUtil';
import useSelf from 'src/shared/domain/user/hook/useSelf';
import PrimaryButton from 'src/shared/ui/component/button/PrimaryButton';
import CheerIcon from 'src/shared/ui/component/icon/CheerIcon';

import CheerDetails from '!@svgr/webpack?modules!src/shared/app/cheer/assets/CheerDetails.svg';

import styles from './CheerInfos.style';

const useStyles = createUseThemeStyles(styles);
type Props = {
  transitionState: TransitionStatus;
  defaultBraindate?: Braindate;
  isPosting?: boolean;
  onSubmit: () => void;
};

const CheerInfos = ({
  transitionState,
  defaultBraindate,
  isPosting,
  onSubmit,
}: Props) => {
  const intl = useIntl();
  const self = useSelf();
  const classes = useStyles();
  const [{ value: recipient }] = useField('recipient');
  const [{ value: braindate }, , { setValue: setBraindate }] =
    useField('braindate');
  const { data, isLoading } = useGetPastBraindatesQuery(
    {
      limit: 0,
    },
    {
      skip: !!defaultBraindate,
    },
  );
  const braindates = defaultBraindate
    ? [defaultBraindate]
    : data?.results || [];
  const braindatesWith =
    braindates.length && recipient && self
      ? getCommonBraindatesWithUser(braindates, recipient, self, true)
      : [];
  useEffect(() => {
    if (!braindatesWith.length || braindatesWith.length > 1) {
      return;
    }

    if (braindate.id !== braindatesWith[0].id) {
      setBraindate(braindatesWith[0]);
    }
  }, [braindatesWith]);
  const cheersCategories = [
    {
      value: cheerCategoryInsightful,
      label: defaultCheersMessages.insightful,
    },
    {
      value: cheerCategoryGenerous,
      label: defaultCheersMessages.generous,
    },
    {
      value: cheerCategoryFearless,
      label: defaultCheersMessages.fearless,
    },
    {
      value: cheerCategoryDelightful,
      label: defaultCheersMessages.delightful,
    },
  ].map(({ value, label }) => ({
    value,
    label: intl.formatMessage(label),
  }));
  return (
    <CheerModalWrapper transitionState={transitionState}>
      <div className={classes.root}>
        <div className={classes.assets}>
          <CheerDetails />

          <p>{intl.formatMessage(messages.note)}</p>
        </div>

        <div className={classes.content}>
          <div className={classes.header}>
            <UserAvatar user={recipient} variant={small} />
            <h2 className={classes.title}>
              {intl.formatMessage(messages.sendCheerTo, {
                name: getUserFirstName(recipient),
              })}
            </h2>
          </div>

          <Field
            name="braindate"
            required
            component={FormikFormGroup}
            disabled={braindatesWith.length <= 1}
            componentOpts={{
              label: intl.formatMessage(messages.braindate),
              inputComp: FormikAutocomplete,
              inputOpts: {
                disabled: braindatesWith.length <= 1,
                isFetching: isLoading,
                options: braindatesWith,
                renderOption: (props, value) => (
                  <span {...props}>
                    {getTopicTitle(getBraindateTopic(value))}
                  </span>
                ),
                isOptionEqualToValue: (option, value) =>
                  option &&
                  value &&
                  getBraindateId(option) === getBraindateId(value),
                getOptionLabel: (value) =>
                  getTopicTitle(getBraindateTopic(value)),
              },
            }}
          />

          <Field
            id="category"
            name="category"
            component={FormikFormGroup}
            componentOpts={{
              inputComp: FormikSelect,
              hasLengthCounter: true,
              label: intl.formatMessage(messages.cheerFor, {
                name: getUserFirstName(recipient),
              }),
              inputOpts: {
                options: cheersCategories,
              },
            }}
            placeholder={intl.formatMessage(messages.cheerForPlaceholder)}
            variant="outlined"
            required
          />

          <Field
            id="message"
            name="message"
            component={FormikFormGroup}
            componentOpts={{
              useTextarea: true,
              hasLengthCounter: true,
              label: intl.formatMessage(messages.message),
              className: classes.textarea,
            }}
            placeholder={intl.formatMessage(messages.messagePlaceholder)}
            maxLength={cheersMessageMaxLength}
            variant="outlined"
          />

          <CheerModalSponsors />

          <div className={classes.actions}>
            <PrimaryButton
              layoutClass={classes.submit}
              isFetching={isPosting}
              type="button"
              onClick={onSubmit}
            >
              {!isPosting ? <CheerIcon /> : null}
              {intl.formatMessage(messages.send)}
            </PrimaryButton>
          </div>
        </div>
      </div>
    </CheerModalWrapper>
  );
};

export default CheerInfos;
