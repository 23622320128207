import { memo, useEffect, useState } from 'react';

import { useMediaQuery } from '@mui/material';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { lap } from '@braindate/ui/lib/token/mediaQuery';

import Container from 'src/shared/app/base/component/container/Container';
import MainTopBarActions, {
  marketQueryFormButtonId,
} from 'src/shared/app/base/component/maintopbar/MainTopBarActions';
import useRouteMatch from 'src/shared/app/base/route/hook/useRouteMatch';
import useRoutes from 'src/shared/app/base/route/hook/useRoutes';
import {
  homeRoute,
  peopleFeedRoute,
} from 'src/shared/app/base/route/setting/routeSettings';
import { getRoutePath } from 'src/shared/app/base/route/util/routeUtils';
import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import useFeatureDecisions from 'src/shared/app/feature/hook/useFeatureDecisions';
import MarketQueryForm from 'src/shared/app/market/component/header/search/query/MarketQueryForm';
import useMarketFiltersReset from 'src/shared/app/market/hook/useMarketFiltersReset';
import { getMarketQuery } from 'src/shared/app/market/selector/marketSearchQuerySelectors';
import EventBranding from 'src/shared/app/nav/component/branding/event/EventBranding';
import MainNav from 'src/shared/app/nav/component/main/MainNav';
import SkipNavLink from 'src/shared/app/nav/component/skip/SkipNavLink';
import { newTopicButtonPortal } from 'src/shared/app/nav/settings/navSettings';
import ConditionalWrapper from 'src/shared/ui/component/misc/ConditionalWrapper';
import HideOnScroll from 'src/shared/ui/component/misc/HideOnScroll';

import styles from './MainTopBar.style';

const useStyles = createUseThemeStyles(styles);

const MainTopBar = () => {
  const isDesktop = useMediaQuery(lap);
  const activeRoute = useRouteMatch();
  const { pathname } = useLocation();
  const hasMarketQuery = useSelector((state) => !!getMarketQuery(state));
  const { shouldHideTopBarOnScroll } = useFeatureDecisions();
  const resetMarketFilters = useMarketFiltersReset();
  const [homePath, peopleFeedPath] = useRoutes(homeRoute, peopleFeedRoute);
  const [isSearchOpened, setIsSearchOpened] = useState<boolean>(hasMarketQuery);
  const classes = useStyles({
    isSearchOpened,
  });
  const isMarketPage = [homePath, peopleFeedPath].includes(
    getRoutePath(activeRoute),
  );

  const onSearchOpened = () => {
    if (isDesktop) return;
    if (!isSearchOpened) return;
    document.getElementById('query-mobile')?.focus();
  };

  useEffect(() => {
    if (hasMarketQuery && !isDesktop) {
      setIsSearchOpened(true);
    }
  }, [hasMarketQuery]);
  useEffect(() => {
    if (!isSearchOpened || !isDesktop) return;
    setIsSearchOpened(false);
  }, [isDesktop, pathname]);

  const handleLogoClick = () => {
    resetMarketFilters();
  };

  return (
    <>
      <ConditionalWrapper
        condition={shouldHideTopBarOnScroll}
        wrapper={(child) => <HideOnScroll>{child}</HideOnScroll>}
      >
        <div role="banner" className={classNames(classes.root)}>
          <Container>
            <SkipNavLink />
            <div className={classes.inner} onTransitionEnd={onSearchOpened}>
              <div className={classes.left}>
                <EventBranding
                  classes={{
                    logo: classes.logo,
                  }}
                  onClick={handleLogoClick}
                />

                <MainNav className={classes.nav} wrapToContent />
              </div>

              <div className={classes.right}>
                <MainTopBarActions
                  isMarketPage={isMarketPage}
                  isSearchOpened={isSearchOpened}
                  onOpenSearch={() => setIsSearchOpened(true)}
                />
              </div>
            </div>

            <div className={classes.query}>
              <MarketQueryForm
                id="mobile"
                isOpened={isSearchOpened}
                onClose={() => {
                  setIsSearchOpened(false);
                  document.getElementById(marketQueryFormButtonId)?.focus();
                }}
              />
            </div>
          </Container>
        </div>
      </ConditionalWrapper>

      <div id={newTopicButtonPortal} />
    </>
  );
};

export default memo(MainTopBar);
