import { getConversationId } from '@braindate/domain/lib/conversation/util';

import * as types from 'src/shared/app/conversation/action/uiConversationActionTypes';
import { createReducer } from 'src/shared/core/util/reducerUtils';

/*
 |------------------------------------------------------------------------------
 | Initial state
 |------------------------------------------------------------------------------
 */
const initialState = {
  backUrl: '',
  pendingAction: null,
  isInitialized: {},
};

/*
 |------------------------------------------------------------------------------
 | Reducers
 |------------------------------------------------------------------------------
 */
const setBackUrl = (
  state: Record<string, any>,
  { url }: Record<string, any>,
) => ({ ...state, backUrl: url });

const resetBackUrl = (state: Record<string, any>) => ({
  ...state,
  backUrl: '',
});

const setPendingAction = (
  state: Record<string, any>,
  { action }: Record<string, any>,
) => ({ ...state, pendingAction: action });

const resetPendingAction = (state: Record<string, any>) => ({
  ...state,
  pendingAction: null,
});

const markAsInitialized = (
  state: Record<string, any>,
  { conversation }: Record<string, any>,
) => ({
  ...state,
  isInitialized: {
    ...state.isInitialized,
    [getConversationId(conversation)]: true,
  },
});

const unmarkAsInitialized = (
  state: Record<string, any>,
  { conversation }: Record<string, any>,
) => ({
  ...state,
  isInitialized: {
    ...state.isInitialized,
    [getConversationId(conversation)]: false,
  },
});

export default createReducer(initialState, {
  [types.SET_CONVERSATION_BACK_URL]: setBackUrl,
  [types.RESET_CONVERSATION_BACK_URL]: resetBackUrl,
  [types.SHOW_ACTION_CONFIRMATION_MODAL]: setPendingAction,
  [types.DISMISS_ACTION_CONFIRMATION_MODAL]: resetPendingAction,
  [types.MARK_CONVERSATION_AS_INITIALIZED]: markAsInitialized,
  [types.UNMARK_CONVERSATION_AS_INITIALIZED]: unmarkAsInitialized,
});
