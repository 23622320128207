import { IntlShape } from 'react-intl';
import * as Yup from 'yup';

import type { Membership } from '@braindate/domain/lib/membership/type';
import { isMembershipOptInCompleted } from '@braindate/domain/lib/membership/util';

import messages from 'src/shared/app/permission/l10n/permissionL10n';
import type { CustomOptin } from 'src/shared/app/permission/type/permissionType';
import {
  isMembershipNotificationsAccepted,
  isMembershipTermsAccepted,
} from 'src/shared/domain/membership/util/membershipUtil';

export function isOptinDone(membership: Membership) {
  return !!(
    isMembershipOptInCompleted(membership) &&
    isMembershipTermsAccepted(membership)
  );
}
export function isNotificationOptinDone(
  membership: Membership,
  areEmailsNotificationsEnabled: boolean,
) {
  return !!(
    !areEmailsNotificationsEnabled ||
    isMembershipNotificationsAccepted(membership)
  );
}
export function getCustomOptins(
  customOptins: Array<CustomOptin>,
  intl: IntlShape,
) {
  const required = [];
  const notRequired = [];
  const validationShape = {};
  const initialValues = {};
  customOptins.forEach((customOptin) => {
    initialValues[getCustomOptinId(customOptin)] = false;

    if (customOptin.required) {
      validationShape[getCustomOptinId(customOptin)] = Yup.boolean()
        .required(intl.formatMessage(messages.errorRequired))
        .oneOf([true], intl.formatMessage(messages.errorRequired));
      required.push(customOptin);
    } else {
      notRequired.push(customOptin);
    }
  });
  return {
    initialValues,
    required,
    notRequired,
    validationShape,
  };
}
export function getCustomOptinId(customOptin: CustomOptin) {
  return customOptin.label;
}
