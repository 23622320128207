import {
  getEventFeatures,
  getEventPlugins,
} from '@braindate/domain/lib/event/util';

import featureConfig from 'src/shared/app/feature/config/featureConfig';
import featureRouter from 'src/shared/app/feature/featureRouter';
import useEvent from 'src/shared/domain/event/hook/useEvent';

export default function useFeatureRouter() {
  const event = useEvent();
  const plugins = getEventPlugins(event);
  const features = getEventFeatures(event);
  // TODO: antipattern?
  featureRouter.reset();

  for (const key in featureConfig) {
    featureRouter.setFeature(key, featureConfig[key]);
  }

  for (const key in plugins) {
    featureRouter.setFeature(key, !!plugins[key]);
  }

  for (const key in features) {
    featureRouter.setFeature(key, features[key]);
  }
}
