import { IntlShape } from 'react-intl';
import * as Yup from 'yup';

import messages from 'src/shared/app/cheer/l10n/cheerl10n';
import { queueNotification } from 'src/shared/app/notification/action/uiNotificationActions';
import { NOTIFICATION_COMPONENT_KEYS } from 'src/shared/app/notification/constant/notificationComponentsMap';
import {
  notificationSuccessLevel,
  notificationTopType,
} from 'src/shared/app/notification/constant/notificationConstants';

export const temporaryCheerInitialGradient = {
  gradient_received: 'linear-gradient(180deg, #FFAF6F 0%, #FF7960 100%)',
  gradient_sent: 'linear-gradient(180deg, #4E00CD 0%, #DFA5BA 100%)',
};
export const cheersMessageMaxLength = 200;
export const cheerCategoryInsightful = 'insightful';
export const cheerCategoryGenerous = 'generous';
export const cheerCategoryFearless = 'fearless';
export const cheerCategoryDelightful = 'delightful';
export const categories = [
  cheerCategoryInsightful,
  cheerCategoryGenerous,
  cheerCategoryFearless,
  cheerCategoryDelightful,
];
export type CheerCategories =
  | typeof cheerCategoryInsightful
  | typeof cheerCategoryGenerous
  | typeof cheerCategoryFearless
  | typeof cheerCategoryDelightful;

const buildCheerSuccessNotification = (cheerId: number) => ({
  id: `cheer-success-${cheerId}`,
  level: notificationSuccessLevel,
  type: notificationTopType,
  componentKey: NOTIFICATION_COMPONENT_KEYS.CHEER_SUCCESS,
});

export const showCheerSuccessNotification = (cheerId: number) =>
  queueNotification(buildCheerSuccessNotification(cheerId));
export const getCheerFormSchema = (intl: IntlShape) => {
  // $FlowIssue
  return Yup.object().shape({
    category: Yup.string().oneOf(categories).required(),
    braindate: Yup.object().required(),
    recipient: Yup.object().required(),
    message: Yup.string().max(
      cheersMessageMaxLength,
      intl.formatMessage(messages.cheersMessageMaxLengthError),
    ),
    send_gift: Yup.boolean(),
  });
};
