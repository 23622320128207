import { memo } from 'react';

import type { Braindate } from '@braindate/domain/lib/braindate/type';
import { getBraindatePath } from '@braindate/domain/lib/braindate/util';
import { getTopicTitle } from '@braindate/domain/lib/topic/util';

import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import { getBraindateTopic } from 'src/shared/domain/braindate/util/braindateUtil';
import LinkWithChevron from 'src/shared/ui/component/link/LinkWithChevron';
import ConditionalWrapper from 'src/shared/ui/component/misc/ConditionalWrapper';
import Subheading from 'src/shared/ui/text/Subheading';

import styles from './BraindateCardTitle.style';

const useStyles = createUseThemeStyles(styles);
type Props = {
  braindate: Braindate;
  isPreview?: boolean;
};

const BraindateCardTitle = ({ braindate, isPreview }: Props) => {
  const classes = useStyles();
  const topic = getBraindateTopic(braindate);
  const href = getBraindatePath(braindate);
  return (
    <div className={classes.root}>
      <ConditionalWrapper
        condition={!isPreview}
        wrapper={(child) => (
          <LinkWithChevron href={href}>{child}</LinkWithChevron>
        )}
      >
        <Subheading as="h3" layoutClass={classes.topicTitle}>
          {getTopicTitle(topic)}
        </Subheading>
      </ConditionalWrapper>
    </div>
  );
};

export default memo<Props>(BraindateCardTitle);
