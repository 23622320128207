import AccountSuccessBanner from 'src/shared/app/account/base/component/AccountSuccessBanner';
import GenericActionErrorNotification from 'src/shared/app/booking/component/notification/GenericActionErrorNotification';
import InvalidActionNotification from 'src/shared/app/booking/component/notification/InvalidActionNotification';
import InvitationSentNotification from 'src/shared/app/booking/component/notification/InvitationSentNotification';
import PhoneUpdatedNotification from 'src/shared/app/chat/component/notification/PhoneUpdatedNotification';
import CheckInModal from 'src/shared/app/check-in/component/notification/CheckInModal';
import CheckInNotification from 'src/shared/app/check-in/component/notification/CheckInNotification';
import CheckInTakeOver from 'src/shared/app/check-in/component/notification/CheckInTakeOver';
import NewCheerModal from 'src/shared/app/cheer/component/modal/new/NewCheerModal';
import PostBraindateCheerModal from 'src/shared/app/cheer/component/modal/new/PostBraindateCheerModal';
import CheerRecipientModal from 'src/shared/app/cheer/component/modal/recipient/CheerRecipientModal';
import CheerSuccessNotification from 'src/shared/app/cheer/component/notification/CheerSuccessNotification';
import InterestNoResultNotification from 'src/shared/app/interest/component/notification/InterestNoResultNotification';
import InterestResultNotification from 'src/shared/app/interest/component/notification/InterestResultNotification';
import MarketSlackNotification from 'src/shared/app/market/component/prompt/MarketSlackNotification';
import ModerationAcceptedAndSlackNotification from 'src/shared/app/moderation/component/ModerationAcceptedAndSlackNotification';
import ModerationAcceptedNotification from 'src/shared/app/moderation/component/ModerationAcceptedNotification';
import ModerationPostNotification from 'src/shared/app/moderation/component/ModerationPostNotification';
import ModerationRejectedNotification from 'src/shared/app/moderation/component/ModerationRejectedNotification';
import NotificationPreferencesSuccessBanner from 'src/shared/app/notification-preference/components/NotificationPreferencesSuccessBanner';
import ChangePasswordSuccessNotification from 'src/shared/app/preference/notification/ChangePasswordSuccessNotification';
import SupportSentErrorNotification from 'src/shared/app/support/component/SupportSentErrorNotification';
import SupportSentNotification from 'src/shared/app/support/component/SupportSentNotification';
import NewTopicNotificationReturning from 'src/shared/app/topic/new/component/NewTopicNotificationReturning';
import NewTopicNotificationTopicEngagement from 'src/shared/app/topic/new/component/NewTopicNotificationTopicEngagement';
import BraindateFeedbackModal from 'src/shared/components/domain/braindate/modal/BraindateFeedbackModal';
import TopicNotJoinableNotification from 'src/shared/components/domain/topic/details/body/notification/TopicNotJoinableNotification';
import BraindateExistsNotification from 'src/shared/components/domain/topic/notifications/BraindateExistsNotification/components';
import SMSPhoneReminderModal from 'src/shared/components/domain/user/modals/phone-reminder/SMSPhoneReminderModal';

import VirtualBraindateEarlyReminder from 'plugin/virtual-braindate/component/notification/VirtualBraindateEarlyReminder';

export const NOTIFICATION_COMPONENT_KEYS = {
  TOPIC_ENGAGEMENT_ONGOING_EVENTS: 'TOPIC_ENGAGEMENT_ONGOING_EVENTS',
  NEW_TOPIC_RETURNING: 'NEW_TOPIC_RETURNING',
  BRAINDATE_FEEDBACK: 'BRAINDATE_FEEDBACK',
  NEW_CHEER: 'NEW_CHEER',
  POST_BRAINDATE_CHEER: 'POST_BRAINDATE_CHEER',
  ADD_SMS_PHONE_REMINDER: 'ADD_SMS_PHONE_REMINDER',
  ACCOUNT_SUCCESS_BANNER: 'ACCOUNT_SUCCESS_BANNER',
  NOTIFICATION_PREFERENCES_SUCCESS_BANNER:
    'NOTIFICATION_PREFERENCES_SUCCESS_BANNER',
  VIRTUAL_BRAINDATE_EARLY_REMINDER: 'VIRTUAL_BRAINDATE_EARLY_REMINDER',
  INVITATION_SENT_NOTIFICATION: 'INVITATION_SENT_NOTIFICATION',
  INVALID_ACTION_NOTIFICATION: 'INVALID_ACTION_NOTIFICATION',
  GENERIC_ACTION_ERROR_NOTIFICATION: 'GENERIC_ACTION_ERROR_NOTIFICATION',
  PHONE_UPDATED_NOTIFICATION: 'PHONE_UPDATED_NOTIFICATION',
  SHARE_CONTACT_NOTIFICATION_ERROR: 'SHARE_CONTACT_NOTIFICATION_ERROR',
  CHECK_IN_NOTIFICATION: 'CHECK_IN_NOTIFICATION',
  CHECK_IN_TAKEOVER: 'CHECK_IN_TAKEOVER',
  CHECK_IN_MODAL: 'CHECK_IN_MODAL',
  INTEREST_RESULT_NOTIFICATION: 'INTEREST_RESULT_NOTIFICATION',
  INTEREST_NO_RESULT_NOTIFICATION: 'INTEREST_NO_RESULT_NOTIFICATION',
  CHEER_RECIPIENT_MODAL: 'CHEER_RECIPIENT_MODAL',
  CHEER_SUCCESS: 'CHEER_SUCCESS',
  MARKET_SLACK_NOTIFICATION: 'MARKET_SLACK_NOTIFICATION',
  MODERATION_POST_NOTIFICATION: 'MODERATION_POST_NOTIFICATION',
  MODERATION_ACCEPTED_NOTIFICATION: 'MODERATION_ACCEPTED_NOTIFICATION',
  MODERATION_REJECTED_NOTIFICATION: 'MODERATION_REJECTED_NOTIFICATION',
  MODERATION_ACCEPTED_AND_SLACK_NOTIFICATION:
    'MODERATION_ACCEPTED_AND_SLACK_NOTIFICATION',
  CHANGE_PASSWORD_SUCCESS_NOTIFICATION: 'CHANGE_PASSWORD_SUCCESS_NOTIFICATION',
  SUPPORT_SENT_ERROR_NOTIFICATION: 'SUPPORT_SENT_ERROR_NOTIFICATION',
  SUPPORT_SENT_NOTIFICATION: 'SUPPORT_SENT_NOTIFICATION',
  TOPIC_NOT_JOINABLE_NOTIFICATION: 'TOPIC_NOT_JOINABLE_NOTIFICATION',
  BRAINDATE_EXISTS: 'BRAINDATE_EXISTS',
};
export const NOTIFICATION_COMPONENT_MAPPING = {
  [NOTIFICATION_COMPONENT_KEYS.TOPIC_ENGAGEMENT_ONGOING_EVENTS]:
    NewTopicNotificationTopicEngagement,
  [NOTIFICATION_COMPONENT_KEYS.NEW_TOPIC_RETURNING]:
    NewTopicNotificationReturning,
  [NOTIFICATION_COMPONENT_KEYS.BRAINDATE_FEEDBACK]: BraindateFeedbackModal,
  [NOTIFICATION_COMPONENT_KEYS.NEW_CHEER]: NewCheerModal,
  [NOTIFICATION_COMPONENT_KEYS.ADD_SMS_PHONE_REMINDER]: SMSPhoneReminderModal,
  [NOTIFICATION_COMPONENT_KEYS.ACCOUNT_SUCCESS_BANNER]: AccountSuccessBanner,
  [NOTIFICATION_COMPONENT_KEYS.NOTIFICATION_PREFERENCES_SUCCESS_BANNER]:
    NotificationPreferencesSuccessBanner,
  [NOTIFICATION_COMPONENT_KEYS.VIRTUAL_BRAINDATE_EARLY_REMINDER]:
    VirtualBraindateEarlyReminder,
  [NOTIFICATION_COMPONENT_KEYS.INVITATION_SENT_NOTIFICATION]:
    InvitationSentNotification,
  [NOTIFICATION_COMPONENT_KEYS.INVALID_ACTION_NOTIFICATION]:
    InvalidActionNotification,
  [NOTIFICATION_COMPONENT_KEYS.GENERIC_ACTION_ERROR_NOTIFICATION]:
    GenericActionErrorNotification,
  [NOTIFICATION_COMPONENT_KEYS.PHONE_UPDATED_NOTIFICATION]:
    PhoneUpdatedNotification,
  [NOTIFICATION_COMPONENT_KEYS.CHECK_IN_NOTIFICATION]: CheckInNotification,
  [NOTIFICATION_COMPONENT_KEYS.CHECK_IN_TAKEOVER]: CheckInTakeOver,
  [NOTIFICATION_COMPONENT_KEYS.CHECK_IN_MODAL]: CheckInModal,
  [NOTIFICATION_COMPONENT_KEYS.INTEREST_RESULT_NOTIFICATION]:
    InterestResultNotification,
  [NOTIFICATION_COMPONENT_KEYS.INTEREST_NO_RESULT_NOTIFICATION]:
    InterestNoResultNotification,
  [NOTIFICATION_COMPONENT_KEYS.CHEER_RECIPIENT_MODAL]: CheerRecipientModal,
  [NOTIFICATION_COMPONENT_KEYS.CHEER_SUCCESS]: CheerSuccessNotification,
  [NOTIFICATION_COMPONENT_KEYS.POST_BRAINDATE_CHEER]: PostBraindateCheerModal,
  [NOTIFICATION_COMPONENT_KEYS.MARKET_SLACK_NOTIFICATION]:
    MarketSlackNotification,
  [NOTIFICATION_COMPONENT_KEYS.MODERATION_POST_NOTIFICATION]:
    ModerationPostNotification,
  [NOTIFICATION_COMPONENT_KEYS.MODERATION_ACCEPTED_NOTIFICATION]:
    ModerationAcceptedNotification,
  [NOTIFICATION_COMPONENT_KEYS.MODERATION_REJECTED_NOTIFICATION]:
    ModerationRejectedNotification,
  [NOTIFICATION_COMPONENT_KEYS.MODERATION_ACCEPTED_AND_SLACK_NOTIFICATION]:
    ModerationAcceptedAndSlackNotification,
  [NOTIFICATION_COMPONENT_KEYS.CHANGE_PASSWORD_SUCCESS_NOTIFICATION]:
    ChangePasswordSuccessNotification,
  [NOTIFICATION_COMPONENT_KEYS.SUPPORT_SENT_ERROR_NOTIFICATION]:
    SupportSentErrorNotification,
  [NOTIFICATION_COMPONENT_KEYS.SUPPORT_SENT_NOTIFICATION]:
    SupportSentNotification,
  [NOTIFICATION_COMPONENT_KEYS.TOPIC_NOT_JOINABLE_NOTIFICATION]:
    TopicNotJoinableNotification,
  [NOTIFICATION_COMPONENT_KEYS.BRAINDATE_EXISTS]: BraindateExistsNotification,
};
