import { lap } from '@braindate/ui/lib/token/mediaQuery';

import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

export default {
  root: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  notifications: {
    position: 'relative',
    [lap]: {
      display: 'none',
    },
  },
  badge: {
    position: 'absolute',
    top: 4,
    right: 4,
    border: ({ theme }: ThemeFunction<unknown>) =>
      `1px solid ${theme.colorBase}`,
  },
};
