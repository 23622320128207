import type { Braindate } from '@braindate/domain/lib/braindate/type';
import {
  getBraindateLocation,
  getBraindatePod,
} from '@braindate/domain/lib/braindate/util';
import {
  getLocationBraindateMeetingPoint,
  getLocationBraindatePods,
  getLocationGroupBraindatePods,
} from '@braindate/domain/lib/location/util';
import { getTopicKind, isGroupTopic } from '@braindate/domain/lib/topic/util';
import { assertObject, assertString } from '@braindate/util/lib/assert';
import { isNumber } from '@braindate/util/lib/type';

import { getBraindateTopic } from 'src/shared/domain/braindate/util/braindateUtil';

/**
 * Get the name of the pod for a given braindate
 * @param  {Braindate} braindate - Braindate to look into for the pod number
 * @param  {string} locale - Which locale to get the pod in

 * @return {string} - The pod name for the braindate
 */
export function getBraindatePodName(
  braindate: Braindate,
  locale: string,
): string {
  assertObject(braindate, 'braindate');
  assertString(locale, 'string');
  const pod = getBraindatePod(braindate);
  const location = getBraindateLocation(braindate);
  const podNames = !location
    ? []
    : (isGroupTopic(getBraindateTopic(braindate))
        ? getLocationGroupBraindatePods(location, locale)
        : getLocationBraindatePods(location, locale)) || [];
  // $FlowIssue - We have ascertain pod is a number
  return isNumber(pod) ? podNames[pod - 1] || String(pod) : pod;
}

/**
 * Get the name of the meetingPoint for a given braindate
 * @param  {Braindate} braindate - Braindate to determine if it's a one-on-one or group
 * @param  {string} locale - Which locale to get the meeting point in

 * @return {string} - The meeting point name for the braindate
 */
export function getBraindateMeetingPoint(
  braindate: Braindate,
  locale: string,
): string {
  assertObject(braindate, 'braindate');
  assertString(locale, 'string');
  const location = getBraindateLocation(braindate);

  if (!location) {
    return '';
  }

  return getLocationBraindateMeetingPoint(
    location,
    getTopicKind(getBraindateTopic(braindate)),
    locale,
  );
}
