import { rem } from 'polished';

import { lap } from '@braindate/ui/lib/token/mediaQuery';
import { baseFontSize } from '@braindate/ui/lib/token/typography';

import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

type ThemeProps = ThemeFunction<{
  variant: 'horizontal' | 'vertical';
}>;
export default {
  root: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    flexWrap: 'wrap',
    minWidth: 0,
  },
  user: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    minWidth: 0,
  },
  actions: {
    display: 'flex',
    flexGrow: 1,
    justifyContent: 'flex-end',
  },
  nameContainer: {
    display: 'flex',
    minWidth: 0,
  },
  name: {
    fontSize: 14,
    fontWeight: 600,
    color: ({ theme }: ThemeProps) => theme.textColor,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  separator: {
    display: 'none',
    [lap]: {
      color: ({ theme }: ThemeProps) => theme.textColor,
      display: ({ variant }: ThemeProps) =>
        variant === 'horizontal' ? 'block' : 'none',
    },
  },
  titleContainer: {
    display: 'flex',
    minWidth: 0,
  },
  title: {
    fontSize: 14,
    fontStyle: 'normal',
    fontWeight: 500,
    color: ({ theme }: ThemeProps) => theme.textColor,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  link: {
    minWidth: 0,
    display: 'flex',
    flexDirection: 'column',
    gap: ({ variant }: ThemeProps) => (variant === 'vertical' ? 0 : '4px'),
    [lap]: {
      flexDirection: ({ variant }: ThemeProps) =>
        variant === 'vertical' ? 'column' : 'row',
    },
    '&:focus-visible': {
      textDecoration: 'underline',
    },
  },
  icon: {
    // Necessary for transformation to work
    display: 'inline-block',
    position: 'relative',
    bottom: '-0.2em',
    marginLeft: rem(8, baseFontSize),
    stroke: ({ theme }: ThemeProps) => theme.accentColor,
    color: ({ theme }: ThemeProps) => theme.accentColor,
    transition: ['transform', '0.2s', 'ease-in-out'],
    '$root:hover &': {
      transform: 'translateX(-3px)',
    },
    '$root:active &': {
      transform: 'translateX(-3px)',
    },
  },
};
