import { useEffect } from 'react';

import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { getEventId } from '@braindate/domain/lib/event/util';

import useAuthenticationToken from 'src/shared/app/authentication/hook/useAuthenticationToken';
import { invalidateTags } from 'src/shared/app/base/api/actions/apiActions';
import { TAG_TYPE } from 'src/shared/app/base/api/apiConstant';
import { fetchEvent } from 'src/shared/domain/event/action/eventActions';
import useEvent from 'src/shared/domain/event/hook/useEvent';
import { getEvent } from 'src/shared/domain/event/selector/eventSelectors';
import useLocations from 'src/shared/domain/location/hook/useLocations';
import useMembership from 'src/shared/domain/membership/hook/useMembership';
import useSelf from 'src/shared/domain/user/hook/useSelf';

export default function useInitialPayloadFetching() {
  const token = useAuthenticationToken();
  const dispatch = useDispatch();
  const user = useSelf();
  const membership = useMembership();
  const safeEvent = useEvent();
  const privateEvent = useSelector(getEvent, shallowEqual);
  const locations = useLocations();
  useEffect(() => {
    if (token) {
      const eventId = getEventId(safeEvent);

      if (!user) {
        dispatch(invalidateTags(TAG_TYPE.USER, 'me'));
      }

      if (!membership) {
        dispatch(invalidateTags(TAG_TYPE.MEMBERSHIP));
      }

      if (!privateEvent) {
        dispatch(fetchEvent(eventId));
      }

      if (!locations.length) {
        dispatch(invalidateTags(TAG_TYPE.LOCATION, 'LIST'));
      }
    }
  }, [token]);
}
