import { memo, useState } from 'react';

import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

import { getLocalizedData } from '@braindate/domain/lib/base/l10n/util';

import { ampli } from 'src/ampli';
import { useCountPendingBraindatesQuery } from 'src/shared/app/base/api/endpoint/braindatesEndpoint';
import MainTopBarCreateTopicButton from 'src/shared/app/base/component/maintopbar/MainTopBarCreateTopicButton';
import useRoutes from 'src/shared/app/base/route/hook/useRoutes';
import {
  confirmedBraindatesRoute,
  homeRoute,
  pendingBraindatesRoute,
  peopleFeedRoute,
} from 'src/shared/app/base/route/setting/routeSettings';
import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import LazyBraindatesMain from 'src/shared/app/braindate/component/main/LazyBraindatesMain';
import useFeatureDecisions from 'src/shared/app/feature/hook/useFeatureDecisions';
import LazyMarketMain from 'src/shared/app/market/component/MarketMain';
import NotificationsMenu from 'src/shared/app/nav/component/notifications-menu/NotificationsMenu';
import messages from 'src/shared/app/nav/l10n/navigationL10n';
import { getNotificationCount } from 'src/shared/app/notification/selector/appNotificationSelectors';
import useEvent from 'src/shared/domain/event/hook/useEvent';
import BellIcon from 'src/shared/ui/component/icon/BellIcon';
import CalendarIcon2 from 'src/shared/ui/component/icon/CalendarIcon2';
import MarketNavItemIcon from 'src/shared/ui/component/icon/MarketNavItemIcon';
import NotificationBadge from 'src/shared/ui/component/messaging/NotificationBadge';

import { getCustomMenuLinks } from 'plugin/custom-menu/util/customMenuUtils';

import styles from './MainNav.style';
import MainNavLink from './MainNavLink';
import MainNavSubMenu from './MainNavSubMenu';

const useStyles = createUseThemeStyles(styles);
type Props = {
  className?: string;
  wrapToContent?: boolean;
  isMobile?: boolean;
};

const MainNav = ({ className, wrapToContent, isMobile }: Props) => {
  const classes = useStyles({
    wrapToContent,
  });
  const intl = useIntl();
  const { isCustomMenuEnabled } = useFeatureDecisions();
  const event = useEvent();
  const notificationsCount = useSelector(getNotificationCount);
  const hasNotifications = notificationsCount > 0;
  const { data } = useCountPendingBraindatesQuery();
  const hasPendingBraindates = data?.count || 0;
  const externalItems =
    (isCustomMenuEnabled && getCustomMenuLinks(event)) || [];
  const [notificationsMenuOpened, setNotificationsMenuOpened] = useState(false);
  const [
    homePath,
    pendingBraindatesPath,
    confirmedBraindatesPath,
    peopleFeedPath,
  ] = useRoutes(
    homeRoute,
    pendingBraindatesRoute,
    confirmedBraindatesRoute,
    peopleFeedRoute,
  );
  const braindatesPath = hasPendingBraindates
    ? pendingBraindatesPath
    : confirmedBraindatesPath;

  const getExternalLinkLabel = (link: Record<string, any>) =>
    getLocalizedData(link, intl.locale).label;

  const getExternalLinkHref = (link: Record<string, any>) => link.href;

  const getExternalLinkIcon = (link: Record<string, any>) => link.icon;

  const getExternalLinkTarget = (link: Record<string, any>) => link.target;

  const externalItemsElts = isCustomMenuEnabled
    ? externalItems.map((link, key) => (
        <MainNavLink
          title={getExternalLinkLabel(link)}
          to={getExternalLinkHref(link)}
          target={getExternalLinkTarget(link)}
          rel="noreferrer"
          className={classes.submenuItem}
          key={`link-${key}`}
        >
          <img
            className="external-link-image"
            src={getExternalLinkIcon(link)}
            alt={intl.formatMessage(messages.visitLink)}
          />
        </MainNavLink>
      ))
    : [];
  const badgeElt = hasNotifications && (
    <NotificationBadge
      layoutClass={classNames(classes.badge, {
        [classes.badgeMenuOpened]: notificationsMenuOpened,
      })}
    />
  );
  return (
    <nav
      className={classNames(classes.root, className)}
      aria-label={intl.formatMessage(
        isMobile ? messages.mobileLabel : messages.label,
      )}
    >
      <ul className={classes.list}>
        {isCustomMenuEnabled &&
          externalItemsElts.map((item, key) => (
            <li
              className={classNames([classes.item, classes.itemDesktopOnly])}
              key={key}
            >
              {item}
            </li>
          ))}

        <li className={classes.item}>
          <MainNavLink
            title={intl.formatMessage(messages.market)}
            to={homePath}
            icon={<MarketNavItemIcon />}
            match={peopleFeedPath}
            preloadComp={LazyMarketMain}
            exact
          />
        </li>

        <li className={classes.item}>
          <MainNavLink
            title={intl.formatMessage(messages.myBraindates)}
            to={braindatesPath}
            icon={<CalendarIcon2 />}
            preloadComp={LazyBraindatesMain}
          />
        </li>

        <li className={classNames(classes.item, classes.itemDesktopOnly)}>
          <NotificationsMenu
            isOpened={notificationsMenuOpened}
            onClose={() => {
              ampli.notificationOpen();
              setNotificationsMenuOpened(false);
            }}
          >
            <MainNavLink
              title={intl.formatMessage(messages.notifications)}
              icon={<BellIcon />}
              active={notificationsMenuOpened}
              onClick={() => setNotificationsMenuOpened(true)}
              className={classNames(classes.submenuItem)}
            >
              {badgeElt}
            </MainNavLink>
          </NotificationsMenu>
        </li>

        {isCustomMenuEnabled && (
          <MainNavSubMenu
            className={classNames(classes.item, classes.itemMobileOnly)}
            items={externalItemsElts}
          />
        )}

        <li className={classNames(classes.item, classes.itemDesktopOnly)}>
          <MainTopBarCreateTopicButton />
        </li>
      </ul>
    </nav>
  );
};

export default memo<Props>(MainNav);
