export const braindateCreated = 'braindate-created';
export const braindateChanged = 'braindate-changed';
export const braindateFollowup = 'braindate-followup';
export const braindateReminder = 'braindate-checkin-reminder';
export const groupBraindateReminder = 'group-braindate-checkin-reminder';
export const braindateEarlyReminder = 'braindate-early-reminder';
export const groupBraindateEarlyReminder = 'group-braindate-early-reminder';
export const braindateCheckIn = 'braindate-checkin';
export const calendarSyncStatusMessage = 'calendar-sync-status';
export const moderationState = 'topic-moderation';
export const notificationCountUpdate = 'notifications-count-update';
export const fishbowlReminder = 'fishbowl-braindate-checkin-reminder';
export const fishbowlEarlyReminder = 'fishbowl-braindate-early-reminder';
export const engagement = 'engagement-trigger';
export const cheersReceived = 'cheers-received';
export const addPhoneNumberReminder = 'add-phone-number';
