import { memo } from 'react';

import type { Braindate } from '@braindate/domain/lib/braindate/type';

import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import BraindateCardTitle from 'src/shared/app/braindate/component/card/BraindateCardTitle';
import TopicMenu from 'src/shared/app/topic/components/TopicMenu';
import Card from 'src/shared/ui/component/data-display/Card';

import styles from './UpcomingBraindateCard.style';
import UpcomingBraindateCardDate from './UpcomingBraindateCardDate';
import UpcomingBraindateCardFooter from './UpcomingBraindateCardFooter';
import UpcomingBraindateCardHeader from './UpcomingBraindateCardHeader';

const useStyles = createUseThemeStyles(styles);
type Props = {
  braindate: Braindate;
  showCheckin?: boolean;
  isPreview?: boolean;
};

const UpcomingBraindateCard = ({
  braindate,
  showCheckin = true,
  isPreview,
}: Props) => {
  const classes = useStyles();
  return (
    <Card
      rootLayoutClass={classes.card}
      layoutClass={classes.cardContent}
      disableChildrenMargin
    >
      <div className={classes.root}>
        <UpcomingBraindateCardDate braindate={braindate} />

        <div className={classes.content}>
          {!isPreview ? (
            <TopicMenu
              braindate={braindate}
              classes={{
                button: classes.buttonMenu,
              }}
            />
          ) : null}

          <UpcomingBraindateCardHeader braindate={braindate} />

          <BraindateCardTitle braindate={braindate} isPreview={isPreview} />

          <UpcomingBraindateCardFooter
            braindate={braindate}
            showCheckin={showCheckin}
            isPreview={isPreview}
          />
        </div>
      </div>
    </Card>
  );
};

export default memo<Props>(UpcomingBraindateCard);
