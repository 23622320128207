import { createReducer } from 'src/shared/core/util/reducerUtils';

import * as liveChatActionTypes from 'plugin/live-chat/action/liveChatActionTypes';

const initialState = {
  status: null,
  openedStatus: false,
};

const _setStatus = (state: string, action: Record<string, any>) => {
  const { status } = action;
  return { ...state, status };
};

const _setOpenedStatus = (state: string, action: Record<string, any>) => {
  const { status } = action;
  return { ...state, openedStatus: status };
};

export default createReducer(initialState, {
  [liveChatActionTypes.LIVE_CHAT_UPDATE_STATUS]: _setStatus,
  [liveChatActionTypes.LIVE_CHAT_UPDATE_OPENED_STATUS]: _setOpenedStatus,
});
