import { takeOverElevation } from 'src/shared/app/base/style/token/elevation';
import { gutter } from 'src/shared/app/base/style/token/grid';

export default {
  root: {
    width: '100%',
    zIndex: takeOverElevation,
    padding: [0, gutter, 0],
  },
};
