/* eslint-disable jsx-a11y/tabindex-no-positive */
import { memo } from 'react';
import type { Node } from 'react';

import { Link } from 'react-router-dom';

import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';

import styles from './PopoverButton.style';

const useStyles = createUseThemeStyles(styles);
type Props = {
  icon: Node;
  subtitle?: string;
  to?: string;
  children: Node;
  onClick?: () => void;
};
type ContainerProps = {
  to?: string;
  onClick?: () => void;
  children: Node;
};

const PopoverButtonContainer = ({ to, onClick, children }: ContainerProps) => {
  const classes = useStyles();

  if (to) {
    return (
      <Link to={to} tabIndex={1} className={classes.root} onClick={onClick}>
        {children}
      </Link>
    );
  }

  return (
    <button className={classes.root} tabIndex={1} onClick={onClick}>
      {children}
    </button>
  );
};

const PopoverButton = ({ icon, subtitle, to, children, onClick }: Props) => {
  const classes = useStyles();
  return (
    <PopoverButtonContainer to={to} onClick={onClick}>
      <span className={classes.container}>
        {icon}
        <span>{children}</span>
      </span>
      <span className={classes.subtitle}>{subtitle}</span>
    </PopoverButtonContainer>
  );
};

export default memo<Props>(PopoverButton);
