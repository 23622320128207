import { createRef, useEffect, useState } from 'react';
import type { Node } from 'react';

import { useId } from 'react-aria';
import { useIntl } from 'react-intl';

import useTrapTabs from 'src/shared/app/base/hook/useTrapTabs';
import messages from 'src/shared/app/base/l10n/uiL10n';
import BaseModal from 'src/shared/app/base/modal/component/BaseModal';
import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import {
  disableBodyScrolling,
  enableBodyScrolling,
} from 'src/shared/core/util/browserUtil';
import PrimaryButton from 'src/shared/ui/component/button/PrimaryButton';
import SecondaryButton from 'src/shared/ui/component/button/SecondaryButton';
import Subheading from 'src/shared/ui/component/text/Subheading';

import styles from './ConfirmationModal.style';

type Props = {
  title?: string;
  message?: string;
  confirmCta?: string;
  cancelCta?: string;
  handleConfirm: () => void;
  handleCancel?: () => void;
};
const useStyles = createUseThemeStyles(styles);

const ConfirmationModal = ({
  confirmCta,
  cancelCta,
  title,
  message,
  handleConfirm,
  handleCancel: onCancel,
}: Props): Node => {
  const intl = useIntl();
  const confirmText = confirmCta || intl.formatMessage(messages.confirm);
  const cancelText = cancelCta || intl.formatMessage(messages.cancel);
  const [isOpen, setIsOpen] = useState<boolean>(true);
  useEffect(() => {
    disableBodyScrolling();
    return () => {
      enableBodyScrolling();
    };
  }, []);
  const rootRef = createRef();
  useTrapTabs(rootRef);
  const classes = useStyles();
  const messageId = useId();

  const handleCancel = () => setIsOpen(false);

  const titleElt = title && (
    <Subheading ariaDescribedby={messageId}>{title}</Subheading>
  );
  const messageElt = message ? (
    <p className={classes.message} id={messageId}>
      {message}
    </p>
  ) : null;
  const confirmBtn = (
    <PrimaryButton layoutClass={classes.button} onClick={handleConfirm}>
      {confirmText}
    </PrimaryButton>
  );
  const cancelBtn = handleCancel && (
    <SecondaryButton layoutClass={classes.button} onClick={handleCancel}>
      {cancelText}
    </SecondaryButton>
  );
  return (
    <BaseModal isOpen={isOpen} onAfterClose={onCancel} ariaLabel={title}>
      {titleElt}
      {messageElt}
      <div className={classes.actions}>
        {cancelBtn}
        {confirmBtn}
      </div>
    </BaseModal>
  );
};

export default ConfirmationModal;
