import { useEffect, useRef, useState } from 'react';
import type { Node } from 'react';

import { portalNotificationId } from 'src/shared/app/base/constant/domConstants';
import usePortalRegistrationLength from 'src/shared/app/base/portal/notification/hook/usePortalRegistrationLength';
import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import useFeatureDecisions from 'src/shared/app/feature/hook/useFeatureDecisions';
import useHideOnScroll from 'src/shared/ui/context/HideOnScrollContext/hook/useHideOnScroll';

import styles from './Fixed.style';

const useStyles = createUseThemeStyles(styles);
type Props = {
  children: Node;
  zIndex?: number;
  rootEltTestId?: string;
};

const Fixed = ({ children, zIndex = 1, rootEltTestId }: Props): Node => {
  const rootRef = useRef<HTMLElement | null>(null);
  const { shouldHideTopBarOnScroll } = useFeatureDecisions();
  const elementsLength = usePortalRegistrationLength(portalNotificationId);
  const [_, setRerender] = useState<number>(0);
  useEffect(() => {
    // Needed because when the portal is unregistered, the top position of the wrapper is not updated
    window.requestAnimationFrame(() => {
      window.requestAnimationFrame(() => {
        if (process.env.JEST_WORKER_ID !== undefined) return;
        setRerender((prevState) => prevState + 1);
      });
    });
  }, [elementsLength]);
  const classes = useStyles();
  const isPageScrolled = useHideOnScroll();
  return (
    <div
      id={rootEltTestId}
      ref={rootRef}
      className={classes.root}
      style={{
        zIndex,
        transition: shouldHideTopBarOnScroll
          ? `225ms cubic-bezier(0, 0, 0.2, 1) 0ms`
          : 'none',
        top: isPageScrolled ? 0 : 88,
      }}
    >
      {children}
    </div>
  );
};

export default Fixed;
