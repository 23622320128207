import { em } from 'polished';

import { brightRed } from '@braindate/ui/lib/token/palette';

export default {
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: `1px solid ${brightRed}`,
    padding: [0, 8],
    borderRadius: 22,
    fontWeight: 700,
    fontSize: em(11),
    color: brightRed,
    textTransform: 'uppercase',
  },
};
