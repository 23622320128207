import useRouteMatch from 'src/shared/app/base/route/hook/useRouteMatch';
import type { RouteData } from 'src/shared/app/base/route/type/routeTypes';
import { getRoutePath } from 'src/shared/app/base/route/util/routeUtils';
import NotificationContainerItem from 'src/shared/app/notification/component/NotificationContainerItem';
import type { Notification } from 'src/shared/app/notification/type/notificationTypes';
import {
  getNotificationExcludePaths,
  getNotificationId,
  getNotificationIncludePaths,
  getNotificationIsActive,
  mapNotificationPath,
  orderNotificationsByPriority,
} from 'src/shared/app/notification/util/notificationUtil';

type Props = {
  queue: Array<Notification<any>>;
};
export default function NotificationContainerDumb({ queue }: Props): Node {
  const notificationsOrdered = orderNotificationsByPriority(queue);

  /*
   |----------------------------------------------------------------------------
   | Hooks
   |----------------------------------------------------------------------------
   */
  const activeRoute = useRouteMatch();

  /*
   |----------------------------------------------------------------------------
   | Elements
   |----------------------------------------------------------------------------
   */
  const showMainNotification = (notification) => {
    if (!getNotificationIsActive(notification)) {
      return false;
    }

    const excludePaths = getNotificationExcludePaths(notification);
    const includePaths = getNotificationIncludePaths(notification);

    const getRoutesElt = (values: Array<string | RouteData>): string[] =>
      values
        .map<string>((route) => mapNotificationPath(route, notification))
        .filter(Boolean);

    const excludeRoutesElt = getRoutesElt(excludePaths);
    const includeRoutesElt = getRoutesElt(includePaths);

    const isActiveRouteIncluded = (routes: string[]): boolean =>
      routes.some((route) => route === getRoutePath(activeRoute));

    return (
      !isActiveRouteIncluded(excludeRoutesElt) &&
      (includeRoutesElt.length === 0 || isActiveRouteIncluded(includeRoutesElt))
    );
  };

  const firstShowedNotificationIndex =
    notificationsOrdered.findIndex(showMainNotification);
  return (
    <>
      {notificationsOrdered.map((notification, index) => (
        <NotificationContainerItem
          key={getNotificationId(notification)}
          notification={notification}
          isVisible={firstShowedNotificationIndex === index}
        />
      ))}
    </>
  );
}
