import type { Membership } from '@braindate/domain/lib/membership/type';
import {
  getMembershipGuidanceURL,
  getMembershipId,
} from '@braindate/domain/lib/membership/util';
import { isObject } from '@braindate/util/lib/type';

import { membershipGuidanceEndpoint } from 'src/shared/app/base/settings/endpointSettings';
import type { GetState } from 'src/shared/core/type/reduxTypes';

import * as types from './membershipActionTypes';
// Reset
export function resetMembership() {
  return {
    type: types.RESET_MEMBERSHIP,
  };
}
export function fetchGuidanceMarketPrompt(membership: Membership) {
  return (dispatch: any, _: GetState, { smartGet }: Record<string, any>) => {
    dispatch(fetchGuidanceMarketPromptRequest());
    return smartGet(
      getMembershipGuidanceURL(membership) ||
        membershipGuidanceEndpoint(getMembershipId(membership)),
    )
      .then((json) =>
        dispatch(fetchGuidanceMarketPromptSuccess(json, membership)),
      )
      .catch((exception) =>
        dispatch(fetchGuidanceMarketPromptFailure(exception)),
      );
  };
}
export function fetchGuidanceMarketPromptRequest() {
  return {
    type: types.FETCH_GUIDANCE_MARKET_PROMPT_REQUEST,
  };
}
export function fetchGuidanceMarketPromptSuccess(
  result: Record<string, any>,
  membership: Membership,
) {
  return {
    type: types.FETCH_GUIDANCE_MARKET_PROMPT_SUCCESS,
    guidance: result,
    membership,
  };
}
export function fetchGuidanceMarketPromptFailure(exception: Error) {
  return {
    type: types.FETCH_GUIDANCE_MARKET_PROMPT_FAILURE,
    exception,
  };
}
export function deleteGuidanceMarketPrompt(
  membership: Membership,
  label: string,
  data?: Record<string, any>,
) {
  let params = {
    label,
  };

  if (isObject(data)) {
    params = { ...params, ...data };
  }

  return (dispatch: any, _: GetState, { deleteFn }: Record<string, any>) => {
    dispatch(deleteGuidanceMarketPromptRequest());
    return deleteFn(getMembershipGuidanceURL(membership), params)
      .then(() => dispatch(deleteGuidanceMarketPromptSuccess(membership)))
      .catch((exception) =>
        dispatch(deleteGuidanceMarketPromptFailure(exception)),
      );
  };
}
export function deleteGuidanceMarketPromptRequest() {
  return {
    type: types.DELETE_GUIDANCE_MARKET_PROMPT_REQUEST,
  };
}
export function deleteGuidanceMarketPromptSuccess(membership: Membership) {
  return {
    type: types.DELETE_GUIDANCE_MARKET_PROMPT_SUCCESS,
    membership,
  };
}
export function deleteGuidanceMarketPromptFailure(exception: Error) {
  return {
    type: types.DELETE_GUIDANCE_MARKET_PROMPT_FAILURE,
    exception,
  };
}
