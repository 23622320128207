import type { State } from 'src/shared/core/type/reduxTypes';

/*
 |------------------------------------------------------------------------------
 | Base selectors
 |------------------------------------------------------------------------------
 */
const getEnvironmentState = (state) => state.app.env;

/*
 |------------------------------------------------------------------------------
 | Exports
 |------------------------------------------------------------------------------
 */
export const getEnvironment = (state: State): string =>
  getEnvironmentState(state).environment;
export const isDevModeEnabled = (state: State): boolean =>
  getEnvironmentState(state).devMode;
export const getDefaultEvent = (state: State): number =>
  getEnvironmentState(state).event;
export const getAPIURL = (state: State): string =>
  getEnvironmentState(state).apiURL;
export const getAvatarURL = (state: State): string =>
  getEnvironmentState(state).avatarURL;
export const getMediaURL = (state: State): string =>
  getEnvironmentState(state).mediaURL;
export const getWebsocketURL = (state: State): string =>
  getEnvironmentState(state).websocketURL;
export const getPlatformBaseDomain = (state: State): string =>
  getEnvironmentState(state).platformBaseDomain;
export const getStaticURL = (state: State): string =>
  getEnvironmentState(state).staticURL;
export const getTrackingId = (state: State): string =>
  getEnvironmentState(state).trackingId;
export const isTrackingEnabled = (state: State): boolean =>
  getEnvironmentState(state).isTrackingEnabled;
export const isErrorReportingEnabled = (state: State): boolean =>
  getEnvironmentState(state).isErrorReportingEnabled;
export const isServiceWorkerEnabled = (state: State): boolean =>
  getEnvironmentState(state).isServiceWorkerEnabled;
export const getVersion = (state: State): string =>
  getEnvironmentState(state).version;
export const getPreconnectURLS = (state: State): string =>
  getEnvironmentState(state).preconnectURLS;
export const getBranchName = (state: State): string =>
  getEnvironmentState(state).branchName;
export const getVersionNumber = (state: State): string =>
  getEnvironmentState(state).versionNumber;
export const getShowcasePageUrl = (state: State): string =>
  getEnvironmentState(state).showcasePageUrl;
export const getLiveChatEnvOptions = (state: State): Record<string, any> => ({
  liveChatIntercomAppId: getEnvironmentState(state).liveChatIntercomAppId,
  liveChatHubspotAppId: getEnvironmentState(state).liveChatHubspotAppId,
});
export const getWhatIsBraindateURL = (state: State): string =>
  getEnvironmentState(state).videoWhatIsBraindateUrl;
export const getAmplitudeKey = (state: State): string =>
  getEnvironmentState(state).amplitudeKey;
export const getOIDCAuthority = (state: State): string =>
  getEnvironmentState(state).oidcAuthority;
export const getOIDCClientSecret = (state: State): string =>
  getEnvironmentState(state).oidcClientSecret;
export const getOIDCSecret = (state: State): string =>
  getEnvironmentState(state).oidcSecret;
export const getNextUrl = (state: State): string =>
  getEnvironmentState(state).nextUrl;
