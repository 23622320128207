import { memo } from 'react';
import type { Node } from 'react';

import Info from '@mui/icons-material/InfoOutlined';
import { Tooltip } from '@mui/material';

import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';

import styles from './Checkbox.style';

type Props = {
  name: string;
  value?: string;
  checked?: boolean;
  text: string;
  icon?: Node;
  helper?: string;
  onChange: (e: React.SyntheticEvent<HTMLInputElement>) => void;
  'aria-describedby'?: string;
};
const useStyles = createUseThemeStyles(styles);

const Checkbox = ({
  value,
  checked,
  name,
  text,
  icon,
  helper,
  onChange,
  'aria-describedby': ariaDescribedby,
  ...rest
}: Props): Node => {
  /*
   |----------------------------------------------------------------------------
   | Classes
   |----------------------------------------------------------------------------
   */
  const classes = useStyles();

  /*
   |----------------------------------------------------------------------------
   | Elements
   |----------------------------------------------------------------------------
   */
  return (
    <>
      <input
        {...rest}
        id={name}
        name={name}
        type="checkbox"
        checked={checked}
        value={value}
        onChange={onChange}
        aria-describedby={ariaDescribedby}
      />
      <label className={classes.label} htmlFor={name}>
        {icon && <span className={classes.icon}>{icon}</span>}
        {text}
        {helper ? (
          <Tooltip title={helper} placement="bottom" arrow>
            <Info className={classes.helper} />
          </Tooltip>
        ) : null}
      </label>
    </>
  );
};

export default memo<Props>(Checkbox);
