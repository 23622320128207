import { rem } from 'polished';

import { tablet } from '@braindate/ui/lib/token/mediaQuery';
import { baseFontSize } from '@braindate/ui/lib/token/typography';

import { REGULAR_SIDEBAR_WIDTH } from 'src/shared/app/base/component/template/component/sidebar/SideBar.style';

import { templateMain, templateRoot } from './style/template.style';

export default {
  root: {
    extend: templateRoot,
    position: 'relative',
  },
  branding: {
    marginTop: rem(40, baseFontSize),
    [tablet]: {
      position: 'fixed',
      left: 28,
      top: 0,
    },
  },
  logo: {
    maxWidth: '120px !important',
  },
  main: {
    extend: templateMain,
    display: 'flex',
    justifyContent: 'center',
  },
  content: {
    [tablet]: {
      width: `calc(100% - ${REGULAR_SIDEBAR_WIDTH * 2 + 20}px)`,
    },
  },
};
