import { memo } from 'react';
import type { Node } from 'react';

import { getStaticAsset } from 'src/shared/app/base/util/assetUtils';
import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import defaultOnboardingPicture from 'src/shared/app/onboarding/assets/onboarding_background.jpg';

import styles from './OnboardingIntroBackground.style';

type Props = {
  backgroundImage: Record<string, any>;
};
const useStyles = createUseThemeStyles(styles);

const OnboardingIntroBackground = ({ backgroundImage }: Props): Node => {
  const classes = useStyles();
  const rootStyles = {
    backgroundImage: backgroundImage
      ? `url(${backgroundImage})`
      : `url(${getStaticAsset(defaultOnboardingPicture)})`,
  };
  return <div className={classes.root} style={rootStyles} />;
};

export default memo<Props>(OnboardingIntroBackground);
