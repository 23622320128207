import { memo } from 'react';
import type { Node } from 'react';

import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import classnames from 'classnames';
import { uniq } from 'ramda';
import { useIntl } from 'react-intl';

import type { Location } from '@braindate/domain/lib/location/type';
import {
  getLocationBraindateMeetingPoint,
  getLocationLabel,
  isVirtualLocation,
} from '@braindate/domain/lib/location/util';
import type { TopicKind } from '@braindate/domain/lib/topic/type';

import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import messages from 'src/shared/domain/location/l10n/locationL10n';

import styles from './LocationsInfo.style';

type Props = {
  locations: Location | Location[];
  className?: string;
  withAccent?: boolean;
  withIcon?: boolean;
  kind?: TopicKind | null | undefined;
  showMeetingPoint?: boolean;
  isBlock?: boolean;
  isCompactForMobile?: boolean;
};
const useStyles = createUseThemeStyles(styles);

const LocationsInfo = ({
  locations,
  className,
  withAccent = false,
  withIcon = true,
  kind,
  showMeetingPoint = false,
  isBlock = false,
  isCompactForMobile = false,
}: Props): Node => {
  const locationsArray = Array.isArray(locations) ? locations : [locations];

  const classes = useStyles({
    withAccent,
    isBlock:
      isBlock && locations && locations[0] && !isVirtualLocation(locations[0]),
    isCompactForMobile,
  });
  const intl = useIntl();

  const getMettingPointName = (location) => {
    if (
      !showMeetingPoint ||
      locationsArray.length > 1 ||
      isVirtualLocation(locationsArray[0]) ||
      !kind
    )
      return null;
    return getLocationBraindateMeetingPoint(location, kind);
  };

  const getText = () => {
    if (locationsArray.length < 2) {
      if (isVirtualLocation(locationsArray[0])) {
        return intl.formatMessage(messages.locationInfoVirtual);
      }
      const podName = getMettingPointName(locationsArray[0]);
      const locationName = getLocationLabel(locationsArray[0]);
      return podName ? `${podName} — ${locationName}` : locationName;
    }

    // TEMP https://e180.atlassian.net/browse/PAX-5826
    return intl.formatMessage(messages.inPerson);

    return uniq(
      locationsArray
        .map((location) => {
          if (isVirtualLocation(location)) {
            return false;
          }
          return getLocationLabel(location);
        })
        .filter(Boolean),
    ).join(', ');
  };

  if (!locationsArray || locationsArray.length === 0) return null;

  return (
    <div className={classnames([className, classes.root])}>
      {withIcon && (
        <LocationOnOutlinedIcon
          classes={{
            root: classes.icon,
          }}
        />
      )}
      <div className={classes.text}>{getText()}</div>
    </div>
  );
};

export default memo<Props>(LocationsInfo);
