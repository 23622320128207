import { DateTime, Zone } from 'luxon';

import { timeFormat12h } from 'src/shared/app/locale/settings/localeSettings';

export const dateFormatOpts = {
  weekday: 'short',
  month: 'short',
  day: 'numeric',
};
export const dateLongFormatOpts = {
  month: 'long',
  day: 'numeric',
  year: 'numeric',
};
export const datetimeFormatOpts = {
  weekday: 'short',
  month: 'short',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
};
export const monthDayFormatOpts = {
  month: 'short',
  day: 'numeric',
};
export const weekDayFormatOpts = {
  weekday: 'short',
};
export const dayFormatOpts = {
  day: 'numeric',
};
export const longDayFormatOpts = {
  weekday: 'long',
  month: 'long',
  day: 'numeric',
};
export const longWeekDayFormatOpts = {
  weekday: 'long',
  month: 'long',
  day: 'numeric',
  year: 'numeric',
};
export const timeFormatOpts = {
  hour: 'numeric',
  minute: 'numeric',
};

/**
 * Formats a date to a short format.
 * @param {DateTime} date - The date to format.
 * @param {string} locale - The locale to use for formatting.
 * @returns {string} - The formatted date string.
 * @example
 * formatDate(DateTime.local(), 'en-US'); // "Mon, Oct 2"
 */
export function formatDate(date: DateTime, locale: string): string {
  return date.setLocale(locale).toLocaleString(dateFormatOpts);
}

/**
 * Formats a date to a long format.
 * @param {DateTime} date - The date to format.
 * @param {string} locale - The locale to use for formatting.
 * @returns {string} - The formatted date string.
 * @example
 * formatDateLong(DateTime.local(), 'en-US'); // "October 2, 2023"
 */
export function formatDateLong(date: DateTime, locale: string): string {
  return date.setLocale(locale).toLocaleString(dateLongFormatOpts);
}

/**
 * Formats a date to ISO format.
 * @param {DateTime} date - The date to format.
 * @param {string} locale - The locale to use for formatting.
 * @returns {string} - The formatted date string in ISO format.
 * @example
 * formatDateInput(DateTime.local(), 'en-US'); // "2023-10-02"
 */
export function formatDateInput(date: DateTime, locale: string): string {
  return date.setLocale(locale).toISODate();
}

/**
 * Formats a date to month and day format.
 * @param {DateTime} date - The date to format.
 * @param {string} locale - The locale to use for formatting.
 * @returns {string} - The formatted date string.
 * @example
 * formatMonthDay(DateTime.local(), 'en-US'); // "Oct 2"
 */
export function formatMonthDay(date: DateTime, locale: string): string {
  return date.setLocale(locale).toLocaleString(monthDayFormatOpts);
}

/**
 * Formats a date to time format.
 * @param {DateTime} date - The date to format.
 * @param {string} locale - The locale to use for formatting.
 * @returns {string} - The formatted time string.
 * @example
 * formatTimeOfDate(DateTime.local(), 'en-US'); // "10:30 AM"
 */
export function formatTimeOfDate(date: DateTime, locale: string): string {
  return date.setLocale(locale).toLocaleString(timeFormatOpts);
}

/**
 * Formats a date to weekday format.
 * @param {DateTime} date - The date to format.
 * @param {string} locale - The locale to use for formatting.
 * @returns {string} - The formatted weekday string.
 * @example
 * formatWeekDay(DateTime.local(), 'en-US'); // "Mon"
 */
export function formatWeekDay(date: DateTime, locale: string): string {
  return date.setLocale(locale).toLocaleString(weekDayFormatOpts);
}

/**
 * Formats a date to day format.
 * @param {DateTime} date - The date to format.
 * @param {string} locale - The locale to use for formatting.
 * @returns {string} - The formatted day string.
 * @example
 * formatDay(DateTime.local(), 'en-US'); // "2"
 */
export function formatDay(date: DateTime, locale: string): string {
  return date.setLocale(locale).toLocaleString(dayFormatOpts);
}

/**
 * Formats a date to long day format.
 * @param {DateTime} date - The date to format.
 * @param {string} locale - The locale to use for formatting.
 * @returns {string} - The formatted long day string.
 * @example
 * formatLongDay(DateTime.local(), 'en-US'); // "Monday, October 2"
 */
export function formatLongDay(date: DateTime, locale: string): string {
  return date.setLocale(locale).toLocaleString(longDayFormatOpts);
}

/**
 * Formats a date to long weekday format.
 * @param {DateTime} date - The date to format.
 * @param {string} locale - The locale to use for formatting.
 * @returns {string} - The formatted long weekday string.
 * @example
 * formatLongWeekDay(DateTime.local(), 'en-US'); // "Monday, October 2, 2023"
 */
export function formatLongWeekDay(date: DateTime, locale: string): string {
  return date.setLocale(locale).toLocaleString(longWeekDayFormatOpts);
}

/**
 * Formats a date and time.
 * @param {DateTime} date - The date to format.
 * @param {string} locale - The locale to use for formatting.
 * @param {string} [timeFormat] - The time format to use (optional).
 * @param {boolean} [withLocalZone=false] - Whether to use the local timezone (optional).
 * @param {Object} [dateTimeFormat=datetimeFormatOpts] - The date and time format options (optional).
 * @returns {string} - The formatted date and time string.
 * @example
 * formatDateTime(DateTime.local(), 'en-US'); // "Mon, Oct 2, 10:30 AM"
 */
export function formatDateTime(
  date: DateTime,
  locale: string,
  timeFormat?: string,
  withLocalZone: boolean = false,
  dateTimeFormat: Record<string, any> = datetimeFormatOpts,
): string {
  return setDateTimezone(date, withLocalZone)
    .setLocale(locale)
    .toLocaleString(setTimeFormatOption({ ...dateTimeFormat }, timeFormat));
}

/**
 * Formats a time.
 * @param {DateTime} date - The date to format.
 * @param {string} locale - The locale to use for formatting.
 * @param {string} [timeFormat] - The time format to use (optional).
 * @param {boolean} [withLocalZone=false] - Whether to use the local timezone (optional).
 * @returns {string} - The formatted time string.
 * @example
 * formatTime(DateTime.local(), 'en-US'); // "10:30 AM"
 */
export function formatTime(
  date: DateTime,
  locale: string,
  timeFormat?: string,
  withLocalZone: boolean = false,
) {
  return setDateTimezone(date, withLocalZone)
    .setLocale(locale)
    .toLocaleString(setTimeFormatOption({ ...timeFormatOpts }, timeFormat));
}

/**
 * Sets the time format option.
 * @param {Object} opts - The options object.
 * @param {string} [timeFormat] - The time format to use (optional).
 * @returns {Object} - The updated options object.
 * @example
 * setTimeFormatOption({}, '12h'); // { hourCycle: 'h12' }
 */
export function setTimeFormatOption(
  opts: Record<string, any>,
  timeFormat?: string,
): Record<string, any> {
  if (timeFormat) {
    // $FlowIssue
    opts.hourCycle = timeFormat === timeFormat12h ? 'h12' : 'h24';
  }

  return opts;
}

/**
 * Sets the date timezone.
 * @param {DateTime} date - The date to set the timezone for.
 * @param {boolean} withLocalZone - Whether to use the local timezone.
 * @returns {DateTime} - The date with the timezone set.
 * @example
 * setDateTimezone(DateTime.local(), true); // DateTime with local timezone
 */
export function setDateTimezone(
  date: DateTime,
  withLocalZone: boolean,
): DateTime {
  if (withLocalZone) {
    const { instance: localZone } = Zone;

    if (localZone.isValid) {
      return date.setZone(localZone);
    }
  }

  return date;
}
