import { useDispatch } from 'react-redux';

import type { ComplaintContentType } from '@braindate/domain/lib/complaint/type';

import modalRegistry from 'src/shared/app/base/modal/registry/modalRegistry';
import { triggerModal } from 'src/shared/app/base/modal/slice/modalSlice';
import ReportingModal from 'src/shared/components/domain/complaints/components/ReportingModal';
import { reportingModalId } from 'src/shared/components/domain/complaints/util';

modalRegistry.add(reportingModalId, ReportingModal);

const useReportingModal = () => {
  const dispatch = useDispatch();
  return (
    contentType: ComplaintContentType,
    content: Record<string, any> & {
      id: number;
    },
  ) => {
    dispatch(
      triggerModal(reportingModalId, {
        contentType,
        content,
      }),
    );
  };
};

export default useReportingModal;
