import * as _ from 'lodash';

/**
 * https://gist.github.com/kottenator/433f677e5fdddf78d195
 * Specific error parser for django-rest-framework errors structure
 * Tested on django-rest-framework v2.3.13
 * Live example at http://jsbin.com/wiqumo
 *
 * Error structure examples:
 *
 *     // The most simple case
 *     { detail: "Authentication is required" }
 *
 *     // Simple form validation error
 *     { title: ["This field is required."], "description": ["This field is required."]}
 *
 *     // Simple list validation error
 *     [{}, {"title": ["This field is required."]}]
 *
 *     // Complex nested object validation error
 *     {"experience": [
 *         {}, {}, {"position": ["This field is required."], "employer": ["This field is required."]}
 *     ]}
 */
export function parseErrors(data) {
  function _camelCaseToVerbose(text) {
    return text.replace(/(?=[A-Z])/g, ' ');
  }

  function _underscoredToVerbose(text) {
    return text.replace(/[\d_]/g, ' ');
  }

  function _capitalize(text) {
    text = text.toLowerCase();
    text = text.charAt(0).toUpperCase() + text.slice(1);
    return text;
  }

  function _parseErrorItem(item, listPos) {
    const listItemTemplate = _.template('Item <%= i %>: <%= content %>');

    // const containerTemplate = _.template('<%= content %>');
    let output = [];

    _.each(item, function (value, key) {
      const fieldTemplate = _.template('<%- name %>: <%= content %>');

      // const plainValueTemplate = _.template(
      //   '<span class="value"><%- value %></span>',
      // );
      let plainValue;
      let listValue;
      let content;

      if (_.isString(value)) {
        plainValue = value;
      } else if (_.isArray(value)) {
        if (_.isString(value[0])) {
          plainValue = value.join(' ');
        } else {
          listValue = _parseErrorList(value);
        }
      }

      if (plainValue) {
        content = plainValue;
      } else if (listValue) {
        content = listValue;
      }

      if (content) {
        if (key.search(/[A-Z]/) !== -1) key = _camelCaseToVerbose(key);
        if (key.search(/[\d_]/) !== -1) key = _underscoredToVerbose(key);
        key = _capitalize(key);
        output.push(
          fieldTemplate({
            name: key,
            content,
          }),
        );
      }
    });

    output = output.join(', ');

    if (output) {
      // output = containerTemplate({ content: output });
      if (listPos) {
        output = listItemTemplate({
          i: listPos,
          content: output,
        });
      }
    }

    return output;
  }

  function _parseErrorList(items) {
    // const containerTemplate = _.template(
    //   '<ul class="list"><%= content %></ul>',
    // );
    let output = [];

    _.each(items, function (item, i) {
      if (!_.isEmpty(item)) {
        output.push(_parseErrorItem(item, i + 1));
      }
    });

    output = output.join(', ');
    // if (output) {
    //   output = containerTemplate({ content: output });
    // }
    return output;
  }

  if (_.isArray(data)) {
    return _parseErrorList(data);
  }

  return _parseErrorItem(data);
}
