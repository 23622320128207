import { rem } from 'polished';

import { aaDarkGrey } from '@braindate/ui/lib/token/palette';
import { baseFontSize } from '@braindate/ui/lib/token/typography';

import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

export default {
  root: {
    minWidth: '0 !important',
    padding: 0,
    backgroundColor: 'transparent',
    color: ({ theme }: ThemeFunction<unknown>) => theme.accentColor,
    fontSize: rem(13, baseFontSize),
    fontWeight: 700,
    letterSpacing: 1,
    lineHeight: 15 / 13,
    textTransform: 'uppercase',
    WebkitFontSmoothing: 'antialiased',
    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'underline',
    },
    '&:active': {
      backgroundColor: 'transparent',
    },
  },
  'root-withError': {
    color: ({ theme }: ThemeFunction<unknown>) => theme.errorMessageColor,
  },
  'root-isDisabled': {
    color: aaDarkGrey,
  },
};
