import { memo } from 'react';
import type { Node } from 'react';

import classNames from 'classnames';

import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import BaseIcon from 'src/shared/ui/component/icon/BaseIcon';

import svg from './chevron.svg';
import styles from './ChevronIcon.style';

export type Props = {
  className?: string;
  title?: string;
  right?: boolean;
  up?: boolean;
  down?: boolean;
};
const useStyles = createUseThemeStyles(styles);

const ChevronIcon = ({ className, title, right, up, down }: Props): Node => {
  /*
   |----------------------------------------------------------------------------
   | Classes
   |----------------------------------------------------------------------------
   */
  const classes = useStyles();
  const rootClasses = classNames(classes.root, className, {
    [classes['root-right']]: right,
    [classes['root-up']]: up,
    [classes['root-down']]: down,
  });

  /*
   |----------------------------------------------------------------------------
   | Elements
   |----------------------------------------------------------------------------
   */
  return <BaseIcon className={rootClasses} svg={svg} title={title} />;
};

export default memo<Props>(ChevronIcon);
