import { memo } from 'react';
import type { Node } from 'react';

import withForwardRef from '@braindate/util/lib/react/hoc/withForwardRef';

import Button, { VARIANT } from 'src/shared/ui/component/button/Button';
import type { Props as ButtonProps } from 'src/shared/ui/component/button/Button';

const PrimaryButton = (props: ButtonProps): Node => (
  <Button {...props} variant={VARIANT.PRIMARY} />
);

export default memo<ButtonProps>(withForwardRef(PrimaryButton));
