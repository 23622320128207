import type { Notification } from '@braindate/domain/lib/notification/type';
import {
  getNotificationClearURL,
  getNotificationId,
  getNotificationMarkReadURL,
} from '@braindate/domain/lib/notification/util';

import { TAG_TYPE } from 'src/shared/app/base/api/apiConstant';
import { apiRoot } from 'src/shared/app/base/api/apiRoot';
import { defaultLimitPages } from 'src/shared/app/base/api/constant/apiConstant';
import type {
  ApiListResult,
  EndpointSelector,
  UseMutation,
  UseQuery,
} from 'src/shared/app/base/api/type/apiQueryType';
import {
  getQueryTag,
  infiniteLoadingSettings,
  listLoadingSettings,
  optimisticUpdate,
} from 'src/shared/app/base/api/utils/endpointUtils';
import {
  clearAllNotificationsEndpoint,
  notificationsEndpoint,
  webpushEndpoint,
} from 'src/shared/app/base/settings/endpointSettings';

type Api = {
  endpoints: {
    getPushSubscriptions: EndpointSelector<
      void,
      (PushSubscription & {
        id: number;
      })[]
    >;
  };
  useGetNotificationsQuery: UseQuery<ApiListResult<Notification>>;
  useClearAllNotificationsMutation: UseMutation<void, void>;
  useMarkNotificationAsReadMutation: UseMutation<Notification, Notification>;
  useDismissNotificationMutation: UseMutation<Notification, Notification>;
  useGetPushSubscriptionsQuery: UseQuery<
    (PushSubscription & {
      id: number;
      user_agent: string;
    })[]
  >;
  usePostPushSubscriptionMutation: UseMutation<
    PushSubscription,
    PushSubscription & {
      id: number;
    }
  >;
  useDeletePushSubscriptionMutation: UseMutation<
    string | number,
    PushSubscription & {
      id: number;
    }
  >;
};
const extendedApi: Api = apiRoot.injectEndpoints({
  endpoints: (builder) => ({
    getNotifications: builder.query({
      query: ({ page = 1, limit } = {}) => ({
        url: notificationsEndpoint(),
        params: {
          limit: limit || defaultLimitPages,
          offset: (page - 1) * (limit || defaultLimitPages),
          for_platform: 'pax',
        },
      }),
      ...infiniteLoadingSettings<Notification>({
        tagType: TAG_TYPE.NOTIFICATION,
        idGetter: getNotificationId,
      }),
    }),
    clearAllNotifications: builder.mutation({
      query: () => ({
        url: clearAllNotificationsEndpoint(),
        method: 'POST',
      }),

      async onQueryStarted(_, mutation) {
        optimisticUpdate<Notification>({
          extendedApi,
          mutation,
          type: TAG_TYPE.NOTIFICATION,
          id: 'LIST',
          callback: (draft) => {
            draft.is_hidden = true;
            return draft;
          },
        });
      },
    }),
    markNotificationAsRead: builder.mutation({
      query: (notification: Notification) => ({
        url: getNotificationMarkReadURL(notification),
        method: 'POST',
      }),

      async onQueryStarted(notification, mutation) {
        optimisticUpdate<Notification>({
          extendedApi,
          mutation,
          type: TAG_TYPE.NOTIFICATION,
          id: notification.id,
          callback: (draft) => {
            draft.read = true;
            return draft;
          },
        });
      },

      invalidatesTags: [TAG_TYPE.NOTIFICATION],
    }),
    dismissNotification: builder.mutation({
      query: (notification: Notification) => ({
        url: getNotificationClearURL(notification),
        method: 'POST',
      }),

      async onQueryStarted(notification, mutation) {
        optimisticUpdate<Notification>({
          extendedApi,
          mutation,
          type: TAG_TYPE.NOTIFICATION,
          id: notification.id,
          callback: (draft) => {
            draft.is_hidden = true;
            return draft;
          },
        });
      },

      invalidatesTags: (_result, _error, { id }) =>
        getQueryTag(TAG_TYPE.NOTIFICATION, id),
    }),
    // Push notifications
    getPushSubscriptions: builder.query({
      query: () => ({
        url: webpushEndpoint(),
      }),
      ...listLoadingSettings({
        tagType: TAG_TYPE.PUSH_SUBSCRIPTION,
        idGetter: ({ id }) => id,
      }),
    }),
    postPushSubscription: builder.mutation({
      query: (subscription: PushSubscription) => ({
        url: webpushEndpoint(),
        method: 'POST',
        params: subscription,
      }),
      invalidatesTags: [TAG_TYPE.PUSH_SUBSCRIPTION],
    }),
    deletePushSubscription: builder.mutation({
      query: (id: string | number) => ({
        url: webpushEndpoint(id),
        method: 'DELETE',
      }),
      invalidatesTags: [TAG_TYPE.PUSH_SUBSCRIPTION],
    }),
  }),
});
export const {
  useGetNotificationsQuery,
  useClearAllNotificationsMutation,
  useMarkNotificationAsReadMutation,
  useDismissNotificationMutation,
  useGetPushSubscriptionsQuery,
  usePostPushSubscriptionMutation,
  useDeletePushSubscriptionMutation,
} = extendedApi;
