import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  id: '',
  data: null,
};
const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    triggerModal: {
      reducer(state, { payload }) {
        const { id, data } = payload;
        state.id = id;
        state.data = data;
      },

      prepare(id, data) {
        return {
          payload: {
            id,
            data,
          },
        };
      },
    },

    clearModal(state) {
      const { id, data } = initialState;
      state.id = id;
      state.data = data;
    },
  },
});
export const { triggerModal, clearModal } = modalSlice.actions;
export default modalSlice.reducer;
