export const SHOW_SIGNUP_EMAIL_FORM = 'SHOW_SIGNUP_EMAIL_FORM';
export const SHOW_SIGNUP_PASSWORD_FORM = 'SHOW_SIGNUP_PASSWORD_FORM';
export const SHOW_SIGNUP_ABOUT_FORM = 'SHOW_SIGNUP_ABOUT_FORM';
export const VALIDATE_SIGNUP_ACTIVATION_CODE_REQUEST =
  'VALIDATE_SIGNUP_ACTIVATION_CODE_REQUEST';
export const VALIDATE_SIGNUP_ACTIVATION_CODE_SUCCESS =
  'VALIDATE_SIGNUP_ACTIVATION_CODE_SUCCESS';
export const VALIDATE_SIGNUP_ACTIVATION_CODE_FAILURE =
  'VALIDATE_SIGNUP_ACTIVATION_CODE_FAILURE';
export const VALIDATE_SIGNUP_EMAIL_REQUEST = 'VALIDATE_SIGNUP_EMAIL_REQUEST';
export const VALIDATE_SIGNUP_EMAIL_SUCCESS = 'VALIDATE_SIGNUP_EMAIL_SUCCESS';
export const VALIDATE_SIGNUP_EMAIL_FAILURE = 'VALIDATE_SIGNUP_EMAIL_FAILURE';
export const SIGNUP_CREATE_USER_REQUEST = 'SIGNUP_CREATE_USER_REQUEST';
export const SIGNUP_CREATE_USER_SUCCESS = 'SIGNUP_CREATE_USER_SUCCESS';
export const SIGNUP_CREATE_USER_FAILURE = 'SIGNUP_CREATE_USER_FAILURE';
