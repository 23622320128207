import type { BraindateEvent } from '@braindate/domain/lib/event/type';
import { getEventPlugins } from '@braindate/domain/lib/event/util';
import { assertObject } from '@braindate/util/lib/assert';

import type { SlackPluginStatus } from 'src/shared/app/base/plugin/slack/types/SlackPluginStatus';
import { slackPlugin } from 'src/shared/app/feature/settings/featureSettings';

export function getSlackOptions(
  event: BraindateEvent,
): Record<string, any> | null | undefined {
  const options = getEventPlugins(event)[slackPlugin];

  if (options) {
    assertObject(options, 'options');
    return options;
  }
}
export function getSlackURL(event: BraindateEvent): string | null | undefined {
  return (getSlackOptions(event) || {}).slack_url;
}
export function getSlackChannelName(
  event: BraindateEvent,
): string | null | undefined {
  return (getSlackOptions(event) || {}).channel_name;
}
export function getSlackManagePermissionsUrl(
  status: SlackPluginStatus,
): string | null | undefined {
  return status.manage_permissions_url;
}
export function getSlackManageIsOperational(
  status: SlackPluginStatus,
): boolean | null | undefined {
  return status.is_operational;
}
export function getSlackManageAuthUrl(
  status: SlackPluginStatus,
): string | null | undefined {
  return status.auth_url;
}
