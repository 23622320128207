import { memo, useEffect } from 'react';
import type { Node } from 'react';

import { Drawer } from '@mui/material';
import { useId } from 'react-aria';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import { getUserFullName } from '@braindate/domain/lib/user/util';

import useRoutes from 'src/shared/app/base/route/hook/useRoutes';
import { profileRoute } from 'src/shared/app/base/route/setting/routeSettings';
import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import {
  hideHelpButton,
  resetHelpButtonHidden,
} from 'src/shared/app/help/action/uiHelpButtonReducerActions';
import type { DrawerMenuItemType } from 'src/shared/app/nav/component/drawermenu/DrawerMenu';
import DrawerMenuButton from 'src/shared/app/nav/component/drawermenu/DrawerMenuButton';
import MobileDrawerMenuList from 'src/shared/app/nav/component/drawermenu/MobileDrawerMenuList';
import UserAvatar, {
  xlarge,
} from 'src/shared/components/domain/user/avatar/UserAvatar';
import useSelf from 'src/shared/domain/user/hook/useSelf';

import styles from './MobileDrawerMenu.style';

type Props = {
  menuItems: DrawerMenuItemType[];
  isMenuOpened: boolean;
  setIsMenuOpened: (newValue: boolean) => void;
};
const useStyles = createUseThemeStyles(styles);

const MobileDrawerMenu = ({
  menuItems,
  isMenuOpened,
  setIsMenuOpened,
}: Props): Node => {
  const menuId = useId();
  const self = useSelf();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [profilePath] = useRoutes(profileRoute);
  useEffect(() => {
    setIsMenuOpened(false);
  }, [pathname]);
  useEffect(() => {
    if (isMenuOpened) {
      dispatch(hideHelpButton());
    } else {
      dispatch(resetHelpButtonHidden());
    }
  }, [isMenuOpened]);
  if (!self) return null;
  return (
    <>
      <DrawerMenuButton
        isMenuOpened={isMenuOpened}
        setIsMenuOpened={setIsMenuOpened}
      />

      <Drawer
        id={menuId}
        open={isMenuOpened}
        onClose={() => setIsMenuOpened(false)}
        keepMounted={false}
        anchor="right"
        PaperProps={{
          sx: {
            width: '100%',
            height: '100dvh',
            maxWidth: 320,
            borderRadius: 0,
            '&:before': {
              display: 'none',
            },
          },
        }}
      >
        <div className={classes.root}>
          <div className={classes.content}>
            <Link className={classes.profile} to={profilePath}>
              <UserAvatar user={self} variant={xlarge} />
              <span>{getUserFullName(self)}</span>
            </Link>

            <MobileDrawerMenuList items={menuItems} />
          </div>
        </div>
      </Drawer>
    </>
  );
};

export default memo<Props>(MobileDrawerMenu);
