import { memo } from 'react';
import type { Node } from 'react';

import classNames from 'classnames';
import { useField } from 'formik';
import { useId, useVisuallyHidden } from 'react-aria';
import { useIntl } from 'react-intl';

import styles from 'src/shared/app/base/component/data-entry/form-builder/FormBuilder.style';
import messages from 'src/shared/app/base/component/data-entry/form-builder/l10n/formBuilderL10n';
import type { Field } from 'src/shared/app/base/component/data-entry/form-builder/type/formBuilderTypes';
import { getFieldTitle } from 'src/shared/app/base/component/data-entry/form-builder/utils/formBuilderUtils';
import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import ErrorMessage from 'src/shared/ui/component/form/ErrorMessage';
import StarRating from 'src/shared/ui/component/icon/StarRating';

const useStyles = createUseThemeStyles(styles);

const FormBuilderRating = ({ field, name }: Field): Node => {
  const intl = useIntl();
  const labelId = useId();
  const classes = useStyles();
  const { visuallyHiddenProps } = useVisuallyHidden();
  const [{ value: activeRating }, { error }, { setValue }] = useField(name);
  return (
    <>
      <label id={labelId}>{getFieldTitle(field, intl)}</label>

      <div
        aria-labelledby={labelId}
        role="radiogroup"
        className={classes.ratings}
      >
        {Array(5)
          .fill(0)
          .map((_, key) => {
            const value = key + 1;
            const isActive = activeRating === value;
            const looksActive = value <= activeRating;
            const id = `${name}-${value}`;
            return (
              <label
                htmlFor={id}
                className={classNames(classes.ratingButton)}
                aria-label={intl.formatMessage(messages.feedbackEmotion, {
                  count: value,
                })}
                key={key}
              >
                <input
                  type="radio"
                  value={value}
                  id={id}
                  name={name}
                  checked={isActive}
                  onChange={() => setValue(value)}
                  {...visuallyHiddenProps}
                />
                <StarRating isActive={looksActive} />
              </label>
            );
          })}
      </div>

      {error ? (
        <ErrorMessage className={classes.error}>{error}</ErrorMessage>
      ) : null}
    </>
  );
};

export default memo<Field>(FormBuilderRating);
