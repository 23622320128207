import { combineReducers } from 'redux';

import appContactReducer from './appContactReducer';
import appLoginLinkReducer from './appLoginLinkReducer';
import appRetrieveUsernameReducer from './appRetrieveUsernameReducer';

const appSupportReducer = combineReducers({
  retrieveEmail: appRetrieveUsernameReducer,
  loginLink: appLoginLinkReducer,
  contact: appContactReducer,
});
export default appSupportReducer;
