import { lap } from '@braindate/ui/lib/token/mediaQuery';

import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

export default {
  root: {
    position: 'relative',
    display: 'flex',
    height: '100vh',
    overflowY: 'hidden',
    flexDirection: 'column',
    [lap]: {
      flexDirection: 'row',
    },
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    height: '100%',
  },
  portals: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  intro: {
    width: '100%',
    height: '42vh',
    position: 'relative',
    [lap]: {
      height: '100vh',
      width: 'calc(100% - 520px)',
    },
  },
  background: {
    position: 'absolute',
    width: '100%',
    left: 0,
    bottom: -3,
    zIndex: 1,
    fill: ({ theme }: ThemeFunction<unknown>) => theme.colorBase,
    [lap]: {
      display: 'none',
    },
  },
  content: {
    position: 'relative',
    width: '100%',
    height: '58vh',
    padding: [25, 30, 0, 30],
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    [lap]: {
      padding: [0, 30],
      maxWidth: 520,
      height: '100vh',
    },
  },
  logo: {
    display: 'none',
    [lap]: {
      display: 'block',
      width: 170,
      height: 22,
      minHeight: 22,
      marginTop: 38,
      color: ({ theme }: ThemeFunction<unknown>) => theme.accentColor,
    },
  },
};
