/* eslint-disable prefer-promise-reject-errors */
import { useEffect } from 'react';

import * as Sentry from '@sentry/react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import usePushNotificationSubscription from 'src/client/worker/hooks/usePushNotificationSubscription';
import messages from 'src/client/worker/intl/pushL18n';
import {
  arePushNotificationsSupported,
  createNewPushSubscription,
  getServiceSubscription,
  getServiceWorkerRegistration,
  notificationsPermissionsGranted,
} from 'src/client/worker/utils/pushNotificationsUtils';
import { invalidateTags } from 'src/shared/app/base/api/actions/apiActions';
import { TAG_TYPE } from 'src/shared/app/base/api/apiConstant';
import {
  useDeletePushSubscriptionMutation,
  usePostPushSubscriptionMutation,
} from 'src/shared/app/base/api/endpoint/notificationsEndpoint';
import useIsErrorReportingEnabled from 'src/shared/app/base/hook/useIsErrorReportingEnabled';
import useFeatureDecisions from 'src/shared/app/feature/hook/useFeatureDecisions';
import { isPWA } from 'src/shared/core/util/browserUtil';
import { isSsr } from 'src/shared/core/util/ssrUtil';
import useSelf from 'src/shared/domain/user/hook/useSelf';

const debug = require('debug')('usePushNotificationsManager');

const usePushNotificationsManager = () => {
  const self = useSelf();
  const intl = useIntl();
  const dispatch = useDispatch();
  const isErrorReportingEnabled = useIsErrorReportingEnabled();
  const [postSubscription] = usePostPushSubscriptionMutation();
  const [deleteSubscription] = useDeletePushSubscriptionMutation();
  const { arePushNotificationsEnabled } = useFeatureDecisions();
  const currentPushSubscription = usePushNotificationSubscription();

  /**
   * Gets the user's permission to send notifications.
   * @param {boolean} manual boolean
   * @returns {Promise<boolean>} Promise
   */
  const requestNotificationPermissions = async (manual: boolean) => {
    if (Notification.permission === notificationsPermissionsGranted) {
      return true;
    }

    const permission = await Notification.requestPermission();
    if (permission === notificationsPermissionsGranted) return true;

    if (manual) {
      throw new Error(intl.formatMessage(messages.permissionDenied));
    } else {
      Sentry.captureException(new Error('Permission denied'));
      return false;
    }
  };

  /**
   * @description Checks if the user already has a subscription and if not, creates a new one.
   * @param {boolean} manual boolean
   * @returns {Promise<void>} Promise
   */
  const subscribeToWebPush = async (manual: boolean = false) => {
    debug('subscribe to web push');
    if (!self) return debug('No user found');

    if (!arePushNotificationsEnabled) {
      const error = new Error(
        'Tried to enable push notifications while feature flag not enabled',
      );

      if (isErrorReportingEnabled) {
        Sentry.captureException(error);
      }

      return debug(error.message);
    }

    const notificationsPermissions =
      await requestNotificationPermissions(manual);
    if (!notificationsPermissions) return;
    const pushSubscription = await getServiceSubscription();
    if (pushSubscription) return;
    const newSubscription = await createNewPushSubscription();

    if (newSubscription) {
      // $FlowIssue Flow isn't able to handle rtk mutation
      const response = await postSubscription(newSubscription);

      if (response && response.error) {
        await unsubscribeFromWebPush();
        throw response.error;
      }

      dispatch(invalidateTags(TAG_TYPE.PUSH_SUBSCRIPTION));
    }
  };

  /**
   * Unsuscribe from web push notifications
   * @returns {Promise<void>} Promise
   */
  const unsubscribeFromWebPush = async () => {
    const subscription = await getServiceSubscription();
    const swRegistration = await getServiceWorkerRegistration();
    await Promise.all([
      ...(currentPushSubscription
        ? [deleteSubscription(currentPushSubscription.id)]
        : []),
      subscription?.unsubscribe(),
      swRegistration?.unregister(),
    ]);
  };

  const notificationsCanBeEnabled = arePushNotificationsSupported();
  useEffect(() => {
    // Don't execute when is SSR and only execute if is a PWA when user is logged
    if (
      !isSsr &&
      notificationsCanBeEnabled &&
      isPWA() &&
      self &&
      arePushNotificationsEnabled
    ) {
      subscribeToWebPush();
    }
  }, [notificationsCanBeEnabled, self]);
  return {
    notificationsCanBeEnabled,
    subscribeToWebPush,
    unsubscribeFromWebPush,
  };
};

export default usePushNotificationsManager;
