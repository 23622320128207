// Params
export const forStartupParam = 'for_startup';
// #region Login
export function loginEndpoint() {
  return (apiUrl: string) => `${apiUrl}login/`;
}
export function loginWithDoubleDutchEndpoint() {
  return (apiUrl: string) => `${apiUrl}plugins/doubledutch/login/`;
}
export function loginWithExternalAuthEndpoint() {
  return (apiUrl: string) => `${apiUrl}plugins/sso/login/`;
}
export function logoutEndpoint() {
  return (apiUrl: string) => `${apiUrl}logout/`;
}
// #endregion
// #region Signup
export function signupActivationEndpoint() {
  return (apiUrl: string) => `${apiUrl}plugins/signup/check_code/`;
}
export function signupEmailEndpoint() {
  return (apiUrl: string) => `${apiUrl}plugins/signup/check_username/`;
}
export function signupCreateUserEndpoint() {
  return (apiUrl: string) => `${apiUrl}plugins/signup/create_user/`;
}
// #endregion
// #region Support
export function supportRetrieveUsernameEndpoint(eventId: number | string) {
  return (apiUrl: string) => `${apiUrl}events/${eventId}/retrieve_membership/`;
}
export function supportLoginLinkEndpoint(eventId: number | string) {
  return (apiUrl: string) => `${apiUrl}events/${eventId}/send_login_link/`;
}
export function supportContactEndpoint(evendId: number | string) {
  return (apiUrl: string) =>
    `${apiUrl}events/${evendId}/send_email_to_support/`;
}
// #endregion
export function codeOfConductEndpoint() {
  return (apiUrl: string, eventId: number | string) =>
    `${apiUrl}customization/${eventId}/code_of_conduct/`;
}
// #region Users
export function usersEndpoint() {
  return (apiUrl: string, eventId: number) =>
    `${apiUrl}events/${eventId}/users/`;
}
export function usersTopicReactionsEndpoint(topicId: number) {
  return (apiUrl: string) => `${apiUrl}users/topic_reactions/?topic=${topicId}`;
}
// To be consistent with statistics, we pass the eventID
// to get the stats for the user only for this event
export function userEndpoint(id: number | string) {
  return (apiUrl: string, eventId: number | string) => {
    if (typeof eventId === 'number') {
      return `${apiUrl}users/${id}/?event=${eventId}`;
    }

    return `${apiUrl}users/${id}/?domain=${eventId}`;
  };
}
// Custom endpoint for delete user, because this is the only action that we can use without event ID
export function userDeleteEndpoint() {
  return (apiUrl: string) => `${apiUrl}users/me/`;
}
export function userStatisticsEndpoint(id: number, eventId: number) {
  return (apiUrl: string) =>
    `${apiUrl}users/${id}/statistics/?event=${eventId}`;
}
export function userCanCreateTopicEndpoint(id: number, kind?: string) {
  return (apiUrl: string, eventId: string) => {
    const baseUrl = `${apiUrl}users/${id}/can_create_topic/?event=${eventId}`;

    if (kind) {
      return `${baseUrl}&kind=${kind}`;
    }

    return baseUrl;
  };
}
export function userActivityEndpoint(id: number | string) {
  return (apiUrl: string, eventId: number) =>
    `${apiUrl}users/${id}/unavailabilities/?event=${eventId}`;
}
export function emailVerificationEndpoint(id: string | number) {
  return (apiUrl: string, eventId: number) =>
    `${apiUrl}users/${id}/send_email_validation/?event=${eventId}`;
}
export function userValidateEmailEndpoint() {
  return (apiUrl: string, eventId: number) =>
    `${apiUrl}users/check_email/?event=${eventId}`;
}
export function userSetPasswordEndpoint() {
  return (apiUrl: string) => `${apiUrl}users/set_password/`;
}
export function userSendPasswordLinkEndpoint() {
  return (apiUrl: string) => `${apiUrl}users/reset_password_init/`;
}
export function userResetPasswordEndpoint() {
  return (apiUrl: string) => `${apiUrl}users/reset_password/`;
}
export function userChangePasswordEndpoint(id: number | string) {
  return (apiUrl: string) => `${apiUrl}users/${id}/change_password/`;
}
export function userAvatarEndpoint(id: number | string) {
  return (apiUrl: string) => `${apiUrl}users/${id}/avatar/`;
}
export function userLanguagesEndpoint() {
  return (apiUrl: string) => `${apiUrl}users/languages/`;
}
export function userRecommendationUserEndpoint(userId: number | string) {
  return (apiUrl: string) => `${apiUrl}users/${userId}/user_recommendation/`;
}
export function userRecommendationTopicEndpoint(userId: number | string) {
  return (apiUrl: string) => `${apiUrl}users/${userId}/topic_recommendation/`;
}
// #endregion
// #region Memberships
export function membershipEndpoint() {
  return (apiUrl: string, eventId: number | string) => {
    if (typeof eventId === 'number') {
      return `${apiUrl}events/${eventId}/membership/`;
    }

    return `${apiUrl}events/-/membership/?domain=${eventId}`;
  };
}
export function membershipCalendarSyncStatusEndpoint(id: number | string) {
  return (apiUrl: string) => `${apiUrl}memberships/${id}/calendar_sync_status/`;
}
export function membershipGuidanceEndpoint(id: number | string) {
  return (apiUrl: string) => `${apiUrl}memberships/${id}/guidance/`;
}
// #endregion
// #region Events
export function eventEndpoint(id: number | string) {
  if (typeof id === 'number') {
    return (apiUrl: string) => `${apiUrl}events/${id}/`;
  }

  return (apiUrl: string) => `${apiUrl}events/-/?domain=${id}`;
}
export function timeSlotsSuggestionsEndpoint() {
  return (apiUrl: string, eventId: number) =>
    `${apiUrl}events/${eventId}/braindate_timepropositions/`;
}
// #endregion
// #region AI
export function aiTopicSuggestionsEndpoint() {
  return (apiUrl: string) => `${apiUrl}topic_suggestions/`;
}
// #endregion
// #region Topics
export function topicsEndpoint() {
  return (apiUrl: string, eventId: number) =>
    `${apiUrl}events/${eventId}/topics/`;
}
export function topicsCountEndpoint() {
  return (apiUrl: string, eventId: number) =>
    `${apiUrl}topics/count/?event=${eventId}`;
}
export function topicEndpoint(topicId: number) {
  return (apiUrl: string, eventId: number) =>
    `${apiUrl}topics/${topicId}/?event=${eventId}`;
}
export function topicCanJoinEndpoint(topicId: number) {
  return (apiUrl: string) => `${apiUrl}topics/${topicId}/can_join/`;
}
export function topicCanDeleteEndpoint(topicId: number) {
  return (apiUrl: string) => `${apiUrl}topics/${topicId}/can_delete/`;
}
export function topicReactionEndpoint(reactionId: number) {
  return (apiUrl: string) => `${apiUrl}topic_reactions/${reactionId}/`;
}
export function topicReactionsEndpoint() {
  return (apiUrl: string) => `${apiUrl}topic_reactions/`;
}
export function commentReactionEndpoint(reactionId: number) {
  return (apiUrl: string) => `${apiUrl}comment_reactions/${reactionId}/`;
}
export function commentReactionsEndpoint() {
  return (apiUrl: string) => `${apiUrl}comment_reactions/`;
}
export function topicBraindateEndpoint(id: number) {
  return (apiUrl: string) => `${apiUrl}topics/${id}/braindate/`;
}
export function topicAvailableFiltersEndpoint() {
  return (apiUrl: string, eventId: number) =>
    `${apiUrl}topics/available_filters/?event=${eventId}&for_marketplace=1`;
}
export function keywordsEndpoint() {
  return (apiUrl: string, eventId: number) =>
    `${apiUrl}events/${eventId}/keywords/?past=false`;
}
export function featuredKeywordsEndpoint(eventId: number) {
  return (apiUrl: string) =>
    `${apiUrl}events/${eventId}/keywords/?featured=1&past=false`;
}
// #endregion
// #region Inspiration topics
export function inspirationTopicsEndpoint() {
  return (apiUrl: string, eventId: number) =>
    `${apiUrl}events/${eventId}/inspiration_topics/`;
}
export function inspirationTopicEndpoint(id: number) {
  return (apiUrl: string) => `${apiUrl}inspiration_topics/${id}/`;
}
// #endregion
// Comments
export function commentsEndpoint() {
  return (apiUrl: string) => `${apiUrl}topic_comments/`;
}
export function commentEndpoint(commentId: number) {
  return (apiUrl: string) => `${apiUrl}topic_comments/${commentId}/`;
}
// Braindates
export function braindatesEndpoint(paginated?: boolean) {
  if (paginated) {
    return (apiUrl: string, eventId: number) =>
      `${apiUrl}v2/braindates/?event=${eventId}`;
  }

  return (apiUrl: string, eventId: number) =>
    `${apiUrl}events/${eventId}/braindates/`;
}
export function braindatesCountEndpoint() {
  return (apiUrl: string, eventId: number) =>
    `${apiUrl}v2/braindates/count/?event=${eventId}`;
}
export function braindateEndpoint(id: number) {
  return (apiUrl: string) => `${apiUrl}braindates/${id}/`;
}
export function braindateDiscussionSuggestionsEndpoint(id: number) {
  return (apiUrl: string) => `${apiUrl}braindates/${id}/agenda/`;
}
// #endregion
// #region Conversations
export function conversationsEndpoint() {
  return (apiUrl: string) => `${apiUrl}conversations/`;
}
export function conversationEndpoint(conversationId: number) {
  return (apiUrl: string) => `${apiUrl}conversations/${conversationId}/`;
}
// #endregion
// #region Filters
export function filtersEndpoint(eventId: number) {
  return (apiUrl: string) => `${apiUrl}events/${eventId}/filters/`;
}
export function featuredFiltersEndpoint() {
  return (apiUrl: string, eventId: number) =>
    `${apiUrl}events/${eventId}/keywords_categorized/`;
}
// #endregion
// Complaints
export function complaintsEndpoint() {
  return (apiUrl: string) => `${apiUrl}complaints/`;
}
// Availability
export function availabilityEndpoint(membershipId: number) {
  return (apiUrl: string) =>
    `${apiUrl}memberships/${membershipId}/availabilities/`;
}
// #endregion
// #region FAQ
export function faqEndpoint() {
  return (apiUrl: string, eventId: number) => `${apiUrl}events/${eventId}/faq/`;
}
export function faqQuestionEndpoint(id: number) {
  return (apiUrl: string) => `${apiUrl}faq/questions/${id}/`;
}
// #endregion
// #region Interests
export function interestsEndpoint(id: number) {
  return (apiUrl: string) => `${apiUrl}events/${id}/interests/`;
}
// #endregion
// #region Braindate feedback
export function feedbackEndpoint(braindateId: number) {
  return (apiUrl: string) => `${apiUrl}braindates/${braindateId}/feedback/`;
}
export function virtualCallEndpoint(conversationId: number) {
  return (apiUrl: string) =>
    `${apiUrl}plugins/video-call/${conversationId}/join/`;
}
export function conversationLeaveEndpoint(conversationId: number) {
  return (apiUrl: string) => `${apiUrl}conversations/${conversationId}/leave/`;
}
export function conversationCancelEndpoint(conversationId: number) {
  return (apiUrl: string) => `${apiUrl}conversations/${conversationId}/cancel/`;
}
export function locationsEndpoint() {
  return (apiUrl: string, eventId: string | number) => {
    if (typeof eventId === 'number') {
      return `${apiUrl}events/${eventId}/locations/`;
    }

    return `${apiUrl}events/-/locations/?domain=${eventId}`;
  };
}
// #endregion
// #region Custom Optins
export function customOptinEndpoint(eventId: number) {
  return (apiUrl: string) => `${apiUrl}customoptin/?event=${eventId}`;
}
// #endregion
// #region Notifications
export function notificationsEndpoint() {
  return (apiUrl: string, eventId: number) =>
    `${apiUrl}events/${eventId}/notifications/`;
}
export function markReadAllNotificationEndpoint(eventId: number) {
  return (apiUrl: string) =>
    `${apiUrl}notifications/mark_all_read/?event=${eventId}`;
}
export function clearAllNotificationsEndpoint() {
  return (apiUrl: string, eventId: number) =>
    `${apiUrl}notifications/clear_all/?event=${eventId}`;
}
// #endregion
// #region Push notifications
export function webpushEndpoint(id?: string | number) {
  return (apiUrl: string) => `${apiUrl}webpush/${id ? `${id}/` : ''}`;
}
// #endregion
// #region Cheers
export function cheersEndpoint() {
  return (apiUrl: string, eventId: number) =>
    `${apiUrl}plugins/cheers/?event=${eventId}`;
}
export function remainigGiftEndpoint() {
  return (apiUrl: string) => `${apiUrl}plugins/cheers/remaining_gifts/`;
}
export function cheerEligibleParticipantsEndpoint() {
  return (apiUrl: string) => `${apiUrl}plugins/cheers/eligible_participants/`;
}
export function cheerEndpoint(cheerId: number) {
  return (apiUrl: string, eventId: number) =>
    `${apiUrl}plugins/cheers/${cheerId}/?event=${eventId}`;
}
export function sendCheerEndpoint() {
  return (apiUrl: string) => `${apiUrl}plugins/cheers/send_cheer/`;
}
// #endregion
// #region Transcribe
export function getTranscribeTokenEndpoint(braindateId: number) {
  return (apiUrl: string) =>
    `${apiUrl}plugins/transcribe/${braindateId}/get_token/`;
}
// #endregion
export function getTogethernessEndpoint(eventId: number) {
  return (apiUrl: string) => `${apiUrl}togetherness/${eventId}/`;
}
