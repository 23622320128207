import * as types from 'src/shared/app/integration/action/integrationActionTypes';
import { createReducer } from 'src/shared/core/util/reducerUtils';

const initialState = {
  isAppEmbedded: null,
};

const _markAppAsEmbedded = (state: Record<string, any>) => ({
  ...state,
  isAppEmbedded: true,
});

const _markAppAsNotEmbedded = (state: Record<string, any>) => ({
  ...state,
  isAppEmbedded: false,
});

export default createReducer(initialState, {
  [types.MARK_APP_AS_EMBEDDED]: _markAppAsEmbedded,
  [types.MARK_APP_AS_NOT_EMBEDDED]: _markAppAsNotEmbedded,
});
