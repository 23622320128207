// Libs
import type { Braindate } from '@braindate/domain/lib/braindate/type';
import { getBraindateConversation } from '@braindate/domain/lib/braindate/util';
import { getTopicAuthor, isGroupTopic } from '@braindate/domain/lib/topic/util';
import type { User } from '@braindate/domain/lib/user/type';

import { getBraindateTopic } from 'src/shared/domain/braindate/util/braindateUtil';
import { getConversationOtherParticipants } from 'src/shared/domain/conversation/util/conversationUtil';

export function getBraindateWith(self: User, braindate: Braindate) {
  const topic = getBraindateTopic(braindate);
  const isGroupBraindate = isGroupTopic(topic);
  const conversation = getBraindateConversation(braindate);
  if (isGroupBraindate) return getTopicAuthor(topic);
  const otherParticipants = self
    ? getConversationOtherParticipants(conversation, self)
    : [];
  return otherParticipants[0];
}
