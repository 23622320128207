import { memo } from 'react';
import type { Node } from 'react';

import ErrorBoundry from 'src/shared/app/base/component/error/ErrorBoundry';
import useMounted from 'src/shared/app/base/hook/useMounted';
import NotificationPortal from 'src/shared/app/base/portal/notification/NotificationPortal';
import { mainContentId } from 'src/shared/app/nav/settings/navSettings';

type Props = {
  layoutClass?: string;
  children: Node;
};

const MainSwitch = ({ layoutClass, children }: Props): Node => {
  /*
   |----------------------------------------------------------------------------
   | Hooks
   |----------------------------------------------------------------------------
   */
  const isMounted = useMounted();

  /*
   |----------------------------------------------------------------------------
   | Elements
   |----------------------------------------------------------------------------
   */
  const notificationPortal = isMounted && <NotificationPortal />;
  return (
    <main className={layoutClass} id={mainContentId}>
      {notificationPortal}
      <ErrorBoundry>{children}</ErrorBoundry>
    </main>
  );
};

export default memo<Props>(MainSwitch);
