import { Fragment, memo } from 'react';
import type { Node } from 'react';

type Props = {
  className: string;
  field: Record<string, any>;
  inputProps: Record<string, any>;
  list: Array<string> | null | undefined;
  handleFocus: () => void;
  handleBlur: () => void;
};

const FormikDatalist = ({
  className,
  field,
  inputProps,
  list,
  handleFocus,
  handleBlur,
}: Props): Node => {
  const options = list || [];
  const listId = `${inputProps.id}-options`;

  /*
   |----------------------------------------------------------------------------
   | Elements
   |----------------------------------------------------------------------------
   */
  return (
    <>
      <input
        className={className}
        {...field}
        {...inputProps}
        type="text"
        list={listId}
        onFocus={handleFocus}
        onBlur={(e) => {
          field.onBlur(e);
          handleBlur();
        }}
      />
      <datalist id={listId}>
        {options.map((value) => (
          <option value={value} key={value}>
            {value}
          </option>
        ))}
      </datalist>
    </>
  );
};

export default memo<Props>(FormikDatalist);
