import * as types from 'src/shared/app/authentication/action/support/supportRetrieveUsernameActionTypes';
import { createReducer } from 'src/shared/core/util/reducerUtils';

/*
|------------------------------------------------------------------------------
| INITIAL STATE
|------------------------------------------------------------------------------
*/
const initialState = {
  isFetching: false,
  failure: false,
  success: false,
  username: null,
  membership_id: null,
};

/*
|------------------------------------------------------------------------------
| REDUCERS
|------------------------------------------------------------------------------
*/
const _retrieveEmailRequest = (
  state: Record<string, any>,
): Record<string, any> => ({
  ...state,
  isFetching: true,
  success: false,
  failure: false,
});

const _retrieveEmailFailure = (
  state: Record<string, any>,
): Record<string, any> => ({
  ...state,
  failure: true,
  success: false,
  isFetching: false,
});

const _retrieveEmailSuccess = (
  state: Record<string, any>,
  action: Record<string, any>,
): Record<string, any> => {
  const { username, membership_id: membershipId } = action;
  return {
    ...state,
    success: true,
    failure: false,
    isFetching: false,
    username,
    membership_id: membershipId,
  };
};

const _resetRetrievedUsername = (
  state: Record<string, any>,
): Record<string, any> => ({
  ...state,
  failure: false,
  success: false,
  isFetching: false,
});
/*
|------------------------------------------------------------------------------
| REDUCER
|------------------------------------------------------------------------------
*/

export default createReducer(initialState, {
  [types.SUPPORT_RETRIEVE_EMAIL_REQUEST]: _retrieveEmailRequest,
  [types.SUPPORT_RETRIEVE_EMAIL_FAILURE]: _retrieveEmailFailure,
  [types.SUPPORT_RETRIEVE_EMAIL_SUCCESS]: _retrieveEmailSuccess,
  [types.RESET_RETRIEVED_USERNAME]: _resetRetrievedUsername,
});
