import { convertSlackSharingToNotification } from 'src/shared/app/market/util/marketUtils';
import { queueNotification } from 'src/shared/app/notification/action/uiNotificationActions';

import * as types from './uiMarketActionTypes';

export function showMarketAutocomplete() {
  return {
    type: types.SHOW_MARKET_AUTOCOMPLETE,
  };
}
export function hideMarketAutocomplete() {
  return {
    type: types.HIDE_MARKET_AUTOCOMPLETE,
  };
}
export function dismissMarketTopicNotFoundError() {
  return {
    type: types.DISMISS_MARKET_TOPIC_NOT_FOUND,
  };
}
export function setMarketFirstTopic(topicId: number) {
  return {
    type: types.SET_MARKET_FIRST_TOPIC,
    topicId,
  };
}
export function resetMarketFirstTopic() {
  return {
    type: types.RESET_MARKET_FIRST_TOPIC,
  };
}
export function freezeMarketTopics() {
  return {
    type: types.FREEZE_MARKET_TOPICS,
  };
}
export function unfreezeMarketTopics() {
  return {
    type: types.UNFREEZE_MARKET_TOPICS,
  };
}
export function setMarketCurrentPage(page: number) {
  return {
    type: types.SET_MARKET_CURRENT_PAGE,
    page,
  };
}
export function resetMarketCurrentPage() {
  return {
    type: types.RESET_MARKET_CURRENT_PAGE,
  };
}
export function showMarketTopicInspirations() {
  return {
    type: types.SHOW_TOCIC_INSPIRATIONS,
    show: true,
  };
}
export function hideMarketTopicInspirations() {
  return {
    type: types.SHOW_TOCIC_INSPIRATIONS,
    show: false,
  };
}
export function showSlackTopicSharedNotification() {
  return queueNotification(convertSlackSharingToNotification());
}
