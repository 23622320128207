export const allTopicsLimit = 10;
export const allPeopleLimit = 10;
export const trendingFilter = 'highlight';
export const mostUsedFilter = 'most_used';
export const newestFilter = 'new';
export const jobTitleFilter = 'position';
export const companyFilter = 'company';
export const bookmarkedParam = 'bookmarked';
export const slackNotificationTimemout = 10000; // miliseconds

export const favoriteNotificationTimeout = 4000; // miliseconds

export const orderingParam = 'ordering';
export const recommendedSortingOrder = '-relevance';
export const newestSortingOrder = '-date_created';
export const mostViewedSortingOrder = '-statistics__views_count';
export const upcomingSortingOrder = 'timing';
export const soonSortingOrder = 'soon';
export const randomSortingOrder = 'random_order';
export const kindFilterParam = 'kind';
export const languageFilterParam = 'language';
export const upcomingFilterParam = 'status';
export const dateIntervalFilterParam = 'by_date';
export const canJoinFilterParam = 'can_join';
export const locationFilterParam = 'location';
export const conversationTypeFilterParam = 'braindate_format';
export const participantCategoriesFilterParam = 'categories';
export const offerKindFilter = 'offer';
export const groupKindFilter = 'group';
export const fishbowlKindFilter = 'fishbowl';
export const superGroupKindFilter = 'super_group';
export const keywordParam = 'keyword';
export const defaultSortOptions = {
  newest: newestSortingOrder,
  most_viewed: mostViewedSortingOrder,
  date: upcomingSortingOrder,
  random: randomSortingOrder,
  recommended: recommendedSortingOrder,
};
