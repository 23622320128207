import { rem } from 'polished';

import { baseFontSize } from '@braindate/ui/lib/token/typography';

import { successColor } from 'src/shared/app/base/style/token/color';
import { popOverElevation } from 'src/shared/app/base/style/token/elevation';
import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

export default {
  '@keyframes appear': {
    from: {
      opacity: 0,
      transform: 'translateY(0)',
    },
    to: {
      opacity: 1,
      transform: 'translateY(20px)',
    },
  },
  root: {
    position: 'absolute',
    top: '100%',
    animation: '$appear 0.3s ease-in-out',
    animationFillMode: 'both',
    padding: [15, 13],
    backgroundColor: ({ theme }: ThemeFunction<unknown>) => theme.colorBase,
    borderRadius: 10,
    boxShadow: '0px 4px 15px 0px #00000026',
    zIndex: popOverElevation,
    '&:before': {
      content: '""',
      position: 'absolute',
      top: -8,
      left: '50%',
      transform: 'translateX(-50%)',
      width: 0,
      height: 0,
      borderLeft: '8px solid transparent',
      borderRight: '8px solid transparent',
      borderBottom: ({ theme }: ThemeFunction<unknown>) =>
        `8px solid ${theme.colorBase}`,
    },
  },
  title: {
    margin: [0, 0, 5, 0],
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
    margin: 0,
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    marginBottom: 5,
    fontSize: rem(14, baseFontSize),
    color: ({ theme }: ThemeFunction<unknown>) => theme.favoriteIconColor,
  },
  itemValid: {
    color: `${successColor} !important`,
    stroke: successColor,
  },
  icon: {
    padding: 2,
    border: ({ theme }: ThemeFunction<unknown>) =>
      `1px solid ${theme.calendarDisableButtonColor}`,
    borderRadius: '50%',
  },
  iconValid: {
    border: `1px solid ${successColor} !important`,
  },
};
