import { em } from 'polished';

import { tablet } from '@braindate/ui/lib/token/mediaQuery';
import { baseFontSize } from '@braindate/ui/lib/token/typography';

import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

export default {
  root: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'left',
    padding: [40, 15],
    alignItems: 'center',
    [tablet]: {
      flexDirection: 'row',
      alignItems: 'center',
      padding: 0,
    },
  },
  assets: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: 25,
    '& svg': {
      maxWidth: 165,
      marginBottom: 0,
      [tablet]: {
        maxWidth: 285,
      },
    },
    '& p': {
      maxWidth: 230,
      marginTop: 25,
      [tablet]: {
        maxWidth: 285,
      },
    },
    [tablet]: {
      width: '50%',
    },
  },
  content: {
    width: '100%',
    maxWidth: 360,
    [tablet]: {
      width: '50%',
    },
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
  },
  title: {
    fontSize: em(16, baseFontSize),
    fontWeight: 600,
    margin: 0,
    color: ({ theme }: ThemeFunction<unknown>) => theme.accentColor,
  },
  textarea: {
    '& textarea': {
      minHeight: 88,
    },
  },
  actions: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: 35,
    [tablet]: {
      justifyContent: 'flex-end',
    },
  },
  submit: {
    gap: '5px',
  },
};
