import { memo } from 'react';
import type { Node } from 'react';

import classNames from 'classnames';

import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import BaseIcon from 'src/shared/ui/component/icon/BaseIcon';

import styles from './UserIcon.style';
import svg from './userIcon.svg';

type Props = {
  className?: string;
  title?: string;
};
const useStyles = createUseThemeStyles(styles);

const UserIcon = ({ className, title }: Props): Node => {
  const classes = useStyles();
  return (
    <BaseIcon
      className={classNames(classes.root, className)}
      svg={svg}
      title={title}
    />
  );
};

export default memo<Props>(UserIcon);
