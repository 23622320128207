import { createElement, createRef, memo, useEffect, useState } from 'react';
import type { Node } from 'react';

import classNames from 'classnames';

import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import {
  guidanceFirstInvitation,
  guidancePendingInvitations,
  guidanceQuickStart,
  guidanceSearchInterest,
  guidanceSearchKeyword,
} from 'src/shared/app/market/guidance/settings/guidanceSettings';
import { trackGAEvent } from 'src/shared/core/service/tracking/gaService';

import styles from './MarketBasePromptCard.style';
import MarketFilterPromptCard from './MarketFilterPromptCard';
import MarketFirstInvitationPromptCard from './MarketFirstInvitationPromptCard';
import MarketPendingInvitationsPromptCard from './MarketPendingInvitationsPromptCard';
import MarketSearchPromptCard from './MarketSearchPromptCard';

type Props = {
  guidanceLabel: string;
  handleDismiss: () => void;
};
const useStyles = createUseThemeStyles(styles);

const MarketBasePromptCardDumb = ({
  guidanceLabel,
  handleDismiss,
}: Props): Node => {
  let cardComponent;
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    window.requestAnimationFrame(() => setIsVisible(true));
    trackGAEvent('Market', 'Show Prompt', guidanceLabel);
  }, []);

  const onDismiss = () =>
    window.requestAnimationFrame(() => setIsVisible(false));

  const onTransitionEnd = ({ target }: TransitionEvent) => {
    if (!isVisible) {
      const { current: rootElt } = rootRef;

      if (target === rootElt) {
        handleDismiss();
      }
    }
  };

  const classes = useStyles();
  const rootStyles = classNames(classes.root, {
    [classes['root-isVisible']]: isVisible,
  });
  const rootRef = createRef();

  switch (guidanceLabel) {
    case guidanceFirstInvitation:
      cardComponent = MarketFirstInvitationPromptCard;
      break;

    case guidancePendingInvitations:
      cardComponent = MarketPendingInvitationsPromptCard;
      break;

    case guidanceQuickStart:
      cardComponent = MarketFilterPromptCard;
      break;

    case guidanceSearchKeyword:
      cardComponent = MarketSearchPromptCard;
      break;

    case guidanceSearchInterest:
      cardComponent = MarketSearchPromptCard;
      break;

    default:
      break;
  }

  const cardElt =
    cardComponent &&
    createElement(cardComponent, {
      guidanceLabel,
      handleDismiss: onDismiss,
    });
  return (
    <div className={rootStyles} ref={rootRef} onTransitionEnd={onTransitionEnd}>
      {cardElt}
    </div>
  );
};

export default memo<Props>(MarketBasePromptCardDumb);
