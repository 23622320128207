import { createContext } from 'react';

export type ModalStatusContextProps = {
  modals: Set<symbol>;
  register: (symbol: symbol) => void;
  unregister: (symbol: symbol) => void;
};

export default createContext<ModalStatusContextProps>({
  modals: new Set(),
  register: () => {},
  unregister: () => {},
});
