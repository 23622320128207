import type { DropdownOption } from 'src/shared/ui/component/form/dropdown/type/dropdownTypes';

export function getDropdownOptionLabel(
  option: DropdownOption | DropdownOption[],
): string {
  if (option && Array.isArray(option)) {
    return option
      .map((optionValue) =>
        typeof optionValue === 'object' ? optionValue.label : optionValue,
      )
      .join(', ');
  }

  if (option && typeof option === 'object') {
    return option.label;
  }

  return option;
}
export function getDropdownOptionValue(option: DropdownOption): string {
  if (option && typeof option === 'object') {
    return option.value;
  }

  return option;
}
export function getDropdownLabel(
  options: Array<DropdownOption>,
  selectedValue?: string | string[],
): string {
  const option = getDropdownOption(options, selectedValue);
  return getDropdownOptionLabel(option);
}

/*
|------------------------------------------------------------------------------
| PRIVATE FUNCTIONS
|------------------------------------------------------------------------------
*/
export const getDropdownOption = (
  options: Array<DropdownOption>,
  selectedValue?: string | string[],
): DropdownOption | DropdownOption[] => {
  const isMultiple = Array.isArray(selectedValue);

  const callback = (option) => {
    if (option && isMultiple && selectedValue) {
      return selectedValue.includes(
        typeof option === 'object' ? option.value : option,
      );
    }

    if (option && typeof option === 'object') {
      return option.value === selectedValue;
    }

    return option === selectedValue;
  };

  return (
    (isMultiple ? options.filter(callback) : options.find(callback)) ||
    options[0]
  );
};
