import { memo } from 'react';
import type { Node } from 'react';

import { Field } from 'formik';
import { useIntl } from 'react-intl';

import FormikFormGroup from 'src/shared/app/base/component/data-entry/form/FormikFormGroup';
import type { Field as FieldProps } from 'src/shared/app/base/component/data-entry/form-builder/type/formBuilderTypes';
import { getFieldTitle } from 'src/shared/app/base/component/data-entry/form-builder/utils/formBuilderUtils';

const FormBuilderText = ({ field, name }: FieldProps): Node => {
  const intl = useIntl();
  return (
    <div>
      <Field
        id={name}
        name={name}
        rows="3"
        component={FormikFormGroup}
        componentOpts={{
          useTextareaMinify: true,
          label: getFieldTitle(field, intl),
          hasLengthCounter: true,
        }}
        maxLength={200}
      />
    </div>
  );
};

export default memo<FieldProps>(FormBuilderText);
