import * as types from 'src/shared/app/market/action/marketActionTypes';
import { keywordContext } from 'src/shared/app/market/constant/marketConstants';
import { createReducer } from 'src/shared/core/util/reducerUtils';

const initialState = {
  queryValue: '',
  queryContext: keywordContext,
};

const setQuery = (
  state: Record<string, any>,
  { value }: Record<string, any>,
) => ({ ...state, queryValue: value });

const resetQuery = (state: Record<string, any>) => ({
  ...state,
  queryValue: '',
});

const setQueryContext = (
  state: Record<string, any>,
  { context }: Record<string, any>,
) => ({ ...state, queryContext: context });

export default createReducer(initialState, {
  [types.SET_MARKET_QUERY]: setQuery,
  [types.RESET_MARKET_QUERY]: resetQuery,
  [types.SET_MARKET_QUERY_CONTEXT]: setQueryContext,
});
