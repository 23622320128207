import { base64ToUint8Array } from 'src/shared/core/util/browserUtil';

const debug = require('debug')('usePushNotificationsManager');

export const notificationsPermissionsGranted = 'granted';

/**
 * @description Checks if the browser supports push notifications
 * @returns {boolean} Is supported
 */
export const arePushNotificationsSupported = () => {
  return (
    typeof navigator !== 'undefined' &&
    typeof window !== 'undefined' &&
    !!navigator &&
    'serviceWorker' in navigator &&
    !!window &&
    'PushManager' in window
  );
};

/**
 * Gets the Service Worker registration.
 * @returns {Promise<ServiceWorkerRegistration>} Promise
 */
export const getServiceWorkerRegistration = async () => {
  const registeredServiceWorker =
    await navigator.serviceWorker?.getRegistration('/service-worker.js');

  if (registeredServiceWorker) {
    const newRegistration = await navigator.serviceWorker?.ready;
    return newRegistration;
  }

  if (!navigator) return;
  await navigator.serviceWorker?.register('/service-worker.js');
  const newRegistration = await navigator.serviceWorker?.ready;
  return newRegistration;
};

/**
 * Get the current subscription
 * @returns {Promise<PushSubscription | null>} Current Active PushSubscription
 */
export const getServiceSubscription = async () => {
  const swRegistration = await getServiceWorkerRegistration();

  if (!swRegistration) {
    return debug('No service worker ready');
  }

  const pushSubscription = await swRegistration?.pushManager?.getSubscription();
  return pushSubscription;
};

/**
 * @description Creates a new subscription and saves it on the server.
 * @returns {Promise<void>} Promise
 */
export const createNewPushSubscription = async () => {
  const swRegistration = await getServiceWorkerRegistration();

  if (!swRegistration) {
    return debug('No service worker ready');
  }

  // https://developer.mozilla.org/en-US/docs/Web/API/PushManager/subscribe
  const newSubscription = await swRegistration.pushManager.subscribe({
    userVisibleOnly: true,
    applicationServerKey: base64ToUint8Array(
      // $FlowIssue
      process.env.VAPID_PUBLIC_KEY,
    ),
  });
  return newSubscription;
};
