import { createReducer } from 'src/shared/core/util/reducerUtils';
import * as types from 'src/shared/domain/event/action/eventActionTypes';

const initialState = null;

const reset = () => null;

const _setEvent = (state: number, action: Record<string, any>) => action.result;

export default createReducer(initialState, {
  [types.FETCH_EVENT_SUCCESS]: _setEvent,
  [types.FETCH_PUBLIC_EVENT_SUCCESS]: _setEvent,
  [types.RESET_EVENT]: reset,
});
