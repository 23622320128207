import { User } from '@braindate/common/src/types/user';

import { apiRoot } from 'src/shared/app/base/api/apiRoot';
import { getTogethernessEndpoint } from 'src/shared/app/base/settings/endpointSettings';

const extendedApi = apiRoot.injectEndpoints({
  endpoints: (builder) => ({
    getTogetherness: builder.query<User[], { eventId: number }>({
      query: ({ eventId }) => ({
        url: getTogethernessEndpoint(eventId),
      }),
    }),
  }),
});

export const { useGetTogethernessQuery } = extendedApi;
