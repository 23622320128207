export const minTargetSize = 48;
export const borderRadius = 8;
export const borderWidth = 1;
export const outlineWidth = 1;
export const focusOutline = {
  outlineOffset: 2,
  outlineStyle: 'solid',
  outlineWidth,
};
export const absoluteFull = {
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
};
