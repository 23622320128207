import * as types from 'src/shared/app/authentication/action/base/uiAuthenticationActionTypes';
import { authenticationPasswordForm } from 'src/shared/app/authentication/constant/uiAuthenticationConstants';
import { createReducer } from 'src/shared/core/util/reducerUtils';

/*
|------------------------------------------------------------------------------
| INITIAL STATE
|------------------------------------------------------------------------------
*/
const initialState = {
  loginSection: null,
};

/*
|------------------------------------------------------------------------------
| REDUCERS
|------------------------------------------------------------------------------
*/
const _resetLoginSection = (
  state: Record<string, any>,
): Record<string, any> => ({ ...state, loginSection: null });

const _showLoginPasswordSection = (
  state: Record<string, any>,
): Record<string, any> => ({
  ...state,
  loginSection: authenticationPasswordForm,
});
/*
|------------------------------------------------------------------------------
| REDUCER
|------------------------------------------------------------------------------
*/

export default createReducer(initialState, {
  [types.RESET_AUTHENTICATION_LOGIN_SECTION]: _resetLoginSection,
  [types.SHOW_AUTHENTICATION_LOGIN_PASSWORD_SECTION]: _showLoginPasswordSection,
});
