import { memo, useEffect } from 'react';
import type { Node } from 'react';

import { useLocation } from 'react-router-dom';

import { scrollToTop } from 'src/shared/core/util/browserUtil';

type Props = {
  children: Node;
};

const ScrollToTop = ({ children }: Props): Node => {
  /*
   |----------------------------------------------------------------------------
   | Hooks
   |----------------------------------------------------------------------------
   */
  const location = useLocation();

  /*
   |----------------------------------------------------------------------------
   | Effects
   |----------------------------------------------------------------------------
   */
  useEffect(() => {
    scrollToTop();
  }, [location.pathname]);

  /*
   |----------------------------------------------------------------------------
   | Elements
   |----------------------------------------------------------------------------
   */
  return children;
};

export default memo<Props>(ScrollToTop);
