import { lap } from '@braindate/ui/lib/token/mediaQuery';

import { REGULAR_SIDEBAR_WIDTH } from 'src/shared/app/base/component/template/component/sidebar/SideBar.style';
import { topAppBarElevation } from 'src/shared/app/base/style/token/elevation';
import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

export default {
  root: {
    position: 'sticky',
    top: 0,
    left: 0,
    width: '100%',
    height: 88,
    zIndex: topAppBarElevation,
    backgroundColor: ({ theme }: ThemeFunction<unknown>) => theme.colorBase,
    transition: '0.2s ease-out',
    overflowX: 'hidden !important',
  },
  inner: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'relative',
    transform: ({
      isSearchOpened,
    }: ThemeFunction<{
      isSearchOpened: boolean;
    }>) => `translateX(${isSearchOpened ? -40 : 0}px)`,
    opacity: ({
      isSearchOpened,
    }: ThemeFunction<{
      isSearchOpened: boolean;
    }>) => (isSearchOpened ? 0 : 1),
    transition: '0.4s ease-out',
    [lap]: {
      transform: 'none',
      opacity: 1,
    },
  },
  left: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  right: {
    display: 'flex',
    justifyContent: 'flex-end',
    [lap]: {
      minWidth: REGULAR_SIDEBAR_WIDTH + 20,
    },
  },
  nav: {
    display: 'none',
    [lap]: {
      display: 'flex',
    },
  },
  logo: {
    width: 145,
    height: 43,
    [lap]: {
      width: 170,
      height: 50,
    },
  },
  query: {
    display: 'block',
    [lap]: {
      display: 'none',
    },
  },
};
