import { memo } from 'react';
import type { Node } from 'react';

import Alert from '@mui/material/Alert';

import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import CheckIcon from 'src/shared/ui/component/icon/CheckIcon';
import WarningOutlineIcon from 'src/shared/ui/component/icon/WarningOutlineIcon';
import BaseLink from 'src/shared/ui/component/link/BaseLink';
import ConditionalWrapper from 'src/shared/ui/component/misc/ConditionalWrapper';

import styles from './NotificationBanner.style';

type Props = {
  withInfo?: boolean;
  withWarning?: boolean;
  withError?: boolean;
  href?: string;
  icon?: Node;
  handleDismiss?: () => void;
  children: Node;
  action?: Node;
  isCentered?: boolean;
};
const useStyles = createUseThemeStyles(styles);

const NotificationBanner = ({
  withInfo,
  withWarning,
  withError,
  icon,
  href,
  handleDismiss,
  children,
  action,
  isCentered,
}: Props): Node => {
  /*
   |----------------------------------------------------------------------------
   | Classes
   |----------------------------------------------------------------------------
   */
  const classes = useStyles({
    withLink: !!href,
    isDismissable: !!handleDismiss,
    isCentered,
  });

  /*
   |----------------------------------------------------------------------------
   | Event Handlers
   |----------------------------------------------------------------------------
   */
  const getSeverity = () => {
    if (withError) {
      return 'error';
    }

    if (withWarning) {
      return 'warning';
    }

    if (withInfo) {
      return 'info';
    }

    return 'success';
  };

  const getIcon = () => {
    if (icon) return icon;

    if (withError) {
      return null;
    }

    if (withWarning) {
      return <WarningOutlineIcon />;
    }

    return <CheckIcon className={classes.checkIcon} />;
  };

  return (
    <Alert
      className={classes.alert}
      severity={getSeverity()}
      onClose={handleDismiss}
      icon={getIcon()}
      action={action}
      classes={{
        message: classes.message,
        icon: classes.icon,
      }}
    >
      <ConditionalWrapper
        condition={!!href}
        wrapper={(child) => (
          <BaseLink className={classes.link} href={href} tabIndex={0}>
            {child}
          </BaseLink>
        )}
      >
        <p
          className={classes.text}
          {...(!href
            ? {
                tabIndex: 0,
              }
            : {})}
        >
          {children}
        </p>
      </ConditionalWrapper>
    </Alert>
  );
};

export default memo<Props>(NotificationBanner);
