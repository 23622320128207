import { NOTIFICATION_COMPONENT_KEYS } from 'src/shared/app/notification/constant/notificationComponentsMap';
import {
  notificationSuccessLevel,
  notificationTopType,
  notificationWarningLevel,
} from 'src/shared/app/notification/constant/notificationConstants';

export function convertModerationPostToNotification() {
  return {
    id: 'moderation-post-info',
    level: notificationWarningLevel,
    type: notificationTopType,
    componentKey: NOTIFICATION_COMPONENT_KEYS.MODERATION_POST_NOTIFICATION,
  };
}
export function convertModerationAcceptedToNotification() {
  return {
    id: 'moderation-accepted',
    level: notificationSuccessLevel,
    type: notificationTopType,
    componentKey: NOTIFICATION_COMPONENT_KEYS.MODERATION_ACCEPTED_NOTIFICATION,
  };
}
export function convertModerationRejectedToNotification() {
  return {
    id: 'moderation-rejected',
    level: notificationWarningLevel,
    type: notificationTopType,
    componentKey: NOTIFICATION_COMPONENT_KEYS.MODERATION_REJECTED_NOTIFICATION,
  };
}
export function convertModerationAcceptedAndSlackToNotification() {
  return {
    id: 'moderation-accepted-slack',
    level: notificationSuccessLevel,
    type: notificationTopType,
    componentKey:
      NOTIFICATION_COMPONENT_KEYS.MODERATION_ACCEPTED_AND_SLACK_NOTIFICATION,
  };
}
