import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import classNames from 'classnames';
import { useIntl } from 'react-intl';

import messages from 'src/shared/app/base/l10n/validationL10n';
import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import { validatePasswordRules } from 'src/shared/app/base/util/passwordUtils';

import styles from './PasswordChecker.style';

const useStyles = createUseThemeStyles(styles);
type Props = {
  value: string;
};

const PasswordChecker = ({ value }: Props) => {
  const intl = useIntl();
  const classes = useStyles();
  const { atLeastChars, upperAndLower, oneNumber, specialChar } =
    validatePasswordRules(value);
  const options = [
    {
      title: messages.atLeastChars,
      isValid: atLeastChars,
    },
    {
      title: messages.upperAndLower,
      isValid: upperAndLower,
    },
    {
      title: messages.oneNumber,
      isValid: oneNumber,
    },
    {
      title: messages.specialChar,
      isValid: specialChar,
    },
  ];
  return (
    <div className={classes.root}>
      <h3 className={classes.title}>
        {intl.formatMessage(messages.passwordTitle)}
      </h3>

      <ul className={classes.list}>
        {options.map(({ title, isValid }, key) => (
          <li
            className={classNames(classes.item, {
              [classes.itemValid]: isValid,
            })}
            key={key}
          >
            {isValid ? (
              <CheckIcon
                className={classNames(classes.icon, classes.iconValid)}
              />
            ) : (
              <ClearIcon className={classes.icon} />
            )}
            {intl.formatMessage(title)}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default PasswordChecker;
