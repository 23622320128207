import { rem } from 'polished';

import { lap } from '@braindate/ui/lib/token/mediaQuery';
import { baseFontSize } from '@braindate/ui/lib/token/typography';

import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

export default {
  root: {
    padding: 15,
    [lap]: {
      padding: [19, 21],
    },
  },
  closeButton: {
    transform: 'rotate(-90deg)',
    fontSize: `${rem(32, baseFontSize)} !important`,
    cursor: 'pointer',
  },
  head: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 30,
  },
  heading: {
    margin: 0,
    color: ({ theme }: ThemeFunction<unknown>) => theme.textColor,
  },
  section: {
    borderBottom: ({ theme }: ThemeFunction<unknown>) =>
      `1px solid ${theme.calendarDisableButtonBackgroundColor}`,
    paddingBottom: 18,
    '&:last-of-type': {
      borderBottom: 'none',
    },
  },
  sectionHeading: {
    marginBottom: 14,
    color: ({ theme }: ThemeFunction<unknown>) => theme.textColor,
  },
};
