import { em } from 'polished';

import { warningColor } from '@braindate/ui/lib/token/color';
import { aaDarkGrey } from '@braindate/ui/lib/token/palette';
import { subheading1FontSize } from '@braindate/ui/lib/token/typography';

import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

export default {
  root: {
    margin: [em(8, subheading1FontSize), 0],
    color: ({ theme }: ThemeFunction<unknown>) => theme.accentColor,
    textAlign: 'center',
    textTransform: 'uppercase',
    WebkitFontSmoothing: 'antialiased',
  },
  'root-withError': {
    color: ({ theme }: ThemeFunction<unknown>) => [
      theme.errorMessageColor,
      '!important',
    ],
  },
  'root-withWarning': {
    color: [warningColor, '!important'],
  },
  'root-withDisabled': {
    color: [aaDarkGrey, '!important'],
  },
};
