import { useDispatch } from 'react-redux';

import { liveChatsActions } from 'plugin/live-chat/action/liveChatAction';

import type { InternalLiveChatActions } from 'plugin/live-chat/type/liveChatType';

export default function useLiveChatActions(): InternalLiveChatActions {
  const dispatch = useDispatch();
  const { load, kill, toggle, identify, update } = liveChatsActions;
  return {
    load: () => {
      dispatch(load);
    },
    kill: () => {
      dispatch(kill);
    },
    toggle: () => {
      dispatch(toggle);
    },
    identify: () => {
      dispatch(identify);
    },
    update: () => {
      dispatch(update);
    },
  };
}
