import { assertObject } from '@braindate/util/lib/assert';

import { enhanceErrorReportingWithState } from 'src/client/service/errorReportingService';
import { isErrorReportingEnabled } from 'src/shared/app/base/selector/appEnvSelectors';
import type { Store } from 'src/shared/core/type/reduxTypes';

/**
 * Initialize and configure the error reporting service
 * @param   {Store} store - Redux store
 * @returns {undefined}
 *
 * @throws Will throw an exception if parameters are not valid
 */
export function configureErrorReporting(store: Store): void {
  assertObject(store, 'store');

  if (isErrorReportingEnabled(store.getState())) {
    enhanceErrorReportingWithState(store);
  }
}
