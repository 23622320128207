import { memo } from 'react';
import type { Node } from 'react';

import { HeadProvider } from 'react-head';
import { IntlProvider } from 'react-intl';
import { JssProvider } from 'react-jss';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import App from 'src/shared/app/base/component/App';
import { isDevModeEnabled } from 'src/shared/app/base/selector/appEnvSelectors';
import { getLocale } from 'src/shared/app/locale/selector/localeSelectors';
import type { Store } from 'src/shared/core/type/reduxTypes';
import { HideOnScrollContextProvider } from 'src/shared/ui/context/HideOnScrollContext';

type Props = {
  store: Store;
  messages?: Record<string, any>;
};

const ClientApp = ({ store, messages }: Props): Node => {
  const state = store.getState();
  return (
    <HeadProvider>
      <Provider store={store}>
        <IntlProvider locale={getLocale(state)} messages={messages}>
          <BrowserRouter>
            <JssProvider
              id={{
                minify: !isDevModeEnabled(state),
              }}
            >
              <HideOnScrollContextProvider>
                <App />
              </HideOnScrollContextProvider>
            </JssProvider>
          </BrowserRouter>
        </IntlProvider>
      </Provider>
    </HeadProvider>
  );
};

export default memo<Props>(ClientApp);
