import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import type { Braindate } from '@braindate/domain/lib/braindate/type';
import { isBraindateCancelled } from '@braindate/domain/lib/braindate/util';

import { useBraindateCheckInMutation } from 'src/shared/app/base/api/endpoint/braindatesEndpoint';
import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import messages from 'src/shared/app/check-in/l10n/checkInL10n';
import {
  convertBraindateReminderToNotification,
  hasUserCheckedIn,
} from 'src/shared/app/check-in/util/checkInUtil';
import { queueNotification } from 'src/shared/app/notification/action/uiNotificationActions';
import { trackGAEvent } from 'src/shared/core/service/tracking/gaService';
import {
  hasOtherRescheduledBraindate,
  isBraindateCheckInReady,
} from 'src/shared/domain/braindate/util/braindateUtil';
import useIsSelfCheckinEnabled from 'src/shared/domain/event/hook/useIsSelfCheckinEnabled';
import useSelf from 'src/shared/domain/user/hook/useSelf';
import SecondaryButton from 'src/shared/ui/component/button/SecondaryButton';
import CircledCheckIcon from 'src/shared/ui/component/icon/CircledCheckIcon';

import styles from './CheckInButton.style';

type Props = {
  braindate: Braindate;
  showModalOnSuccess?: boolean;
  onClick?: () => void;
};
const useStyles = createUseThemeStyles(styles);

const CheckInButton = ({ braindate, showModalOnSuccess, onClick }: Props) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const classes = useStyles();
  const self = useSelf();
  const isSelfCheckinEnabled = useIsSelfCheckinEnabled();
  const hasUserCheckedInBoolean = self && hasUserCheckedIn(braindate, self);
  const [checkInBraindate, { isLoading }] = useBraindateCheckInMutation();

  const handleClick = async () => {
    if (onClick) {
      onClick();
    }

    if (
      self &&
      isBraindateCheckInReady(braindate) &&
      !isBraindateCancelled(braindate) &&
      !hasOtherRescheduledBraindate(braindate, self)
    ) {
      trackGAEvent('Self check-in', 'Check in', 'Take over notification');
      await checkInBraindate(braindate);

      if (showModalOnSuccess) {
        dispatch(
          queueNotification(
            convertBraindateReminderToNotification(
              braindate,
              isSelfCheckinEnabled,
            ),
          ),
        );
      }
    }
  };

  return (
    <SecondaryButton
      onClick={handleClick}
      isSubmitting={isLoading}
      isDisabled={
        !isBraindateCheckInReady || hasUserCheckedInBoolean || undefined
      }
      withSuccess
    >
      {!isLoading && <CircledCheckIcon className={classes.joinIcon} />}
      {intl.formatMessage(
        hasUserCheckedInBoolean ? messages.checkedInButton : messages.checkIn,
      )}
    </SecondaryButton>
  );
};

export default CheckInButton;
