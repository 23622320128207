import { memo } from 'react';

import classNames from 'classnames';
import { useButton } from 'react-aria';
import { Link } from 'react-router-dom';

import withForwardRef from '@braindate/util/lib/react/hoc/withForwardRef';

import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';

import styles from './TopBarIcon.style';

const useStyles = createUseThemeStyles(styles);
type Props = {
  id?: string;
  label?: string;
  active?: boolean;
  to?: string;
  'aria-controls'?: boolean;
  onClick?: () => void;
  forwardedRef?: {
    current: null | HTMLElement;
  };
  tabIndex?: number;
  children: Node;
};

const TopBarIcon = ({
  id,
  label,
  active,
  to,
  'aria-controls': ariaControls,
  onClick,
  forwardedRef,
  tabIndex,
  children,
}: Props) => {
  const classes = useStyles();
  const { buttonProps } = useButton({
    onPress: () => {
      setTimeout(function () {
        // On Touch event, the event is triggerred before the regular click event, and the click
        // is catched by the popover overlay, so it closes the popover directly.
        // This delay will make sure the click event is triggered before the popover is opening.
        if (onClick) onClick();
      }, 50);
    },
    'aria-label': label,
    'aria-controls': active ? ariaControls : undefined,
    component: 'span',
  });
  const Component = to ? Link : 'span';
  return (
    <Component
      id={id}
      className={classNames(classes.root, active && classes.active)}
      ref={forwardedRef}
      {...buttonProps}
      to={to}
      role="button"
      tabIndex={tabIndex}
    >
      {children}
    </Component>
  );
};

export default memo<Props>(withForwardRef(TopBarIcon));
