import * as types from 'src/shared/app/signup/action/signupActionTypes';
import {
  signupAboutForm,
  signupActivationForm,
  signupEmailForm,
} from 'src/shared/app/signup/constant/signupConstants';
import { createReducer } from 'src/shared/core/util/reducerUtils';

/*
|------------------------------------------------------------------------------
| INITIAL STATE
|------------------------------------------------------------------------------
*/
const initialState = {
  form: signupActivationForm,
};

/*
|------------------------------------------------------------------------------
| REDUCERS
|------------------------------------------------------------------------------
*/
const _showEmailForm = (state: Record<string, any>): Record<string, any> => ({
  ...state,
  form: signupEmailForm,
});

const _showAboutForm = (state: Record<string, any>): Record<string, any> => ({
  ...state,
  form: signupAboutForm,
});
/*
|------------------------------------------------------------------------------
| REDUCER
|------------------------------------------------------------------------------
*/

export default createReducer(initialState, {
  [types.SHOW_SIGNUP_EMAIL_FORM]: _showEmailForm,
  [types.SHOW_SIGNUP_ABOUT_FORM]: _showAboutForm,
});
