import { useMemo } from 'react';

import { useGetPushSubscriptionsQuery } from 'src/shared/app/base/api/endpoint/notificationsEndpoint';
import useFeatureDecisions from 'src/shared/app/feature/hook/useFeatureDecisions';
import useSelf from 'src/shared/domain/user/hook/useSelf';

const usePushNotificationSubscription = () => {
  const self = useSelf();
  const { arePushNotificationsEnabled } = useFeatureDecisions();
  const { currentData: pushSubscriptions } = useGetPushSubscriptionsQuery(
    undefined,
    {
      skip: !self || !arePushNotificationsEnabled,
    },
  );
  const currentPushSubscription = useMemo(() => {
    if (!pushSubscriptions || typeof navigator === 'undefined') return null;
    const { userAgent } = navigator;
    return pushSubscriptions.find((sub) => sub.user_agent === userAgent);
  }, [pushSubscriptions]);
  return currentPushSubscription;
};

export default usePushNotificationSubscription;
