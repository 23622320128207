import { liveChatTawkScriptURL } from 'plugin/live-chat/setting/liveChatSettings';

import type {
  WidgetIntegrationActions,
  WidgetLoadParams,
} from 'plugin/live-chat/type/liveChatType';

function getTawkPropertyId(
  sdkOptions: Record<string, any>,
): string | null | undefined {
  return sdkOptions.property_id;
}

function getTawkWidgetId(
  sdkOptions: Record<string, any>,
): string | null | undefined {
  return sdkOptions.widget_id || 'default';
}

export const tawkWidgetActions: WidgetIntegrationActions = {
  load: async ({
    onStatusChange,
    sdkOptions,
    name,
    email,
  }: WidgetLoadParams) => {
    const propertyId = getTawkPropertyId(sdkOptions);
    const widgetId = getTawkWidgetId(sdkOptions);

    if (!window.Tawk_API) {
      window.Tawk_LoadStart = new Date();
      const userName = name || '';
      const userEmail = email || '';
      window.Tawk_API = {
        visitor: {
          userName,
          userEmail,
        },
        onStatusChange: (status) => {
          onStatusChange(status);
        },
      };
    }

    if (propertyId && widgetId) {
      window.loadJS(
        liveChatTawkScriptURL
          .replace('{property_id}', String(propertyId))
          .replace('{widget_id}', String(widgetId)),
      );
    }
  },
  kill: () => {
    if (window.Tawk_API) {
      if (window.Tawk_API.isVisitorEngaged()) {
        window.Tawk_API.endChat();
      }

      if (!window.Tawk_API.isChatMinimized()) {
        window.Tawk_API.minimize();
      }

      window.Tawk_API.hideWidget();
    }
  },

  toggle() {
    if (window.Tawk_API) {
      window.Tawk_API.toggle();
    }
  },

  identify: async () => {},
  update: () => {},
};
