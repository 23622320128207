import { memo } from 'react';
import type { Node } from 'react';

import classNames from 'classnames';

import type { Picture } from '@braindate/domain/lib/base/type';
import { isString } from '@braindate/util/lib/type';

import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import ResponsivePicture from 'src/shared/ui/component/image/ResponsivePicture';

import styles from './Avatar.style';
import avatarSizes from './setting/avatarSizes';

import type { AvatarProps } from './type/avatarTypes';

type Props = AvatarProps & {
  src: string | Picture;
};
const useStyles = createUseThemeStyles(styles);

const Avatar = ({
  layoutClass,
  // $FlowIssue
  variant,
  customSize,
  alt,
  src,
}: Props): Node => {
  /*
   |----------------------------------------------------------------------------
   | Classes
   |----------------------------------------------------------------------------
   */
  const classes = useStyles({
    variant,
    customSize,
  });
  const rootClasses = classNames(classes.root, layoutClass);

  /*
   |----------------------------------------------------------------------------
   | Elements
   |----------------------------------------------------------------------------
   */
  if (isString(src)) {
    return <img className={rootClasses} src={src} alt={alt} />;
  }

  return (
    <ResponsivePicture
      alt={alt}
      className={rootClasses}
      picture={src}
      displayWidth={customSize || avatarSizes[variant]}
    />
  );
};

// Make variants available
export * from './setting/avatarVariants';
export default memo<Props>(Avatar);
