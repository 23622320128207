import { rem } from 'polished';

import { warningColor } from '@braindate/ui/lib/token/color';
import { baseFontSize } from '@braindate/ui/lib/token/typography';

import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

export default {
  root: {
    margin: rem(8, baseFontSize),
    color: ({ theme }: ThemeFunction<unknown>) => theme.accentColor,
    fontWeight: 700,
    textAlign: 'center',
    textTransform: 'uppercase',
  },
  'root-withError': {
    color: ({ theme }: ThemeFunction<unknown>) => theme.errorMessageColor,
  },
  'root-withWarning': {
    color: warningColor,
  },
};
