import { rem } from 'polished';

import { lap } from '@braindate/ui/lib/token/mediaQuery';
import { baseFontSize } from '@braindate/ui/lib/token/typography';

import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

export default {
  root: {
    display: 'none',
    width: 200,
    height: 48,
    fontSize: rem(20),
    fontWeight: 700,
    gap: '4px',
    minHeight: 'unset',
    [lap]: {
      display: 'flex',
    },
  },
  icon: {
    width: 14,
    height: 14,
    marginRight: 10,
    fontSize: rem(6, baseFontSize),
    border: ({ theme }: ThemeFunction<unknown>) =>
      `1.5px solid ${theme.accentColor}`,
    borderRadius: '50%',
    padding: 1.5,
  },
};
