import * as types from 'src/shared/app/market/action/marketActionTypes';
import { createReducer } from 'src/shared/core/util/reducerUtils';

export type MarketFiltersReducerType = {
  status: string;
  kind: string[];
  language: string[];
  date: string;
  selfLocation: boolean;
  canJoin: boolean;
  conversationType: string[];
  participantCategories: string[];
};
export const defaultMarketFilters = {
  status: '',
  kind: [],
  language: [],
  date: '',
  selfLocation: false,
  canJoin: false,
  conversationType: [],
  participantCategories: [],
};
export const marketFilterInitialState: MarketFiltersReducerType = {
  ...defaultMarketFilters,
};

const _setStatus = (
  state: Record<string, any>,
  action: Record<string, any>,
) => {
  const { value } = action;
  return { ...state, status: value };
};

const _resetStatus = (state: Record<string, any>) => ({ ...state, status: '' });

const _setKind = (state: Record<string, any>, action: Record<string, any>) => {
  const { value } = action;
  return { ...state, kind: value };
};

const _resetKind = (state: Record<string, any>) => ({ ...state, kind: [] });

const _setLanguage = (
  state: Record<string, any>,
  action: Record<string, any>,
) => {
  const { value } = action;
  return { ...state, language: value };
};

const _resetLanguage = (state: Record<string, any>) => ({
  ...state,
  language: '',
});

const _setSelfLocation = (
  state: Record<string, any>,
  action: Record<string, any>,
) => {
  const { value } = action;
  return { ...state, selfLocation: value };
};

const _setCanJoin = (
  state: Record<string, any>,
  action: Record<string, any>,
) => {
  const { value } = action;
  return { ...state, canJoin: value };
};

const _setDate = (state: Record<string, any>, action: Record<string, any>) => {
  const { value } = action;
  return { ...state, date: value };
};

const _setConversationType = (
  state: Record<string, any>,
  action: Record<string, any>,
) => {
  const { value } = action;
  return { ...state, conversationType: value };
};

const _resetConversationType = (state: Record<string, any>) => ({
  ...state,
  conversationType: [],
});

const _setParticipantCategory = (
  state: Record<string, any>,
  action: Record<string, any>,
) => {
  const { value } = action;
  return { ...state, participantCategories: value };
};

const _resetParticipantCategory = (state: Record<string, any>) => ({
  ...state,
  participantCategories: [],
});

const _resetFilters = (state: Record<string, any>) => {
  const { ...filtersToReset } = marketFilterInitialState;
  return { ...state, ...filtersToReset };
};

const _setAvailableFilters = (
  state: Record<string, any>,
  payload: Record<string, any>,
) => {
  const { availableFilters } = payload;
  return { ...state, availableFilters };
};

export default createReducer(marketFilterInitialState, {
  [types.SET_MARKET_STATUS_FILTER]: _setStatus,
  [types.RESET_MARKET_STATUS_FILTER]: _resetStatus,
  [types.SET_MARKET_KIND_FILTER]: _setKind,
  [types.RESET_MARKET_KIND_FILTER]: _resetKind,
  [types.SET_MARKET_LANGUAGE_FILTER]: _setLanguage,
  [types.RESET_MARKET_LANGUAGE_FILTER]: _resetLanguage,
  [types.SET_MARKET_DATE_FILTER]: _setDate,
  [types.SET_MARKET_CONVERSATION_TYPE]: _setConversationType,
  [types.RESET_MARKET_CONVERSATION_TYPE]: _resetConversationType,
  [types.SET_PARTICIPANT_CATEGORY]: _setParticipantCategory,
  [types.RESET_PARTICIPANT_CATEGORY]: _resetParticipantCategory,
  [types.SET_MARKET_LOCATION_FILTER]: _setSelfLocation,
  [types.SET_MARKET_CANJOIN_FILTER]: _setCanJoin,
  [types.RESET_MARKET_FILTERS]: _resetFilters,
});
