export default {
  root: {
    maxHeight: 0,
    opacity: 0,
    transition: 'all 0.45s ease-in',
  },
  'root-isVisible': {
    /* Arbitrary number that must be bigger than the real height for animation */
    maxHeight: 380,
    opacity: 1,
  },
};
