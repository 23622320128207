import { createApi } from '@reduxjs/toolkit/query/react';

import { TAG_TYPE } from 'src/shared/app/base/api/apiConstant';

import customQuery from './utils/customQuery';
// initialize an empty api service that we'll inject endpoints into later as needed
// https://redux-toolkit.js.org/rtk-query/usage/code-splitting
export const apiRoot = createApi({
  baseQuery: customQuery(),

  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === 'HYDRATE') {
      return action.payload[reducerPath];
    }
  },

  endpoints: () => ({}),
  tagTypes: Object.values(TAG_TYPE),
});
