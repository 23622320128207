import type { Node } from 'react';
import { memo } from 'react';

import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import messages from 'src/shared/app/account/profile/l10n/profileL10n';
import { dismissNotification } from 'src/shared/app/notification/action/uiNotificationActions';
import Notification from 'src/shared/app/notification/component/Notification';
import type { Notification as NotificationType } from 'src/shared/app/notification/type/notificationTypes';

type Props = {
  notification: NotificationType<any>;
};

const ModerationPostNotification = ({ notification }: Props): Node => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const handleDismiss = () => {
    dispatch(dismissNotification(notification));
  };

  return (
    <Notification timeout={4000} handleDismiss={handleDismiss}>
      {intl.formatMessage(messages.savedChanges)}
    </Notification>
  );
};

export default memo<Props>(ModerationPostNotification);
