import { useIntl } from 'react-intl';

import SpinnerIcon from '@braindate/ui/lib/component/icon/SpinnerIcon';

import messages from 'src/shared/app/base/l10n/errorL10n';
import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import type { LoadingComponent } from 'src/shared/app/loading/type/loadableTypes';
import PrimaryButton from 'src/shared/ui/component/button/PrimaryButton';

import styles from './FluidLoadingScreen.style';

type Props = LoadingComponent;
const useStyles = createUseThemeStyles(styles);

const FluidLoadingScreen = ({ error, timedOut, retry }: Props) => {
  const classes = useStyles();
  const intl = useIntl();

  if (error || timedOut) {
    return (
      <div className={classes.root}>
        <p className={classes.error}>
          {typeof error === 'string'
            ? error
            : intl.formatMessage(messages.genericError)}
        </p>
        <PrimaryButton onClick={retry}>
          {intl.formatMessage(messages.retry)}
        </PrimaryButton>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <SpinnerIcon />
    </div>
  );
};

export default FluidLoadingScreen;
