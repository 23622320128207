import { memo } from 'react';
import type { ComponentType, Node } from 'react';

import { MAX_NO_SIDEBAR_GRID_WIDTH } from 'src/shared/app/base/style/token/grid';

import FullWidthTemplate from './FullWidthTemplate';

type Props = {
  Switch: ComponentType<any>;
};

const StretchedWidthTemplate = ({ Switch }: Props): Node => (
  <FullWidthTemplate
    contentMaxWidth={MAX_NO_SIDEBAR_GRID_WIDTH}
    Switch={Switch}
  />
);

export default memo<Props>(StretchedWidthTemplate);
