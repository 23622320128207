import type { State } from 'src/shared/core/type/reduxTypes';

const slice = (state: State) => state.ui.nav;

export function selectHasBottomBar(state: State): boolean {
  return slice(state).hasBottomBar;
}
export function selectMobileNavIsRegistered(state: State): boolean {
  return slice(state).withMobileNav;
}
