import { createUseStyles, useTheme } from 'react-jss';
import { $Keys } from 'utility-types';

export default function createUseThemeStyles<T>(
  styles: T,
): (props?: Record<string, any>) => Record<$Keys<T>, string> {
  const useStyles = createUseStyles(styles);
  return (props) => {
    const theme = useTheme();
    // $FlowIssue
    return useStyles({
      theme,
      ...props,
    });
  };
}
