import { memo } from 'react';
import type { Node } from 'react';

import classNames from 'classnames';
import { useButton } from 'react-aria';
import { Link, useLocation, useMatch } from 'react-router-dom';

import { hasURLProtocol } from '@braindate/util/lib/url';

import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import type { ReactElement } from 'src/shared/core/type/reactTypes';
import ConditionalWrapper from 'src/shared/ui/component/misc/ConditionalWrapper';

import styles from './MainNavLink.style';

type Props = {
  title?: string;
  to?: string;
  match?: string[];
  icon?: Node;
  children?: Node;
  preloadComp?: ReactElement;
  target?: string;
  rel?: string;
  className?: string;
  active?: boolean;
  onClick?: () => void;
  disableActiveBackgroundColor?: boolean;
};
const useStyles = createUseThemeStyles(styles);

const MainNavLink = ({
  title,
  to,
  match,
  icon,
  children,
  preloadComp,
  target,
  rel,
  className,
  active,
  onClick,
  disableActiveBackgroundColor,
}: Props): Node => {
  const isExternalLink = to && hasURLProtocol(to);
  const routerMatch = useMatch(to || '/');
  const location = useLocation();

  const onMouseOver = () => {
    if (preloadComp) {
      // $FlowIssue
      preloadComp.preload();
    }
  };

  // Only used when it is not a link
  const { buttonProps } = useButton({
    onMouseOver,
    onPress: onClick || (() => {}),
    component: 'div',
  });
  const classes = useStyles();
  const isActive =
    (to && ((match && match.includes(location.pathname)) || !!routerMatch)) ||
    !!active;
  const rootClasses = classNames(
    classes.root,
    {
      [classes['root-isSelected']]: isActive,
    },
    className,
  );
  let props: Record<string, any> = {
    className: rootClasses,
    onClick,
  };

  if (isExternalLink) {
    props = { ...props, href: to, target, rel };
  } else {
    props = { ...props, to, onMouseOver };
  }

  const iconClassNames = classNames(classes.icon, {
    [classes['icon-isSelected']]: isActive,
    [classes['icon-noActiveBackground']]: disableActiveBackgroundColor,
    [classes['icon-noBorder']]: !icon,
  });

  if (isActive) {
    props['aria-current'] = 'page';
  }

  return (
    <ConditionalWrapper
      condition={!to}
      wrapper={(child) => (
        <div
          className={classNames(classes.root, rootClasses)}
          {...buttonProps}
          tabIndex={0}
        >
          {child}
        </div>
      )}
      elseWrapper={(child) => (
        <ConditionalWrapper
          condition={isExternalLink}
          wrapper={(linkChild) => <a {...props}>{linkChild}</a>}
          elseWrapper={(linkChild) => <Link {...props}>{linkChild}</Link>}
        >
          {child}
        </ConditionalWrapper>
      )}
    >
      <span className={iconClassNames}>
        <span className={classes.iconInner}>
          {icon}
          {children}
        </span>
      </span>
      {title && <span className={classNames(classes.label)}>{title}</span>}
    </ConditionalWrapper>
  );
};

export default memo<Props>(MainNavLink);
