import { Fragment, memo, useEffect, useState } from 'react';
import type { Node } from 'react';

import {
  Autocomplete,
  CircularProgress,
  InputAdornment,
  TextField,
} from '@mui/material';
import classNames from 'classnames';

import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import MagnifierIcon from 'src/shared/ui/component/icon/MagnifierIcon';

import styles from './FormikAutocomplete.style';

const useStyles = createUseThemeStyles(styles);
type Props = {
  id: string;
  field: Record<string, any>;
  options?: Record<string, any>[];
  onChange: (value: Record<string, any>) => void;
  startAdornment: (value: Record<string, any>) => Node;
  getOptionLabel: (value: Record<string, any>) => string;
  onInputChange: (value: string) => string;
  renderOption: (
    props: Record<string, any>,
    value: Record<string, any>,
  ) => Node;
  isOptionEqualToValue: (
    option: Record<string, any>,
    value: Record<string, any>,
  ) => boolean;
  isFetching?: boolean;
  className?: string;
  form: Record<string, any>;
  disabled?: boolean;
  isFetching?: boolean;
  readOnly?: boolean;
};

const FormikAutocomplete = ({
  id,
  field,
  form,
  className,
  isFetching,
  readOnly,
  disabled,
  options,
  onInputChange,
  startAdornment,
  getOptionLabel,
  renderOption,
  isOptionEqualToValue,
}: Props): Node => {
  const classes = useStyles({
    disabled,
  });
  const [inputValue, setInputValue] = useState('');
  useEffect(() => {
    setInputValue(field.value ? getOptionLabel(field.value) : '');
  }, [field.value]);

  const handleChange = (value) => {
    form.setFieldValue(field.name, value);
  };

  const handleInputChange = (value) => {
    if (onInputChange) {
      onInputChange(value);
    }

    setInputValue(value);
  };

  return (
    <Autocomplete
      {...field}
      id={id}
      className={classNames(classes.root, className)}
      classes={classes}
      options={options}
      renderOption={renderOption}
      onChange={(_, value) => handleChange(value)}
      value={field.value || null}
      disabled={disabled}
      loading={isFetching}
      inputValue={inputValue}
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={isOptionEqualToValue}
      renderInput={({ value, defaultValue: _, ...params }) => (
        <TextField
          {...params}
          disabled={disabled || isFetching}
          readOnly={readOnly}
          defaultValue={value}
          onChange={(e) => handleInputChange(e.target.value)}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <InputAdornment position="start">
                {field.value && startAdornment ? (
                  startAdornment(field.value)
                ) : (
                  <MagnifierIcon className={classes.startAdornment} />
                )}
              </InputAdornment>
            ),
            endAdornment: (
              <>
                {isFetching ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
};

export default memo<Props>(FormikAutocomplete);
