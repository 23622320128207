import { rem } from 'polished';

import { baseFontSize } from '@braindate/ui/lib/token/typography';

import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

export default {
  root: {
    backgroundColor: ({
      theme,
      withSuccess,
    }: ThemeFunction<{
      withSuccess: boolean;
    }>) => (withSuccess ? theme.successTextColor : theme.accentColor),
    color: ({ theme }: ThemeFunction<unknown>) => theme.textColorReverse,
    textTransform: 'uppercase',
    textDecoration: 'none',
    fontSize: rem(12, baseFontSize),
    border: ({
      theme,
      withSuccess,
    }: ThemeFunction<{
      withSuccess: boolean;
    }>) =>
      `solid 1px ${withSuccess ? theme.successTextColor : theme.accentColor}`,
    '&:hover': {
      boxShadow: ({ theme }: ThemeFunction<unknown>) => theme.buttonShadowBox,
      textDecoration: 'none',
    },
  },
};
