import { rem } from 'polished';

import { aaDarkGrey } from '@braindate/ui/lib/token/palette';
import { baseFontSize } from '@braindate/ui/lib/token/typography';

import { navBarMobileHeight } from 'src/shared/app/base/component/template/style/template.style';
import { antialiasing } from 'src/shared/app/base/style/token/helper';

export default {
  root: {
    padding: [48, 16, navBarMobileHeight + 48],
  },
  actions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  content: {
    margin: ['2em', 0],
    textAlign: 'center',
  },
  retry: {
    marginTop: 48,
    marginBottom: 8,
  },
  details: {
    extend: antialiasing,
    marginTop: 40,
  },
  ref: {
    textAlign: 'center',
  },
  refLabel: {
    color: aaDarkGrey,
  },
  refId: {
    display: 'block',
    marginTop: 8,
    fontSize: rem(16, baseFontSize),
  },
  message: {
    wordWrap: 'break-word',
  },
};
