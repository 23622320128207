import { Fragment, useRef } from 'react';
import type { Node } from 'react';

import { useSelector } from 'react-redux';
import Transition, { EXITED } from 'react-transition-group/Transition';

import BaseNotification from 'src/shared/app/base/component/template/component/BaseNotification';
import TakeOver from 'src/shared/app/base/component/template/component/TakeOver';
import { notificationTransitionDuration } from 'src/shared/app/notification/constant/notificationConstants';
import { getNotificationQueueOfBundleType } from 'src/shared/app/notification/selector/uiNotificationSelectors';
import type { Notification } from 'src/shared/app/notification/type/notificationTypes';
import {
  getNotificationComponent,
  getNotificationId,
  isModalNotification,
  isTakeOverNotification,
} from 'src/shared/app/notification/util/notificationUtil';

type WrapperProps = {
  notification: Notification<any>;
  children: Node;
};

const RootWrapper = ({ children, notification }: WrapperProps) => {
  const id = getNotificationId(notification);

  if (isModalNotification(notification)) {
    return <BaseNotification key={id}>{children}</BaseNotification>;
  }

  if (isTakeOverNotification(notification)) {
    return <TakeOver key={id}>{children}</TakeOver>;
  }

  return <Fragment key={id}>{children}</Fragment>;
};

type Props = {
  notification: Notification<any>;
  isVisible: boolean;
};
export default function NotificationContainerItem({
  notification,
  isVisible,
}: Props): Node {
  const bundleNotifications = useSelector(
    getNotificationQueueOfBundleType(notification),
  );
  const Component = getNotificationComponent(notification);
  const nodeRef = useRef(null);
  if (!Component) return null;
  return (
    <Transition
      nodeRef={nodeRef}
      in={isVisible}
      timeout={notificationTransitionDuration}
    >
      {(state) =>
        state !== EXITED ? (
          <div ref={nodeRef}>
            <RootWrapper notification={notification}>
              <Component
                notification={notification}
                bundleNotifications={bundleNotifications}
              />
            </RootWrapper>
          </div>
        ) : null
      }
    </Transition>
  );
}
