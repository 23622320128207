import { memo } from 'react';
import type { Node } from 'react';

import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import type { Braindate } from '@braindate/domain/lib/braindate/type';
import {
  getBraindateId,
  getBraindateLocation,
} from '@braindate/domain/lib/braindate/util';
import { isVirtualLocation } from '@braindate/domain/lib/location/util';

import { useGetBraindateQuery } from 'src/shared/app/base/api/endpoint/braindatesEndpoint';
import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import UpcomingBraindateCard from 'src/shared/app/braindate/component/card/upcoming/UpcomingBraindateCard';
import { getJoinedBraindates } from 'src/shared/app/braindate/selector/joinedBraindateSelectors';
import messages from 'src/shared/app/check-in/l10n/checkInTakeOverL10n';
import { dismissNotification } from 'src/shared/app/notification/action/uiNotificationActions';
import TakeOver from 'src/shared/app/notification/component/TakeOver';
import type { Notification } from 'src/shared/app/notification/type/notificationTypes';
import { getNotificationData } from 'src/shared/app/notification/util/notificationUtil';
import ChatIcon from 'src/shared/ui/component/icon/ChatIcon';
import Subheading from 'src/shared/ui/component/text/Subheading';

import useVirtualBraindateOverlayShown from 'plugin/virtual-braindate/hook/useVirtualBraindateOverlayShown';

import styles from './CheckInTakeOver.style';
import CheckInTakeOverOnsiteAction from './CheckInTakeOverOnsiteAction';
import CheckInTakeOverVirtualAction from './CheckInTakeOverVirtualAction';

type Props = {
  notification: Notification<Braindate>;
};
const useStyles = createUseThemeStyles(styles);

const CheckInTakeOver = ({ notification }: Props): Node => {
  const intl = useIntl();
  const classes = useStyles();
  const dispatch = useDispatch();
  const virtualBraindateShown = useVirtualBraindateOverlayShown();
  const braindateNotification: Braindate = getNotificationData(notification);
  const { data } = useGetBraindateQuery(getBraindateId(braindateNotification));
  const braindate = data || braindateNotification;
  const location = getBraindateLocation(braindate);
  const isVirtual = !!location && isVirtualLocation(location);
  const joinedBraindates = useSelector(getJoinedBraindates);
  if (joinedBraindates.includes(getBraindateId(braindate))) return null;

  const handleDismiss = () => {
    dispatch(dismissNotification(notification));
  };

  if (!braindate || !!virtualBraindateShown) return null;
  return (
    <TakeOver handleDismiss={handleDismiss}>
      <div className={classes.root}>
        <div className={classes.top}>
          <div className={classes.icon}>
            <ChatIcon />
          </div>
          <Subheading layoutClass={classes.heading}>
            {intl.formatMessage(messages.takeOverTitle)}
          </Subheading>

          <div className={classes.card}>
            <UpcomingBraindateCard
              braindate={braindate}
              isInactive
              showCheckin={false}
              isPreview
            />
          </div>
        </div>
        <div className={classes.action}>
          {isVirtual ? (
            <CheckInTakeOverVirtualAction
              braindate={braindate}
              onClick={handleDismiss}
            />
          ) : (
            <CheckInTakeOverOnsiteAction
              braindate={braindate}
              onClick={handleDismiss}
            />
          )}
        </div>
      </div>
    </TakeOver>
  );
};

export default memo<Props>(CheckInTakeOver);
