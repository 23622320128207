import { em } from 'polished';

import { baseFontSize } from '@braindate/ui/lib/token/typography';

import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

export default {
  root: {
    width: em(55, baseFontSize),
    height: em(53, baseFontSize),
    transition: '0.25s ease-out',
    color: ({
      theme,
      isActive,
    }: ThemeFunction<{
      isActive: boolean;
    }>) => (isActive ? theme.accentColor : theme.calendarDisableButtonColor),
  },
};
