import type { Node } from 'react';

import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import type { Braindate } from '@braindate/domain/lib/braindate/type';
import {
  getBraindateLocation,
  getBraindatePath,
} from '@braindate/domain/lib/braindate/util';
import { isVirtualLocation } from '@braindate/domain/lib/location/util';
import {
  isFishbowlTopic,
  isGroupTopic,
} from '@braindate/domain/lib/topic/util';
import { getUserFirstName, getUserId } from '@braindate/domain/lib/user/util';

import { dismissNotification } from 'src/shared/app/notification/action/uiNotificationActions';
import Notification from 'src/shared/app/notification/component/Notification';
import messages from 'src/shared/app/notification/l10n/notificationL10n';
import type { Notification as NotificationType } from 'src/shared/app/notification/type/notificationTypes';
import { getNotificationData } from 'src/shared/app/notification/util/notificationUtil';
import { trackGAEvent } from 'src/shared/core/service/tracking/gaService';
import {
  getBraindateParticipants,
  getBraindateTopic,
} from 'src/shared/domain/braindate/util/braindateUtil';
import useSelf from 'src/shared/domain/user/hook/useSelf';

import virtualMessages from 'plugin/virtual-braindate/l10n/virtualBraindateL10n';

type Props = {
  notification: NotificationType<any>;
};

const CheckInNotification = ({ notification }: Props): Node => {
  const self = useSelf();
  const intl = useIntl();
  const dispatch = useDispatch();
  const braindate = getNotificationData<Braindate>(notification);
  const href = getBraindatePath(braindate);
  const topic = getBraindateTopic(braindate);
  const isGroupBraindate = isGroupTopic(topic);
  const participants = getBraindateParticipants(braindate);
  const otherParticipants = participants.filter(
    (p) => self && getUserId(p) !== getUserId(self),
  );
  const location = getBraindateLocation(braindate);
  const isVirtual = location && isVirtualLocation(location);

  const handleDismiss = () => {
    dispatch(dismissNotification(notification));
    trackGAEvent('Self check-in', 'Dismiss notification', 'Notification');
  };

  const getMessageTxt = () => {
    if (isVirtual && isFishbowlTopic(topic)) {
      return intl.formatMessage(virtualMessages.reminderFishbowl);
    }

    return intl.formatMessage(
      isVirtual ? virtualMessages.reminder : messages.checkIn,
      {
        isGroupBraindate,
        firstName: otherParticipants.map(getUserFirstName).join(', '),
      },
    );
  };

  return (
    <Notification href={href} handleDismiss={handleDismiss}>
      {getMessageTxt()}
    </Notification>
  );
};

export default CheckInNotification;
