import { memo } from 'react';
import type { Node } from 'react';

import classNames from 'classnames';

import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';

import styles from './NotificationBadge.style';

type Props = {
  layoutClass?: string;
};
const useStyles = createUseThemeStyles(styles);

const NotificationBadge = ({ layoutClass }: Props): Node => {
  /*
   |----------------------------------------------------------------------------
   | Classes
   |----------------------------------------------------------------------------
   */
  const classes = useStyles();
  const rootClasses = classNames(classes.root, layoutClass);

  /*
   |------------------------------------------------------------------------------
   | Elements
   |------------------------------------------------------------------------------
   */
  return <span className={rootClasses} />;
};

export default memo<Props>(NotificationBadge);
