export const OPEN_BOOKING_SCHEDULE = 'OPEN_BOOKING_SCHEDULE';
export const CLOSE_BOOKING_SCHEDULE = 'CLOSE_BOOKING_SCHEDULE';
export const OPEN_ACCEPT_TIME_SCHEDULE = 'OPEN_ACCEPT_TIME_SCHEDULE';
export const CLOSE_ACCEPT_TIME_SCHEDULE = 'CLOSE_ACCEPT_TIME_SCHEDULE';
export const OPEN_BOOKING_CONFIRMATION_MODAL =
  'OPEN_BOOKING_CONFIRMATION_MODAL';
export const CLOSE_BOOKING_CONFIRMATION_MODAL =
  'CLOSE_BOOKING_CONFIRMATION_MODAL';
export const OPEN_EVENT_OVER_MODAL = 'OPEN_EVENT_OVER_MODAL';
export const CLOSE_EVENT_OVER_MODAL = 'CLOSE_EVENT_OVER_MODAL';
export const OPEN_BRAINDATING_UNAVAILABLE_MODAL =
  'OPEN_BRAINDATING_UNAVAILABLE_MODAL';
export const CLOSE_BRAINDATING_UNAVAILABLE_MODAL =
  'CLOSE_BRAINDATING_UNAVAILABLE_MODAL';
export const SAVE_BOOKING_MESSAGE = 'SAVE_BOOKING_MESSAGE';
export const CLEAR_BOOKING_MESSAGE = 'CLEAR_BOOKING_MESSAGE';
