import type { Braindate } from '@braindate/domain/lib/braindate/type';
import { getBraindateId } from '@braindate/domain/lib/braindate/util';

import { NOTIFICATION_COMPONENT_KEYS } from 'src/shared/app/notification/constant/notificationComponentsMap';
import {
  notificationBundleTypeFeedback,
  notificationInfoLevel,
  notificationVanillaType,
} from 'src/shared/app/notification/constant/notificationConstants';
import type { Notification } from 'src/shared/app/notification/type/notificationTypes';

export function getBraindateFeedbackNotificationId(
  braindate: Braindate,
): string {
  return `braindate-feedback-${getBraindateId(braindate)}`;
}
export function convertPostBraindateFeedbackToNotification(
  braindate: Braindate,
): Notification<any> {
  return {
    id: getBraindateFeedbackNotificationId(braindate),
    level: notificationInfoLevel,
    type: notificationVanillaType,
    componentKey: NOTIFICATION_COMPONENT_KEYS.BRAINDATE_FEEDBACK,
    data: braindate,
    bundleType: notificationBundleTypeFeedback,
  };
}
