import { useSelector } from 'react-redux';

import { getVirtualBraindateOverlayShown } from 'plugin/virtual-braindate/selector/virtualBraindateSelectors';

export default function useVirtualBraindateOverlayShown():
  | number
  | null
  | undefined {
  return useSelector(getVirtualBraindateOverlayShown);
}
