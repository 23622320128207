import { memo } from 'react';
import type { Node } from 'react';

import { useMediaQuery } from '@mui/material';
import classNames from 'classnames';

import { lap } from '@braindate/ui/lib/token/mediaQuery';

import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';

import styles from './FeedList.style';

const useStyles = createUseThemeStyles(styles);
type Props = {
  grid?: boolean;
  count: number;
  itemWidth?: number;
  allFetched?: boolean;
  isCompact?: boolean;
  layoutClass?: string;
  children: Node;
};

const FeedList = ({
  grid,
  count,
  allFetched,
  itemWidth,
  isCompact,
  layoutClass,
  children,
}: Props) => {
  const isLap = useMediaQuery(lap);
  const shouldAddEmptyElement = allFetched && !!(count % 3) && isLap;
  const classes = useStyles({
    grid,
    isCompact,
    shouldAddEmptyElement,
    itemWidth,
  });
  return (
    <ul
      data-testid="feed-list"
      className={classNames(classes.root, layoutClass, {
        [classes.grid]: grid,
      })}
    >
      {children}
    </ul>
  );
};

export default memo<Props>(FeedList);
