import { darken, lighten, mix, rgba } from 'polished';
import { $Shape } from 'utility-types';

import type { BrandingCustomizationType } from '@braindate/domain/lib/event/type';
import { baseColor } from '@braindate/ui/lib/token/color';
import { googleBlue, red, white } from '@braindate/ui/lib/token/palette';
import { baseFontFamily } from '@braindate/ui/lib/token/typography';
import { isNumber } from '@braindate/util/lib/type';

import {
  darkCardBaseColor,
  warningColor,
} from 'src/shared/app/base/style/token/color';
import type { Theme } from 'src/shared/app/base/types/themeTypes';

export function getTheme(
  name: string,
  accentColor: string,
  brandingCustomization: BrandingCustomizationType | null,
): Theme {
  const themes: Record<string, $Shape<Theme>> = {
    dark: {
      name: 'dark',
      colorBase: brandingCustomization?.platform_background_color || baseColor,
      colorBaseHover: lighten(0.2, baseColor),
      colorPrimary: darkCardBaseColor,
      textColor: white,
      textColorSecondary: '#B0B5BE',
      textColorTertiary: '#CDD5D5',
      textColorQuaternary: '#abb2b2',
      textColorReverse: '#000',
      buttonColor: '#000',
      linkColor: '#2BB3EE',
      buttonBackgroundDisableColor: '#B0B5BE',
      chatBubbleColor: darkCardBaseColor,
      slideBackgroundColor: baseColor,
      skeletonPlaceholder: '#58585b',
      braindateCardTimeInactive: '#58585b',
      calendarBackgroundColor: '#454749',
      calendarDisableButtonBackgroundColor: '#58585B',
      calendarDisableButtonColor: '#B0B5BE',
      carouselDotColor: '#CDD5D5',
      errorMessageColor: red,
      favoriteIconColor: '#BABABB',
      favoriteIconFillColor: '#ff7977',
      signinGoogleBackgroundColor: '#92b5ee',
      signinGoogleBackgroundColorHover: '#5190F5',
      signinOfficeBackgroundColor: '#ED8373',
      signinOfficeBackgroundColorHover: '#ED6B5A',
      successTextColor: '#28c18a',
      helperMessageColor: '#BABABB',
    },
    light: {
      name: 'light',
      colorBase: brandingCustomization?.platform_background_color || '#ffffff',
      colorBaseHover: darken(0.2, '#fff'),
      colorPrimary: '#fdfdfd',
      textColor: '#000',
      textColorSecondary: '#7C8698',
      textColorTertiary: '#58585b',
      textColorQuaternary: '#95959b',
      textColorReverse: white,
      buttonColor: white,
      linkColor: '#1F69E0',
      buttonBackgroundDisableColor: '#58585B',
      chatBubbleColor: '#CDD5D5',
      slideBackgroundColor: white,
      skeletonPlaceholder: '#CDD5D5',
      braindateCardTimeInactive: '#CDD5D5',
      calendarBackgroundColor: '#FDFDFD',
      calendarDisableButtonBackgroundColor: '#EFF0F3',
      calendarDisableButtonColor: '#7C8698',
      carouselDotColor: '#CDD5D5',
      errorMessageColor: '#D10000',
      favoriteIconColor: '#707070',
      favoriteIconFillColor: '#D6403D',
      signinGoogleBackgroundColor: '#1C6EF2',
      signinGoogleBackgroundColorHover: darken(0.15, googleBlue),
      signinOfficeBackgroundColor: '#C93E2B',
      signinOfficeBackgroundColorHover: '#9f3122',
      successTextColor: '#1B835D',
      helperMessageColor: '#707070',
    },
  };
  const borderRadius = brandingCustomization?.buttons_border_radius;
  const theme = themes[name];
  return {
    ...theme,
    accentColor,
    accentColorHover: rgba(accentColor, 0.2),
    accentColorSoft:
      brandingCustomization?.secondary_color ||
      mix(0.1, accentColor, theme.colorPrimary),
    flag: '#FF7977',
    alertSuccessFontColor: '#006239',
    alertSuccessBackgroundColor: '#E8FFF1',
    alertErrorFontColor: '#7A0200',
    alertErrorBackgroundColor: '#FFE4E3',
    alertWarningFontColor: '#9C5E00',
    alertWarningBackgroundColor: '#FFF7E8',
    primaryFontFamily: brandingCustomization?.primary_font_family
      ? `'${brandingCustomization?.primary_font_family}', ${baseFontFamily}`
      : baseFontFamily,
    secondaryFontFamily: brandingCustomization?.secondary_font_family
      ? `'${brandingCustomization?.secondary_font_family}', ${baseFontFamily}`
      : baseFontFamily,
    buttonShadowBox: '-3px 3px 5px 0 rgba(0, 0, 0, 0.25) ',
    shadowBox: '0px 4px 15px rgba(0, 0, 0, 0.25)',
    cardShadowBox: '0px 4px 10px 0px rgba(0, 0, 0, 0.05)',
    secondaryTagColor: '#EFF0F3',
    secondaryTagColorHover: '#C6C9CF',
    secondaryTagTextColor: '#58585b',
    conflictColor: warningColor,
    // $FlowIssue isNumber(borderRadius) is checking if borderRadius is a number...
    buttonBorderRadius: isNumber(borderRadius) ? `${borderRadius}px` : '2em',
  };
}
