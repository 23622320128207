import { getLocalizedData } from '@braindate/domain/lib/base/l10n/util';
import type { BraindateEvent } from '@braindate/domain/lib/event/type';
import { getEventPlugins } from '@braindate/domain/lib/event/util';
import { assertObject } from '@braindate/util/lib/assert';

import { isPluginEnabled } from 'plugin/base/util/pluginUtils';
import { socialSharingPlugin } from 'plugin/social-sharing/setting/socialSharingSettings';

export function isSocialSharingPluginEnabled(event: BraindateEvent): boolean {
  return isPluginEnabled(socialSharingPlugin, event);
}
export function getSocialSharingOptions(
  event: BraindateEvent,
): Record<string, any> | null | undefined {
  const options = getEventPlugins(event)[socialSharingPlugin];

  if (options) {
    assertObject(options, 'options');
    return options;
  }
}

/**
 * Get the hashtag of the event
 * @param  {Event} event - Event to get the hashtag from
 * @param  {string} [locale] - Locale in which to get the hashtag
 * @return {string} Hashtag
 */
export function getSocialSharingHashtag(
  event: BraindateEvent,
  locale?: string,
): string | null | undefined {
  return getLocalizedData(getSocialSharingOptions(event) || {}, locale).hashtag;
}

/**
 * Get an object of social handles of the event
 * @param  {Event} event - Event to get the handle object from
 * @param  {string} [locale] - Locale in which to get the handles
 * @return {string} Handle
 */
export function getSocialSharingTwitterHandle(
  event: BraindateEvent,
  locale?: string,
): string | null | undefined {
  return getLocalizedData(getSocialSharingOptions(event) || {}, locale).twitter;
}

/**
 * Get the flag to know if the braindate hashtag should be used or not
 * @param  {Event} event - Event to get the flag from
 * @return {boolean} Use hashtag or not
 */
export function isSocialSharingBraindateHashtagHidden(
  event: BraindateEvent,
): boolean {
  return !!(getSocialSharingOptions(event) || {}).hideBraindateHashtag;
}
