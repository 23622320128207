/* @flow */

import { useEffect } from 'react';

import { useSelector } from 'react-redux';

import { ampli } from 'src/ampli';
import { getAmplitudeKey } from 'src/shared/app/base/selector/appEnvSelectors';
import useSelf from 'src/shared/domain/user/hook/useSelf';

export default function useAmplitudeTracking() {
  const key = useSelector(getAmplitudeKey);

  if (key) {
    const user = useSelf();

    useEffect(() => {
      ampli.load({
        client: {
          apiKey: key,
          configuration: {
            defaultTracking: true,
          },
        },
      });
    }, []);

    useEffect(() => {
      if (ampli.isLoaded && user?.id) {
        ampli.identify(user.uuid);
      }
    }, [user, ampli.isLoaded]);
  }
}
