import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

export default {
  root: {
    width: '100%',
  },
  inputRoot: {
    '& fieldset': {
      borderColor: ({ theme }: ThemeFunction<unknown>) =>
        theme.textColorSecondary,
      borderRadius: 4,
    },
  },
  input: {
    border: 'none !important',
    outline: 'none !important',
    color: ({ theme }: ThemeFunction<unknown>) =>
      `${theme.textColor} !important`,
  },
  startAdornment: {
    color: ({
      theme,
      disabled,
    }: ThemeFunction<{
      disabled: boolean;
    }>) => (disabled ? theme.buttonBackgroundDisableColor : theme.textColor),
  },
  endAdornment: {
    top: 'calc(50% - 16px) !important',
    color: ({ theme }: ThemeFunction<unknown>) => theme.textColor,
    '& .MuiButtonBase-root': {
      minWidth: '32px !important',
      minHeight: '32px !important',
    },
  },
};
