import type { BraindateEvent } from '@braindate/domain/lib/event/type';
import {
  getEventFeatures,
  getEventPlugins,
} from '@braindate/domain/lib/event/util';
import { assertObject, assertString } from '@braindate/util/lib/assert';

import featureConfig from 'src/shared/app/feature/config/featureConfig';

export function isPluginActivatedInObject(
  id: string,
  obj: Record<string, any>,
): boolean {
  assertString(id, 'id');
  assertObject(obj, 'obj');
  return Object.entries(obj).some(([key, value]) => {
    return key === id && value;
  });
}
export function isPluginEnabled(id: string, event: BraindateEvent): boolean {
  assertString(id, 'id');
  assertObject(event, 'event');
  return isPluginActivatedInObject(id, {
    ...featureConfig,
    ...getEventPlugins(event),
    ...getEventFeatures(event),
  });
}
