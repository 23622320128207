import { rem } from 'polished';

import { baseFontSize } from '@braindate/ui/lib/token/typography';

import { borderRadius } from 'src/shared/app/base/style/token/box';
// import { cardBaseColor } from "@braindate/ui/lib/token/color";
import { disabledOpacity } from 'src/shared/app/base/style/token/opacity';
import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

export default {
  '@keyframes glow': {
    '0%': {
      opacity: 0.2,
    },
    '50%': {
      opacity: 0.7,
    },
    '100%': {
      opacity: 0.2,
    },
  },
  root: {
    width: '100%',
    position: 'relative',
    maxHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  wrapper: {
    width: '100%',
    position: 'relative',
    zIndex: 1,
    overflowY: 'auto',
    border: ({
      theme,
      withBorder,
    }: ThemeFunction<{
      withBorder: boolean;
    }>) =>
      withBorder ? `1px solid ${theme.braindateCardTimeInactive}` : 'unset',
    backgroundColor: ({
      theme,
      isAccent,
    }: ThemeFunction<{
      isAccent: boolean;
    }>) => (isAccent ? theme.accentColorSoft : theme.colorPrimary),
    borderRadius,
    boxShadow: ({
      theme,
      isAccent,
    }: ThemeFunction<{
      isAccent: boolean;
    }>) => !isAccent && theme.cardShadowBox,
    padding: ({
      withPadding,
    }: ThemeFunction<{
      withPadding: boolean;
    }>) => withPadding && rem(24, baseFontSize),
    overflow: 'hidden',
    '& *:first-child': {
      marginTop: ({
        disableChildrenMargin,
      }: ThemeFunction<{
        disableChildrenMargin: boolean;
      }>) => (disableChildrenMargin ? undefined : 0),
    },
  },
  'root-isFaded': {
    opacity: disabledOpacity,
  },
  'root-isDisabled': {
    opacity: disabledOpacity,
    pointerEvents: 'none',
  },
};
