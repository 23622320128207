import type { Braindate } from '@braindate/domain/lib/braindate/type';
import { getBraindateId } from '@braindate/domain/lib/braindate/util';
import type { Cheer } from '@braindate/domain/lib/cheer/type';
import { getCheerId } from '@braindate/domain/lib/cheer/util/cheerGetters';
import type { User } from '@braindate/domain/lib/user/type';
import { getUserId } from '@braindate/domain/lib/user/util';

import { NOTIFICATION_COMPONENT_KEYS } from 'src/shared/app/notification/constant/notificationComponentsMap';
import {
  notificationBundleTypeCheerPost,
  notificationInfoLevel,
  notificationModalType,
} from 'src/shared/app/notification/constant/notificationConstants';
import type { Notification as NotificationType } from 'src/shared/app/notification/type/notificationTypes';

export const getCheerReceivedNotificationId = (cheer: Cheer): string =>
  `cheer-received-${getCheerId(cheer)}`;
export function buildNewCheerModalNotification(
  user: User,
  braindate?: Braindate,
  postBraindate: boolean = false,
): NotificationType<any> {
  return {
    id: `new-cheer-${getUserId(user)}`,
    level: notificationInfoLevel,
    type: notificationModalType,
    componentKey: NOTIFICATION_COMPONENT_KEYS.NEW_CHEER,
    data: {
      braindate,
      user,
      postBraindate,
    },
    bundleType: notificationBundleTypeCheerPost,
  };
}
export function buildPostBraindateCheerModalNotification(
  braindate: Braindate,
): NotificationType<any> {
  return {
    id: `post-braindate-${getBraindateId(braindate)}`,
    level: notificationInfoLevel,
    type: notificationModalType,
    componentKey: NOTIFICATION_COMPONENT_KEYS.POST_BRAINDATE_CHEER,
    data: {
      braindate,
    },
    bundleType: notificationBundleTypeCheerPost,
  };
}
export function buildCheerRecipientNotification(
  cheers: Cheer[],
): NotificationType<any> {
  return {
    id: getCheerReceivedNotificationId(cheers[0]),
    level: notificationInfoLevel,
    type: notificationModalType,
    componentKey: NOTIFICATION_COMPONENT_KEYS.CHEER_RECIPIENT_MODAL,
    data: cheers,
  };
}
