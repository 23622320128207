import { memo, useEffect } from 'react';
import type { Node } from 'react';

import { Route, Routes, useLocation, useParams } from 'react-router-dom';

import { getRoutes } from 'src/shared/app/base/route/registry/routeRegistry';
import {
  getRoutePath,
  setHttpStatusCode,
} from 'src/shared/app/base/route/util/routeUtils';

const HTTPStatusCodeElement = () => {
  const params = useParams();
  const location = useLocation();
  useEffect(() => {
    if (!location || location === '*') {
      setHttpStatusCode(params, 404);
    }
  }, [params]);
  return null;
};

const HTTPStatusCodeSwitch = (): Node => {
  return (
    <Routes>
      {getRoutes().map((route) => {
        const path = getRoutePath(route);
        return (
          <Route path={path} element={<HTTPStatusCodeElement />} key={path} />
        );
      })}
    </Routes>
  );
};

export default memo(HTTPStatusCodeSwitch);
