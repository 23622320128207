import { toLower } from 'ramda';

import { assertString } from '@braindate/util/lib/assert';

/**
 * Make the first letter of a string uppercase
 * @param  {string} str - String to transform
 * @return {string} Transformed tring
 */
export function capitalize(str: string): string {
  assertString(str, 'str');
  return str.length ? str[0].toUpperCase() + str.slice(1) : '';
}
export function capitalizeWords(str: string): string {
  assertString(str, 'str');
  return str.split(/\s/).map(capitalize).join(' ');
}

/**
 * Get the last character of a string
 * @param  {string} str - String to get the last character from
 * @return {string} Last character
 */
export function getLastChar(str: string): string {
  assertString(str, 'str');
  const { length } = str;
  return length > 0 ? str[length - 1] : '';
}
export function getFirstChar(str: string): string {
  assertString(str, 'str');
  return str.length > 0 ? str[0] : '';
}
export function appendChar(str: string, char: string): string {
  assertString(str, 'str');
  assertString(char, 'char');
  return getLastChar(str) === char ? str : str + char;
}
export function prependChar(str: string, char: string): string {
  assertString(str, 'str');
  assertString(char, 'char');
  return getFirstChar(str) === char ? str : char + str;
}
export function addTrailingSlash(str: string): string {
  return appendChar(str, '/');
}
export function addLeadingSlash(str: string): string {
  return prependChar(str, '/');
}
export function replaceBackSlashes(str: string): string {
  return str.replace(/\\/g, '/');
}
export function removeAccents(str: string): string {
  return str.normalize('NFD').replace(/[^a-zA-Z0-9 ]/g, '');
}

/**
 * String to slug
 * @param {string} str String to slugify
 * @returns {string} Returns a string without accents, spaces or uppercase characters
 */
export function slugify(str: string): string {
  return toLower(removeAccents(str)).replaceAll(' ', '-');
}

/**
 * Truncates a string to a specified maximum length and adds ellipsis (...) to the end if necessary.
 * If the last character of the truncated string is a space, it is removed before adding the ellipsis.
 * @param {string} value - The input string to truncate.
 * @param {number} maxLength - The maximum length of the truncated string before adding the ellipsis.
 * @returns {string} The truncated string, with ellipsis added if necessary.
 */
export function truncateString(value: string, maxLength: number): string {
  if (value.length <= maxLength) return value;
  let truncated = value.substring(0, maxLength - 3);

  if (truncated[truncated.length - 1] === ' ') {
    truncated = truncated.substring(0, truncated.length - 1);
  }

  return `${truncated}...`;
}
