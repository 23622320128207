import { useDispatch } from 'react-redux';

import type { Braindate } from '@braindate/domain/lib/braindate/type';
import {
  getBraindateId,
  isBraindateCancelled,
} from '@braindate/domain/lib/braindate/util';
import type { Cheer } from '@braindate/domain/lib/cheer/type';
import { getEventSocketURL } from '@braindate/domain/lib/event/util';
import { areMembershipSlackNotificationsEnabled } from '@braindate/domain/lib/membership/util';
import {
  topicModerationRejectedStatus,
  topicModerationVerifiedStatus,
} from '@braindate/domain/lib/topic/setting';
import type { TopicModeration } from '@braindate/domain/lib/topic/type';

import { invalidateTags } from 'src/shared/app/base/api/actions/apiActions';
import { TAG_TYPE } from 'src/shared/app/base/api/apiConstant';
import {
  addPhoneNumberReminder,
  braindateChanged,
  braindateCheckIn,
  braindateCreated,
  braindateEarlyReminder,
  braindateFollowup,
  braindateReminder,
  cheersReceived,
  engagement,
  fishbowlEarlyReminder,
  fishbowlReminder,
  groupBraindateEarlyReminder,
  groupBraindateReminder,
  moderationState,
  notificationCountUpdate,
} from 'src/shared/app/base/constant/socketConstants';
import useSocket from 'src/shared/app/base/hook/useSocket';
import { showCheckInReminder } from 'src/shared/app/check-in/action/uiCheckInActions';
import { getBraindateReminderNotificationId } from 'src/shared/app/check-in/util/checkInUtil';
import { showCheerRecipientModal } from 'src/shared/app/cheer/action/cheerAction';
import useFeatureDecisions from 'src/shared/app/feature/hook/useFeatureDecisions';
import {
  showModerationAcceptedAndSlackBanner,
  showModerationAcceptedBanner,
  showModerationRejectedBanner,
} from 'src/shared/app/moderation/action/moderationActions';
import { dismissNotification } from 'src/shared/app/notification/action/uiNotificationActions';
import { setUnreadNotificationsCount } from 'src/shared/app/notification/reducer/slices/appUnreadCountNotificationSlice';
import { showPostBraindateFeedback } from 'src/shared/app/post-braindate/action/postBraindateActions';
import { showEngagementModal } from 'src/shared/app/topic/new/action/appNewTopicActions';
import showPostSMSPhoneReminder from 'src/shared/components/domain/user/modals/actions/addPhoneReminderActions';
import useEvent from 'src/shared/domain/event/hook/useEvent';
import useIsSelfCheckinEnabled from 'src/shared/domain/event/hook/useIsSelfCheckinEnabled';
import useMembership from 'src/shared/domain/membership/hook/useMembership';

import { showVirtualBraindateEarlyReminder } from 'plugin/virtual-braindate/action/uiVirtualBraindateActions';
import useVirtualBraindate from 'plugin/virtual-braindate/hook/useVirtualBraindate';

export function useDispatchNotification() {
  const membership = useMembership();
  const dispatch = useDispatch();
  const isVirtualBraindateEnabled = useVirtualBraindate();
  const { isSlackIntegrationEnabled } = useFeatureDecisions();
  const isSelfCheckInEnabled = useIsSelfCheckinEnabled();

  const handleNotification = ({
    trigger,
    data,
  }: {
    trigger: string;
    data: Record<string, any>;
  }) => {
    switch (trigger) {
      case notificationCountUpdate:
        dispatch(setUnreadNotificationsCount(data.value));
        dispatch(
          invalidateTags([
            {
              type: TAG_TYPE.NOTIFICATION,
              id: 'LIST',
            },
            {
              type: TAG_TYPE.BRAINDATE,
              id: 'LIST',
            },
          ]),
        );
        break;

      case braindateCreated: {
        // State update
        dispatch(invalidateTags(TAG_TYPE.BRAINDATE, 'LIST'));
        break;
      }

      case braindateChanged: {
        const braindate = data as Braindate;
        // State update
        dispatch(invalidateTags(TAG_TYPE.BRAINDATE, getBraindateId(braindate)));

        // Discard check-in notification if braindate was cancelled
        if (isBraindateCancelled(braindate)) {
          dispatch(
            dismissNotification(getBraindateReminderNotificationId(braindate)),
          );
        }

        break;
      }

      case fishbowlReminder:
      case groupBraindateReminder:
      case braindateReminder: {
        const braindate: Braindate = data as Braindate;
        const currentLocation = window
          ? `${window.location.pathname}${window.location.search}`
          : '';
        const isInConversation = currentLocation.includes(
          `braindates/${getBraindateId(braindate)}/?section=conversation`,
        );

        if (
          (isSelfCheckInEnabled || isVirtualBraindateEnabled) &&
          !isInConversation
        ) {
          dispatch(
            invalidateTags(TAG_TYPE.BRAINDATE, getBraindateId(braindate)),
          );
          dispatch(showCheckInReminder(braindate, isSelfCheckInEnabled));
        }

        break;
      }

      // Every early reminder are only for virtual braindates
      case braindateEarlyReminder: {
        const braindate: Braindate = data as Braindate;

        if (isVirtualBraindateEnabled) {
          dispatch(
            invalidateTags(TAG_TYPE.BRAINDATE, getBraindateId(braindate)),
          );
          dispatch(showVirtualBraindateEarlyReminder(braindate));
        }

        break;
      }

      case groupBraindateEarlyReminder: {
        const braindate: Braindate = data as Braindate;

        if (isVirtualBraindateEnabled) {
          dispatch(
            invalidateTags(TAG_TYPE.BRAINDATE, getBraindateId(braindate)),
          );
          dispatch(showVirtualBraindateEarlyReminder(braindate));
        }

        break;
      }

      case fishbowlEarlyReminder: {
        const braindate: Braindate = data as Braindate;
        dispatch(invalidateTags(TAG_TYPE.BRAINDATE, getBraindateId(braindate)));
        dispatch(showVirtualBraindateEarlyReminder(braindate));
        break;
      }

      // Someone else has checked in
      case braindateCheckIn: {
        const braindate = data as Braindate;
        dispatch(invalidateTags(TAG_TYPE.BRAINDATE, getBraindateId(braindate)));
        break;
      }

      case moderationState: {
        const { state, topic } = data as TopicModeration;
        // If the topic status is rejected, we show the banner and patch the topic status and reason
        // If the topic is accepted, we check is slack is enabled to display the proper notification:
        // Posted or posted and shared on slack
        dispatch(invalidateTags(TAG_TYPE.TOPIC, topic));

        if (state === topicModerationRejectedStatus) {
          dispatch(showModerationRejectedBanner());
        } else if (state === topicModerationVerifiedStatus && membership) {
          if (
            isSlackIntegrationEnabled &&
            areMembershipSlackNotificationsEnabled(membership)
          ) {
            dispatch(showModerationAcceptedAndSlackBanner());
          } else {
            dispatch(showModerationAcceptedBanner());
          }
        }

        break;
      }

      case braindateFollowup: {
        const braindate = data as Braindate;
        dispatch(showPostBraindateFeedback(braindate));
        break;
      }

      case engagement: {
        const engagementType = data as string;
        dispatch(showEngagementModal(engagementType));
        break;
      }

      case cheersReceived: {
        const { cheers } = data as {
          cheers: Cheer[];
        };
        dispatch(showCheerRecipientModal(cheers));
        break;
      }

      case addPhoneNumberReminder: {
        dispatch(showPostSMSPhoneReminder());
        break;
      }

      default:
        break;
    }
  };

  return handleNotification;
}
export default function useGlobalNotifications() {
  const event = useEvent();
  const handleNotification = useDispatchNotification();
  // FIXME: don't hardcode `urls`
  const url = 'urls' in event ? getEventSocketURL(event) : '';
  useSocket(url, handleNotification);
}
