import { rem } from 'polished';

import { lap } from '@braindate/ui/lib/token/mediaQuery';
import { baseFontSize } from '@braindate/ui/lib/token/typography';

import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

import { getReadableColor } from 'plugin/light/util/lightUtils';

export default {
  root: {
    border: ({ theme }: ThemeFunction<unknown>) =>
      `1px solid ${theme.accentColor}`,
    color: ({ theme }: ThemeFunction<unknown>) => theme.accentColor,
    display: 'block',
    backgroundColor: 'transparent',
    padding: [17, 15, 17, 15],
    borderRadius: 5,
    width: '100%',
    [lap]: {
      width: 'max-content',
    },
    '&:hover': {
      color: ({ theme }: ThemeFunction<unknown>) =>
        getReadableColor(theme.accentColor),
      backgroundColor: ({ theme }: ThemeFunction<unknown>) => theme.accentColor,
    },
  },
  container: {
    display: 'flex',
    gap: '10px',
    alignItems: 'center',
    '& > svg': {
      width: '17px',
      height: '17px',
    },
    '& > span': {
      fontWeight: 600,
      fontSize: rem(16, baseFontSize),
      lineHeight: rem(19, baseFontSize),
    },
  },
  subtitle: {
    display: 'block',
    fontWeight: 500,
    fontSize: rem(12, baseFontSize),
    lineHeight: rem(20, baseFontSize),
    paddingLeft: '27px',
    opacity: 0.8,
  },
};
