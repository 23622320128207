import { overlayColor } from '@braindate/ui/lib/token/color';

import { dialogElevation } from 'src/shared/app/base/style/token/elevation';

export const fadeDuration = 500;
export default {
  html: {
    overflow: 'hidden',
  },
  body: {
    overflow: 'hidden',
  },
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: dialogElevation,
    backgroundColor: overlayColor,
    opacity: 0,
    transition: ['opacity', `${fadeDuration}ms`, 'ease-in-out'],
  },
  'overlay-afterOpen': {
    opacity: 1,
  },
  'overlay-beforeClose': {
    opacity: 0,
  },
};
