import { memo } from 'react';
import type { Node } from 'react';

import classNames from 'classnames';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import {
  getEventLogo,
  getEventLogoAltText,
} from '@braindate/domain/lib/event/util';
import { getOriginalPicture } from '@braindate/util/lib/image';

import dateMessages from 'src/shared/app/base/date/l10n/dateL10n';
import useRoutes from 'src/shared/app/base/route/hook/useRoutes';
import { homeRoute } from 'src/shared/app/base/route/setting/routeSettings';
import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import messages from 'src/shared/app/nav/l10n/navigationL10n';
import useEvent from 'src/shared/domain/event/hook/useEvent';
import BraindateBrandedLogoIcon from 'src/shared/ui/component/icon/BraindateBrandedLogoIcon';
import ResponsivePicture from 'src/shared/ui/component/image/ResponsivePicture';

import styles from './EventBranding.style';

type Props = {
  classes?: {
    root?: string;
    logo?: string;
  };
  onClick?: () => void;
};
const useStyles = createUseThemeStyles(styles);

const EventBranding = ({ classes: customClasses, onClick }: Props): Node => {
  const intl = useIntl();
  const event = useEvent();
  const classes = useStyles();
  const logo = getEventLogo(event, intl.locale);
  const logoAltText = getEventLogoAltText(event, intl.locale);
  const [homePath] = useRoutes(homeRoute);
  const hasLogo = !!logo && !!getOriginalPicture(logo);
  return (
    <Link
      className={classNames(classes.root, customClasses?.root)}
      to={homePath}
      aria-label={intl.formatMessage(messages.homePage)}
      onClick={onClick}
    >
      {hasLogo ? (
        <div className={classes.logoContainer}>
          <span className={classes.eventName}>
            <span className={classes.braindate}>Braindate</span>
            <sup>TM</sup> <i>{intl.formatMessage(dateMessages.at)}</i>
          </span>

          <ResponsivePicture
            className={classNames(classes.logo, customClasses?.logo)}
            picture={logo}
            alt={logoAltText}
          />
        </div>
      ) : (
        <BraindateBrandedLogoIcon />
      )}
    </Link>
  );
};

export default memo<Props>(EventBranding);
