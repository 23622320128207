import { feedGetSponsorToInsert } from 'src/shared/ui/base/util/feedUtils';

type Props<T> = {
  renderItem: (item: T, previousItem?: T) => Node;
  previousItem?: T;
  page: number;
  item: T;
  index: number;
  interstitialComponents?: Array<Node>;
  interstitialIndexes?: Array<number>;
};

function FeedQueryPageItem<T>({
  renderItem,
  previousItem,
  item,
  page,
  index,
  interstitialComponents,
  interstitialIndexes,
}: Props<T>) {
  const interstitialComponent = feedGetSponsorToInsert(
    index + (page - 1) * 10,
    interstitialIndexes,
    interstitialComponents,
  );
  return (
    <>
      {interstitialComponent && (
        <li data-testid="feed-item">{interstitialComponent}</li>
      )}
      <li data-testid="feed-item">{renderItem(item, previousItem)}</li>
    </>
  );
}

export default FeedQueryPageItem;
