import { createElement } from 'react';
import type { Node } from 'react';

import { Route, Routes, useLocation } from 'react-router-dom';

import MainSwitch from 'src/shared/app/base/component/switch/MainSwitch';
import useReduxState from 'src/shared/app/base/hook/useReduxState';
import useRouteConfig from 'src/shared/app/base/route/hook/useRouteConfig';
import { getRoutes } from 'src/shared/app/base/route/registry/routeRegistry';
import {
  getRoutePath,
  getRouteRedirect,
  getRouteTemplate,
} from 'src/shared/app/base/route/util/routeUtils';

const TemplateSwitch = (): Node => {
  /*
   |----------------------------------------------------------------------------
   | Hooks
   |----------------------------------------------------------------------------
   */
  const state = useReduxState();
  const config = useRouteConfig();
  const location = useLocation();

  /*
   |----------------------------------------------------------------------------
   | Elements
   |----------------------------------------------------------------------------
   */
  return (
    <Routes>
      {getRoutes().map((route) => {
        const path = getRoutePath(route);

        const element = () => {
          if (getRouteRedirect(route, state, config, location)) {
            // `RedirectSwitch` is instanciated before `TemplateSwitch`, but the
            // latter can somehow still be rendered before the redirection
            // happens. To prevent any exception to be thrown, we render null
            // if we know there's a redirection
            return null;
          }

          return createElement(getRouteTemplate(route, state, config), {
            Switch: MainSwitch,
          });
        };

        return (
          <Route
            path={path}
            element={element()} // Use the same key to prevent re-renders when routes share the
            // same template
            key={1}
          />
        );
      })}
    </Routes>
  );
};

export default TemplateSwitch;
