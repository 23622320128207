import { memo } from 'react';
import type { Node } from 'react';

import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import type { Braindate } from '@braindate/domain/lib/braindate/type';
import {
  getBraindateLocation,
  getBraindatePath,
} from '@braindate/domain/lib/braindate/util';
import { isVirtualLocation } from '@braindate/domain/lib/location/util';

import { useBraindateCheckInMutation } from 'src/shared/app/base/api/endpoint/braindatesEndpoint';
import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import CheckInButton from 'src/shared/app/check-in/component/CheckInButton';
import styles from 'src/shared/app/check-in/component/notification/CheckInActionButton.style';
import messages from 'src/shared/app/check-in/l10n/checkInL10n';
import { isBraindateCheckInReady } from 'src/shared/domain/braindate/util/braindateUtil';
import CameraIcon from 'src/shared/ui/component/icon/CameraIcon';
import LinkButton from 'src/shared/ui/component/link/LinkButton';

import { forceVideoOverlay } from 'plugin/virtual-braindate/slice/virtualBraindateSlice';

type Props = {
  braindate: Braindate;
  onClick: () => void;
};
const useStyles = createUseThemeStyles(styles);

const CheckInActionButton = ({ onClick, braindate }: Props): Node => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const location = getBraindateLocation(braindate);
  const isVirtual = location && isVirtualLocation(location);
  const classes = useStyles();
  const isCheckInReady = isBraindateCheckInReady(braindate);
  const [, { isLoading: isCheckingIn }] = useBraindateCheckInMutation();

  const handleLinkClick = () => {
    if (onClick) {
      onClick();
    }

    dispatch(forceVideoOverlay(true));
  };

  return isVirtual ? (
    <LinkButton
      layoutClass={classes.join}
      onClick={handleLinkClick}
      isSubmitting={isCheckingIn}
      isDisabled={!isCheckInReady}
      to={getBraindatePath(braindate)}
    >
      <CameraIcon className={classes.joinIcon} />
      {intl.formatMessage(messages.joinVirtual)}
    </LinkButton>
  ) : (
    <CheckInButton onClick={onClick} braindate={braindate} />
  );
};

export default memo<Props>(CheckInActionButton);
