import { memo } from 'react';
import type { Node } from 'react';

import ChevronIcon from './ChevronIcon';

import type { Props } from './ChevronIcon';

const ChevronIconDown = (props: Props): Node => <ChevronIcon {...props} down />;

export default memo<Props>(ChevronIconDown);
