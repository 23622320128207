import type { Node } from 'react';

import { Slide } from '@mui/material';

import useHideOnScroll from 'src/shared/ui/context/HideOnScrollContext/hook/useHideOnScroll';

type Props = {
  children: Node;
  dir?: 'down' | 'up';
  ignoreFeatureFlag?: boolean;
};

const HideOnScroll = ({
  children,
  dir = 'down',
  ignoreFeatureFlag = false,
}: Props) => {
  const isScrolled = useHideOnScroll(ignoreFeatureFlag);
  return (
    <Slide appear={false} direction={dir} in={!isScrolled}>
      {children}
    </Slide>
  );
};

export default HideOnScroll;
