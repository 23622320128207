import { lighten } from 'polished';

export const whitish = '#f9f9f9';
export const black100 = '#000000';
export const black75 = lighten(0.25, black100);
export const black65 = lighten(0.35, black100);
export const black55 = lighten(0.45, black100);
export const black45 = lighten(0.55, black100);
export const black35 = lighten(0.65, black100);
export const black25 = lighten(0.75, black100);
export const black15 = lighten(0.85, black100);
export const black5 = lighten(0.95, black100);
export const lightYellow = '#f7edbb';
export const lightRed = '#fde3df';
export const lightGreen = '#e1f4dd';
