import { DateTime, Settings } from 'luxon';

import type { Braindate } from '@braindate/domain/lib/braindate/type';
import {
  getBraindateCheckInStatus,
  getBraindateConversation,
  getBraindateId,
  getBraindateStartTime,
} from '@braindate/domain/lib/braindate/util';
import type { ConversationParticipant } from '@braindate/domain/lib/conversation/type';
import { getConversationParticipants } from '@braindate/domain/lib/conversation/util';
import type { User } from '@braindate/domain/lib/user/type';
import { getUserId } from '@braindate/domain/lib/user/util';
import { assertObject } from '@braindate/util/lib/assert';

import { takeOverMinutesToBraindate } from 'src/shared/app/check-in/settings/checkInSettings';
import { NOTIFICATION_COMPONENT_KEYS } from 'src/shared/app/notification/constant/notificationComponentsMap';
import {
  notificationInfoLevel,
  notificationModalType,
  notificationTakeOverType,
  notificationTopType,
} from 'src/shared/app/notification/constant/notificationConstants';
import type { Notification } from 'src/shared/app/notification/type/notificationTypes';

export function getCheckedInParticipants(
  braindate: Braindate,
): Array<ConversationParticipant> {
  const conversation = getBraindateConversation(braindate);
  const participants = getConversationParticipants(conversation);
  const checkedInState = getBraindateCheckInStatus(braindate) || [];
  return participants.filter((user) =>
    checkedInState.find((data) => data.id === getUserId(user)),
  );
}
export function getMissingParticipants(
  braindate: Braindate,
): Array<ConversationParticipant> {
  const conversation = getBraindateConversation(braindate);
  const participants = getConversationParticipants(conversation);
  const checkedInState = getBraindateCheckInStatus(braindate) || [];
  return participants.filter(
    (user) => !checkedInState.find((data) => data.id === getUserId(user)),
  );
}
export function getCheckedInOtherParticipants(
  braindate: Braindate,
  selfUser: User,
): Array<ConversationParticipant> {
  return getCheckedInParticipants(braindate).filter(
    (user) => getUserId(user) !== getUserId(selfUser),
  );
}
export function getMissingOtherParticipants(
  braindate: Braindate,
  selfUser: User,
): Array<ConversationParticipant> {
  return getMissingParticipants(braindate).filter(
    (user) => getUserId(user) !== getUserId(selfUser),
  );
}
export function hasUserCheckedIn(braindate: Braindate, user: User): boolean {
  const status = getBraindateCheckInStatus(braindate) || [];
  return !!status.find((data) => data.id === getUserId(user));
}
export function hasSomeoneCheckedIn(
  braindate: Braindate,
  selfUser: User,
): boolean {
  assertObject(braindate, 'braindate');
  assertObject(selfUser, 'selfUser');
  const checkedInPax = getCheckedInOtherParticipants(braindate, selfUser) || [];
  return !!checkedInPax.length;
}
export function getBraindateReminderNotificationId(
  braindate: Braindate,
): string {
  return `braindate-reminder-${getBraindateId(braindate)}`;
}
export function showCheckInNotification(braindate: Braindate): boolean {
  const startTime = getBraindateStartTime(braindate);
  const startDateTime = DateTime.fromISO(startTime, {
    zone: Settings.defaultZone,
  });
  const takeOverDateTime = startDateTime.minus({
    minutes: takeOverMinutesToBraindate,
  });
  return takeOverDateTime.diffNow().milliseconds > 0;
}
export function convertBraindateReminderToNotification(
  braindate: Braindate,
  isSelfCheckInEnabled: boolean,
): Notification<Braindate> {
  const showNotification = showCheckInNotification(braindate);

  const getNotificationProps = () => {
    if (showNotification) {
      return {
        type: notificationTopType,
        componentKey: NOTIFICATION_COMPONENT_KEYS.CHECK_IN_NOTIFICATION,
      };
    }

    if (isSelfCheckInEnabled) {
      return {
        type: notificationModalType,
        componentKey: NOTIFICATION_COMPONENT_KEYS.CHECK_IN_MODAL,
      };
    }

    return {
      type: notificationTakeOverType,
      componentKey: NOTIFICATION_COMPONENT_KEYS.CHECK_IN_TAKEOVER,
    };
  };

  return {
    id: getBraindateReminderNotificationId(braindate),
    level: notificationInfoLevel,
    data: braindate,
    exclude: ['braindatesRoute', 'braindateRoute:id'],
    ...getNotificationProps(),
  };
}
