import { TAG_TYPE } from 'src/shared/app/base/api/apiConstant';
import { apiRoot } from 'src/shared/app/base/api/apiRoot';
import type { EntityIdType } from 'src/shared/domain/base/action/entityActions';

import type { Dispatch } from 'react-redux';

export const invalidateTags = (
  type:
    | string
    | {
        type: string;
        id?: EntityIdType;
      }[],
  id?: 'LIST' | 'me' | number,
) => {
  if (id === 'me' && type !== TAG_TYPE.USER) {
    throw new Error('Invalid tag type for id "me"');
  }

  const invalidate = apiRoot.util.invalidateTags;
  return (dispatch: Dispatch) => {
    if (Array.isArray(type)) {
      return dispatch(invalidate(type));
    }

    if (id) {
      return dispatch(
        invalidate([
          {
            type,
            id,
          },
        ]),
      );
    }

    return dispatch(invalidate([type]));
  };
};
