import { lap } from '@braindate/ui/lib/token/mediaQuery';

import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

export const SMALL_SIDEBAR_WIDTH = 202;
export const REGULAR_SIDEBAR_WIDTH = 313;
export const REGULAR_SIDEBAR_WIDTH_WITH_PADDING = REGULAR_SIDEBAR_WIDTH + 20;
type StylesType = ThemeFunction<{
  small: boolean;
  shown: boolean;
}>;
export default {
  root: {
    display: 'none',
    [lap]: {
      display: ({ shown }: StylesType) => (shown ? 'block' : 'none'),
      position: 'relative',
    },
  },
  inner: {
    display: 'flex',
    flexDirection: 'column',
    width: REGULAR_SIDEBAR_WIDTH,
    marginLeft: 20,
  },
  createNewTopic: {
    marginBottom: 20,
  },
};
