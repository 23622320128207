import { css } from '@emotion/react';
import { em } from 'polished';

const styles = () => ({
  root: css({
    fontSize: em(17),
    width: em(28),
    height: em(28),
  }),
});

export default styles;
