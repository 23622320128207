import { rem } from 'polished';

import { overlayColor } from '@braindate/ui/lib/token/color';
import { phablet } from '@braindate/ui/lib/token/mediaQuery';
import { baseFontSize } from '@braindate/ui/lib/token/typography';

import { borderRadius } from 'src/shared/app/base/style/token/box';
import { dialogElevation } from 'src/shared/app/base/style/token/elevation';
import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

export const fadeDuration = 500;
export default {
  '@keyframes enter': {
    from: {
      transform: 'translateY(100px)',
    },
    to: {
      transform: 'translateY(0px)',
    },
  },
  '@keyframes exit': {
    from: {
      transform: 'translateY(0)',
    },
    to: {
      transform: 'translateY(100px)',
    },
  },
  html: {
    overflow: 'hidden',
  },
  body: {
    overflow: 'hidden',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    height: 'max-content',
    width: ({ width }: Record<string, any>) => width || '90%',
    maxWidth: 1000,
    padding: [rem(50, baseFontSize), rem(40, baseFontSize)],
    maxHeight: 'calc(100% - 20px)',
    color: ({ theme }: ThemeFunction<unknown>) => theme.textColor,
    backgroundColor: ({ theme }: ThemeFunction<unknown>) => theme.colorPrimary,
    borderRadius,
    transform: 'translateY(0)',
    overflow: 'auto',
  },
  contentEntering: {
    animation: '$enter 0.4s',
  },
  contentExiting: {
    animation: '$exit 0.4s',
    transform: 'translateY(100px)',
  },
  overlay: {
    display: 'flex',
    justifyContent: 'center',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: dialogElevation,
    backgroundColor: overlayColor,
    opacity: 0,
    transition: ['opacity', `${fadeDuration}ms`, 'ease-in-out'],
    [phablet]: {
      alignItems: 'center',
    },
  },
  'overlay-afterOpen': {
    opacity: 1,
  },
  'overlay-beforeClose': {
    opacity: 0,
  },
};
