import type { Node } from 'react';

import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { getEventName } from '@braindate/domain/lib/event/util';

import messages from 'src/shared/app/moderation/l10n/moderationL10n';
import { dismissNotification } from 'src/shared/app/notification/action/uiNotificationActions';
import Notification from 'src/shared/app/notification/component/Notification';
import type { Notification as NotificationType } from 'src/shared/app/notification/type/notificationTypes';
import useEvent from 'src/shared/domain/event/hook/useEvent';

import { getSlackChannelName } from 'plugin/slack/util/slackUtils';

type Props = {
  notification: NotificationType<any>;
};

const ModerationAcceptedAndSlackNotificationDumb = ({
  notification,
}: Props): Node => {
  const intl = useIntl();
  const event = useEvent();
  const dispatch = useDispatch();
  const eventName = getEventName(event, intl.locale);
  const slackChannelName = getSlackChannelName(event);

  const handleDismiss = () => {
    dispatch(dismissNotification(notification));
  };

  return (
    <Notification timeout={10000} handleDismiss={handleDismiss}>
      {intl.formatMessage(messages.moderationAcceptedAndSlackNotification, {
        eventName,
        slackChannelName,
      })}
    </Notification>
  );
};

export default ModerationAcceptedAndSlackNotificationDumb;
