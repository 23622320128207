import React, { useEffect, useState } from 'react';

import { HomeOutlined } from '@mui/icons-material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import NotificationsIcon from '@mui/icons-material/NotificationsOutlined';
import UserIcon from '@mui/icons-material/Person2Outlined';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import { useMediaQuery } from '@mui/material';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';

import { isManualAvailabilityEnabled } from '@braindate/domain/lib/event/util';
import { lap } from '@braindate/ui/lib/token/mediaQuery';

import { ampli } from 'src/ampli';
import { useGetPendingBraindatesQuery } from 'src/shared/app/base/api/endpoint/braindatesEndpoint';
import useRoutes from 'src/shared/app/base/route/hook/useRoutes';
import {
  availabilityRoute,
  cheersRoute,
  confirmedBraindatesRoute,
  homeRoute,
  newTopicRoute,
  notificationsPreferencesRoute,
  ownTopicsRoute,
  pendingBraindatesRoute,
  peopleFeedRoute,
  preferencesRoute,
  profileEditRoute,
  profileRoute,
} from 'src/shared/app/base/route/setting/routeSettings';
import { getUrlToHomePagePlatform } from 'src/shared/app/base/settings/appSettings';
import useFeatureDecisions from 'src/shared/app/feature/hook/useFeatureDecisions';
import {
  hideHelpButton,
  resetHelpButtonHidden,
} from 'src/shared/app/help/action/uiHelpButtonReducerActions';
import HelpPopover from 'src/shared/app/help/component/HelpPopover';
import DesktopDrawerMenu from 'src/shared/app/nav/component/drawermenu/DesktopDrawerMenu';
import MobileDrawerMenu from 'src/shared/app/nav/component/drawermenu/MobileDrawerMenu';
import useCustomMenuItems from 'src/shared/app/nav/hook/useCustomMenuItems';
import messages from 'src/shared/app/nav/l10n/navigationL10n';
import { userEditInterestsSectionId } from 'src/shared/components/domain/user/constants/userConstants';
import useEvent from 'src/shared/domain/event/hook/useEvent';
import CalendarIcon from 'src/shared/ui/component/icon/CalendarIcon2';
import CheerIcon from 'src/shared/ui/component/icon/CheerIcon';
import CogIcon from 'src/shared/ui/component/icon/CogIcon';
import InterestsIcon from 'src/shared/ui/component/icon/InterestsIcon';
import MarketNavItemIcon from 'src/shared/ui/component/icon/MarketNavItemIcon';
import MyTopicsIcon from 'src/shared/ui/component/icon/MyTopicsIcon';

export type DrawerMenuItemType = {
  icon:
    | React.ReactNode
    | {
        src: string;
        alt: string;
      };
  title: string;
  subtitle?: string;
  to?: string;
  href?: string;
  hash?: string;
  match?: string[];
  target?: string;
  mobileOnly?: boolean;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
};

const DrawerMenu = () => {
  const intl = useIntl();
  const event = useEvent();
  const dispatch = useDispatch();
  const isDesktop = useMediaQuery(lap);
  const { pathname } = useLocation();
  const selectHomeUrl = useSelector(getUrlToHomePagePlatform);
  const [isMenuOpened, setIsMenuOpened] = useState<boolean>(false);
  const [isHelpMenuOpened, setIsHelpMenuOpened] = useState<boolean>(false);
  const { isGoogleSyncEnabled, isOutlookSyncEnabled, isDrawerMenuEnabled } =
    useFeatureDecisions();
  const customMenuItems = useCustomMenuItems();
  const { data } = useGetPendingBraindatesQuery({
    limit: 1,
  });
  const hasPendingBraindates = data?.count || 0;
  useEffect(() => {
    if (isHelpMenuOpened) {
      dispatch(hideHelpButton());
    } else {
      dispatch(resetHelpButtonHidden());
    }
  }, [isHelpMenuOpened]);
  const [
    homePath,
    newTopicPath,
    peopleFeedPath,
    pendingBraindatesPath,
    confirmedBraindatesPath,
    preferencesPath,
    profilePath,
    ownTopicsPath,
    profileEditPath,
    availabilityPath,
    notificationsPreferencesPath,
    cheersPath,
  ] = useRoutes(
    homeRoute,
    newTopicRoute,
    peopleFeedRoute,
    pendingBraindatesRoute,
    confirmedBraindatesRoute,
    preferencesRoute,
    profileRoute,
    ownTopicsRoute,
    profileEditRoute,
    availabilityRoute,
    notificationsPreferencesRoute,
    cheersRoute,
  );
  const braindatesPath = hasPendingBraindates
    ? pendingBraindatesPath
    : confirmedBraindatesPath;
  useEffect(() => {
    setIsMenuOpened(false);
    setIsHelpMenuOpened(false);
  }, [pathname]);
  const displayNavOptions = !isDesktop && isDrawerMenuEnabled;
  const menuItems: DrawerMenuItemType[] = [
    ...(displayNavOptions
      ? [
          {
            icon: <AddCircleOutlineIcon />,
            title: intl.formatMessage(messages.newTopic),
            to: newTopicPath,
            onClick: () => {
              ampli.topicCreationStart({
                Origin: 'Drawer Menu',
              });
            },
          },
          {
            icon: <MarketNavItemIcon />,
            title: intl.formatMessage(messages.market),
            to: homePath,
            match: [peopleFeedPath],
          },
          {
            icon: <CalendarIcon />,
            title: intl.formatMessage(messages.myBraindates),
            to: braindatesPath,
          },
        ]
      : []),
    {
      icon: <UserIcon />,
      title: intl.formatMessage(messages.myProfile),
      to: profilePath,
      mobileOnly: true,
    },
    ...(event.show_navigation
      ? [
          {
            icon: <HomeOutlined />,
            title: intl.formatMessage(messages.home),
            href: selectHomeUrl,
          },
        ]
      : []),
    {
      icon: <CheerIcon />,
      title: intl.formatMessage(messages.cheers),
      to: cheersPath,
    },
    {
      icon: <MyTopicsIcon />,
      title: intl.formatMessage(messages.myTopics),
      to: ownTopicsPath,
    },
    {
      icon: <InterestsIcon />,
      title: intl.formatMessage(messages.myInterests),
      to: `${profileEditPath}#${userEditInterestsSectionId}`,
    },
    ...(isGoogleSyncEnabled ||
    isOutlookSyncEnabled ||
    isManualAvailabilityEnabled(event)
      ? [
          {
            icon: <AccessTimeIcon />,
            title: intl.formatMessage(messages.myAvailabilities),
            to: availabilityPath,
          },
        ]
      : []),
    ...customMenuItems,
    ...(displayNavOptions
      ? [
          {
            icon: <SupportAgentIcon />,
            title: intl.formatMessage(messages.helpFaq),
            onClick: () => {
              setIsMenuOpened(false);
              setIsHelpMenuOpened(true);
            },
          },
        ]
      : []),
    {
      icon: <NotificationsIcon />,
      title: intl.formatMessage(messages.notificationsSettings),
      to: notificationsPreferencesPath,
    },
    {
      icon: <CogIcon />,
      title: intl.formatMessage(messages.accountSettings),
      to: preferencesPath,
    },
  ];

  if (isDesktop) {
    return (
      <DesktopDrawerMenu
        isMenuOpened={isMenuOpened}
        setIsMenuOpened={setIsMenuOpened}
        menuItems={menuItems}
      />
    );
  }

  return (
    <>
      <MobileDrawerMenu
        menuItems={menuItems}
        isMenuOpened={isMenuOpened}
        setIsMenuOpened={setIsMenuOpened}
      />

      <HelpPopover
        open={isHelpMenuOpened}
        onClose={() => setIsHelpMenuOpened(false)}
      />
    </>
  );
};

export default DrawerMenu;
