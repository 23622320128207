import { memo } from 'react';
import type { Node } from 'react';

import { ErrorMessage, Field } from 'formik';

import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import Checkbox from 'src/shared/ui/component/form/Checkbox';

import styles from './FormikCheckbox.style';

type Props = {
  name: string;
  value?: string;
  text: string;
  icon?: Node;
  onChange?: (value: boolean) => void;
  'aria-describedby'?: string;
};
const useStyles = createUseThemeStyles(styles);

const FormikCheckbox = ({
  value,
  name,
  text,
  icon,
  onChange,
  'aria-describedby': ariaDescribedby,
}: Props): Node => {
  const classes = useStyles();
  const errorId = `${name}-error`;
  return (
    <>
      <Field name={name} value={value}>
        {({ field }) => (
          <Checkbox
            {...field}
            onChange={(e) =>
              onChange ? onChange(e.target.checked) : field.onChange(e)
            }
            checked={field.checked}
            text={text}
            icon={icon}
            aria-describedby={ariaDescribedby}
          />
        )}
      </Field>

      <ErrorMessage name={name}>
        {(e) => (
          <div className={classes.error} aria-live="assertive" id={errorId}>
            {e}
          </div>
        )}
      </ErrorMessage>
    </>
  );
};

export default memo<Props>(FormikCheckbox);
