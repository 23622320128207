import * as types from 'src/shared/app/help/action/uiHelpButtonReducerActionsTypes';
import { createReducer } from 'src/shared/core/util/reducerUtils';

const initialState = {
  hideButton: false,
};
export type UiHelpButtonReducerStateType = typeof initialState;

const _setButtonHidden = (state: UiHelpButtonReducerStateType) => ({
  ...state,
  hideButton: true,
});

const _resetButtonHidden = (state: UiHelpButtonReducerStateType) => ({
  ...state,
  hideButton: false,
});

export default createReducer(initialState, {
  [types.HIDE_HELP_BUTTON]: _setButtonHidden,
  [types.RESET_HIDE_HELP_BUTTON]: _resetButtonHidden,
});
