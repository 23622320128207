import { memo } from 'react';
import type { Node } from 'react';

import classNames from 'classnames';

import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import ChevronIcon from 'src/shared/ui/component/icon/ChevronIcon';

import BaseLink from './BaseLink';
import styles from './LinkWithChevron.style';

type Props = {
  href?: string | null;
  state?: Record<string, any>;
  children: Node;
  layoutClass?: string;
  onClick?: ((arg0: Event) => void) | null | undefined;
  handleMouseOver?: () => void;
  inNewWindow?: boolean;
  'aria-haspopup'?: (string | null | undefined) | boolean;
};
const useStyles = createUseThemeStyles(styles);

const LinkWithChevron = ({
  href,
  state,
  children,
  layoutClass,
  onClick,
  handleMouseOver,
  inNewWindow,
  'aria-haspopup': ariaHaspopup,
}: Props): Node => {
  /*
   |------------------------------------------------------------------------------
   | Hooks
   |------------------------------------------------------------------------------
   */

  /*
   |----------------------------------------------------------------------------
   | Classes
   |----------------------------------------------------------------------------
   */
  const classes = useStyles();

  /*
   |----------------------------------------------------------------------------
   | Elements
   |----------------------------------------------------------------------------
   */
  const rootClasses = classNames(classes.root, layoutClass);
  return (
    <BaseLink
      className={rootClasses}
      href={href}
      state={state}
      onClick={onClick}
      onMouseOver={handleMouseOver}
      newWindow={inNewWindow}
      aria-haspopup={ariaHaspopup}
    >
      {children}
      <span className={classes.icon}>
        <ChevronIcon right />
      </span>
    </BaseLink>
  );
};

export default memo<Props>(LinkWithChevron);
