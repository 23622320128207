import type { Node } from 'react';
import React, { memo } from 'react';

import { isNumber } from '@braindate/util/lib/type';

type Props = {
  qty: number;
  renderSkeleton: () => Node;
};

const FeedQueryLoading = ({ qty, renderSkeleton }: Props) => {
  return (
    isNumber(qty) &&
    qty > 0 && (
      <>
        {Array(qty)
          .fill(0)
          .map((_, key) => (
            <li key={key}>{renderSkeleton()}</li>
          ))}
      </>
    )
  );
};

export default memo<Props>(FeedQueryLoading);
