import { memo } from 'react';
import type { Node } from 'react';

import { SSRProvider } from 'react-aria';
import { configure } from 'react-hotkeys';
import { useSelector } from 'react-redux';

import usePushNotificationsManager from 'src/client/worker/hooks/usePushNotificationsManager';
import usePushNotificationsUnregister from 'src/client/worker/hooks/usePushNotificationsUnregister';
import useOauthMessages from 'src/shared/app/account/oauth/hook/useOauthMessages';
import DocumentTitle from 'src/shared/app/base/component/document/DocumentTitle';
import useMounted from 'src/shared/app/base/hook/useMounted';
import ModalCenter from 'src/shared/app/base/modal/component/ModalCenter';
import ModalStatusProvider from 'src/shared/app/base/modal/component/ModalStatusProvider';
import useRouteRegistration from 'src/shared/app/base/route/hook/useRouteRegistration';
import AmplitudeProvider from 'src/shared/app/base/tracking/AmplitudeProvider';
import usePageTracking from 'src/shared/app/base/tracking/hook/usePageTracking';
import useTrackAbandons from 'src/shared/app/base/tracking/hook/useTrackAbandons';
import useTTITracking from 'src/shared/app/base/tracking/hook/useTTITracking';
import useFeatureRouter from 'src/shared/app/feature/hook/useFeatureRouter';
import useGlobalNotifications from 'src/shared/app/notification/hook/useGlobalNotifications';
import { onboardingSelector } from 'src/shared/app/onboarding/selector/uiOnboardingSelectors';
import { isClient } from 'src/shared/core/util/ssrUtil';

import useLiveChatVisitorAuth from 'plugin/live-chat/hook/useLiveChatVisitorAuth';
import VideoCallMainWrapper from 'plugin/virtual-braindate/component/VideoCallMainWrapper';

import useComponentPreload from './hook/useComponentPreload';
import useInitialPayloadFetching from './hook/useInitialPayloadFetching';
import useJSClass from './hook/useJSClass';
import useOpenidConnectTokenRefresh from './hook/useOpenidConnectTokenRefresh';
import useServerStylesRemoval from './hook/useServerStylesRemoval';
import useSetAppTimezone from './hook/useSetAppTimezone';
import useTimeFormatPatch from './hook/useTimeFormatPatch';
import useTouchDetection from './hook/useTouchDetection';
import useUserReportingRegistration from './hook/useUserReportingRegistration';
import useValidationMessages from './hook/useValidationMessages';
import HelpSwitch from './switch/HelpSwitch';
import HTTPStatusCodeSwitch from './switch/HTTPStatusCodeSwitch';
import RedirectSwitch from './switch/RedirectSwitch';
import TemplateSwitch from './switch/TemplateSwitch';

configure({
  stopEventPropagationAfterIgnoring: false,
});

const AppHooks = (): Node => {
  /*
   |----------------------------------------------------------------------------
   | Hooks
   |----------------------------------------------------------------------------
   */
  // Initialize feature router
  useFeatureRouter();
  // Routes
  useRouteRegistration();
  // Calls to API
  useInitialPayloadFetching();
  useTimeFormatPatch();
  // Websockets
  useGlobalNotifications();
  // OauthMessages
  useOauthMessages();
  // DOM manipulation
  useJSClass();
  useTouchDetection();
  useServerStylesRemoval();
  // Preload
  useComponentPreload();
  // Tracking
  usePageTracking();
  useTrackAbandons();
  useTTITracking();
  // Libraries/Services configuration
  useUserReportingRegistration();
  useValidationMessages();
  useSetAppTimezone();
  // Plugins
  useLiveChatVisitorAuth();
  // Service Worker
  usePushNotificationsManager();
  usePushNotificationsUnregister();
  // OpenID connect token refresh
  useOpenidConnectTokenRefresh();
  const isMounted = useMounted();
  // PAX-5141 Watch onboarding redirection to re-render on state change
  useSelector(onboardingSelector);

  /*
   |----------------------------------------------------------------------------
   | Elements
   |----------------------------------------------------------------------------
   */
  const modalCenter = isMounted && <ModalCenter />;
  return (
    <ModalStatusProvider>
      <SSRProvider>
        {isClient && <AmplitudeProvider />}
        <DocumentTitle />
        <RedirectSwitch />
        <HTTPStatusCodeSwitch />
        <TemplateSwitch />

        <VideoCallMainWrapper />

        <HelpSwitch />

        {modalCenter}
      </SSRProvider>
    </ModalStatusProvider>
  );
};

export default memo(AppHooks);
