import useScreenShareParticipantTwilio from 'src/shared/app/base/plugin/virtual-braindate/providers/twilio/lib/src/hooks/useScreenShareParticipant/useScreenShareParticipant';
import useScreenShareParticipantZoom from 'src/shared/app/base/plugin/virtual-braindate/providers/zoom/hooks/useScreenShareParticipant';
import {
  videoCallPluginProviderTwilio,
  videoCallPluginProviderZoom,
} from 'src/shared/app/feature/settings/featureSettings';

import useVideoCallObjectsMapping from './useVideoCallObjectsMapping';

const providersHooks = {
  [videoCallPluginProviderTwilio]: useScreenShareParticipantTwilio,
  [videoCallPluginProviderZoom]: useScreenShareParticipantZoom,
};

function useScreenShareParticipant() {
  const useProvider = useVideoCallObjectsMapping(providersHooks);

  return useProvider();
}

export default useScreenShareParticipant;
