import { em } from 'polished';

export default {
  root: {
    fontSize: '1.2em',
    width: em(15),
    height: em(13),
    verticalAlign: 'middle',
  },
};
