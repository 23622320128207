import { sort } from 'ramda';

import type { Braindate } from '@braindate/domain/lib/braindate/type';
import { getBraindateId } from '@braindate/domain/lib/braindate/util';
import { assert, assertArray, assertObject } from '@braindate/util/lib/assert';
import { isString } from '@braindate/util/lib/type';

import {
  braindateRoute,
  homeRoute,
  peopleFeedRoute,
  topicRoute,
} from 'src/shared/app/base/route/setting/routeSettings';
import type { RouteData } from 'src/shared/app/base/route/type/routeTypes';
import { getRoutePath } from 'src/shared/app/base/route/util/routeUtils';
import { NOTIFICATION_COMPONENT_MAPPING } from 'src/shared/app/notification/constant/notificationComponentsMap';
import {
  notificationErrorLevel,
  notificationInfoLevel,
  notificationModalType,
  notificationSuccessLevel,
  notificationTakeOverType,
  notificationTopType,
  notificationWarningLevel,
} from 'src/shared/app/notification/constant/notificationConstants';
import type { Notification } from 'src/shared/app/notification/type/notificationTypes';
// DIRTY, Yes it is
export function mapNotificationPath(
  route: any,
  notification: Notification<any>,
): string {
  if (isString(route)) {
    if (route === 'homeRoute') {
      return getRoutePath(homeRoute);
    }

    if (route === 'peopleFeedRoute') {
      return getRoutePath(peopleFeedRoute);
    }

    if (route === 'topicRoute') {
      return getRoutePath(topicRoute);
    }

    if (route === 'braindateRoute') {
      return getRoutePath(braindateRoute);
    }

    if (route === 'braindateRoute:id') {
      const braindate = getNotificationData<Braindate>(notification);
      const braindateId = getBraindateId(braindate);
      const braindatePath = getRoutePath(braindateRoute);
      return braindatePath.replace(':id', String(braindateId));
    }
  }

  return getRoutePath(route);
}

/*
|------------------------------------------------------------------------------
| GETTERS
|------------------------------------------------------------------------------
*/
export function getNotificationId(notification: Notification<any>): string {
  assert(notification, 'notification');
  assertObject(notification, 'notification');
  return notification.id;
}
export function getNotificationLevel(notification: Notification<any>): string {
  assert(notification, 'notification');
  assertObject(notification, 'notification');
  return notification.level;
}
export function getNotificationType(notification: Notification<any>): string {
  assert(notification, 'notification');
  assertObject(notification, 'notification');
  return notification.type;
}
export function getNotificationComponent(notification: Notification<any>): any {
  assert(notification, 'notification');
  assertObject(notification, 'notification');
  const { componentKey } = notification;
  if (!componentKey) return null;
  return NOTIFICATION_COMPONENT_MAPPING[componentKey];
}
export function getNotificationData<T = Record<string, any>>(
  notification: Notification<T>,
): T {
  assert(notification, 'notification');
  assertObject(notification, 'notification');
  // $FlowIssue
  return notification.data;
}
export function getNotificationExcludePaths(
  notification: Notification<any>,
): Array<string | RouteData> {
  assert(notification, 'notification');
  assertObject(notification, 'notification');
  const { exclude } = notification;
  return Array.isArray(exclude) ? exclude : [];
}
export function getNotificationIncludePaths(
  notification: Notification<any>,
): Array<any> {
  assert(notification, 'notification');
  assertObject(notification, 'notification');
  const { include } = notification;
  return Array.isArray(include) ? include : [];
}
export function getNotificationIsActive(
  notification: Notification<any>,
): boolean {
  assert(notification, 'notification');
  assertObject(notification, 'notification');
  return notification.active || false;
}
export function getNotificationBundleType(
  notification: Notification<any>,
): string | null | undefined {
  assert(notification, 'notification');
  assertObject(notification, 'notification');
  return notification.bundleType;
}

/*
|------------------------------------------------------------------------------
| CHECKERS
|------------------------------------------------------------------------------
*/
export function isTakeOverNotification(
  notification: Notification<any>,
): boolean {
  return getNotificationType(notification) === notificationTakeOverType;
}
export function isVideoCallTopNotification(
  notification: Notification<any>,
): boolean {
  return getNotificationType(notification) === notificationTopType;
}
export function isModalNotification(notification: Notification<any>): boolean {
  return getNotificationType(notification) === notificationModalType;
}

/*
|------------------------------------------------------------------------------
| FILTERS
|------------------------------------------------------------------------------
*/
export function getErrorNotifications(
  notifications: Array<Notification<any>>,
): Array<Notification<any>> {
  assertArray(notifications, 'notifications');
  return notifications.filter(
    (n) => getNotificationLevel(n) === notificationErrorLevel,
  );
}
export function getSuccessNotifications(
  notifications: Array<Notification<any>>,
): Array<Notification<any>> {
  assertArray(notifications, 'notifications');
  return notifications.filter(
    (n) => getNotificationLevel(n) === notificationSuccessLevel,
  );
}
export function getInfoNotifications(
  notifications: Array<Notification<any>>,
): Array<Notification<any>> {
  assertArray(notifications, 'notifications');
  return notifications.filter(
    (n) => getNotificationLevel(n) === notificationInfoLevel,
  );
}
export function getWarningNotifications(
  notifications: Array<Notification<any>>,
): Array<Notification<any>> {
  assertArray(notifications, 'notifications');
  return notifications.filter(
    (n) => getNotificationLevel(n) === notificationWarningLevel,
  );
}
export function getActiveNotifications(
  notifications: Array<Notification<any>>,
): Array<Notification<any>> {
  assertArray(notifications, 'notifications');
  return notifications.filter((n) => getNotificationIsActive(n));
}
export function getInactiveNotifications(
  notifications: Array<Notification<any>>,
): Array<Notification<any>> {
  assertArray(notifications, 'notifications');
  return notifications.filter((n) => !getNotificationIsActive(n));
}

/*
|------------------------------------------------------------------------------
| SORTING
|------------------------------------------------------------------------------
*/
export function orderNotificationsByPriority(
  notifications: Array<Notification<any>>,
): Array<Notification<any>> {
  assertArray(notifications, 'notifications');
  const order = [
    notificationErrorLevel,
    notificationSuccessLevel,
    notificationInfoLevel,
    notificationWarningLevel,
  ];
  return sort((a, b) => {
    const aScore = order.indexOf(getNotificationLevel(a));
    const bScore = order.indexOf(getNotificationLevel(b));
    return aScore - bScore;
  }, notifications);
}
