import useEvent from 'src/shared/domain/event/hook/useEvent';

import { virtualBraindateFeature } from 'plugin/virtual-braindate/setting/virtualBraindateSettings';

function useVideoCallProvider(): string {
  const event = useEvent();
  const videoCallProvider = event.plugins[virtualBraindateFeature].provider;
  return videoCallProvider;
}

export default useVideoCallProvider;
