import { rem } from 'polished';

import { baseFontSize } from '@braindate/ui/lib/token/typography';

import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

export default {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    // IE 11
    width: '100%',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    paddingBottom: rem(16, baseFontSize),
    fontWeight: 400,
    fontSize: rem(20, baseFontSize),
    fontStyle: 'italic',
    color: ({ theme }: ThemeFunction<unknown>) => theme.accentColor,
    // IE 11
    width: '100%',
  },
  description: {
    textAlign: 'center',
    fontWeight: 400,
    fontSize: rem(16, baseFontSize),
    lineHeight: rem(19, baseFontSize),
    // IE 11
    width: '100%',
  },
  'header-isLeftAligned': {
    justifyContent: 'flex-start',
    textAlign: 'left',
  },
  ctn: {
    fill: ({ theme }: ThemeFunction<unknown>) => theme.accentColor,
  },
  closeBtn: {
    position: 'absolute',
    right: -4,
    top: -4,
  },
  heroText: {
    flex: 1,

    /* For IE 11 to display properly */
    MsFlex: '1 0 auto',
    // IE 11
    maxWidth: '100%',
  },
  icon: {
    marginRight: rem(12, baseFontSize),
  },
};
