import { useEffect, useState } from 'react';

export default function useMobileKeyboardVisibility(): boolean {
  /*
   |----------------------------------------------------------------------------
   | State
   |----------------------------------------------------------------------------
   */
  const [wasTouched, setTouched] = useState(false);
  const [isKeyboardShown, setKeyboardShown] = useState(false);
  const [initialHeight] = useState(
    typeof window !== 'undefined' ? window.innerHeight : 0,
  );

  /*
   |----------------------------------------------------------------------------
   | Effects
   |----------------------------------------------------------------------------
   */
  useEffect(() => {
    window.addEventListener('touchstart', onTouchStart);
    return () => {
      window.removeEventListener('touchstart', onTouchStart);
    };
  }, []);
  useEffect(() => {
    if (wasTouched) {
      window.addEventListener('resize', onResize);
      return () => {
        window.removeEventListener('resize', onResize);
      };
    }
  }, [wasTouched]);

  /*
   |----------------------------------------------------------------------------
   | Event Handlers
   |----------------------------------------------------------------------------
   */
  const onTouchStart = () => {
    window.removeEventListener('touchstart', onTouchStart);
    setTouched(true);
  };

  const onResize = () => setKeyboardShown(window.innerHeight < initialHeight);

  return isKeyboardShown;
}
