import type { Topic } from '@braindate/domain/lib/topic/type';

import { useGetTopicsForMarketQuery } from 'src/shared/app/base/api/endpoint/topicsEndpoint';
import type {
  ApiListResult,
  UseQuery,
} from 'src/shared/app/base/api/type/apiQueryType';
import useMarketParams from 'src/shared/app/market/hook/useMarketParams';

const useTopicsForMarket: UseQuery<ApiListResult<Topic>> = (
  { page = 1 }: any = {},
  { skip }: any = {},
) => {
  const params = useMarketParams();
  return useGetTopicsForMarketQuery(
    {
      page,
      params,
    },
    skip
      ? {
          refetchOnMountOrArgChange: 2000,
        }
      : {},
  );
};

export default useTopicsForMarket;
