export const errorModalId = 'virtualBraindateErrorModalId';
export const permissionErrorModalId = 'virtualBraindatePermissionErrorModalId';
export const supportModalId = 'supportModalId';
export const recordedBraindateParticipantModalId =
  'recordedBraindateParticipantModalId';
export const recordedBraindateHostModalId = 'recordedBraindateHostModalId';
export const MOBILE_LAYOUT_BREAKPOINT = 1000;
export const LAPTOP_LAYOUT_BREAKPOINT = 1200;
export const VIDEO_RATIO_PERCENT = (9 / 16) * 100;
export const EXTENSION_TIME_MINUTES = 10;
export const FISHBOWL_STATUS = {
  WATCHER: 'watcher',
  SPEAKER: 'speaker',
  HOTSEAT: 'hot_seat',
  RESERVED_SPEAKER: 'reserved_speaker',
};
export const FISHBOWL_ACTIONS = {
  CHANGE_STATUS: 'status_change_request',
  CANCEL_REQUEST: 'cancel_last_request',
  CONFIRM_STATUS: 'confirm_status_change',
  PING: 'ping',
  POSSIBLE_STATUSES: 'possible_statuses_recalculation',
  LEAVE_HOTSEAT: 'leave_hot_seat',
  AUDIO_STATE_UPDATE: 'audio_state_update',
  VIDEO_STATE_UPDATE: 'video_state_update',
  SCREENSHARE_STATE_UPDATE: 'screenshare_state_update',
};
export const SOCKET_TRIGGERS = {
  NOTIF: 'notif',
  UPDATE: 'data-update',
};
export const NOTIFICATIONS_COLORS = {
  INFO: '#40606A',
  HILIGHT: '#28C18A',
};
export const VIDEO_PRIORITY = {
  HIGH: 'high',
  LOW: 'low',
  STANDARD: 'standard',
};
