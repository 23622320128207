import { memo, useEffect, useRef, useState } from 'react';
import type { Node } from 'react';

import Container from 'src/shared/app/base/component/container/Container';
import Fixed from 'src/shared/app/base/component/fixed/Fixed';
import { portalNotificationId } from 'src/shared/app/base/constant/domConstants';
import usePortalRegistrationLength from 'src/shared/app/base/portal/notification/hook/usePortalRegistrationLength';
import { notificationElevation } from 'src/shared/app/base/style/token/elevation';
import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';

import styles from './NotificationPortal.style';

const useStyles = createUseThemeStyles(styles);

const NotificationPortal = (): Node => {
  const ref = useRef();
  const [show, setshow]: Record<string, any> = useState<boolean>(false);
  const classes = useStyles({
    show,
  });
  const elementsLength = usePortalRegistrationLength(portalNotificationId);
  useEffect(() => {
    setshow(elementsLength > 0);
  }, [elementsLength]);
  return (
    <Fixed fullWidth zIndex={notificationElevation} show={show}>
      <div className={classes.root}>
        <div className={classes.overlay} />
        {/* $FlowIssue */}
        <Container>
          <div id={portalNotificationId} ref={ref} />
        </Container>
      </div>
    </Fixed>
  );
};

export default memo(NotificationPortal);
