import * as Sentry from '@sentry/react';

import type { User } from '@braindate/domain/lib/user/type';
import { getUserFullName, getUserId } from '@braindate/domain/lib/user/util';
import { assertObject } from '@braindate/util/lib/assert';

export function registerSentryUserFromBrowser(user: User): void {
  assertObject(user, 'user');
  Sentry.configureScope((scope) => {
    scope.setUser({
      id: getUserId(user),
      username: getUserFullName(user),
    });
  });
}
export function unregisterSentryUserFromBrowser(): void {
  Sentry.configureScope((scope) => {
    scope.setUser();
  });
}
