import { memo, useEffect } from 'react';
import type { Node } from 'react';

import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import useRoutes from 'src/shared/app/base/route/hook/useRoutes';
import { preferencesRoute } from 'src/shared/app/base/route/setting/routeSettings';
import messages from 'src/shared/app/chat/l10n/chatNotificationL10n';
import { dismissNotification } from 'src/shared/app/notification/action/uiNotificationActions';
import Notification from 'src/shared/app/notification/component/Notification';
import type { Notification as NotificationType } from 'src/shared/app/notification/type/notificationTypes';
import LazyPreferencesMain from 'src/shared/app/preference/component/LazyPreferencesMain';

type Props = {
  notification: NotificationType<any>;
};

const PhoneUpdatedNotification = ({ notification }: Props): Node => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [preferencesPath] = useRoutes(preferencesRoute);
  useEffect(() => {
    LazyPreferencesMain.preload();
  }, []);

  const handleDismiss = () => {
    dispatch(dismissNotification(notification));
  };

  return (
    <Notification
      timeout={8000}
      handleDismiss={handleDismiss}
      href={preferencesPath}
    >
      {intl.formatMessage(messages.phoneUpdatedNotificationSuccess)}
    </Notification>
  );
};

export default memo<Props>(PhoneUpdatedNotification);
