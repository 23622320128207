import { TAG_ID } from 'src/shared/app/base/api/apiConstant';
import type { ApiListResult } from 'src/shared/app/base/api/type/apiQueryType';
import type { State } from 'src/shared/core/type/reduxTypes';
import type { EntityIdType } from 'src/shared/domain/base/action/entityActions';

type InfiniteLoadingSettings = {
  tagType: string;
  idGetter: (arg0: any) => number;
};
// Adapted from https://stackoverflow.com/a/69938861/13172856
export async function optimisticUpdate<T>({
  extendedApi,
  mutation,
  type,
  id = 'LIST',
  callback,
}: {
  extendedApi: any;
  type: string;
  id?: number | 'LIST';
  mutation: Record<string, any>;
  callback: (item: T, state: State) => T;
}) {
  const { dispatch, getState, queryFulfilled } = mutation;
  const { selectInvalidatedBy, updateQueryData } = extendedApi.util;
  const state = getState();
  const updates = [];

  for (const endpoint of selectInvalidatedBy(state, [
    {
      type,
      id,
    },
  ])) {
    const { endpointName, originalArgs } = endpoint;
    const patchUpdate = dispatch(
      updateQueryData(endpointName, originalArgs, (draft) => {
        const findInArray = (array) => {
          return array.findIndex((item) => item.id === id);
        };

        if (Array.isArray(draft)) {
          if (id === 'LIST') {
            return draft.map(callback);
          }

          const index = findInArray(draft);
          draft[index] = callback(draft[index], state);
          return draft;
        }

        if (draft.results && Array.isArray(draft.results)) {
          if (id === 'LIST') {
            draft.results = draft.results.map(callback);
            return draft;
          }

          const index = findInArray(draft.results);
          draft.results[index] = callback(draft.results[index], state);
          return draft;
        }

        return callback(draft, state);
      }),
    );
    updates.push(patchUpdate);
  }

  try {
    await queryFulfilled;
  } catch {
    updates.forEach((update) => update.undo());
  }
}
export function getQueryTag(
  type: string,
  ids?: EntityIdType | (number | 'me')[],
): {
  type: string;
  id?: EntityIdType;
}[] {
  if (ids) {
    if (Array.isArray(ids)) {
      return [
        ...ids.map((id) => ({
          type,
          id,
        })),
        {
          type,
          id: TAG_ID.LIST,
        },
      ];
    }

    return [
      {
        type,
        id: ids,
      },
    ];
  }

  return [
    {
      type,
    },
  ];
}
export function areRequestResultEmpty(
  ...data: (ApiListResult<any> | void)[]
): boolean {
  return data.every((item) => !item || !item.count);
}
export function listLoadingSettings<T>({
  tagType,
  idGetter,
}: InfiniteLoadingSettings) {
  return {
    providesTags: (results: T[]) =>
      results
        ? getQueryTag(
            tagType,
            results.map((item) => idGetter(item)),
          )
        : getQueryTag(tagType, TAG_ID.LIST),
  };
}
export function infiniteLoadingSettings<T>({
  tagType,
  idGetter,
}: InfiniteLoadingSettings) {
  return {
    // Do not serialize page=1 as it is the default page
    // and we want to share the same cache if we don't specify the page
    serializeQueryArgs: ({
      queryArgs: { page, ...others } = {
        page: 1,
      },
    }: {
      queryArgs: Record<string, any>;
    }) => (page > 1 ? { ...others, page } : others),
    providesTags: (result: ApiListResult<T>) => {
      return result && result.results
        ? getQueryTag(
            tagType,
            result.results.map((item) => idGetter(item)),
          )
        : getQueryTag(tagType, TAG_ID.LIST);
    },
  };
}
