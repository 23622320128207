import classNames from 'classnames';
import { useId } from 'react-aria';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import Markdown from 'src/shared/app/base/component/text/Markdown';
import uiMessages from 'src/shared/app/base/l10n/uiL10n';
import useRoute from 'src/shared/app/base/route/hook/useRoute';
import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import type { TooltipRenderProps } from 'src/shared/app/coachmark/type/tourType';
import CloseButton from 'src/shared/ui/component/button/CloseButton';
import PrimaryButton from 'src/shared/ui/component/button/PrimaryButton';
import LinkButton from 'src/shared/ui/component/link/LinkButton';

import styles from './TourTooltip.style';
import * as S from './TourTooltip.style';

const useStyles = createUseThemeStyles(styles);

const TooltipNextCounter = ({
  index,
  size,
}: {
  index: number;
  size: number;
}) => {
  const classes = useStyles();

  if (size < 2) {
    return null;
  }

  return (
    <div className={classes.dots}>
      {Array(size)
        .fill(0)
        .map((_, key) => {
          return (
            <span
              className={classNames(classes.dot, {
                [classes.dotActive]: index === key,
              })}
              key={key}
            />
          );
        })}
    </div>
  );
};

const TourTooltip = ({
  continuous,
  index,
  step: {
    title,
    content,
    primaryAction,
    primaryActionRoute,
    secondaryAction,
    secondaryActionRoute,
  },
  size,
  isLastStep,
  skipProps,
  backProps,
  closeProps,
  primaryProps,
  tooltipProps,
}: TooltipRenderProps) => {
  const classes = useStyles();
  const intl = useIntl();
  const titleId = useId();
  const primaryPath = useRoute(primaryActionRoute && primaryActionRoute());
  const secondaryPath = useRoute(
    secondaryActionRoute && secondaryActionRoute(),
  );
  const withPrimaryAction = primaryPath && primaryAction;
  const withSecondaryAction = secondaryPath && secondaryAction;
  const withBack = index > 0 && continuous && !withSecondaryAction;
  const withNext = continuous && !isLastStep;
  const withGotIt = isLastStep || !continuous;
  return (
    <S.Root {...tooltipProps}>
      <div className={classes.wrapper}>
        {title && (
          <div id={titleId} className={classes.title}>
            <Markdown>{title}</Markdown>
          </div>
        )}

        <CloseButton
          {...skipProps}
          onClick={(e) => skipProps.onClick(e, 'skip')}
          layoutClass={classes.close}
        />

        <div className={classes.content} aria-describedby={titleId}>
          <Markdown>{content}</Markdown>
        </div>
        <div className={classes.actions}>
          <div>
            <TooltipNextCounter size={size} index={index} />
          </div>
          <div>
            {withSecondaryAction && secondaryPath && (
              <Link className={classes.action} to={secondaryPath}>
                {secondaryAction}
              </Link>
            )}
            {withBack && (
              <PrimaryButton {...backProps} layoutClass={classes.button}>
                {intl.formatMessage(uiMessages.back)}
              </PrimaryButton>
            )}
            {withNext && (
              <PrimaryButton {...primaryProps} layoutClass={classes.button}>
                {intl.formatMessage(uiMessages.next)}
              </PrimaryButton>
            )}
            {withGotIt && !withPrimaryAction && (
              <PrimaryButton {...closeProps} layoutClass={classes.button}>
                {intl.formatMessage(uiMessages.gotIt)}
              </PrimaryButton>
            )}
            {withPrimaryAction && primaryPath && (
              <LinkButton
                to={primaryPath}
                layoutClass={classes.button}
                onClick={(e) => closeProps.onClick(e, 'link')}
              >
                {primaryAction}
              </LinkButton>
            )}
          </div>
        </div>
      </div>
    </S.Root>
  );
};

export default TourTooltip;
