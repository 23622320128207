import { createFeatureDecisions } from 'src/shared/app/feature/factory/featureFactory';
import featureRouter from 'src/shared/app/feature/featureRouter';

export default function useFeatureDecisions() {
  const obj = createFeatureDecisions(featureRouter);
  return {
    isAccountPrefPluginEnabled: obj.isAccountPrefPluginEnabled(),
    isHelpEnabled: obj.isHelpEnabled(),
    isSlackIntegrationEnabled: obj.isSlackIntegrationEnabled(),
    areEmailNotificationsEnabled: obj.areEmailNotificationsEnabled(),
    areSMSNotificationsEnabled: obj.areSMSNotificationsEnabled(),
    isKlikIntegrationEnabled: obj.isKlikIntegrationEnabled(),
    isGithubProfileEnabled: obj.isGithubProfileEnabled(),
    isNotificationPromptShown: obj.isNotificationPromptShown(),
    areMarketPromptsEnabled: obj.areMarketPromptsEnabled(),
    isSignupEnabled: obj.isSignupEnabled(),
    isDoubleDutchIntegrationEnabled: obj.isDoubleDutchIntegrationEnabled(),
    isVideoCallEnabled: obj.isVideoCallEnabled(),
    useLightTheme: obj.useLightTheme(),
    isTopicApprovalEnabled: obj.isTopicApprovalEnabled(),
    isConversationFormatEnabled: obj.isConversationFormatEnabled(),
    isFishbowlEnabled: obj.isFishbowlEnabled(),
    isSuperGroupEnabled: obj.isSuperGroupEnabled(),
    isCustomMenuEnabled: obj.hasCustomMenu(),
    isSSOEnabled: obj.isSSOEnabled(),
    isProvideFeedbackSectionEnabled: obj.isProvideFeedbackSectionEnabled(),
    isVirtualBraindateRecordingsEnabled:
      obj.isVirtualBraindateRecordingsEnabled(),
    isAccessibilityCompatibleEnabled: obj.isAccessibilityCompatibleEnabled(),
    isTranscribeFeatureEnabled: obj.isTranscribeFeatureEnabled(),
    isPeopleTabDisabled: obj.isPeopleTabDisabled(),
    isRecommendationFeatureEnabled: obj.isRecommendationFeatureEnabled(),
    isCheerEnabled: obj.isCheerEnabled(),
    isSmsPhoneReminderWidgetEnabled: obj.isSmsPhoneReminderWidgetEnabled(),
    isTopicCardCustomizationPluginEnabled:
      obj.isTopicCardCustomizationPluginEnabled(),
    isGoogleSyncEnabled: obj.isGoogleSyncEnabled(),
    isOutlookSyncEnabled: obj.isOutlookSyncEnabled(),
    shouldHideTopBarOnScroll: obj.shouldHideTopBarOnScroll(),
    isInspirationTopicsEnabled: obj.isInspirationTopicsEnabled(),
    isDrawerMenuEnabled: obj.isDrawerMenuEnabled(),
    arePushNotificationsEnabled: obj.arePushNotificationsEnabled(),
    areTopicReactionsEnabled: obj.areTopicReactionsEnabled(),
    areCommentsEnabled: obj.areCommentsEnabled(),
    showReactionsCountFeatureEnabled: obj.showReactionsCountFeatureEnabled(),
    isTopicSuggestionsFeatureEnabled: obj.isTopicSuggestionsFeatureEnabled(),
    isOIDCLoginFeatureEnabled: obj.isOIDCLoginFeatureEnabled(),
    isOnboardingNextEnabled: obj.isOnboardingNextEnabled(),
    isTogethernessEnabled: obj.isTogethernessEnabled(),
    isVirtualEmojisEnabled: obj.isVirtualEmojisEnabled(),
  };
}
