import type { Node } from 'react';

import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { getEventSupportEmail } from '@braindate/domain/lib/event/util';

import { supportEmail } from 'src/shared/app/base/settings/contactSettings';
import { dismissNotification } from 'src/shared/app/notification/action/uiNotificationActions';
import Notification from 'src/shared/app/notification/component/Notification';
import messages from 'src/shared/app/notification/l10n/notificationL10n';
import { supportSentNotificationTimeout } from 'src/shared/app/support/settings/supportSettings';
import useEvent from 'src/shared/domain/event/hook/useEvent';

type Props = {
  notification: Notification<any>;
};

const SupportSentNotification = ({ notification }: Props): Node => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const handleDismiss = () => {
    dispatch(dismissNotification(notification));
  };

  const event = useEvent();
  return (
    <Notification
      timeout={supportSentNotificationTimeout}
      handleDismiss={handleDismiss}
    >
      {intl.formatMessage(messages.supportSentNotification, {
        supportEmail: getEventSupportEmail(event) || supportEmail,
      })}
    </Notification>
  );
};

export default SupportSentNotification;
