import { useEffect } from 'react';

import { useLocation } from 'react-router-dom';

import { trackGAPage } from 'src/shared/core/service/tracking/gaService';

export default function usePageTracking() {
  const location = useLocation();
  useEffect(() => {
    const { pathname } = location;
    trackGAPage(pathname);
  }, [location]);
}
