import type { BraindateEventPlugins } from '@braindate/domain/lib/event/type';
import { getEventPlugins } from '@braindate/domain/lib/event/util';

import useEvent from './useEvent';

const useEventPlugin = (): BraindateEventPlugins => {
  const event = useEvent();
  return getEventPlugins(event);
};

export default useEventPlugin;
