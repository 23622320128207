import { useDispatch } from 'react-redux';

import { getBraindateId } from '@braindate/domain/lib/braindate/util';

import { useGetBraindateQuery } from 'src/shared/app/base/api/endpoint/braindatesEndpoint';
import { dismissNotification } from 'src/shared/app/notification/action/uiNotificationActions';
import type { Notification } from 'src/shared/app/notification/type/notificationTypes';
import { getNotificationData } from 'src/shared/app/notification/util/notificationUtil';

import VirtualBraindateEarlyReminderDumb from './VirtualBraindateEarlyReminderDumb';

type Props = {
  notification: Notification<any>;
};

const VirtualBraindateEarlyReminder = ({ notification }: Props) => {
  const dispatch = useDispatch();
  const braindateId = getBraindateId(
    // $FlowIssue
    getNotificationData(notification),
  );
  const { data: braindate } = useGetBraindateQuery(braindateId);

  const handleDismiss = () => {
    dispatch(dismissNotification(notification));
  };

  if (!braindate) return null;
  return (
    <VirtualBraindateEarlyReminderDumb
      braindate={braindate}
      handleDismiss={handleDismiss}
    />
  );
};

export default VirtualBraindateEarlyReminder;
