import { createSelector } from '@reduxjs/toolkit';

import type { State } from 'src/shared/core/type/reduxTypes';

const _getSegment = (state) => state.ui.market;

const _isMarketAutocompleteShown = (state) =>
  _getSegment(state).isAutocompleteShown;

const _getMarketFirstTopicId = (state) => _getSegment(state).topic;

const _isMarketTopicNotFound = (state) => _getSegment(state).topicNotFound;

const _areMarketTopicsFrozen = (state) => _getSegment(state).isFrozen;

const _isMarketUserNotFound = (state) => _getSegment(state).UserNotFound;

const _getMarketFirstUserId = (state) => _getSegment(state).User;

const _getMarketCurrentPage = (state) => _getSegment(state).currentPage;

const _getShowTopicInspirations = (state) =>
  _getSegment(state).showTopicInspirations;

export const isMarketAutocompleteShown: (
  state: Record<string, any>,
) => boolean = createSelector(
  [_isMarketAutocompleteShown],
  (x: boolean): boolean => x,
);
export const getMarketFirstTopicId: (state: Record<string, any>) => number =
  createSelector([_getMarketFirstTopicId], (x: number): number => x);
export const isUserTopicCreated: (state: Record<string, any>) => boolean =
  createSelector([_getMarketFirstTopicId], (id: number): boolean => !!id);
export const isMarketTopicNotFound: (state: Record<string, any>) => boolean =
  createSelector([_isMarketTopicNotFound], (x: boolean): boolean => x);
export const areMarketTopicsFrozen: (state: Record<string, any>) => boolean =
  createSelector([_areMarketTopicsFrozen], (x: boolean): boolean => x);
export const getHiddenPrompts = (state: State): Array<string> =>
  _getSegment(state).hiddenPrompts;
export const getMarketFirstUserId: (state: Record<string, any>) => number =
  createSelector([_getMarketFirstUserId], (x: number): number => x);
export const isMarketUserNotFound: (state: Record<string, any>) => boolean =
  createSelector([_isMarketUserNotFound], (x: boolean): boolean => x);
export const getMarketCurrentPage: (state: Record<string, any>) => number =
  createSelector([_getMarketCurrentPage], (x: number): number => x);
export const getShowTopicInspirations: (state: Record<string, any>) => boolean =
  createSelector([_getShowTopicInspirations], (x: boolean): boolean => x);
