import {
  availabilityRoute,
  newTopicRoute,
  profileEditRoute,
} from 'src/shared/app/base/route/setting/routeSettings';
import messages from 'src/shared/app/coachmark/l10n/coachmarkL10n';
import type {
  Config,
  StepDefault,
} from 'src/shared/app/coachmark/type/tourType';

export const TOUR_SELECTOR_KEYS = {
  MARKET_TOPICS: 'marketTopicsTour',
  BOOKING_CALENDAR: 'bookingCalendarTour',
  PROFILE_MENU: 'profileMenuTour',
  MARKET_SEARCH_INPUT: 'marketSearchInputTour',
};
const TOUR_CONFIG_KEYS = {
  BOOK_FIRST_BRAINDATE: 'bookFirstBraindate',
  CALENDAR_SYNC_NOT_ENABLET: 'calendarSyncNotEnabled',
  ADD_DETAILS_TO_PROFILE: 'addDetailsToProfile',
  EMPTY_MARKET: 'emptyMarket',
};
export const toursDefaultStep: StepDefault = {
  disableBeacon: true,
};
export const tours: Config = {
  bookFirstBraindate: {
    key: TOUR_CONFIG_KEYS.BOOK_FIRST_BRAINDATE,
    steps: [
      {
        targetClassname: TOUR_SELECTOR_KEYS.MARKET_TOPICS,
        title: messages.calendarSyncNotEnabledTitle,
        content: messages.calendarSyncNotEnabledContent,
        placement: 'top',
      },
    ],
  },
  calendarSyncNotEnabled: {
    key: TOUR_CONFIG_KEYS.CALENDAR_SYNC_NOT_ENABLET,
    steps: [
      {
        targetClassname: TOUR_SELECTOR_KEYS.BOOKING_CALENDAR,
        title: messages.calendarSyncGuidanceTitle,
        content: messages.calendarSyncGuidanceContent,
        primaryAction: messages.calendarSyncGuidanceAction,
        // Must be a function because of a circular deps with routeSettings
        primaryActionRoute: () => availabilityRoute,
        placement: 'right',
      },
    ],
  },
  addDetailsToProfile: (formatMessage, options) => ({
    key: TOUR_CONFIG_KEYS.ADD_DETAILS_TO_PROFILE,
    steps: [
      {
        targetClassname: TOUR_SELECTOR_KEYS.PROFILE_MENU,
        title: formatMessage(messages.addDetailsToProfileTitle, options),
        content: messages.addDetailsToProfileContent,
        primaryAction: messages.addDetailsToProfileAction,
        // Must be a function because of a circular deps with routeSettings
        primaryActionRoute: () => profileEditRoute,
        placement: 'bottom',
      },
    ],
  }),
  emptyMarket: {
    key: TOUR_CONFIG_KEYS.EMPTY_MARKET,
    steps: [
      {
        targetClassname: TOUR_SELECTOR_KEYS.MARKET_SEARCH_INPUT,
        title: messages.emptyMarketTitle,
        content: messages.emptyMarketContent,
        primaryAction: messages.emptyMarketAction,
        // Must be a function because of a circular deps with routeSettings
        primaryActionRoute: () => newTopicRoute,
        placement: 'bottom',
      },
    ],
  },
};
