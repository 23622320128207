import { memo, useEffect } from 'react';
import type { Node } from 'react';

import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { portalTakeOverId } from 'src/shared/app/base/constant/domConstants';
import useFixed from 'src/shared/app/base/hook/useFixed';
import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import { selectMobileNavIsRegistered } from 'src/shared/app/nav/selector/navSelectors';
import {
  disableBodyScrolling,
  enableBodyScrolling,
} from 'src/shared/core/util/browserUtil';
import Portal from 'src/shared/ui/component/misc/Portal';

import styles from './TakeOver.style';

type Props = {
  children: Node;
  overMobileNav?: boolean;
  fullHeight?: boolean;
  layoutClass?: string;
};
const useStyles = createUseThemeStyles(styles);

const TakeOver = ({
  children,
  overMobileNav = false,
  fullHeight = false,
  layoutClass,
}: Props): Node => {
  const { innerProps: fixed } = useFixed();
  const mobileNavIsRendered = useSelector(selectMobileNavIsRegistered);
  const classes = useStyles({
    noBottom: mobileNavIsRendered || overMobileNav,
  });
  useEffect(() => {
    disableBodyScrolling();
    return () => {
      enableBodyScrolling();
    };
  }, []);
  return (
    <Portal id={portalTakeOverId}>
      <div
        {...fixed}
        style={{
          ...(fixed?.style || {}),
          ...(fullHeight
            ? {
                top: 0,
                bottom: 0,
              }
            : {}),
        }}
        className={classNames(
          classes.root,
          {
            [classes.animated]: fullHeight,
          },
          layoutClass,
        )}
      >
        <div className={classes.takeOverContent}>{children}</div>
      </div>
    </Portal>
  );
};

export default memo<Props>(TakeOver);
