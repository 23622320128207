import { lap, tablet } from '@braindate/ui/lib/token/mediaQuery';

import {
  MAX_LAPTOP_GRID_WIDTH,
  MAX_MOBILE_GRID_WIDTH,
  MAX_TABLET_GRID_WIDTH,
} from 'src/shared/app/base/style/token/grid';

export default {
  root: {
    width: '100%',
    height: '100%',
    flexGrow: 1,
    maxWidth: MAX_MOBILE_GRID_WIDTH,
    margin: '0 auto',
    [tablet]: {
      maxWidth: MAX_TABLET_GRID_WIDTH,
    },
    [lap]: {
      maxWidth: MAX_LAPTOP_GRID_WIDTH,
    },
  },
};
