import { em } from 'polished';

import { baseFontSize } from '@braindate/ui/lib/token/typography';

import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

export default {
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 48,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflowY: 'auto',
    flex: 1,
  },
  profile: {
    width: 'max-content',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '10px',
    fontSize: em(20, baseFontSize),
    marginBottom: 20,
  },
  scrolling: {
    boxShadow: ({ theme }: ThemeFunction<unknown>) => theme.shadowBox,
  },
};
