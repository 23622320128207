import { isNumber } from '@braindate/util/lib/type';

function isHTTP4xxError(error: Error): boolean {
  // $FlowIssue
  const { status } = error;

  if (isNumber(status)) {
    return status >= 400 && status < 500;
  }

  return false;
}

export default isHTTP4xxError;
