import { rem } from 'polished';

import { lap } from '@braindate/ui/lib/token/mediaQuery';
import { baseFontSize } from '@braindate/ui/lib/token/typography';

import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

export default {
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    justifyContent: 'space-around',
    paddingTop: ({
      small,
    }: ThemeFunction<{
      small: boolean;
    }>) => (small ? '1em' : '3em'),
    paddingBottom: ({
      small,
    }: ThemeFunction<{
      small: boolean;
    }>) => (small ? '1em' : 0),
    [lap]: {
      minHeight: '15em',
    },
  },
  text: {
    maxWidth: 380,
    margin: '24 0 4px',
  },
  title: {
    marginBottom: rem(16, baseFontSize),
    textTransform: 'none',
    fontSize: rem(20, baseFontSize),
    fontWeight: 600,
  },
  content: {
    fontSize: rem(16, baseFontSize),
    textAlign: 'center',
    lineHeight: 1.2,
    fontStyle: 'italic',
    '& p': {
      marginBottom: '0.8em',
    },
  },
  icon: {
    width: 40,
    height: 40,
    margin: 3,
    padding: 3,
  },
  iconWrapper: {
    border: ({ theme }: ThemeFunction<unknown>) =>
      `solid 2px ${theme.accentColor}`,
    borderRadius: '50%',
    color: ({ theme }: ThemeFunction<unknown>) => theme.accentColor,
  },
};
