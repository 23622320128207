import { createElement, memo } from 'react';
import type { Node } from 'react';

import useReduxState from 'src/shared/app/base/hook/useReduxState';
import ScrollToTop from 'src/shared/app/base/route/component/ScrollToTop';
import useRouteConfig from 'src/shared/app/base/route/hook/useRouteConfig';
import useRouteMatch from 'src/shared/app/base/route/hook/useRouteMatch';
import {
  getRouteMain,
  getRouteScrollToTop,
} from 'src/shared/app/base/route/util/routeUtils';

import MainSwitchDumb from './MainSwitchDumb';

type Props = {
  layoutClass?: string;
};

const MainSwitch = ({ layoutClass }: Props): Node => {
  /*
   |----------------------------------------------------------------------------
   | Hooks
   |----------------------------------------------------------------------------
   */
  const state = useReduxState();
  const config = useRouteConfig();
  const route = useRouteMatch();

  /*
   |----------------------------------------------------------------------------
   | Elements
   |----------------------------------------------------------------------------
   */
  const children = createElement(getRouteMain(route, state, config));
  const element = getRouteScrollToTop(route) ? (
    <ScrollToTop>{children}</ScrollToTop>
  ) : (
    children
  );
  return <MainSwitchDumb layoutClass={layoutClass}>{element}</MainSwitchDumb>;
};

export default memo<Props>(MainSwitch);
