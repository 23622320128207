import type { Node } from 'react';
import { useEffect } from 'react';

import type {
  ApiListResult,
  UseQuery,
} from 'src/shared/app/base/api/type/apiQueryType';
import FeedQueryLoading from 'src/shared/ui/component/data-display/FeedQueryLoading';
import FeedQueryPageItem from 'src/shared/ui/component/data-display/FeedQueryPageItem';

type Props<T> = {
  useQuery: UseQuery<ApiListResult<T>>;
  renderItem: (item: T, previousItem: T) => Node;
  pageSeparator: (page: number) => Node;
  params: Record<string, any>;
  page: number;
  count: number;
  isFrozen?: boolean;
  renderSkeleton: () => Node;
  onPageLoad?: () => Node;
  interstitialComponents?: Array<Node>;
  interstitialIndexes?: Array<number>;
};

function FeedQueryPage<T>({
  useQuery,
  renderItem,
  pageSeparator,
  params,
  page,
  count,
  isFrozen,
  renderSkeleton,
  onPageLoad,
  interstitialComponents,
  interstitialIndexes,
}: Props<T>): Node {
  const { data: lastPageData } = useQuery(
    { ...params, page: page - 1 },
    {
      skip: page - 1 <= 0,
    },
  );
  const { data, isLoading } = useQuery(
    { ...params, page },
    {
      skip: isFrozen,
    },
  );
  const limit = params.limit || 10;
  const itemsLeft = count ? count - (page - 1) * limit : 0;
  const isFullPage = data?.results?.length === limit;

  const getSkeletonQty = () => {
    if (!itemsLeft) return limit;
    if (itemsLeft >= limit) return limit;
    return itemsLeft < limit ? itemsLeft : limit;
  };

  useEffect(() => {
    if (data && !isLoading && onPageLoad) {
      onPageLoad();
    }
  }, [data, isLoading]);
  return (
    <>
      {data?.results?.map((item, index) => {
        const lastItemFromLastPage = lastPageData?.results.slice(-1)[0];
        const itemBefore = data.results[index - 1];
        const previousItem = itemBefore || lastItemFromLastPage;
        return (
          <FeedQueryPageItem
            item={item}
            previousItem={previousItem}
            index={index}
            key={index}
            interstitialComponents={interstitialComponents}
            interstitialIndexes={interstitialIndexes}
            page={page}
            renderItem={renderItem}
          />
        );
      })}

      {isLoading ? (
        <FeedQueryLoading
          qty={getSkeletonQty()}
          renderSkeleton={renderSkeleton}
        />
      ) : null}
      {!isLoading && (isFullPage || page === 1) && !!pageSeparator ? (
        <li>{pageSeparator(page)}</li>
      ) : null}
    </>
  );
}

export default FeedQueryPage;
