import { rem } from 'polished';

import { lap, phablet, tablet } from '@braindate/ui/lib/token/mediaQuery';
import { baseFontSize } from '@braindate/ui/lib/token/typography';

import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

export default {
  root: {
    display: 'flex',
    color: ({ theme }: ThemeFunction<unknown>) => theme.textColor,
    marginLeft: '0.5rem',
    [phablet]: {
      marginLeft: 0,
    },
  },
  'root-darkBg': {},
  text: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    minWidth: 0,
    marginLeft: '0.5em',
    lineHeight: 1.2,
    '$root-darkBg &': {
      WebkitFontSmoothing: 'antialiased',
    },
  },
  'text-withIcon': {
    maxWidth: 'calc(100% - 110px)',
    [tablet]: {
      maxWidth: 'calc(100% - 120px)',
    },
    [lap]: {
      maxWidth: 'calc(100% - 140px)',
    },
  },
  name: {
    fontSize: rem(16, baseFontSize),
    fontWeight: 500,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    paddingRight: '0.1em',
  },
  title: {
    fontSize: rem(14, baseFontSize),
    fontStyle: 'italic',
    fontWeight: 300,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'pre-line',
    maxWidth: 200,
    [phablet]: {
      minWidth: 400,
    },
  },
};
