import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

export default {
  root: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    backgroundImage: ({
      backgroundImage,
    }: ThemeFunction<{
      backgroundImage: string;
    }>) => backgroundImage,
    backgroundPosition: 'center center',
    backgroundSize: 'cover',
  },
};
