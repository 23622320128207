import { memo } from 'react';
import type { Node } from 'react';

import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import type { Topic } from '@braindate/domain/lib/topic/type';
import { isFishbowlTopic } from '@braindate/domain/lib/topic/util';
import type { User } from '@braindate/domain/lib/user/type';
import {
  getUserCompany,
  getUserFullName,
  getUserPath,
  getUserPosition,
} from '@braindate/domain/lib/user/util';

import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import UserAvatar, {
  small as avatarSmall,
} from 'src/shared/components/domain/user/avatar/UserAvatar';
import messages from 'src/shared/domain/user/l10n/userL10n';
import ChevronIcon from 'src/shared/ui/component/icon/ChevronIcon';
import ConditionalWrapper from 'src/shared/ui/component/misc/ConditionalWrapper';

import styles from './UserLinkBanner.style';

type Props = {
  user: User;
  checkedIn?: boolean;
  onDarkBackground?: boolean;
  actions?: Node;
  isPreview?: boolean;
  topic: Topic;
  avatarVariant?: string;
  withAvatar?: boolean;
  variant?: 'horizontal' | 'vertical';
};
const useStyles = createUseThemeStyles(styles);

const UserLinkBanner = ({
  user,
  checkedIn,
  onDarkBackground,
  actions,
  isPreview,
  topic,
  avatarVariant = avatarSmall,
  withAvatar = true,
  variant = 'vertical',
}: Props): Node => {
  const intl = useIntl();
  const name = getUserFullName(user);
  const position = getUserPosition(user, intl.locale);
  const company = getUserCompany(user, intl.locale);
  const title =
    position && company
      ? intl.formatMessage(messages.fullPosition, {
          position,
          company,
        })
      : position || company;
  const isFishbowl = isFishbowlTopic(topic);
  const authorElt = isFishbowl && (
    <div>{intl.formatMessage(messages.host)}</div>
  );
  const path = getUserPath(user);
  const classes = useStyles({
    onDarkBackground,
    checkedIn,
    variant,
  });
  return (
    <div className={classes.root}>
      <div className={classes.user}>
        {withAvatar ? (
          <UserAvatar user={user} variant={avatarVariant} showOnlineBadge />
        ) : null}

        <ConditionalWrapper
          condition={!isPreview}
          wrapper={(children) => (
            <Link to={path} className={classes.link}>
              {children}
            </Link>
          )}
          elseWrapper={(children) => (
            <span className={classes.link}>{children}</span>
          )}
        >
          <div className={classes.nameContainer}>
            <span className={classes.name}>{name}</span>
            {!isPreview && variant === 'vertical' && (
              <span className={classes.icon}>
                <ChevronIcon right />
              </span>
            )}
          </div>

          <span className={classes.separator}>∙</span>

          <div className={classes.titleContainer}>
            <span className={classes.title}>{title}</span>
          </div>
          <div>{authorElt}</div>
        </ConditionalWrapper>
      </div>
      {actions && <div className={classes.actions}>{actions}</div>}
    </div>
  );
};

export default memo<Props>(UserLinkBanner);
