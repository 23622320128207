import { useEffect } from 'react';

import useAuthenticationToken from 'src/shared/app/authentication/hook/useAuthenticationToken';
import LazyMarketMain from 'src/shared/app/market/component/MarketMain';
import LazyPermissionMain from 'src/shared/app/permission/component/LazyPermissionMain';
import useMembership from 'src/shared/domain/membership/hook/useMembership';
import { isMembershipTermsAccepted } from 'src/shared/domain/membership/util/membershipUtil';

export default function useComponentPreload() {
  const token = useAuthenticationToken();
  const membership = useMembership();
  // TODO: replace by `isOptinDone` in `permissionUtils`
  const areTermsAccepted = membership && isMembershipTermsAccepted(membership);
  useEffect(() => {
    if (token) {
      LazyMarketMain.preload();
    }
  }, [token]);
  useEffect(() => {
    if (!areTermsAccepted) {
      LazyPermissionMain.preload();
    }
  }, [areTermsAccepted]);
}
