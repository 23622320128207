(function (global, factory) {
  if (typeof define === "function" && define.amd) {
    define(["exports", "@braindate/util/lib/assert"], factory);
  } else if (typeof exports !== "undefined") {
    factory(exports, require("@braindate/util/lib/assert"));
  } else {
    var mod = {
      exports: {}
    };
    factory(mod.exports, global.assert);
    global.eventAssertions = mod.exports;
  }
})(typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : this, function (_exports, _assert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.assertEvent = assertEvent;
  _exports.assertEventAuthenticationConfig = assertEventAuthenticationConfig;
  _exports.assertEventCustomizations = assertEventCustomizations;
  _exports.assertEventDates = assertEventDates;
  _exports.assertEventFeatures = assertEventFeatures;
  _exports.assertEventLanguages = assertEventLanguages;
  _exports.assertEventLogo = assertEventLogo;
  _exports.assertEventPlugins = assertEventPlugins;
  _exports.assertEventProfileRequireFields = assertEventProfileRequireFields;
  _exports.assertEventSponsors = assertEventSponsors;
  _exports.assertEventURLs = assertEventURLs;
  /**
   * Throw an exception if parameter is not an object
   * @param   {any} value - Value to check
   * @returns {undefined}
   */
  function assertEvent(value) {
    (0, _assert.assertObject)(value, "event");
  }

  /**
   * Throw an exception if parameter is not an array
   * @param   {any} value - Value to check
   * @returns {undefined}
   */
  function assertEventLanguages(value) {
    (0, _assert.assertArray)(value, "event.languages");
  }

  /**
   * Throw an exception if parameter is not an object
   * @param   {any} value - Value to check
   * @returns {undefined}
   */
  function assertEventPlugins(value) {
    (0, _assert.assertObject)(value, "event.plugins");
  }

  /**
   * Throw an exception if parameter is not an object
   * @param   {any} value - Value to check
   * @returns {undefined}
   */
  function assertEventCustomizations(value) {
    (0, _assert.assertObject)(value, "event.customizations");
  }

  /**
   * Throw an exception if parameter is not an object
   * @param   {any} value - Value to check
   * @returns {undefined}
   */
  function assertEventFeatures(value) {
    (0, _assert.assertObject)(value, "event.features");
  }

  /**
   * Throw an exception if parameter is not an object
   * @param   {any} value - Value to check
   * @returns {undefined}
   */
  function assertEventSponsors(value) {
    (0, _assert.assertObject)(value, "event.sponsors_v2");
  }

  /**
   * Throw an exception if parameter is not an object
   * @param   {any} value - Value to check
   * @returns {undefined}
   */
  function assertEventLogo(value) {
    (0, _assert.assertObject)(value, "event.logo");
  }

  /**
   * Throw an exception if parameter is not an object
   * @param   {any} value - Value to check
   * @returns {undefined}
   */
  function assertEventURLs(value) {
    (0, _assert.assertObject)(value, "event.urls");
  }

  /**
   * Throw an exception if parameter is not an array
   * @param   {any} value - Value to check
   * @returns {undefined}
   */
  function assertEventDates(value) {
    (0, _assert.assertArray)(value, "event.dates");
  }

  /**
   * Throw an exception if parameter is not an array
   * @param   {any} value - Value to check
   * @returns {undefined}
   */
  function assertEventAuthenticationConfig(value) {
    (0, _assert.assertObject)(value, "event.authentication");
  }

  /**
   * Throw an exception if parameter is not an object
   * @param   {any} value - Value to check
   * @returns {undefined}
   */
  function assertEventProfileRequireFields(value) {
    (0, _assert.assertArray)(value, "event.required_user_profile_fields");
  }
});