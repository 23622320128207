import CheckCircle from '@mui/icons-material/CheckCircleOutline';
import classNames from 'classnames';
import { useIntl } from 'react-intl';

import type { Braindate } from '@braindate/domain/lib/braindate/type';
import { getUserFirstName } from '@braindate/domain/lib/user/util';

import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import styles from 'src/shared/app/check-in/component/OtherUserCheckinCard.style';
import messages from 'src/shared/app/check-in/l10n/checkInL10n';
import { hasUserCheckedIn } from 'src/shared/app/check-in/util/checkInUtil';
import UserAvatar, {
  small,
} from 'src/shared/components/domain/user/avatar/UserAvatar';
import { getBraindateOtherParticipant } from 'src/shared/domain/braindate/util/braindateUtil';
import useSelf from 'src/shared/domain/user/hook/useSelf';

type Props = {
  braindate: Braindate;
};
const useStyles = createUseThemeStyles(styles);

const OtherUserCheckInCard = ({ braindate }: Props) => {
  const intl = useIntl();
  const self = useSelf();
  const classes = useStyles();
  const otherUser = self ? getBraindateOtherParticipant(braindate, self) : null;
  const otherUserCheckedIn =
    !!otherUser && hasUserCheckedIn(braindate, otherUser);
  if (!otherUser) return null;
  return (
    <div className={classes.root}>
      <UserAvatar user={otherUser} variant={small} />

      {intl.formatMessage(
        otherUserCheckedIn
          ? messages.otherUserCheckedIn
          : messages.otherUserNotCheckedIn,
        {
          name: getUserFirstName(otherUser),
        },
      )}
      <CheckCircle
        className={classNames(classes.checkInIcon, {
          [classes.checkInIconActive]: otherUserCheckedIn,
        })}
      />
    </div>
  );
};

export default OtherUserCheckInCard;
