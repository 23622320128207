import useVideoCallProvider from './useVideoCallProvider';

enum Providers {
  videoCallPluginProviderTwilio = 'twilio',
  videoCallPluginProviderZoom = 'zoom',
}

export default function useVideoCallObjectsMapping<T>(mappingObject: {
  [key in Providers]: T;
}): T | null {
  const provider = useVideoCallProvider();
  if (Object.values(Providers).includes(provider as Providers)) {
    const Component = mappingObject[provider as Providers];
    return Component;
  }
  return null;
}
