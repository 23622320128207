import type { Node } from 'react';

import classNames from 'classnames';
import { TransitionStatus } from 'react-transition-group';

import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';

import styles from './ReportingModalWrapper.style';

type Props = {
  transitionState: TransitionStatus;
  className?: string;
  children: Node;
};
const useStyles = createUseThemeStyles(styles);

const ReportingModalWrapper = ({
  transitionState,
  className,
  children,
}: Props) => {
  const classes = useStyles({
    transitionState,
  });
  return <div className={classNames(classes.root, className)}>{children}</div>;
};

export default ReportingModalWrapper;
