import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

// import { supportModalId } from 'plugin/virtual-braindate/constant/virtualBraindateConstants';
import messages from 'src/shared/app/base/l10n/errorL10n';
import { triggerErrorModal } from 'src/shared/app/base/modal/action/modalActions';
import ErrorModal from 'src/shared/app/base/modal/component/ErrorModal';
import { errorModalId } from 'src/shared/app/base/modal/constant/modalConstant';
import modalRegistry from 'src/shared/app/base/modal/registry/modalRegistry';

modalRegistry.add(errorModalId, ErrorModal);
export default function useErrorModal() {
  const dispatch = useDispatch();
  const intl = useIntl();
  return (
    error: string | Error,
    onClose?: () => void,
    isSentryError?: boolean,
  ) => {
    if (isSentryError) {
      error = `${intl.formatMessage(messages.errorId)} ${error.toString()}}`;
    }

    dispatch(triggerErrorModal(errorModalId, error, onClose));
  };
}
