import { memo, useState } from 'react';
import type { Node } from 'react';

import { useField } from 'formik';
import { useIntl } from 'react-intl';

import FormikCheckbox from 'src/shared/app/base/component/data-entry/form/FormikCheckbox';
import styles from 'src/shared/app/base/component/data-entry/form-builder/FormBuilder.style';
import FormBuilderField from 'src/shared/app/base/component/data-entry/form-builder/FormBuilderField';
import type { Field } from 'src/shared/app/base/component/data-entry/form-builder/type/formBuilderTypes';
import { getFieldTitle } from 'src/shared/app/base/component/data-entry/form-builder/utils/formBuilderUtils';
import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';

const useStyles = createUseThemeStyles(styles);

const FormBuilderCheckbox = ({
  field,
  name,
  'aria-describedby': ariaDescribedby,
}: Field): Node => {
  const classes = useStyles();
  const [formikField, , { setValue }] = useField(name);
  const intl = useIntl();
  const [internalValue, setInternalValue] = useState<boolean>(
    !!formikField.value,
  );
  return (
    <>
      <FormikCheckbox
        key={name}
        text={getFieldTitle(field, intl)}
        name={name}
        value={
          (field.more && typeof formikField.value === 'string') || internalValue
        }
        onChange={(newValue) => {
          setInternalValue(newValue);
          setValue(newValue && field.more ? '' : newValue);
        }}
        aria-describedby={ariaDescribedby}
      />
      {internalValue && field.more && (
        <div className={classes.more}>
          {field.more?.map((more) => (
            <FormBuilderField
              key={more.key}
              field={more}
              type={more.field_type}
              name={name}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default memo<Field>(FormBuilderCheckbox);
