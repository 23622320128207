import { useEffect, useRef, useState } from 'react';

import { Close } from '@mui/icons-material';
import {
  SwipeableDrawer,
  SwipeableDrawerProps,
  useMediaQuery,
} from '@mui/material';
import { useButton } from 'react-aria';
import { useDispatch } from 'react-redux';

import { tablet } from '@braindate/ui/lib/token/mediaQuery';

import type { Props as ModalProps } from 'src/shared/app/base/modal/component/Modal';
import Modal from 'src/shared/app/base/modal/component/Modal';
import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import {
  hideHelpButton,
  resetHelpButtonHidden,
} from 'src/shared/app/help/action/uiHelpButtonReducerActions';
import ConditionalWrapper from 'src/shared/ui/component/misc/ConditionalWrapper';

import styles from './ResponsiveModal.style';

type Props = {
  forceOpen?: boolean;
  children: Node;
  modalProps?: ModalProps;
  drawerProps?: SwipeableDrawerProps;
  onClose: () => void;
};
const useStyles = createUseThemeStyles(styles);

const ResponsiveModal = ({
  forceOpen,
  children,
  modalProps,
  drawerProps,
  onClose,
}: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const isDesktop = useMediaQuery(tablet);
  const [isOpened, setIsOpened] = useState<boolean>(true);
  const closeButtonRef = useRef<HTMLElement | null>(null);
  useEffect(() => {
    if (isOpened) {
      dispatch(hideHelpButton());
    } else {
      dispatch(resetHelpButtonHidden());
    }
  }, [isOpened]);
  useEffect(() => {
    if (typeof forceOpen !== 'boolean') return;
    setIsOpened(forceOpen);
  }, [forceOpen]);

  const handleClose = () => {
    setIsOpened(false);
  };

  const { buttonProps: closeButtonProps } = useButton(
    {
      elementType: 'svg',
      onPress: handleClose,
    },
    closeButtonRef,
  );

  const onAfterClose = () => {
    if (isOpened) return; // onTransitionEnd is called after the opening animation too

    onClose();
  };

  return (
    <ConditionalWrapper
      condition={isDesktop}
      wrapper={(child) => (
        <Modal
          isOpen={isOpened}
          content={child}
          handleClose={handleClose}
          onAfterClose={onAfterClose}
          hideActions
          {...modalProps}
        />
      )}
      elseWrapper={(child) => (
        <SwipeableDrawer
          anchor="bottom"
          open={isOpened}
          onTransitionEnd={() => {
            if (isOpened) return;
            onAfterClose();
          }}
          onClose={handleClose}
          onOpen={() => {}}
          keepMounted={false}
          disableDiscovery
          disableSwipeToOpen
          PaperProps={{
            sx: {
              maxHeight: '90vh',
            },
          }}
          {...drawerProps}
        >
          <Close
            {...closeButtonProps}
            className={classes.close}
            ref={closeButtonRef}
          />
          {child}
        </SwipeableDrawer>
      )}
    >
      {children}
    </ConditionalWrapper>
  );
};

export default ResponsiveModal;
