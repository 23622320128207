import { lap } from '@braindate/ui/lib/token/mediaQuery';

import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

export default {
  root: {
    display: 'flex',
    alignContent: 'center',
  },
  button: {
    height: 'min-content',
    minHeight: 'unset',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    padding: 0,
    background: 'none',
    borderRadius: 0,
    outlineWidth: '0px !important',
    gap: '5px',
    color: ({ theme }: ThemeFunction<unknown>) => theme.textColor,
    justifyContent: 'center',
  },
  'icon-mobile': {
    [lap]: {
      display: 'none !important',
    },
  },
  'icon-desktop': {
    display: 'none',
    [lap]: {
      display: 'flex',
    },
  },
  avatarActive: {
    outline: ({ theme }: ThemeFunction<unknown>) =>
      `2px solid ${theme.accentColor}`,
  },
};
