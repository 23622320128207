import { useRef } from 'react';

import { Chip } from '@mui/material';
import classNames from 'classnames';
import { useButton } from 'react-aria';
import { useIntl } from 'react-intl';

import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import messages from 'src/shared/app/cheer/l10n/defaultCheersl10n';
import {
  cheerCategoryDelightful,
  cheerCategoryFearless,
  cheerCategoryGenerous,
  cheerCategoryInsightful,
} from 'src/shared/app/cheer/util/cheerUtils';
import type { CheerCategories } from 'src/shared/app/cheer/util/cheerUtils';
import useCheerPlugin from 'src/shared/domain/event/hook/useCheerPlugin';
import CheerDelightfulIcon from 'src/shared/ui/component/icon/CheerDelightfulIcon';
import CheerFearlessIcon from 'src/shared/ui/component/icon/CheerFearlessIcon';
import CheerGenerousIcon from 'src/shared/ui/component/icon/CheerGenerousIcon';
import CheerInsightfulIcon from 'src/shared/ui/component/icon/CheerInsightfulIcon';
import CheerLoveIcon from 'src/shared/ui/component/icon/CheerLoveIcon';

import styles from './CheerBanner.style';

const useStyles = createUseThemeStyles(styles);
type Props = {
  children: string;
  category?: CheerCategories;
  className?: string;
  isAuthor?: boolean;
  onClick?: () => void;
};

const CheerBanner = ({
  children,
  category,
  className,
  onClick,
  isAuthor = true,
}: Props) => {
  const intl = useIntl();
  const { gradient_sent, gradient_received } = useCheerPlugin();
  const classes = useStyles({
    gradient: isAuthor ? gradient_sent : gradient_received,
  });
  const ref = useRef<HTMLDivElement | null>(null);
  const { buttonProps } = useButton(
    {
      elementType: 'div',
      onPress: onClick,
    },
    ref,
  );
  const icons = {
    [cheerCategoryInsightful]: <CheerInsightfulIcon />,
    [cheerCategoryGenerous]: <CheerGenerousIcon />,
    [cheerCategoryFearless]: <CheerFearlessIcon />,
    [cheerCategoryDelightful]: <CheerDelightfulIcon />,
  };
  return (
    <div
      className={classNames(classes.root, className)}
      ref={ref}
      {...(onClick ? buttonProps : {})}
    >
      {category ? (
        <div className={classes.chip}>
          <Chip label={intl.formatMessage(messages[category])} color="info" />
        </div>
      ) : null}
      {category ? icons[category] || <CheerLoveIcon /> : <CheerLoveIcon />}
      {children}
    </div>
  );
};

export default CheerBanner;
