import { Field, Form, useField } from 'formik';
import { useId } from 'react-aria';
import { useIntl } from 'react-intl';

import type { ComplaintContentType } from '@braindate/domain/lib/complaint/type';

import FormikFormGroup from 'src/shared/app/base/component/data-entry/form/FormikFormGroup';
import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import useComplaintText from 'src/shared/components/domain/complaints/hooks/useComplaintText';
import usePossibleComplaintReasons from 'src/shared/components/domain/complaints/hooks/usePossibleComplaintReasons';
import messages from 'src/shared/components/domain/complaints/l10n/complaintsL10n';
import PrimaryButton from 'src/shared/ui/component/button/PrimaryButton';
import RadioList from 'src/shared/ui/component/radio/RadioList';
import Heading from 'src/shared/ui/component/text/Heading';

import styles from './ReportingForm.style';

const useStyles = createUseThemeStyles(styles);
type Props = {
  contentType: ComplaintContentType;
  isSubmitting: boolean;
};

const ReportingForm = ({ contentType, isSubmitting }: Props) => {
  const intl = useIntl();
  const classes = useStyles();
  const { title, subtitle, explanation } = useComplaintText(contentType);
  const possibleComplaintReasons = usePossibleComplaintReasons();
  const headingId = useId();
  const [, { value: reason }] = useField('reason');
  return (
    <Form className={classes.root} noValidate>
      <Heading level={2} layoutClass={classes.title}>
        {title}
      </Heading>

      <div>
        <Heading level={3} layoutClass={classes.subtitle} id={headingId}>
          {subtitle}
        </Heading>
        <p className={classes.explanation}>{explanation}</p>

        <RadioList
          name="reason"
          aria-labelledby={headingId}
          options={possibleComplaintReasons}
        />
      </div>

      <Field
        id="more"
        name="more"
        minRows="3"
        component={FormikFormGroup}
        componentOpts={{
          useTextarea: true,
          label: intl.formatMessage(messages.tellMore),
          hasLengthCounter: true,
        }}
        maxLength="300"
      />
      <div className={classes.submit}>
        <PrimaryButton
          isDisabled={!reason}
          isSubmitting={isSubmitting}
          type="submit"
        >
          {intl.formatMessage(messages.submit)}
        </PrimaryButton>
      </div>
    </Form>
  );
};

export default ReportingForm;
