import { useIntl } from 'react-intl';
import { setLocale } from 'yup';

import messages from 'src/shared/app/base/l10n/validationL10n';

export default function useValidationMessages() {
  const intl = useIntl();
  setLocale({
    // $FlowIssue
    mixed: {
      required: intl.formatMessage(messages.requiredError),
    },
    // $FlowIssue
    string: {
      required: intl.formatMessage(messages.requiredError),
      email: intl.formatMessage(messages.invalidEmailError),
      url: intl.formatMessage(messages.invalidUrlError),
      // $FlowIssue
      max: ({ max }) =>
        intl.formatMessage(messages.maxLengthError, {
          length: max,
        }),
    },
  });
}
