import { useEffect } from 'react';

import { useDispatch } from 'react-redux';

import { markAsTouchDevice } from 'src/shared/app/base/action/deviceActions';

export default function useTouchDetection() {
  const dispatch = useDispatch();
  useEffect(() => {
    window.addEventListener('touchstart', onTouchStart);
    return () => {
      window.removeEventListener('touchstart', onTouchStart);
    };
  }, []);

  const onTouchStart = () => {
    dispatch(markAsTouchDevice());
    const htmlElt = document.documentElement;

    if (htmlElt) {
      htmlElt.classList.add('touch');
    }

    window.removeEventListener('touchstart', onTouchStart);
  };
}
