import type { Node } from 'react';

import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import messages from 'src/shared/app/booking/l10n/bookingL10n';
import { dismissNotification } from 'src/shared/app/notification/action/uiNotificationActions';
import Notification from 'src/shared/app/notification/component/Notification';
import type { Notification as NotificationType } from 'src/shared/app/notification/type/notificationTypes';
import MailSentIcon from 'src/shared/ui/component/icon/MailSentIcon';

import styles from './InvitationSentNotification.style';

type Props = {
  notification: NotificationType<any>;
};
const useStyles = createUseThemeStyles(styles);

const InvitationSentNotification = ({ notification }: Props): Node => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleDismiss = () => {
    dispatch(dismissNotification(notification));
  };

  return (
    <Notification
      timeout={5000}
      icon={<MailSentIcon className={classes.icon} />}
      handleDismiss={handleDismiss}
    >
      {intl.formatMessage(messages.notification)}
    </Notification>
  );
};

export default InvitationSentNotification;
