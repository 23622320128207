import type { Braindate } from '@braindate/domain/lib/braindate/type';
import { getBraindateFeedbackURL } from '@braindate/domain/lib/braindate/util';

import { parseResponse } from 'src/shared/app/base/util/ajaxUtils';
import { queueNotification } from 'src/shared/app/notification/action/uiNotificationActions';
import { convertPostBraindateFeedbackToNotification } from 'src/shared/app/post-braindate/util/postBraindateUtil';
import type { GetState } from 'src/shared/core/type/reduxTypes';

import * as types from './postBraindateActionTypes';

export function setPostBraindateFeedback(value: string, braindate: Braindate) {
  return (dispatch: any, _: GetState, { post }: Record<string, any>) => {
    post(getBraindateFeedbackURL(braindate), {
      answer: value,
    })
      .then(parseResponse)
      .then(() => {
        dispatch(setPostBraindateFeedbackSuccess());
      })
      .catch((error: Error) => {
        dispatch(setPostBraindateFeedbackFailure(error));
        throw error;
      });
  };
}
export function setPostBraindateFeedbackRequest() {
  return {
    type: types.SET_POST_BRAINDATE_FEEDBACK_REQUEST,
  };
}
export function setPostBraindateFeedbackFailure(error: Error) {
  return {
    type: types.SET_POST_BRAINDATE_FEEDBACK_FAILURE,
    error,
  };
}
export function setPostBraindateFeedbackSuccess() {
  return {
    type: types.SET_POST_BRAINDATE_FEEDBACK_SUCCESS,
  };
}
export function showPostBraindateFeedback(braindate: Braindate) {
  return queueNotification(
    convertPostBraindateFeedbackToNotification(braindate),
  );
}
