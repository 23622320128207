import * as React from 'react';

import * as Sentry from '@sentry/react';

import Error500 from './Error500';

type Props = {
  children: React.ReactNode;
  onError?: (arg0: Error) => void;
};
type State = {
  sentryId: string;
  error: Error | null | undefined;
  errorInfo: Record<string, any> | null | undefined;
};

class ErrorBoundry extends React.PureComponent<Props, State> {
  // eslint-disable-next-line react/state-in-constructor
  state = {
    sentryId: '',
    error: null,
    errorInfo: null,
  };

  componentDidCatch(error: Error, errorInfo: Record<string, any>) {
    Sentry.configureScope((scope) => scope.setExtras(errorInfo));
    const sentryId = Sentry.captureException(error);
    this.setState({
      sentryId,
      error,
      errorInfo,
    });

    // eslint-disable-next-line react/destructuring-assignment
    if (this.props.onError) {
      // eslint-disable-next-line react/destructuring-assignment
      this.props.onError(error);
    }
  }

  render() {
    const { sentryId, error, errorInfo } = this.state;

    if (error) {
      if (process.env.DEV_MODE) {
        // eslint-disable-next-line no-console
        console.error(error);
      }

      return (
        <Error500 sentryId={sentryId} error={error} errorInfo={errorInfo} />
      );
    }

    // eslint-disable-next-line react/destructuring-assignment
    return this.props.children;
  }
}

export default ErrorBoundry;
