import { useMemo, useState } from 'react';

import { useDispatch } from 'react-redux';
import { SwitchTransition, Transition } from 'react-transition-group';

import type { Braindate } from '@braindate/domain/lib/braindate/type';
import { isGroupTopic } from '@braindate/domain/lib/topic/util/';
import type { User } from '@braindate/domain/lib/user/type';

import ResponsiveModal from 'src/shared/app/base/modal/component/ResponsiveModal';
import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import { getCheckedInOtherParticipants } from 'src/shared/app/check-in/util/checkInUtil';
import CheerModalWrapper from 'src/shared/app/cheer/component/modal/CheerModalWrapper';
import NewCheerForm from 'src/shared/app/cheer/component/modal/new/NewCheerForm';
import PostBraindateCheerModalForm from 'src/shared/app/cheer/component/modal/new/PostBraindateCheerModalForm';
import { dismissNotification } from 'src/shared/app/notification/action/uiNotificationActions';
import type { Notification } from 'src/shared/app/notification/type/notificationTypes';
import { getNotificationData } from 'src/shared/app/notification/util/notificationUtil';
import {
  getBraindateOtherParticipant,
  getBraindateTopic,
} from 'src/shared/domain/braindate/util/braindateUtil';
import useSelf from 'src/shared/domain/user/hook/useSelf';

import styles from './PostBraindateCheerModal.style';

const useStyles = createUseThemeStyles(styles);
type Props = {
  notification: Notification<{
    braindate: Braindate;
  }>;
};

const PostBraindateCheerModal = ({ notification }: Props) => {
  const self = useSelf();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { braindate } = getNotificationData(notification);
  const topic = getBraindateTopic(braindate);
  const participants = useMemo(() => {
    if (!self) return [];
    return isGroupTopic(topic)
      ? getCheckedInOtherParticipants(braindate, self)
      : [getBraindateOtherParticipant(braindate, self)];
  }, [self]);
  const [userToCheer, setUserToCheer] = useState<User | null>(
    participants.length === 1 ? participants[0] : null,
  );
  const [showForm, setShowForm] = useState<boolean>(false);

  const handleSubmit = ({ user }) => {
    setShowForm(true);
    setUserToCheer(user);
  };

  const handleClose = () => {
    setShowForm(false);
    dispatch(dismissNotification(notification));
  };

  return (
    <ResponsiveModal forceOpen={!!notification.active} onClose={handleClose}>
      <div className={classes.container}>
        <SwitchTransition mode="out-in">
          <Transition key={showForm && userToCheer ? 'form' : 'intro'}>
            {(state) => (
              <CheerModalWrapper transitionState={state}>
                {showForm && userToCheer ? (
                  <NewCheerForm
                    notification={{
                      ...notification,
                      data: {
                        braindate,
                        user: userToCheer,
                      },
                    }}
                    handleClose={handleClose}
                    key="form"
                  />
                ) : (
                  <PostBraindateCheerModalForm
                    braindate={braindate}
                    participants={participants}
                    userToCheer={userToCheer}
                    handleSubmit={handleSubmit}
                    key="intro"
                  />
                )}
              </CheerModalWrapper>
            )}
          </Transition>
        </SwitchTransition>
      </div>
    </ResponsiveModal>
  );
};

export default PostBraindateCheerModal;
