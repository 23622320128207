import { rem } from 'polished';

import { baseFontSize } from '@braindate/ui/lib/token/typography';

import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

export default {
  form: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    // input class not working? had to add the input style here (tried everything, nothing else is working)
    '& input': {
      color: ({ theme }: ThemeFunction<unknown>) =>
        theme.name === 'dark'
          ? `${theme.textColorReverse} !important`
          : theme.accentColor,
    },
  },
  magnifier: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    cursor: 'pointer',
    margin: [0, 6, 0, 0],
    color: ({ theme }: ThemeFunction<unknown>) =>
      theme.name === 'dark' ? theme.textColorReverse : theme.accentColor,
    '& svg': {
      width: 20,
      height: 20,
    },
  },
  input: {
    position: 'relative',
    width: '100%',
    height: '100%',
    padding: [0, '!important'],
    color: 'black !important',
    border: [['none'], '!important'],
    fontWeight: [600, '!important'],
    lineHeight: [1, '!important'],
    WebkitFontSmoothing: 'antialiased',
    '&::placeholder': {
      fontSize: rem(14, baseFontSize),
      fontWeight: 500,
      color: ({ theme }: ThemeFunction<unknown>) =>
        theme.name === 'dark' ? theme.textColorReverse : theme.textColor,
    },
  },
  dismiss: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    fill: ({ theme }: ThemeFunction<unknown>) => theme.accentColor,
    '& a svg': {
      width: 16,
      height: 16,
    },
  },
};
