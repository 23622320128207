import { createSlice } from '@reduxjs/toolkit';

const initialState: {
  joined: number[];
} = {
  joined: [],
};
const joinedBraindatesSlice = createSlice({
  name: 'joinedBraindates',
  initialState,
  reducers: {
    joinedBraindateCall: (
      state: Record<string, any>,
      data: Record<string, any>,
    ) => {
      state.joined.push(data.payload);
    },
  },
});
export const { joinedBraindateCall } = joinedBraindatesSlice.actions;
export default joinedBraindatesSlice.reducer;
