import { createSlice } from '@reduxjs/toolkit';

export type UiPeopleReducerState = {
  isFrozen: boolean;
  currentPage: number;
};
const initialState: UiPeopleReducerState = {
  isFrozen: false,
  currentPage: 1,
};
const slice = createSlice<UiPeopleReducerState>({
  name: 'ui-people-reducer',
  initialState,
  reducers: {
    freezePeople: (state) => {
      state.isFrozen = true;
    },
    unfreezePeople: (state) => {
      state.isFrozen = false;
    },
    setPeopleCurrentPage: (
      state,
      {
        payload,
      }: {
        payload: number;
      },
    ) => {
      state.currentPage = payload;
    },
  },
});
export const { freezePeople, unfreezePeople, setPeopleCurrentPage } =
  slice.actions;
export default slice.reducer;
