import { useEffect, useRef } from 'react';

import { useLocation } from 'react-router-dom';

import useAuthenticationToken from 'src/shared/app/authentication/hook/useAuthenticationToken';
import useDidUpdateEffect from 'src/shared/app/base/hook/useDidUpdateEffect';
import useMembership from 'src/shared/domain/membership/hook/useMembership';
import useSelf from 'src/shared/domain/user/hook/useSelf';

import useLiveChat from 'plugin/live-chat/hook/useLiveChat';
import useLiveChatActions from 'plugin/live-chat/hook/useLiveChatActions';

export function hideLiveChatWidget() {
  window.Tawk_API.endChat();
  window.Tawk_API.hideWidget();
}
export default function useLiveChatVisitorAuth() {
  const token = useAuthenticationToken();
  const hasLiveChat = useLiveChat();
  const { load, kill, identify, update } = useLiveChatActions();
  const membership = useMembership();
  const selfUser = useSelf();
  const isIdentified = useRef(false);
  useEffect(() => {
    if (hasLiveChat) {
      load();
    } else {
      kill();
    }
  }, [token, hasLiveChat]);
  const location = useLocation();
  useDidUpdateEffect(() => {
    update();
  }, [location]);
  useDidUpdateEffect(() => {
    if (hasLiveChat && membership && selfUser && !isIdentified.current) {
      identify();
      isIdentified.current = true;
    }
  }, [membership, selfUser, hasLiveChat]);
}
