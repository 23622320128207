import {
  helpFeature,
  marketPromptsFeature,
  onboardingIntroAFeature,
} from 'src/shared/app/feature/settings/featureSettings'; // Features are usually enable or disable at an event or user level. Features
// configured here can therefore be overwritten once we fetch the event or user
// data (in `withFeatureController`) if an event setting exist.
// This config object is mostly helpful for enabling or disabling release features,
//  i.e. features whose development is in progress or to have a default
// turned on for experimental features.

export default {
  [helpFeature]: true,
  [marketPromptsFeature]: true,
  [onboardingIntroAFeature]: true,
};
