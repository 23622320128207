import { lap } from '@braindate/ui/lib/token/mediaQuery';

import { takeOverElevation } from 'src/shared/app/base/style/token/elevation';
import { horizontalSmallDeviceBreakpoint } from 'src/shared/app/base/style/util/responsive';
import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

type ThemeProperties = ThemeFunction<{
  noBottom?: boolean;
}>;
export default {
  '@keyframes slideUp': {
    from: {
      transform: 'translateY(100%)',
    },
    to: {
      transform: 'translateY(0)',
    },
  },
  root: {
    position: 'fixed',
    left: 0,
    right: 0,
    bottom: ({ noBottom }: ThemeProperties) =>
      !noBottom ? 88 : '0 !important',
    zIndex: takeOverElevation,
    backgroundColor: ({ theme }: ThemeFunction<unknown>) => theme.colorBase,
    [lap]: {
      bottom: 0,
    },
    [horizontalSmallDeviceBreakpoint]: {
      top: '0px !important',
      paddingTop: 8,
      overflow: 'auto',
      bottom: 40,
    },
  },
  animated: {
    animation: '$slideUp 0.5s ease-out',
    animationFillMode: 'forwards',
  },
  takeOverContent: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    maxHeight: '100vh',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
};
