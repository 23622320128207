import type { BraindateEvent } from '@braindate/domain/lib/event/type';
import { getEventPlugins } from '@braindate/domain/lib/event/util';
import { assertObject } from '@braindate/util/lib/assert';

import { liveChatPlugin } from 'plugin/live-chat/setting/liveChatSettings';

export function getLiveChatOptions(
  event: BraindateEvent,
): Record<string, any> | null | undefined {
  const options = getEventPlugins(event)[liveChatPlugin];

  if (options) {
    assertObject(options, 'options');
    return options;
  }
}
export function getLiveChatProvider(
  sdkOptions: Record<string, any>,
): string | null | undefined {
  return sdkOptions.provider;
}
