export const TAG_TYPE = {
  TOPIC: 'TOPIC',
  CHEER: 'CHEER',
  TOPIC_MODERATION: 'TOPIC_MODERATION',
  TOPIC_KEYWORDS: 'TOPIC_KEYWORDS',
  PRESELECTED_KEYWORDS: 'PRESELECTED_KEYWORDS',
  INSPIRATION_TOPIC: 'INSPIRATION_TOPIC',
  USER: 'USER',
  BRAINDATE: 'BRAINDATE',
  CONVERSATION: 'CONVERSATION',
  EVENT: 'EVENT',
  MEMBERSHIP: 'MEMBERSHIP',
  MEMBERSHIP_OAUTH_STATUS: 'MEMBERSHIP_OAUTH_STATUS',
  LOCATION: 'LOCATION',
  NOTIFICATION: 'NOTIFICATION',
  UNAVAILABILITIES: 'UNAVAILABILITIES',
  MANUAL_AVAILABILITIES: 'MANUAL_AVAILABILITIES',
  PUSH_SUBSCRIPTION: 'PUSH_SUBSCRIPTION',
  COMMENT: 'COMMENT',
  FAQ_QUESTION: 'FAQ_QUESTION',
  COMPLAINT: 'COMPLAINT',
  TOGETHERNESS_SEEN: 'TOGETHERNESS_SEEN',
};
export const TAG_ID = {
  LIST: 'LIST',
};
export const ENTITIES_TYPE = {
  braindates: TAG_TYPE.BRAINDATE,
  conversations: TAG_TYPE.CONVERSATION,
  events: TAG_TYPE.EVENT,
  memberships: TAG_TYPE.MEMBERSHIP,
  topics: TAG_TYPE.TOPIC,
  users: TAG_TYPE.USER,
  locations: TAG_TYPE.LOCATION,
  cheer: TAG_TYPE.CHEER,
  notifications: TAG_TYPE.NOTIFICATION,
};
