import useLocations from 'src/shared/domain/location/hook/useLocations';
import useSelfLocations from 'src/shared/domain/location/hook/useSelfLocations';
import useMembership from 'src/shared/domain/membership/hook/useMembership';
import { computeMembershipTimezone } from 'src/shared/domain/membership/util/membershipUtil';

const useMembershipTimezone = () => {
  const membership = useMembership();
  const eventLocations = useLocations();
  const userLocations = useSelfLocations();
  return computeMembershipTimezone(membership, eventLocations, userLocations);
};

export default useMembershipTimezone;
