import { useEffect } from 'react';

import { Settings } from 'luxon';

import useMembershipTimezone from 'src/shared/domain/membership/hook/useMembershipTimezone';

export default function useSetAppTimezone() {
  const timezone = useMembershipTimezone();
  useEffect(() => {
    Settings.defaultZone = timezone;
  }, [timezone]);
  return timezone;
}
