import * as types from 'src/shared/app/market/action/uiMarketActionTypes';
import { createReducer } from 'src/shared/core/util/reducerUtils';

const initialState = {
  postedTopicID: 0,
  isAutocompleteShown: false,
  topic: null,
  topicNotFound: false,
  isFrozen: false,
  hiddenPrompts: [],
  currentPage: 1,
  showTopicInspirations: false,
};

const _showAutocomplete = (state: Record<string, any>) => ({
  ...state,
  isAutocompleteShown: true,
});

const _hideAutocomplete = (state: Record<string, any>) => ({
  ...state,
  isAutocompleteShown: false,
});

const _setMarketTopicNotFoundError = (state: Record<string, any>) => ({
  ...state,
  topicNotFound: true,
});

const _dismissMarketTopicNotFoundError = (state: Record<string, any>) => ({
  ...state,
  topicNotFound: false,
});

const _setFirstTopic = (
  state: Record<string, any>,
  action: Record<string, any>,
) => {
  const { topicId: topic } = action;
  return { ...state, topic };
};

const _resetFirstTopic = (state: Record<string, any>) => ({
  ...state,
  topic: null,
});

const _freeze = (state: Record<string, any>) => {
  return { ...state, isFrozen: true };
};

const _unfreeze = (state: Record<string, any>) => ({
  ...state,
  isFrozen: false,
});

const _setMarketCurrentPage = (
  state: Record<string, any>,
  action: Record<string, any>,
) => {
  const { page } = action;
  return { ...state, currentPage: page };
};

const _resetMarketCurrentPage = (state: Record<string, any>) => {
  return { ...state, currentPage: 1, showTopicInspirations: false };
};

const _showTopicInspirations = (
  state: Record<string, any>,
  action: Record<string, any>,
) => {
  const { show } = action;
  return { ...state, showTopicInspirations: show };
};

export default createReducer(initialState, {
  [types.SHOW_MARKET_AUTOCOMPLETE]: _showAutocomplete,
  [types.HIDE_MARKET_AUTOCOMPLETE]: _hideAutocomplete,
  [types.SET_MARKET_FIRST_TOPIC]: _setFirstTopic,
  [types.RESET_MARKET_FIRST_TOPIC]: _resetFirstTopic,
  [types.SHOW_MARKET_TOPIC_NOT_FOUND]: _setMarketTopicNotFoundError,
  [types.DISMISS_MARKET_TOPIC_NOT_FOUND]: _dismissMarketTopicNotFoundError,
  [types.FREEZE_MARKET_TOPICS]: _freeze,
  [types.UNFREEZE_MARKET_TOPICS]: _unfreeze,
  [types.SET_MARKET_CURRENT_PAGE]: _setMarketCurrentPage,
  [types.RESET_MARKET_CURRENT_PAGE]: _resetMarketCurrentPage,
  [types.SHOW_TOCIC_INSPIRATIONS]: _showTopicInspirations,
});
