import { memo, useRef } from 'react';
import type { Node } from 'react';

import Popover from '@mui/material/Popover';
import { useIntl } from 'react-intl';

import { getNotificationId } from '@braindate/domain/lib/notification/util';

import { ampli } from 'src/ampli';
import {
  useClearAllNotificationsMutation,
  useGetNotificationsQuery,
} from 'src/shared/app/base/api/endpoint/notificationsEndpoint';
import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import { notificationsMenuId } from 'src/shared/app/nav/settings/navSettings';
import NotificationCard from 'src/shared/app/notification/component/card/NotificationCard';
import NotificationCardSkeleton from 'src/shared/app/notification/component/card/NotificationCardSkeleton';
import EmptyNotificationFeed from 'src/shared/app/notification/component/main/EmptyNotificationFeed';
import messages from 'src/shared/app/notification/l10n/notificationL10n.json';
import trackingEvents from 'src/shared/core/service/tracking/trackingEvents';
import { VARIANT } from 'src/shared/ui/component/button/Button';
import SecondaryButton from 'src/shared/ui/component/button/SecondaryButton';
import FeedQuery from 'src/shared/ui/component/data-display/FeedQuery';

import styles from './NotificationsMenu.style';

const useStyles = createUseThemeStyles(styles);
type Props = {
  children: Node;
  isOpened: boolean;
  onClose: () => void;
  className?: string;
  withRoundedChild?: boolean;
};

const NotificationsMenu = ({
  children,
  isOpened,
  onClose,
  className,
  withRoundedChild,
}: Props) => {
  /*
  |----------------------------------------------------------------------------
  | States
  |----------------------------------------------------------------------------
  */
  const anchorRef = useRef();
  const { data } = useGetNotificationsQuery();
  const notificationsCount = data?.count || 0;
  const [clearAllNotifications, { isLoading: isClearingAll }] =
    useClearAllNotificationsMutation();

  /*
  |----------------------------------------------------------------------------
  | Hooks
  |----------------------------------------------------------------------------
  */
  const intl = useIntl();
  const classes = useStyles({
    withRoundedChild,
  });

  /*
  |----------------------------------------------------------------------------
  | Actions
  |----------------------------------------------------------------------------
  */
  const onClearAllClick = () => {
    ampli.notificationCleared();
    trackingEvents.notification.readAll('Top button');
    clearAllNotifications();
  };

  /*
  |----------------------------------------------------------------------------
  | Elements
  |----------------------------------------------------------------------------
  */
  return (
    <div className={className}>
      <span className={classes.button} ref={anchorRef}>
        {children}
      </span>

      {anchorRef.current ? (
        <Popover
          id={notificationsMenuId}
          open={isOpened}
          anchorEl={anchorRef.current}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          onClose={() => onClose()}
          classes={{
            paper: classes.root,
          }}
        >
          <div className={classes.notificationsMenu}>
            <header className={classes.header}>
              <h3 className={classes.title}>
                {intl.formatMessage(messages.titlePage)}
              </h3>

              <SecondaryButton
                onClick={onClearAllClick}
                size="small"
                isFetching={isClearingAll}
                isDisabled={!notificationsCount}
                layoutClass={classes.actionButton}
              >
                {intl.formatMessage(messages.clearAll)}
              </SecondaryButton>
            </header>

            <div className={classes.feed}>
              <FeedQuery
                useQuery={useGetNotificationsQuery}
                manualConfig={{
                  isActive: true,
                  variant: VARIANT.SECONDARY,
                }}
                emptyComponent={<EmptyNotificationFeed small />}
                params={{
                  limit: 5,
                }}
                isCompact
                layoutClass={classes.list}
                renderItem={(notification) => {
                  if (notification.is_hidden) return null;
                  return (
                    <NotificationCard
                      notification={notification}
                      onClick={onClose}
                      key={getNotificationId(notification)}
                    />
                  );
                }}
                renderSkeleton={() => <NotificationCardSkeleton />}
              />
            </div>
          </div>
        </Popover>
      ) : null}
    </div>
  );
};

export default memo<Props>(NotificationsMenu);
