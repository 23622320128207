import { memo } from 'react';
import type { Node } from 'react';

import classNames from 'classnames';

import Markdown from 'src/shared/app/base/component/text/Markdown';
import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import CloseButton from 'src/shared/ui/component/button/CloseButton';
import PrimaryButton from 'src/shared/ui/component/button/PrimaryButton';

import styles from './BasePromptCard.style';
import CallToActionCard from './CallToActionCard';

type Props = {
  icon?: Node;
  children?: Node;
  text: string;
  description?: string;
  confirmCta: string;
  onClick: () => void;
  handleDismiss?: () => void;
};
const useStyles = createUseThemeStyles(styles);

const BasePromptCard = ({
  icon,
  children,
  text,
  description,
  confirmCta,
  onClick,
  handleDismiss,
}: Props): Node => {
  /*
   |----------------------------------------------------------------------------
   | Classes
   |----------------------------------------------------------------------------
   */
  const classes = useStyles();
  const headerClasses = classNames(classes.header, {
    [classes['header-isLeftAligned']]: icon,
  });

  /*
   |----------------------------------------------------------------------------
   | Elements
   |----------------------------------------------------------------------------
   */
  const dismissElt = handleDismiss && (
    <span className={classes.ctn}>
      <CloseButton layoutClass={classes.closeBtn} onClick={handleDismiss} />
    </span>
  );
  const iconElt = icon && <span className={classes.icon}>{icon}</span>;
  return (
    <CallToActionCard>
      <div className={classes.root}>
        {dismissElt}
        <div className={headerClasses}>
          {iconElt}
          <Markdown>{text}</Markdown>
        </div>
        {description && (
          <div className={classes.description}>{description}</div>
        )}
        {children && children}
        <PrimaryButton onClick={onClick}>{confirmCta}</PrimaryButton>
      </div>
    </CallToActionCard>
  );
};

export default memo<Props>(BasePromptCard);
