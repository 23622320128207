/* eslint-disable no-console */
import { assertString } from '@braindate/util/lib/assert';
import { hasURLProtocol } from '@braindate/util/lib/url';

import {
  addTrailingSlash,
  replaceBackSlashes,
} from 'src/shared/core/util/stringUtil';

export function getStaticAsset(path: string): string {
  assertString(path, 'path');
  let p = replaceBackSlashes(path);

  if (hasURLProtocol(path)) {
    // When defining a ChunkExtractor, we need to supply a public path. This
    // make assets that are part of a lazy loaded bundle be prepended with the
    // static URL, but with a wrong path. This resets the original asset path.
    if (p.indexOf(__webpack_public_path__) > -1) {
      p = p.replace(__webpack_public_path__, '');
    } else {
      return p;
    }
  }

  const segments = p.split('/');
  const filename = segments[segments.length - 1];
  return `${addTrailingSlash(__webpack_public_path__)}${filename}`;
}
