import { memo } from 'react';

import type { Braindate } from '@braindate/domain/lib/braindate/type';

import { getBraindateWith } from 'src/shared/app/braindate/component/list/util/braindateUtils';
import UserLinkBanner from 'src/shared/components/domain/user/banner/UserLinkBanner';
import { getBraindateTopic } from 'src/shared/domain/braindate/util/braindateUtil';
import useSelf from 'src/shared/domain/user/hook/useSelf';

type Props = {
  braindate: Braindate;
  withAvatar?: boolean;
  isPreview?: boolean;
  variant?: 'horizontal' | 'vertical';
};

const BraindateCardAuthor = ({
  braindate,
  withAvatar,
  isPreview,
  variant = 'vertical',
}: Props) => {
  const self = useSelf();
  const topic = getBraindateTopic(braindate);
  if (!self) return null;
  const braindateWith = getBraindateWith(self, braindate);
  return (
    <UserLinkBanner
      user={braindateWith}
      topic={topic}
      withAvatar={withAvatar}
      isPreview={isPreview}
      variant={variant}
    />
  );
};

export default memo<Props>(BraindateCardAuthor);
