import React, { useEffect } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { IconButton, Tooltip } from '@mui/material';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { ampli } from 'src/ampli';
import useRoutes from 'src/shared/app/base/route/hook/useRoutes';
import { newTopicRoute } from 'src/shared/app/base/route/setting/routeSettings';
import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import messages from 'src/shared/app/help/l10n/helpL10n';
import { newTopicButtonPortal } from 'src/shared/app/nav/settings/navSettings';
import LazyNewTopicMain from 'src/shared/app/topic/new/component/LazyNewTopicMain';
import Portal from 'src/shared/ui/component/misc/Portal';

import styles from './HelpButton.style';

const useStyles = createUseThemeStyles(styles);

const NewTopicFab = () => {
  const intl = useIntl();
  const classes = useStyles();
  const [newTopicPath] = useRoutes(newTopicRoute);
  useEffect(() => {
    LazyNewTopicMain.preload();
  }, []);
  return (
    <Portal id={newTopicButtonPortal}>
      <Tooltip title={intl.formatMessage(messages.newTopicTooltip)} arrow>
        <IconButton
          color="primary"
          variant="contained"
          aria-label={intl.formatMessage(messages.newTopicTooltip)}
          classes={{
            root: classes.root,
          }}
          component={Link}
          to={newTopicPath}
          onClick={() => {
            ampli.topicCreationStart({
              Origin: 'Mobile FAB',
            });
          }}
        >
          <AddIcon className={classes.icon} color="inherit" />
        </IconButton>
      </Tooltip>
    </Portal>
  );
};

export default NewTopicFab;
