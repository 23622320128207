import { memo, useEffect, useRef } from 'react';
import type { Node } from 'react';

import type { Picture } from '@braindate/domain/lib/base/type';

import {
  getOriginalPicture,
  getPictureSrcSets,
  isOriginalPictureVectorial,
} from 'src/shared/app/base/util/imageUtils';

type Props = {
  picture: Picture;
  alt: string;
  className?: string;
  displayWidth: number;
  handleLoad?: () => void;
  handleError?: () => void;
  provideFallback: boolean;
};

const ResponsivePicture = ({
  picture,
  alt,
  className,
  displayWidth,
  handleLoad,
  handleError,
  provideFallback,
}: Props): Node => {
  const imgRef = useRef(null);
  useEffect(() => {
    if (handleLoad) {
      const { current: imgElt } = imgRef;

      if (imgElt instanceof HTMLImageElement && imgElt.complete) {
        handleLoad();
      }
    }
  }, []);
  let fallbackApplied = false;

  const onError = () => {
    if (handleError) {
      handleError();
    }

    if (provideFallback && !fallbackApplied) {
      fallbackApplied = true;
      // $FlowIssue
      imgRef.current.onerror = null;
      // $FlowIssue
      imgRef.current.onload = null;
      // $FlowIssue
      imgRef.current.srcset = '';
      // $FlowIssue
      imgRef.current.src = getOriginalPicture(picture);
    }
  };

  const props = {
    alt,
    className,
    onLoad: handleLoad,
    onError,
    ref: imgRef,
  };

  if (isOriginalPictureVectorial(picture) || !displayWidth) {
    return <img {...props} src={getOriginalPicture(picture)} alt={props.alt} />;
  }

  // TODO: build source elements (handle webP format)
  const sourceElts = [];
  const { src, srcSet } = getPictureSrcSets(picture, displayWidth);
  return (
    <picture>
      {sourceElts}

      <img
        {...props}
        src={src}
        srcSet={srcSet}
        style={{
          verticalAlign: 'middle',
        }}
        alt={props.alt}
      />
    </picture>
  );
};

export default memo<Props>(ResponsivePicture);
