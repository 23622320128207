/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { em } from 'polished';

const styles = () => ({
  root: css({
    fontSize: em(22),
    width: em(16),
    height: em(16),
    verticalAlign: 'middle',
    fill: 'currentColor',
    stroke: 'none',
  }),
});

export default styles;
