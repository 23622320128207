import { useLocation } from 'react-router-dom';

import { getPathWithSearchString } from 'src/shared/app/authentication/util/urlUtils';
/**
 * @param {Object} route object
 * @returns {String} List of params that need to be forwarded
 */

export default function useRouteWithUrlParamsString(
  route: Record<string, any>,
): string {
  const location = useLocation();
  return getPathWithSearchString(location, route);
}
