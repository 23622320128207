import { css } from '@emotion/react';
import { em } from 'polished';

const styles = () => ({
  root: css({
    width: em(30),
    height: em(30),
    fill: 'currentColor',
    stroke: 'none',
    fontSize: '0.5em',
  }),
});

export default styles;
