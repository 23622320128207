import { memo } from 'react';
import type { Node } from 'react';

import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';

import styles from './GroupAvatarQueueEmpty.style';

type Props = {
  number?: number | null | undefined;
  isFilled?: boolean;
  hasPlus?: boolean;
  variant: string;
};
const useStyles = createUseThemeStyles(styles);

const GroupAvatarQueueEmpty = ({
  number,
  isFilled,
  hasPlus,
  variant,
}: Props): Node => {
  /*
   |----------------------------------------------------------------------------
   | Classes
   |----------------------------------------------------------------------------
   */
  const classes = useStyles({
    isFilled,
    variant,
  });
  const hasNumber = number && number > 1;
  return (
    <div className={classes.root}>
      {(hasNumber || hasPlus) && (
        <div>
          <span className={classes.plus}>+</span>
          {hasNumber && number}
        </div>
      )}
    </div>
  );
};

export default memo<Props>(GroupAvatarQueueEmpty);
