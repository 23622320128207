import { createElement } from 'react';
import type { ComponentType } from 'react';

import { hydrateRoot } from 'react-dom/client';

import { assertLooseObject, assertObject } from '@braindate/util/lib/assert';

import { containerId } from 'src/shared/app/base/constant/domConstants';
import type { Store } from 'src/shared/core/type/reduxTypes';
/**
 * Render the application client-side
 * @param   {React.ComponentType<any>} comp - Component to render
 * @param   {Store} store - Redux store
 * @param   {Object} [messages] - Translated strings
 * @returns {undefined}
 *
 * @throws Will throw an exception if parameters are not valid
 */

export default function renderApp(
  comp: ComponentType<any>,
  store: Store,
  messages?: Record<string, any>,
): void {
  assertObject(store, 'store');
  assertLooseObject(messages, 'messages');
  const rootElt = document.querySelector(`#${containerId}`);

  if (rootElt) {
    // https://e180.atlassian.net/browse/PAX-4311
    // Fix found here: https://github.com/facebook/react/issues/24430#issuecomment-1156537554
    rootElt
      .querySelectorAll('html > script[src^=chrome-extension]')
      .forEach((s) => {
        s.parentNode?.removeChild(s);
      });
  }

  if (rootElt) {
    hydrateRoot(
      rootElt,
      createElement(comp, {
        store,
        messages,
      }),
    );
  }
}
