import { rem } from 'polished';

import { fadeDuration } from '@braindate/ui/lib/token/animation';
import { lap } from '@braindate/ui/lib/token/mediaQuery';
import { baseFontSize } from '@braindate/ui/lib/token/typography';

import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

export default {
  menuItem: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 'unset !important',
    '& span, & svg': {
      transition: `${fadeDuration} ease-out`,
    },
    '&:focus': {
      outlineOffset: 0,
      outlineWidth: '0px !important',
    },
    '& .MuiListItemIcon-root': {
      color: ({ theme }: ThemeFunction<unknown>) => theme.textColor,
    },
    '&:hover': {
      color: ({ theme }: ThemeFunction<unknown>) => theme.accentColor,
      '& .MuiListItemIcon-root': {
        color: ({ theme }: ThemeFunction<unknown>) => theme.accentColor,
      },
    },
  },
  menuItemActive: {
    color: ({ theme }: ThemeFunction<unknown>) => theme.accentColor,
    '& .MuiListItemIcon-root': {
      color: ({ theme }: ThemeFunction<unknown>) => theme.accentColor,
    },
  },
  menuContent: {
    display: 'flex',
    width: '100%',
  },
  divider: {
    width: '100%',
    position: 'absolute',
    bottom: 0,
    left: 0,
  },
  withDivider: {
    marginBottom: 4,
    alignItems: 'flex-start',
  },
  iconContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    fontSize: `${rem(12, baseFontSize)} !important`,
    [lap]: {
      fontSize: '1rem',
    },
  },
  title: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
};
