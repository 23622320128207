import { css } from '@emotion/react';
import { em } from 'polished';

const styles = () => ({
  root: css({
    width: em(150),
    height: em(118),
  }),
});

export default styles;
