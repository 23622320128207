import type { Node } from 'react';

import classNames from 'classnames';
import { useVisuallyHidden } from 'react-aria';

import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';

import styles from './Label.style';

const useStyles = createUseThemeStyles(styles);
type Props = {
  className?: string;
  children?: Node;
  hidden?: boolean;
};

const Label = ({ className, children, hidden, ...props }: Props) => {
  const classes = useStyles();
  const { visuallyHiddenProps } = useVisuallyHidden();
  return (
    <label
      {...(hidden ? visuallyHiddenProps : {})}
      {...props}
      className={classNames(classes.root, className)}
    >
      {children}
    </label>
  );
};

export default Label;
