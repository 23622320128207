import { useEffect, useState } from 'react';

import useEventListener from 'src/shared/app/base/component/hook/useEventListener';

const debug = require('debug')('useTrapTabs');

const focusableElements =
  'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';
type RefObject = {
  current: HTMLElement | null | undefined;
};

const isElementVisible = (element) => element.offsetParent !== null;

const getFirstVisibleElement = (elements: NodeList<HTMLElement>) =>
  Array.from(elements).find(isElementVisible);

const getLastVisibleElement = (elements: NodeList<HTMLElement>) =>
  Array.from(elements).reverse().find(isElementVisible);

export default function useTrapTabs(ref: RefObject) {
  // https://uxdesign.cc/how-to-trap-focus-inside-modal-to-make-it-ada-compliant-6a50f9a70700

  /**
   * Trap Tabs
   */
  const handleTabs = (e: KeyboardEvent) => {
    if (ref.current) {
      const focusableContent = ref.current.querySelectorAll(focusableElements);
      const firstFocusableElement = getFirstVisibleElement(focusableContent);
      const lastFocusableElement = getLastVisibleElement(focusableContent);
      debug(
        'lastFocusableElement',
        firstFocusableElement,
        'lastFocusableElement',
        lastFocusableElement,
      );

      if (ref.current) {
        const isTabPressed = e.key === 'Tab' || e.keyCode === 9;

        if (!isTabPressed) {
          return;
        }

        if (e.shiftKey) {
          // if shift key pressed for shift + tab combination
          if (
            lastFocusableElement &&
            document.activeElement === firstFocusableElement
          ) {
            lastFocusableElement.focus(); // add focus for the last focusable element

            e.preventDefault();
          }
        } else {
          // if tab key is pressed
          // eslint-disable-next-line no-lonely-if
          if (
            firstFocusableElement &&
            document.activeElement === lastFocusableElement
          ) {
            // if focused has reached to last focusable element then focus first focusable element after pressing tab
            firstFocusableElement.focus(); // add focus for the first focusable element

            e.preventDefault();
          }
        }
      }
    }
  };

  useEventListener('keydown', handleTabs);

  /**
   * Autofocus last active element on unmount
   */
  const [sourceActiveElement, setSourceActiveElement] = useState<
    HTMLElement | null | undefined
  >();
  useEffect(() => {
    if (document.activeElement) {
      setSourceActiveElement(document.activeElement);
    }
  }, []);
  useEffect(
    () => () => {
      if (sourceActiveElement) sourceActiveElement.focus();
    },
    [sourceActiveElement],
  );

  /**
   * Autofocus first element
   */
  useEffect(() => {
    if (ref.current) {
      const focusableContent = ref.current.querySelectorAll(focusableElements);
      const firstFocusableElement = getFirstVisibleElement(focusableContent);

      if (firstFocusableElement) {
        firstFocusableElement.focus();
      }
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref, ref.current]);
}
