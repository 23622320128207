import { IntlShape } from 'react-intl';

import { topicGroupKind } from '@braindate/domain/lib/topic/setting';
import { assertObject } from '@braindate/util/lib/assert';

import messages from 'src/shared/app/market/guidance/l10n/guidanceL10n';
import {
  kindFilterParam,
  upcomingSortingOrder,
} from 'src/shared/app/market/settings/marketSettings';

export const guidanceFirstInvitation = 'first-invitation';
export const guidancePendingInvitations = 'pending-invitations';
export const guidanceQuickStart = 'quick-start';
export const guidanceSearchKeyword = 'search-keyword';
export const guidanceSearchInterest = 'search-interest';
export const topIndex = 0; // 1st position

export const firstQuickStartIndex = 3; // 4th position

export const secondQuickStartIndex = 19; // 20th position

const guidanceSettings = {
  [guidanceFirstInvitation]: {
    indexes: [topIndex],
    text: messages.guidanceFirstInvitationText,
    action: messages.guidanceFirstInvitationCta,
    hasAvatar: true,
  },
  [guidanceQuickStart]: {
    indexes: [firstQuickStartIndex, secondQuickStartIndex],
    text: messages.guidanceQuickStartText,
    action: messages.guidanceQuickStartCta,
    hasAvatar: false,
    sortValue: upcomingSortingOrder,
    filterValues: {
      [kindFilterParam]: topicGroupKind,
    },
  },
  [guidancePendingInvitations]: {
    indexes: [topIndex],
    text: messages.guidancePendingInvitationText,
    action: messages.guidancePendingInvitationCta,
    hasAvatar: false,
  },
  [guidanceSearchKeyword]: {
    indexes: [topIndex],
    text: messages.guidanceSearchKeywordText,
    action: messages.guidanceSearchKeywordCta,
  },
  [guidanceSearchInterest]: {
    indexes: [topIndex],
    text: messages.guidanceSearchInterestText,
    action: messages.guidanceSearchInterestCta,
  },
};
export function getGuidanceText(
  label: string, // $FlowIssue
  intl: IntlShape,
  conditionalValues?: Record<string, any>,
): string {
  const guidanceItem = guidanceSettings[label];
  assertObject(guidanceItem, 'guidanceItem');
  return intl.formatMessage(guidanceItem.text, conditionalValues);
}
export function getGuidanceAction(
  label: string, // $FlowIssue
  intl: IntlShape,
  params?: Record<string, any>,
): string {
  const guidanceItem = guidanceSettings[label];
  assertObject(guidanceItem, 'guidanceItem');
  return intl.formatMessage(guidanceItem.action, params || {});
}
export function getGuidanceDescription(
  label: string, // $FlowIssue
  intl: IntlShape,
  params?: Record<string, any>,
): string | null | undefined {
  const guidanceItem = guidanceSettings[label];
  assertObject(guidanceItem, 'guidanceItem');
  if (!guidanceItem.description) return null;
  return intl.formatMessage(guidanceItem.description, params || {});
}
export function getGuidanceIndexes(label: string): Array<number> {
  const guidanceItem = guidanceSettings[label];
  if (!guidanceItem) return [];
  assertObject(guidanceItem, 'guidanceItem');
  return Array.isArray(guidanceItem.indexes) ? guidanceItem.indexes : [];
}
export function hasGuidanceAvatar(label: string): boolean {
  const guidanceItem = guidanceSettings[label];
  assertObject(guidanceItem, 'guidanceItem');
  return guidanceItem.avatar;
}
export function getGuidanceQuickStartFilterValues(): {
  string: string;
} {
  // $FlowIssue
  return guidanceSettings[guidanceQuickStart].filterValues;
}
export function getGuidanceQuickStartSortValue(): string {
  // $FlowIssue
  return guidanceSettings[guidanceQuickStart].sortValue;
}
