import { memo, useState } from 'react';
import type { Node } from 'react';

import { useIntl } from 'react-intl';

import AccessibleModal from 'src/shared/app/base/modal/component/AccessibleModal';
import useClearModalDispatch from 'src/shared/app/base/modal/hook/useClearModalDispatch';
import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import PrimaryButton from 'src/shared/ui/component/button/PrimaryButton';

import messages from 'plugin/virtual-braindate/l10n/virtualBraindateL10n';

import styles from './VirtualBraindateSupportModal.style';

const useStyles = createUseThemeStyles(styles);
type Props = {
  handleClose?: () => void;
};

const VirtualBraindateSupportModal = ({ handleClose }: Props): Node => {
  /*
   |----------------------------------------------------------------------------
   | State
   |----------------------------------------------------------------------------
   */
  const [forceClose, setForceClose] = useState(false);

  /*
   |----------------------------------------------------------------------------
   | Hooks
   |----------------------------------------------------------------------------
   */
  const intl = useIntl();
  const dispatchClearModal = useClearModalDispatch();

  /*
   |----------------------------------------------------------------------------
   | Event Handlers
   |----------------------------------------------------------------------------
   */
  const onCloseClick = () => {
    if (handleClose) {
      handleClose();
    }

    setForceClose(true);
  };

  /*
   |----------------------------------------------------------------------------
   | Classes
   |----------------------------------------------------------------------------
   */
  const classes = useStyles();

  /*
   |----------------------------------------------------------------------------
   | Elements
   |----------------------------------------------------------------------------
   */
  return (
    <AccessibleModal
      contentClass={classes.content}
      overlayClass={classes.overlay}
      label={intl.formatMessage(messages.permissionErrorLabel)}
      forceClose={forceClose}
      handleAfterClose={dispatchClearModal}
    >
      <p className={classes.message}>
        {intl.formatMessage(messages.noSupport)}
      </p>
      <PrimaryButton onClick={onCloseClick}>
        {intl.formatMessage(messages.noSupportOK)}
      </PrimaryButton>
    </AccessibleModal>
  );
};

export default memo<Props>(VirtualBraindateSupportModal);
