import { memo } from 'react';
import type { Node } from 'react';

import classNames from 'classnames';

import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import BaseIcon from 'src/shared/ui/component/icon/BaseIcon';

import svg from './circled-check.svg';
import styles from './CircledCheckIcon.style';

type Props = {
  className?: string;
  title?: string; // Self passed
};
const useStyles = createUseThemeStyles(styles);

const CircledCheckIcon = ({ className, title }: Props): Node => {
  const classes = useStyles();
  // Classes
  const rootClasses = classNames(classes.root, className);
  return <BaseIcon className={rootClasses} svg={svg} title={title} />;
};

export default memo<Props>(CircledCheckIcon);
