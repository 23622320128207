import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

export default {
  button: {
    backgroundColor: 'transparent',
    width: 42,
    height: 42,
    padding: 0,
    position: 'relative',
    '& svg': {
      color: ({ theme }: ThemeFunction<unknown>) => theme.textColor,
      marginTop: '3px !important',
    },
    '&:focus-visible': {
      outline: 'none',
      '&:after': {
        position: 'absolute',
        content: '""',
        top: 5,
        left: 5,
        width: 32,
        height: 32,
        border: ({ theme }: ThemeFunction<unknown>) =>
          `1px solid ${theme.accentColor}`,
        borderRadius: '50%',
      },
    },
  },
};
