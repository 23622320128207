import { matchRoutes } from 'react-router';
import { useLocation } from 'react-router-dom';

import { isFunction } from '@braindate/util/lib/type';

import useReduxState from 'src/shared/app/base/hook/useReduxState';
import { getRoutes } from 'src/shared/app/base/route/registry/routeRegistry';
import { notFoundRoute } from 'src/shared/app/base/route/setting/routeSettings';
import type { RouteData } from 'src/shared/app/base/route/type/routeTypes';

const useRouteMatch = (): RouteData => {
  const location = useLocation();
  const state = useReduxState();
  const route = getRoutes().find(({ path }) => {
    if (!path) return false;
    // If (typeof path === 'function') isn't there, flow doesn't let us use path as a function
    const routePath =
      isFunction(path) && typeof path === 'function' ? path(state) : path;
    return !!matchRoutes(
      [
        {
          path: routePath,
        },
      ],
      location,
    );
  });
  if (!route) return notFoundRoute;
  return route;
};

export default useRouteMatch;
