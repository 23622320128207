import { em } from 'polished';

import { baseFontSize } from '@braindate/ui/lib/token/typography';

import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

export default {
  root: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
  },
  icon: {
    position: 'relative',
    fontSize: `${em(18, baseFontSize)} !important`,
    color: ({
      theme,
      withAccent,
    }: ThemeFunction<{
      withAccent?: boolean;
    }>) => (withAccent ? theme.accentColor : 'inherit'),
  },
};
