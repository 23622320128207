import { assertObject, assertString } from '@braindate/util/lib/assert';

/**
 * Get the query parameters of a url
 * @param  {string} url - Url to get the parameters from
 * @return {Object} Query parameters
 */
export function parseQueryParams(url: string): Record<string, any> {
  assertString(url, 'url');
  const index = url.indexOf('?');

  if (index > -1) {
    const pairs = decodeURIComponent(url.substring(index + 1)).split('&');
    const params = {};

    for (let i = 0; i < pairs.length; i += 1) {
      const pair = pairs[i].split('=');
      const [key, value] = pair;

      if (key) {
        params[key] = typeof value === 'undefined' ? true : value;
      }
    }

    return params;
  }

  return {};
}

/**
 * Get the origin of a url
 * @param  {string} url - Url to get the origin from
 * @return {string} Origin
 */
export function parseOrigin(url: string): string {
  assertString(url, 'url');
  const index = url.indexOf('?');
  return index > -1 ? url.substring(0, index) : url;
}

/**
 * Add query parameters to an existing url
 * @param  {string} url - Url to add the parameters to
 * @param  {Object} params - Query parameters to add
 * @return {string} Serialized url
 */
export function serializeUrl(url: string, params: Record<string, any>): string {
  assertString(url, 'url');
  assertObject(params, 'params');
  const origin = parseOrigin(url);
  const queryParams = { ...parseQueryParams(url), ...params };
  const keys = Object.keys(queryParams);

  if (keys.length) {
    const pairs = keys.reduce(
      (acc, key) => [...acc, `${key}=${encodeURIComponent(queryParams[key])}`],
      [],
    );
    return `${origin}?${pairs.join('&')}`;
  }

  return origin;
}
