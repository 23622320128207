import { useMemo } from 'react';

import { useIntl } from 'react-intl';

import {
  complaintTypeComment,
  complaintTypeInvitation,
  complaintTypeTopic,
} from '@braindate/domain/lib/complaint/setting';
import type { ComplaintContentType } from '@braindate/domain/lib/complaint/type';

import messages from 'src/shared/components/domain/complaints/l10n/complaintsL10n';

const useComplaintText = (contentType: ComplaintContentType) => {
  const intl = useIntl();
  return useMemo(() => {
    return {
      [complaintTypeTopic]: {
        title: intl.formatMessage(messages.reportTopic),
        subtitle: intl.formatMessage(messages.reportTopicSubtitle),
        explanation: intl.formatMessage(messages.reportTopicExplanation),
        success: intl.formatMessage(messages.reportTopicSuccess),
      },
      [complaintTypeInvitation]: {
        title: intl.formatMessage(messages.reportInvitation),
        subtitle: intl.formatMessage(messages.reportInvitationSubtitle),
        explanation: intl.formatMessage(messages.reportInvitationExplanation),
        success: intl.formatMessage(messages.reportInvitationSuccess),
      },
      [complaintTypeComment]: {
        title: intl.formatMessage(messages.reportComment),
        subtitle: intl.formatMessage(messages.reportCommentSubtitle),
        explanation: intl.formatMessage(messages.reportCommentExplanation),
        success: intl.formatMessage(messages.reportCommentSuccess),
      },
    }[contentType];
  }, [contentType]);
};

export default useComplaintText;
