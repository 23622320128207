export default {
  root: {
    width: 15,
    height: 15,
  },
  'root-large': {
    width: 30,
    height: 30,
  },
};
