import type { Topic } from '@braindate/domain/lib/topic/type';

import type { Notification } from 'src/shared/app/notification/type/notificationTypes';

import * as types from './uiNotificationActionTypes';

export function queueNotification(notification: Notification<any>) {
  return {
    type: types.QUEUE_NOTIFICATION,
    notification,
  };
}
export function dismissNotification(notification: string | Notification<any>) {
  return {
    type: types.DISMISS_NOTIFICATION,
    notification,
  };
}
export function dismissNotificationsBundle(
  notifications: Array<Notification<any>>,
) {
  return (dispatch: any) =>
    notifications.map<Notification<any>>((notification) =>
      dispatch(dismissNotification(notification)),
    );
}
export function dismissTopicNotifications(topic: string | Topic) {
  return {
    type: types.DISMISS_TOPIC_NOTIFICATIONS,
    topic,
  };
}
