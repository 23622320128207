export const conversationFormatHelpMeSolve = 'help_me_solve';
export const conversationFormatBrainstorm = 'brainstorm';
export const conversationFormatStorytelling = 'storytelling';
export const conversationFormatAma = 'ama';
export const conversationFormatDiscussion = 'discussion';
export const conversationFormatDeepDive = 'dive';
export const conversationFormats = [
  conversationFormatHelpMeSolve,
  conversationFormatBrainstorm,
  conversationFormatStorytelling,
  conversationFormatAma,
  conversationFormatDiscussion,
  conversationFormatDeepDive,
];
