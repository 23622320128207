import { rem, rgba } from 'polished';

import { midnight, white } from '@braindate/ui/lib/token/palette';
import { baseFontSize } from '@braindate/ui/lib/token/typography';

export default {
  root: {
    position: 'absolute',
    zIndex: 10,
    bottom: 0,
    left: 0,
    fontWeight: 'normal',
    padding: ['2px', '5px'],
    color: white,
    background: rgba(midnight, 0.7),
    fontSize: ({ fontSize }: Record<string, any>) =>
      rem(fontSize, baseFontSize),
    boxShadow: 'rgba(0, 0, 0, 0.25) 0px 2px 2px 0px',
  },
  inner: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  presenting: {
    fontStyle: 'italic',
    paddingRight: '2px',
  },
};
