import { rem } from 'polished';

import { fadeDuration } from '@braindate/ui/lib/token/animation';
import { baseFontSize } from '@braindate/ui/lib/token/typography';

import { antialiasing } from 'src/shared/app/base/style/token/helper';
import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

export default {
  root: {
    extend: antialiasing,
    color: ({ theme }: ThemeFunction<unknown>) => theme.textColor,
    fontSize: rem(15, baseFontSize),
    lineHeight: 18 / 15,
    textDecoration: 'none',
    transition: ['color', fadeDuration],
    '&:hover': {
      color: ({ theme }: ThemeFunction<unknown>) => theme.accentColor,
      textDecoration: 'none',
    },
    '&:active': {
      textDecoration: 'none',
    },
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    // Necessary for transformation to work
    display: 'inline-block',
    position: 'relative',
    marginLeft: rem(8, baseFontSize),
    stroke: ({ theme }: ThemeFunction<unknown>) => theme.accentColor,
    color: ({ theme }: ThemeFunction<unknown>) => theme.accentColor,
    transition: ['transform', '0.2s', 'ease-in-out'],
    '$root:hover &': {
      transform: 'translateX(-3px)',
    },
    '$root:active &': {
      transform: 'translateX(-3px)',
    },
  },
};
