import { memo, useRef } from 'react';
import type { Node } from 'react';

import { useIntl } from 'react-intl';
import { useDebouncedCallback } from 'use-debounce';
import { $Values } from 'utility-types';

import useEventListener from 'src/shared/app/base/component/hook/useEventListener';
import styles from 'src/shared/app/base/component/infiniteScoll/InfiniteScroll.style';
import messages from 'src/shared/app/base/l10n/uiL10n';
import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import Button, { VARIANT } from 'src/shared/ui/component/button/Button';

const defaultThreshold = 250;
export type ManualListConfig = {
  isActive: boolean;
  variant?: $Values<typeof VARIANT>;
  className?: string;
  label?: string;
  align?: 'left' | 'center' | 'right';
};
type Props = {
  children: Node;
  itemsLeft?: number | null;
  threshold?: number;
  onNext?: () => any;
  manualConfig?: ManualListConfig;
  isLazyFetching?: boolean;
  containerRef?: {
    current: HTMLElement | null;
  };
};
const useStyles = createUseThemeStyles(styles);

const InfiniteScroll = ({
  children,
  itemsLeft,
  threshold = defaultThreshold,
  onNext,
  manualConfig = {
    isActive: false,
    variant: VARIANT.PRIMARY,
    align: 'center',
  },
  isLazyFetching,
  containerRef,
}: Props): Node => {
  const classes = useStyles({
    align: manualConfig.align,
  });
  const ref = useRef();
  const intl = useIntl();
  const onWindowScroll = useDebouncedCallback(() => {
    const { current: feedElt } = ref;
    if (manualConfig.isActive) return;

    if (onNext && feedElt instanceof HTMLElement) {
      const { top, height } = feedElt.getBoundingClientRect();
      const y = top + height - window.innerHeight;
      const delta = threshold || 0;

      if (y < delta) {
        onNext();
      }
    }
  }, 100);
  useEventListener('scroll', onWindowScroll, containerRef);
  const manualLoadElt = manualConfig.isActive && onNext && (
    <div className={classes.manualAction}>
      <Button
        onClick={onNext}
        isFetching={isLazyFetching}
        isDisabled={isLazyFetching}
        variant={manualConfig.variant || VARIANT.PRIMARY}
        layoutClass={manualConfig.className}
      >
        {manualConfig.label
          ? `${manualConfig.label} ${itemsLeft ? `(${itemsLeft})` : ''}`
          : intl.formatMessage(
              itemsLeft ? messages.loadMoreItemsLeft : messages.loadMore,
              {
                itemsLeft,
              },
            )}
      </Button>
    </div>
  );
  return (
    <div className={classes.root} ref={ref}>
      {children}
      {manualLoadElt}
    </div>
  );
};

export default memo<Props>(InfiniteScroll);
