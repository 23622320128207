import { createSelector } from '@reduxjs/toolkit';

import type { User } from '@braindate/domain/lib/user/type';
import {
  getUserFirstName,
  getUserLastName,
} from '@braindate/domain/lib/user/util';

import {
  signupAboutForm,
  signupActivationForm,
  signupEmailForm,
} from 'src/shared/app/signup/constant/signupConstants';

/*
|------------------------------------------------------------------------------
| INPUTS
|------------------------------------------------------------------------------
*/
const _segment = (state) => state.ui.signup;

const _isMarkedAsIncomplete = (state) => _segment(state).isIncomplete;

const _isSignupCompleted = (state, user) =>
  user && // Required data
  getUserFirstName(user) &&
  getUserLastName(user) &&
  !_isMarkedAsIncomplete(state);

const _getForm = (state) => _segment(state).form;

/*
|------------------------------------------------------------------------------
| SELECTORS
|------------------------------------------------------------------------------
*/
export const isSignupCompleted: (
  state: Record<string, any>,
  user: User,
) => boolean = createSelector([_isSignupCompleted], (x: any): any => x);
export const isSignupMarkedAsIncomplete: (
  state: Record<string, any>,
) => boolean = createSelector(
  [_isMarkedAsIncomplete],
  (x: boolean): boolean => x,
);
export const isValidationActivationFormShown: (
  state: Record<string, any>,
) => boolean = createSelector(
  [_getForm],
  (form: string): boolean => form === signupActivationForm,
);
export const isValidationEmailFormShown: (
  state: Record<string, any>,
) => boolean = createSelector(
  [_getForm],
  (form: string): boolean => form === signupEmailForm,
);
export const isSignupAboutFormShown: (state: Record<string, any>) => boolean =
  createSelector(
    [_getForm],
    (form: string): boolean => form === signupAboutForm,
  );
