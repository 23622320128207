/* eslint-disable no-console */
import * as Sentry from '@sentry/react';
import ReactGA from 'react-ga4';

import { assertNumber, assertString } from '@braindate/util/lib/assert';

/**
 * Initialize the GA package
 * @param {string} trackingId - Tracking id to initialize with
 * @return {undefined}
 */
export function initializeGAPackage() {
  if (ReactGA.isInitialized) return;
  if (!process.env.TRACKING_ID) return;
  ReactGA.initialize([
    {
      trackingId: process.env.TRACKING_ID,
    },
  ]);
}

/**
 * Set page in GA and send a pageview
 * @param  {string} pathname - Page to track
 * @return {undefined}
 */
export function trackGAPage(pathname: string): void {
  assertString(pathname, 'pathname');

  try {
    initializeGAPackage();
    ReactGA.send({
      hitType: 'pageview',
      page: pathname,
      title: pathname,
    });
  } catch (error) {
    console.error('Call to ReactGA.send has thrown error:', error);
  }
}

/**
 * Send an event to GA
 * @param  {string} category - Category of the event
 * @param  {string} action - Action of the event
 * @param  {any} [label] - Label of the event
 * @param  {number} [value] - Value of the event
 * @param  {boolean} [nonInteraction] - Whether event is a non-interaction event
 * Ref: https://support.google.com/analytics/answer/1033068#NonInteractionEvents
 * @return {undefined}
 */
export function trackGAEvent(
  category: string,
  action: string,
  label?: any,
  value?: number,
  nonInteraction?: boolean,
): void {
  assertString(category, 'category');
  assertString(action, 'action');
  const event = {
    category: 'event',
    eventCategory: category,
    action,
    label: undefined,
    value: undefined,
    nonInteraction: undefined,
  };

  if (label !== null && label !== undefined) {
    event.label = label;
  }

  if (value !== null && value !== undefined) {
    assertNumber(value, 'value');
    event.value = value;
  }

  if (typeof nonInteraction === 'boolean') {
    event.nonInteraction = nonInteraction;
  }

  try {
    initializeGAPackage();
    ReactGA.event(event);
  } catch (error) {
    Sentry.captureException(error);
  }
}
export function trackGAUserTiming(
  category: string,
  variable: string,
  value: number,
): void {
  assertString(category, 'category');
  assertString(variable, 'variable');
  assertNumber(value, 'value');
  initializeGAPackage();
  ReactGA.event({
    category,
    action: variable,
    label: 'timing',
    value,
    nonInteraction: true,
  });
}
