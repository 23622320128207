import { OutlinedFlag } from '@mui/icons-material';
import AddCommentOutlinedIcon from '@mui/icons-material/AddCommentOutlined';
import GraphIcon from '@mui/icons-material/BarChart';

import type { RouteData } from 'src/shared/app/base/route/type/routeTypes';
import messages from 'src/shared/app/notification/l10n/notificationL10n';
import CancelIcon from 'src/shared/ui/component/icon/Cancelicon';
import CheerIcon from 'src/shared/ui/component/icon/CheerIcon';
import CircledCheckIcon from 'src/shared/ui/component/icon/CircledCheckIcon';
import ClockIcon from 'src/shared/ui/component/icon/ClockIcon';
import InvitationIcon from 'src/shared/ui/component/icon/InvitationIcon';
import MessageIcon from 'src/shared/ui/component/icon/MessageIcon';
import RecommendIcon from 'src/shared/ui/component/icon/RecommendIcon';
import UserIcon from 'src/shared/ui/component/icon/UserIcon';

export type Notification<T = any> = {
  id: string;
  level: 'error' | 'success' | 'info' | 'warning';
  type: 'takeOver' | 'top' | 'secondary' | 'modal' | 'vanilla';
  componentKey?: string;
  data?: T;
  exclude?: Array<RouteData | string>;
  include?: Array<RouteData | string>;
  active?: boolean;
  bundleType?: string;
};
export const notificationsTemplateStringsMap = {
  braindate_message: 'braindate-message',
  group_braindate_message: 'group-braindate-message',
  braindate_invitation_received: 'braindate-invitation-received',
  braindate_invitation_changed: 'braindate-invitation-changed',
  braindate_invitation_declined: 'braindate-invitation-declined',
  braindate_confirmed: 'braindate-confirmed',
  group_braindate_full: 'group-braindate-full',
  group_braindate_join: 'group-braindate-join',
  fishbowl_braindate_join: 'fishbowl-braindate-join',
  group_braindate_leave: 'group-braindate-leave',
  fishbowl_braindate_leave: 'fishbowl-braindate-leave',
  braindate_cancelled: 'braindate-cancelled',
  group_braindate_cancelled: 'group-braindate-cancelled',
  fishbowl_braindate_cancelled: 'fishbowl-braindate-cancelled',
  braindate_early_reminder: 'braindate-early-reminder',
  group_braindate_early_reminder: 'group-braindate-early-reminder',
  fishbowl_braindate_early_reminder: 'fishbowl-braindate-early-reminder',
  topic_recommendation: 'topic-recommendation',
  user_recommendation: 'user-recommendation',
  braindate_tomorrow_agenda: 'braindate-tomorrow-agenda',
  cheer_received_cheer: 'cheers-received-cheer',
  braindate_checkin_reminder: 'braindate-checkin-reminder',
  group_braindate_checkin_reminder: 'group-braindate-checkin-reminder',
  export_ready: 'export-ready',
  braindate_rescheduled: 'braindate-rescheduled',
  topic_comment: 'topic-comment',
  // Moderation
  moderation_complaint_dismissed: 'moderation-complaint-dismissed',
  moderation_complaint_accepted: 'moderation-complaint-accepted',
  moderation_accepted_complaint_created:
    'moderation-accepted-complaint-created',
  moderation_accepted_complaint_deleted:
    'moderation-accepted-complaint-deleted',
};
export const notificationsMapTemplatesSettings = {
  [notificationsTemplateStringsMap.braindate_message]: {
    title: messages.braindate_message,
    icon: <MessageIcon />,
  },
  [notificationsTemplateStringsMap.group_braindate_message]: {
    title: messages.group_braindate_message,
    icon: <MessageIcon />,
  },
  [notificationsTemplateStringsMap.braindate_invitation_received]: {
    title: messages.braindate_invitation_received,
    icon: <InvitationIcon />,
  },
  [notificationsTemplateStringsMap.braindate_invitation_changed]: {
    title: messages.braindate_invitation_changed,
    icon: <ClockIcon />,
  },
  [notificationsTemplateStringsMap.braindate_invitation_declined]: {
    title: messages.braindate_invitation_declined,
    icon: <CancelIcon />,
  },
  [notificationsTemplateStringsMap.braindate_confirmed]: {
    title: messages.braindate_confirmed,
    icon: <CircledCheckIcon />,
  },
  [notificationsTemplateStringsMap.group_braindate_full]: {
    title: messages.group_braindate_full,
    icon: <UserIcon />,
  },
  [notificationsTemplateStringsMap.group_braindate_join]: {
    title: messages.group_braindate_join,
    icon: <UserIcon />,
  },
  [notificationsTemplateStringsMap.fishbowl_braindate_join]: {
    title: messages.fishbowl_braindate_join,
    icon: <UserIcon />,
  },
  [notificationsTemplateStringsMap.group_braindate_leave]: {
    title: messages.group_braindate_leave,
    icon: <UserIcon />,
  },
  [notificationsTemplateStringsMap.fishbowl_braindate_leave]: {
    title: messages.fishbowl_braindate_leave,
    icon: <UserIcon />,
  },
  [notificationsTemplateStringsMap.braindate_cancelled]: {
    title: messages.braindate_cancelled,
    icon: <CancelIcon />,
  },
  [notificationsTemplateStringsMap.group_braindate_cancelled]: {
    title: messages.group_braindate_cancelled,
    icon: <CancelIcon />,
  },
  [notificationsTemplateStringsMap.fishbowl_braindate_cancelled]: {
    title: messages.fishbowl_braindate_cancelled,
    icon: <CancelIcon />,
  },
  [notificationsTemplateStringsMap.braindate_checkin_reminder]: {
    title: messages.braindate_early_reminder,
    icon: <ClockIcon />,
  },
  [notificationsTemplateStringsMap.group_braindate_checkin_reminder]: {
    title: messages.braindate_early_reminder,
    icon: <ClockIcon />,
  },
  [notificationsTemplateStringsMap.export_ready]: {
    title: messages.exportReadyTitle,
    icon: <GraphIcon />,
  },
  [notificationsTemplateStringsMap.braindate_early_reminder]: {
    title: messages.braindate_early_reminder,
    icon: <ClockIcon />,
  },
  [notificationsTemplateStringsMap.group_braindate_early_reminder]: {
    title: messages.group_braindate_early_reminder,
    icon: <ClockIcon />,
  },
  [notificationsTemplateStringsMap.fishbowl_braindate_early_reminder]: {
    title: messages.fishbowl_braindate_early_reminder,
    icon: <ClockIcon />,
  },
  [notificationsTemplateStringsMap.topic_recommendation]: {
    title: messages.topic_recommendation,
    icon: <RecommendIcon />,
  },
  [notificationsTemplateStringsMap.user_recommendation]: {
    title: messages.user_recommendation,
    icon: <RecommendIcon />,
  },
  [notificationsTemplateStringsMap.braindate_rescheduled]: {
    title: messages.braindate_invitation_changed,
    icon: <ClockIcon />,
  },
  [notificationsTemplateStringsMap.braindate_tomorrow_agenda]: {
    title: messages.braindate_tomorrow_agenda,
    icon: <ClockIcon />,
  },
  [notificationsTemplateStringsMap.cheer_received_cheer]: {
    title: messages.cheer,
    icon: <CheerIcon />,
  },
  [notificationsTemplateStringsMap.topic_comment]: {
    title: messages.topic_comment,
    icon: <AddCommentOutlinedIcon />,
  },
  [notificationsTemplateStringsMap.moderation_complaint_dismissed]: {
    title: messages.reportUpdate,
    icon: <OutlinedFlag />,
  },
  [notificationsTemplateStringsMap.moderation_complaint_accepted]: {
    title: messages.topicRemoved,
    icon: <OutlinedFlag />,
  },
  [notificationsTemplateStringsMap.moderation_accepted_complaint_created]: {
    title: messages.reportUpdate,
    icon: <OutlinedFlag />,
  },
  [notificationsTemplateStringsMap.moderation_accepted_complaint_deleted]: {
    title: messages.reportUpdate,
    icon: <OutlinedFlag />,
  },
};
