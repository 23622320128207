import { lap } from '@braindate/ui/lib/token/mediaQuery';

import { mobileBottomNavElevation } from 'src/shared/app/base/style/token/elevation';
import { horizontalSmallDeviceBreakpoint } from 'src/shared/app/base/style/util/responsive';
import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';
import { mobileBarHeight } from 'src/shared/app/nav/style/nav.style';

export default {
  spacer: {
    height: mobileBarHeight,
    [lap]: {
      display: 'none',
    },
  },
  root: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100vw',
    height: mobileBarHeight,
    zIndex: mobileBottomNavElevation,
    borderTop: ({ theme }: ThemeFunction<unknown>) =>
      `1px solid ${theme.accentColor}`,
    backgroundColor: ({ theme }: ThemeFunction<unknown>) => theme.colorBase,
    [lap]: {
      display: 'none',
    },
    [horizontalSmallDeviceBreakpoint]: {
      height: 40,
    },
  },
  inner: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
};
