import { useContext } from 'react';

import useFeatureDecisions from 'src/shared/app/feature/hook/useFeatureDecisions';
import { HideOnScrollContext } from 'src/shared/ui/context/HideOnScrollContext';

const useHideOnScroll = (ignoreFeatureFlag: boolean = false): boolean => {
  const { isPageScrolled } = useContext(HideOnScrollContext);
  const { shouldHideTopBarOnScroll } = useFeatureDecisions();

  // This is only for AB Testing for the isDrawerMenuEnabled feature flag (When feature flag is off)
  // https://e180.atlassian.net/browse/PAX-4952
  if (shouldHideTopBarOnScroll && !ignoreFeatureFlag) {
    return isPageScrolled;
  }

  return false;
};

export default useHideOnScroll;
