import { memo, useState } from 'react';
import type { Node } from 'react';

import { useIntl } from 'react-intl';

import Markdown from 'src/shared/app/base/component/text/Markdown';
import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';
import EventBranding from 'src/shared/app/nav/component/branding/event/EventBranding';
import messages from 'src/shared/app/onboarding/l10n/onboardingL10n';
import useEventLandingCustomization from 'src/shared/domain/event/hook/useEventLandingCustomization';
import HeroText from 'src/shared/ui/component/text/HeroText';

import VirtualBraindateSupportModal from 'plugin/virtual-braindate/component/modal/VirtualBraindateSupportModal';

import styles from './IntroScreen.style';
import OnboardingIntroBackground from './OnboardingIntroBackground';

const useStyles = createUseThemeStyles(styles);

const IntroScreenDumb = (): Node => {
  const intl = useIntl();
  const {
    eventName,
    customTitle,
    customSubtitle,
    customTextColor,
    bannerOverlayColor,
    customBackgroundImage,
  } = useEventLandingCustomization();
  const [isSupportModalShown, setSupportModalShown] = useState(false);
  const classes = useStyles({
    titleColor: customTextColor,
    bannerOverlayColor,
    isTransparent: bannerOverlayColor && bannerOverlayColor === '#00000000',
  });
  const supportModal = isSupportModalShown && (
    <VirtualBraindateSupportModal
      handleClose={() => setSupportModalShown(false)}
    />
  );
  const title = customTitle || intl.formatMessage(messages.titleGeneric);
  const subtitle = customSubtitle || intl.formatMessage(messages.subtitle);
  return (
    <>
      {supportModal}

      <div className={classes.root}>
        <OnboardingIntroBackground backgroundImage={customBackgroundImage} />

        <div className={classes.branding}>
          <EventBranding
            classes={{
              logo: classes.logo,
            }}
          />
        </div>

        <div className={classes.body}>
          <div className={classes.content}>
            <HeroText as="h1" layoutClass={classes.hero}>
              <Markdown>{title}</Markdown>{' '}
            </HeroText>

            <span className={classes.name}>{eventName}</span>

            <HeroText as="div" small layoutClass={classes.heroSubTitle}>
              <Markdown>{subtitle}</Markdown>
            </HeroText>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(IntroScreenDumb);
