import * as types from 'src/shared/app/onboarding/action/uiOnboardingActionTypes';
import { createReducer } from 'src/shared/core/util/reducerUtils';

const initialState = {
  isSplashSeen: false,
  isCompletedOnDevice: false,
  isTimezoneWarningShown: false,
};

const markSplashAsSeen = (state: Record<string, any>) => ({
  ...state,
  isSplashSeen: true,
});

const completeOnDevice = (state: Record<string, any>) => ({
  ...state,
  isCompletedOnDevice: true,
});

const resetOnDevice = (state: Record<string, any>) => ({
  ...state,
  isCompletedOnDevice: false,
});

const markTimezoneWarningAsSeen = (state: Record<string, any>) => ({
  ...state,
  isTimezoneWarningShown: true,
});

export default createReducer(initialState, {
  [types.MARK_SPLASH_AS_SEEN]: markSplashAsSeen,
  [types.COMPLETE_ONBOARDING_ON_DEVICE]: completeOnDevice,
  [types.RESET_ONBOARDING_ON_DEVICE]: resetOnDevice,
  [types.MARK_TIMEZONE_WARNING_AS_SHOWN]: markTimezoneWarningAsSeen,
});
