export const SET_AUTHENTICATION_TOKEN = 'SET_AUTHENTICATION_TOKEN';
export const UNSET_AUTHENTICATION_TOKEN = 'UNSET_AUTHENTICATION_TOKEN';
export const SET_AUTHENTICATION_EMAIL = 'SET_AUTHENTICATION_EMAIL';
export const UNSET_AUTHENTICATION_EMAIL = 'UNSET_AUTHENTICATION_EMAIL';
export const SET_AUTHENTICATION_UID = 'SET_AUTHENTICATION_UID';
export const UNSET_AUTHENTICATION_UID = 'UNSET_AUTHENTICATION_UID';
export const SET_AUTHENTICATION_CID = 'SET_AUTHENTICATION_CID';
export const UNSET_AUTHENTICATION_CID = 'UNSET_AUTHENTICATION_CID';
export const SET_AUTHENTICATION_RESET_TOKEN = 'SET_AUTHENTICATION_RESET_TOKEN';
export const UNSET_AUTHENTICATION_RESET_TOKEN =
  'UNSET_AUTHENTICATION_RESET_TOKEN';
export const VALIDATE_EMAIL_REQUEST = 'VALIDATE_EMAIL_REQUEST';
export const VALIDATE_EMAIL_SUCCESS = 'VALIDATE_EMAIL_SUCCESS';
export const VALIDATE_EMAIL_FAILURE = 'VALIDATE_EMAIL_FAILURE';
export const VERIFY_EMAIL_REQUEST = 'VERIFY_EMAIL_REQUEST';
export const VERIFY_EMAIL_SUCCESS = 'VERIFY_EMAIL_SUCCESS';
export const VERIFY_EMAIL_FAILURE = 'VERIFY_EMAIL_FAILURE';
export const CREATE_PASSWORD_REQUEST = 'CREATE_PASSWORD_REQUEST';
export const CREATE_PASSWORD_SUCCESS = 'CREATE_PASSWORD_SUCCESS';
export const CREATE_PASSWORD_FAILURE = 'CREATE_PASSWORD_FAILURE';
export const SEND_PASSWORD_LINK_REQUEST = 'SEND_PASSWORD_LINK_REQUEST';
export const SEND_PASSWORD_LINK_SUCCESS = 'SEND_PASSWORD_LINK_SUCCESS';
export const SEND_PASSWORD_LINK_FAILURE = 'SEND_PASSWORD_LINK_FAILURE';
export const RESET_PASSWORD_LINK_SENT = 'RESET_PASSWORD_LINK_SENT';
export const VALIDATE_PASSWORD_LINK_REQUEST = 'VALIDATE_PASSWORD_LINK_REQUEST';
export const VALIDATE_PASSWORD_LINK_SUCCESS = 'VALIDATE_PASSWORD_LINK_SUCCESS';
export const VALIDATE_PASSWORD_LINK_FAILURE = 'VALIDATE_PASSWORD_LINK_FAILURE';
export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const SAVE_INITIAL_URL = 'SAVE_INITIAL_URL';
export const RESET_INITIAL_URL = 'RESET_INITIAL_URL';
export const SET_LOGGED_IN_WITH_BOS = 'SET_LOGGED_IN_WITH_BOS';
