import { em } from 'polished';

import { baseFontSize } from '@braindate/ui/lib/token/typography';

import { borderRadius } from 'src/shared/app/base/style/token/box';
import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

export default {
  root: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: em(30, baseFontSize),
    border: 'dashed 1px',
    borderColor: ({ theme }: ThemeFunction<unknown>) => theme.accentColor,
    borderRadius,
  },
};
