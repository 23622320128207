import { em } from 'polished';

import { tablet } from '@braindate/ui/lib/token/mediaQuery';
import { baseFontSize } from '@braindate/ui/lib/token/typography';

import type { ThemeFunction } from 'src/shared/app/base/types/themeTypes';

type ThemeProps = ThemeFunction<{
  gradient?: string;
}>;
export default {
  root: {
    position: 'relative',
    width: '100%',
    height: 115,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: '14px',
    borderRadius: 8,
    padding: [0, 12],
    fontSize: em(12, baseFontSize),
    textAlign: 'center',
    color: ({ theme }: ThemeProps) => theme.textColorReverse,
    background: ({ gradient }: ThemeProps) => gradient,
    '& svg': {
      width: 56,
      height: 56,
    },
  },
  chip: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
    [tablet]: {
      width: 'max-content',
      display: 'block',
      position: 'absolute',
      top: 12,
      left: 12,
    },
  },
};
